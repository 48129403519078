import React, { useCallback, useEffect, useRef } from 'react';
import { PolygonProps, Polygon as GPolygon } from '@react-google-maps/api';

interface Props<T> extends PolygonProps {
  value?: T;
  color: string;
  onEdit?: (paths: google.maps.LatLngLiteral[]) => void;
  onSelect?: (value?: T) => void;
  index: number;
  // paths: { lat: number; lng: number }[];
  // editable?: boolean;
}

export const Polygon = <T,>({
  onEdit = () => {
    return;
  },
  onSelect = () => {
    return;
  },
  value,
  onClick = () => {
    return;
  },
  ...props
}: Props<T>) => {
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);

  const handleEdit = useCallback(() => {
    if (polygonRef.current) {
      // const nextPath: CoordsBounds = polygonRef.current
      //   .getPath()
      //   .getArray()
      //   .map((latLng) => [latLng.lat(), latLng.lng()]);

      onEdit(
        polygonRef.current
          .getPath()
          .getArray()
          .map<google.maps.LatLngLiteral>((latLng) => latLng.toJSON()),
      );
    }
    console.log('handleEdit');
  }, [onEdit]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      // console.log(polygon)
      if (!polygon) return;

      polygonRef.current = polygon;

      polygon.addListener('bounds_changed', () => {
        handleEdit();
      });

      // const path = polygon?.getPath();
      // listenersRef?.current.push(
      //   path?.addListener('set_at', handleEdit),
      //   path?.addListener('insert_at', handleEdit),
      //   path?.addListener('remove_at', handleEdit),
      // );
    },
    [onEdit],
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis?.remove());
    polygonRef.current = null;
  }, []);

  const handleClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (onSelect) onSelect(value);
      if (onClick) onClick(event);
    },
    [onSelect, value, onClick],
  );

  useEffect(() => {
    return onUnmount;
  }, [onUnmount]);

  return (
    <GPolygon
      {...props}
      // editable
      // draggable
      onClick={handleClick}
      onMouseUp={handleEdit}
      onDragEnd={handleEdit}
      onLoad={onLoad}
      visible
      options={{
        fillColor: props.color,
        strokeColor: props.color,
        fillOpacity: 0.4,
        zIndex: 2000000000 + props.index + 1 || 1,
      }}
    />
  );
};
