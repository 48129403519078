import React from 'react';
import { COLORS, FONT_FAMILY } from '~/constants/styles';

interface Props {
  size?: number;
  family?: FONT_FAMILY;
  color?: COLORS;
  className: string;
  children?: React.ReactNode;
}

export default class Text extends React.PureComponent<Props, {}> {
  static defaultProps: Props = {
    color: COLORS.DARK,
    size: 16,
    family: FONT_FAMILY.REGULAR,
    className: '',
  };
  render() {
    const { size, color, family, className } = this.props;

    const classNames = [];
    if (size) classNames.push(`text-size-${size}`);
    if (color) classNames.push(`color-${color}`);
    if (family) classNames.push(`family-${family}`);
    if (className) classNames.push(className);

    return <p className={classNames.join(' ')}>{this.props.children}</p>;
  }
}
