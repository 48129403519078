import './style.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Log from './containers/Logs/Log';
import { localesStore, dspStore, logStore } from '~/mobx';
import { Table } from '~/view/components/Table/Table';
import Headers from '~/view/components/Headers/Headers';
import HeaderTitle from '~/view/components/Headers/components/HeaderTitle/HeaderTitle';
import { RouterComponentProps } from '~/types/main';
import { withRouter } from '~/utils';

type IProps = RouterComponentProps<{ id?: string }, { type: string }>;

class LogScreen extends Component<IProps, {}> {
  constructor(props: IProps, context: any) {
    super(props, context);
    if (props.router.params.id) {
      const id = props.router.params.id;
      logStore.setDspId(id);
      if (!dspStore.dsp) {
        dspStore.getDsp(id);
      }
    }
  }

  render() {
    if (!dspStore.dsp) {
      return null;
    }

    return (
      <div className="d-flex flex-column full-width">
        <Headers>
          <HeaderTitle
            title={localesStore.t('dsp_profile_log_header', { dsp_name: dspStore.dsp?.provider })}
            backToDspType={(this.props.router.location.state as { type: string })?.type || 'all'}
            backToDspId={Number(this.props.router.params.id)}
            goBackButton={true}
          />
        </Headers>
        <div className="d-flex flex-column flex-grow-1 pt-6">
          <Table>
            <Log />
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(LogScreen));
