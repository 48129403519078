import React, { ReactElement } from 'react';
import Icon from '~/view/components/Controls/Icon/Icon';
import styles from '~/constants/styles';
import { ICONS } from '~/constants/icons';
import './style.scss';

interface ISpinnerProps {
  style?: React.CSSProperties;
  className?: string;
  iconClassName?: string;
  size?: number;
  color?: string;
  container?: boolean;
  onCancel?: () => void;
}

const Spinner = ({ className, iconClassName = '', size = 24, color = '#ffffff', container = false, onCancel }: ISpinnerProps): ReactElement => {
  return (
    <div className={className} onClick={onCancel}>
      <div
        className={`spinner my-auto d-flex ${container ? 'container' : ''} ${onCancel ? 'cancelable' : ''}`}
        style={{ width: size + 6, height: size + 6 }}>
        <Icon
          className={`spinner-icon ${iconClassName}`}
          style={{
            color,
            position: 'absolute',
            //  left: `calc(50% - ${(size + 6) / 2}px)`, top: `calc(50% - ${(size + 6) / 2}px)`
          }}
          size={size}
          name={ICONS.IcLoader}
        />
        {onCancel ? <Icon name={ICONS.Close} size={10} className={'cancel-icon'} color={styles.colors.gray2} /> : null}
      </div>
    </div>
  );
};

export default Spinner;
