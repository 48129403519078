import React, { Component } from 'react';
import ButtonOld from '~/view/components/Controls/ButtonOld/ButtonOld';
import Modal from '~/view/components/Controls/Modal/Modal';
import { localesStore } from '~/mobx';
import { modalStore } from '~/mobx';
import ModalBackground from '~/view/components/Controls/Modal/ModalBackground/ModalBackground';
import ModalContent from '~/view/components/Controls/Modal/ModalContent/ModalContent';
import ModalFooter from '~/view/components/Controls/Modal/ModalFooter/ModalFooter';
import ModalHeader from '~/view/components/Controls/Modal/ModalHeader/ModalHeader';
import './style.scss';

export default class TestModal extends Component<{}, {}> {
  closeModal = () => {
    modalStore.closeTestModal();
  };

  render() {
    return (
      <Modal className="customers-modal" isOpen={true} onRequestClose={this.closeModal} withCloseIcon>
        <ModalBackground>
          <ModalHeader title={localesStore.t('hello', { name: 'Ivan | Vlad' })} />
          <ModalContent>Modal Content</ModalContent>
          <ModalFooter>
            <ButtonOld onClick={this.closeModal} className={'customers-modal-save-btn'} title={localesStore.t('save')} />
          </ModalFooter>
        </ModalBackground>
      </Modal>
    );
  }
}
