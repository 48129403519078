import { action, observable, makeObservable, flow } from 'mobx';
import client from '~/services/client';
import { dnfOrganizationCast, IDNFOrganization } from '~/types/dnf';
import { LoadingStatus } from '~/types/main';

interface GetOrganizationResponse {
  status: string;
  data: {
    id: number;
    name: string;
    is_opted_in: boolean;
  }[];
}

interface GetCutoffTimenResponse {
  status: string;
  data: {
    cutoff_time: number;
  };
}

class DriverNotFoundStore {
  constructor() {
    makeObservable(this, {
      organizations: observable,
      cutoffTime: observable,
      organizationsStatus: observable,
      cutoffTimeStatus: observable,
      getOrganizations: action,
      setOrganizations: action,
      setOrganization: action,
    });
  }

  public organizations: IDNFOrganization[] = [];
  public cutoffTime = 0;
  public organizationsStatus: LoadingStatus = LoadingStatus.Init;
  public cutoffTimeStatus: LoadingStatus = LoadingStatus.Init;

  public getOrganizations = flow(function* (this: DriverNotFoundStore) {
    try {
      this.organizationsStatus = LoadingStatus.Loading;

      const { data }: GetOrganizationResponse = yield client.get('/v1/organizations');

      this.organizations = data.map<IDNFOrganization>(dnfOrganizationCast);

      this.organizationsStatus = LoadingStatus.Success;
    } catch (error) {
      this.organizationsStatus = LoadingStatus.Error;
    }
  }).bind(this);

  public setOrganizations = flow(function* (this: DriverNotFoundStore, isOptedIn: boolean) {
    try {
      this.organizationsStatus = LoadingStatus.Loading;

      yield client.post('/v1/organizations/set_opted_in', { is_opted_in: isOptedIn });
      this.organizations = this.organizations.map((organization) => ({ ...organization, isOptedIn }));

      this.organizationsStatus = LoadingStatus.Success;
    } catch (error) {
      this.organizationsStatus = LoadingStatus.Error;
    }
  }).bind(this);

  public setOrganization = flow(function* (this: DriverNotFoundStore, organization: IDNFOrganization, isOptedIn: boolean) {
    try {
      this.organizationsStatus = LoadingStatus.Loading;

      yield client.post(`/v1/organizations/${organization.id}`, { is_opted_in: isOptedIn });
      this.organizations[this.organizations.findIndex(({ id }) => organization.id === id)].isOptedIn = isOptedIn;

      this.organizationsStatus = LoadingStatus.Success;
    } catch (error) {
      this.organizationsStatus = LoadingStatus.Error;
    }
  }).bind(this);

  public getCutoffTime = flow(function* (this: DriverNotFoundStore) {
    try {
      this.cutoffTimeStatus = LoadingStatus.Loading;

      const { data }: GetCutoffTimenResponse = yield client.get('/v1/settings/cutoff_time');
      this.cutoffTime = data.cutoff_time;

      this.cutoffTimeStatus = LoadingStatus.Success;
    } catch (error) {
      this.cutoffTimeStatus = LoadingStatus.Error;
    }
  }).bind(this);

  public setCutoffTime = flow(function* (this: DriverNotFoundStore, cutoffTime) {
    try {
      this.cutoffTimeStatus = LoadingStatus.Loading;

      yield client.post('/v1/settings/cutoff_time', { cutoff_time: cutoffTime });
      this.cutoffTime = cutoffTime;

      this.cutoffTimeStatus = LoadingStatus.Success;
    } catch (error) {
      this.cutoffTimeStatus = LoadingStatus.Error;
    }
  }).bind(this);
}

const driverNotFoundStore = new DriverNotFoundStore();

export default driverNotFoundStore;
