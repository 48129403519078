import moment, { Duration } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Label from '~/view/components/Label/Label';
import './style.scss';
import { localesStore } from '~/mobx';

const GAP_TO_STORE = 15;
const GAP_TO_CUSTOMER = 30;

export enum TIMER_TYPE {
  TO_STORE = 1,
  TO_CUSTOMER = 2,
}

type PropsTimer = {
  type: TIMER_TYPE;
  startAt: string | null;
  endAt: string | null;
  withNa: boolean;
  className: string;
};

type PropsRunTimer = {
  text: string;
  startAt: string;
  criticalTime: number;
  stop: boolean;
  className: string;
};

type PropsStopedTimer = {
  text: string;
  startAt: string;
  endAt: string;
  criticalTime: number;
  className: string;
};

const getColor = (duration: Duration, time: number) => {
  if (duration.asMinutes() < time) {
    return 'green';
  }

  return 'red';
};

const getTime = (duration: Duration): string => {
  if (duration.asHours() >= 24) {
    return '23:59:59';
  }

  return moment.utc(duration.abs().asMilliseconds()).format(duration.asMinutes() > 60 ? 'HH:mm:ss' : 'mm:ss');
};

const label = (text: string, color: string, className = '') => {
  return <Label text={text} className={`due-timer text-size-12 family-semibold letter-spacing-0 ${className}`} color={color} />;
};

const RunTimer = ({ text, startAt, criticalTime, stop, className }: PropsRunTimer) => {
  const startTime = moment(startAt).format('YYYY-MM-DD HH:mm:ss');
  const [now, setNow] = useState<moment.Moment>(moment());
  const dueTimeMoment = moment(startTime);
  const diff = now.diff(dueTimeMoment);
  const duration = useMemo(() => moment.duration(diff), [diff]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!stop) {
      interval = setInterval(() => setNow(moment()), 1000);
    }

    if (interval && stop) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [stop]);

  return label(`${text} ${getTime(duration)}`, getColor(duration, criticalTime), className);
};

const StopedTimer = ({ text, startAt, endAt, criticalTime, className }: PropsStopedTimer) => {
  const startTime = moment(startAt);
  const endTime = moment(endAt);

  const diff = endTime.diff(startTime);
  const duration = moment.duration(diff);

  return label(`${text} ${getTime(duration)}`, getColor(duration, criticalTime), className);
};

const Na = (text: string, className: string) => {
  return label(`${text} ${localesStore.t('not_available')}`, 'gray', className);
};

const Timer = ({ type, startAt, endAt, withNa, className }: PropsTimer) => {
  let text = localesStore.t('to_store_timer');
  let criticalTime = GAP_TO_STORE;

  if (type === TIMER_TYPE.TO_CUSTOMER) {
    text = localesStore.t('to_customer_timer');
    criticalTime = GAP_TO_CUSTOMER;
  }

  if (startAt !== null && endAt === null) {
    return RunTimer({ text, startAt, criticalTime, stop: false, className });
  }

  if (startAt !== null && endAt !== null) {
    return StopedTimer({ text, startAt, endAt, criticalTime, className });
  }

  if (withNa === true) {
    return Na(text, className);
  }

  return null;
};

export default Timer;
