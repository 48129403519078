import { localesStore, messagesStore } from '~/mobx';

export const leaveWithoutSaving = (onPress?: () => void) => {
  messagesStore.addMessage({
    type: 'modal',
    title: localesStore.t('leave_without_saving_header'),
    message: localesStore.t('leave_without_saving_body'),
    contentClassName: 'message-popup-content_small',
    options: [
      { text: localesStore.t('leave'), onPress, appearance: 'primary' },
      { text: localesStore.t('stay'), textClassName: 'color-dark', appearance: 'tertiary' },
    ],
  });
};

export const confirmDelete = (message: string, onPress?: () => void, title = 'delete_zone') => {
  if (!onPress || typeof onPress !== 'function') return;
  messagesStore.addMessage({
    type: 'modal',
    title: localesStore.t(title),
    message: localesStore.t(message),
    contentClassName: 'message-popup-content_small',
    options: [
      { text: localesStore.t('delete'), onPress, appearance: 'destructive-primary' },
      { text: localesStore.t('cancel_button'), textClassName: 'color-dark', appearance: 'tertiary' },
    ],
  });
};
