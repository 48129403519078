import { Icons } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import userStore from '~/mobx/userStore/userStore';
import Icon from '~/view/components/Controls/Icon/Icon';

type Props = {
  url?: string | null;
  size?: number;
  permissions?: string[];
  roles?: number[];
};

const ExternalOpenButton = ({ url = null, size = 20, permissions = [], roles = [] }: Props) => {
  if (url === null) {
    return null;
  }

  if (!userStore.hasPermissions(permissions) || !userStore.hasRole(roles)) {
    return null;
  }

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return <Icon name={Icons.IcLinkAway} size={size} color={'#429eff'} className={'cursor-pointer'} onPress={handleClick} />;
};

export default ExternalOpenButton;
