import React, { memo, useCallback } from 'react';
import { localesStore } from '~/mobx';
import { useDebouncedCallback } from '~/utils';
import TimePicker from '~/view/components/Controls/TimePicker/TimePicker';
import Text from '~/view/components/Typo/Text/Text';
import './style.scss';

interface IProps {
  onChange: (from: Date, to: Date) => void;
  timeFrom: Date;
  timeTo: Date;
}

export const ColumnTimeFilter = memo(({ onChange, timeTo, timeFrom }: IProps) => {
  const debouncedHandleChange = useDebouncedCallback((_timeFrom, _timeTo) => {
    onChange(_timeFrom, _timeTo);
  }, 1000);

  const handleChangeTimeFrom = useCallback(
    (time: Date) => {
      debouncedHandleChange(time, timeTo);
    },
    [debouncedHandleChange, timeTo],
  );

  const handleChangeTimeTo = useCallback(
    (time: Date) => {
      debouncedHandleChange(timeFrom, time);
    },
    [debouncedHandleChange, timeFrom],
  );

  return (
    <div className="time-dropdown-modal d-flex flex-row">
      <div className="d-flex flex-column">
        <Text size={18} className="color-gray2">
          {localesStore.t('delivery_from')}
        </Text>
        <TimePicker className="pt-2" onTimeChanged={handleChangeTimeFrom} date={timeFrom} />
      </div>
      <div className="d-flex flex-column pl-4">
        <Text size={18} className="color-gray2">
          {localesStore.t('delivery_to')}
        </Text>
        <TimePicker className="pt-2" onTimeChanged={handleChangeTimeTo} date={timeTo} />
      </div>
    </div>
  );
});
