import moment from 'moment';
import React, { ChangeEvent, Component } from 'react';
import TabPicker from '~/view/components/TabPicker/TabPicker';
import TimeField from '~/view/components/Controls/TimeField/TimeField';
import { localesStore } from '~/mobx';
import './style.scss';

interface Props {
  date: Date;
  className?: string;
  disabled?: boolean;
  onTimeChanged: (time: Date) => void;
}

export enum TIME_FORMAT {
  AM = 'AM',
  PM = 'PM',
}

export interface ITimePickerState {
  format: TIME_FORMAT;
  time: string;
  visible: boolean;
}

export default class TimePicker extends Component<Props, ITimePickerState> {
  state: ITimePickerState = {
    format: Number(moment(this.props.date).format('HH')) >= 12 ? TIME_FORMAT.PM : TIME_FORMAT.AM,
    time: moment(this.props.date).format('hh:mm'),
    visible: false,
  };

  onTimeChanged = () => {
    this.props.onTimeChanged(moment(`${this.state.time} ${this.state.format}`, 'hh:mm A').toDate());
  };

  changeTime = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ time: event.target.value }, this.onTimeChanged);
  };

  changeTimeFormat = (format: TIME_FORMAT) => {
    this.setState({ format }, this.onTimeChanged);
  };

  render() {
    return (
      <div className={`d-flex flex-row flex-1 ${this.props.className || ''} ${this.props.disabled ? 'time-picker_disabled' : ''}`}>
        <div className="d-flex flex-row time-picker__times justify-content-center flex-1">
          <TimeField format="12" onChange={this.changeTime} value={this.state.time} />
        </div>
        <div className="d-flex ml-2">
          <TabPicker
            onChange={this.changeTimeFormat}
            value={this.state.format}
            options={[
              { value: TIME_FORMAT.AM, label: localesStore.t('AM') },
              { value: TIME_FORMAT.PM, label: localesStore.t('PM') },
            ]}
          />
        </div>
      </div>
    );
  }
}
