import React from 'react';
import './style.scss';

interface Props {
  children?: React.ReactNode;
}

export const TableHeader = (props: Props) => {
  return <header className="table-header d-flex align-items-center color-gray mb-2">{props.children}</header>;
};
