import { Component } from 'react';
import { Button } from 'taker-web-ui';
import { ICONS } from '~/constants/icons';
import { messagesStore } from '~/mobx';
import Icon from '~/view/components/Controls/Icon/Icon';
import DropDownPortal from '~/view/components/DropDownPortal/DropDownPortal';
import { IMessageModal } from '~/mobx/messagesStore/messagesStore';
import './style.scss';

interface Props {
  message: IMessageModal;
}

export default class MessagesPopup extends Component<Props, {}> {
  state = {};
  onButtonPress = (cb: (() => void) | undefined) => () => {
    if (cb) cb();
    this.requestClose();
  };

  requestClose = () => {
    if (this.props.message.message.onRequestClose) this.props.message.message.onRequestClose();
    messagesStore.removeMessage(this.props.message.id);
  };

  render() {
    return (
      <DropDownPortal>
        <div className="message-popup">
          <div className="message-popup-backdrop" onClick={this.requestClose}></div>
          <div className="message-popup-content">
            {this.props.message.message.title ? (
              <p className="color-dark text-size-20 family-semibold mb-4 mt-2 text-center">{this.props.message.message.title}</p>
            ) : null}
            {this.props.message.message.message ? <p className="color-black text-size-16 text-center">{this.props.message.message.message}</p> : null}
            <div className="d-flex flex-1 mt-4">
              {this.props.message.message.options?.map((option, index) => (
                // <button
                //   onClick={this.onButtonPress(option.onPress)}
                //   className={`py-3 d-flex flex-1 ${index ? 'ml-1' : ''} ${option.className || ''}`}>
                //   <span className={option.textClassName}>{option.text}</span>
                // </button>
                <Button
                  stretched
                  appearance={option.appearance}
                  title={option.text}
                  className={`py-3 ${index ? 'ml-1' : ''}`}
                  onClick={this.onButtonPress(option.onPress)}
                />
              ))}
            </div>
            <Icon onPress={this.requestClose} className="modal-message-close  text-pointer color-gray2" name={ICONS.Close} size={15} />
          </div>
        </div>
      </DropDownPortal>
    );
  }
}
