import * as colors from 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';
// const breakpoints = {}

type TThemeColors = {
  // main colors
  mainblue: string;
  dark: string;
  gray: string;
  white: string;

  // secondary colors
  graysoft: string;
  gray2: string;
  gray3: string;
  gray4: string;
  red: string;
  green: string;
  orange: string;
  yellow: string;
  bluelight: string;
  greenlight: string;
  redlight: string;
  chart: string;
  subscribe: string;
};

type TTheme = {
  colors: TThemeColors;
};

export const getDurations = (options: { index: number; max: number; diff: number }): number => {
  if (options.index > options.max) return options.max * options.diff;
  return options.index * options.diff;
};

const theme: TTheme = { colors: colors.default as TThemeColors };

export default theme;
