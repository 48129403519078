import React, { ChangeEvent, Component } from 'react';
import PhoneInput2, { PhoneInputProps } from 'react-phone-input-2';
import './style.scss';

interface IProps extends PhoneInputProps {
  onChange: (value: string, data: {}, event: ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
  hideFlagsIcon?: boolean;
  className?: string;
  error?: boolean;
}

export default class PhoneInput extends Component<IProps, {}> {
  render() {
    const { className = '', hideFlagsIcon = false } = this.props;

    return (
      <PhoneInput2
        {...this.props}
        containerClass={`input input-mini family-regular ${this.props.error ? 'input-error' : ''} border-gray color-dark text-size-16 ${className} ${
          hideFlagsIcon ? 'hide-flags' : ''
        }`}
        prefix={'+'}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}
