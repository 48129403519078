import React, { useEffect, useRef } from 'react';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

export type TimelineItem<T> = {
  item: T;
  danger: boolean;
};

type Props = {
  data: TimelineItem<any>[];
  leading: (item: TimelineItem<any>) => JSX.Element;
  trailing: (item: TimelineItem<any>, index: number) => JSX.Element;
  notFull?: boolean;
};

const Timeline = ({ leading, trailing, notFull = false, data }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container || !data) {
      return;
    }

    container.scrollIntoView({ block: 'end' });
  }, [containerRef, data]);

  const renderItem = (item: TimelineItem<any>, index: number) => {
    const isLast = data.length - 1 === index;
    const isNextDanger = data[index + 1]?.danger === true;

    return (
      <div className={'timeline_item'} key={index}>
        <div className={'leading'}>{leading(item)}</div>
        <div className={`line_container`}>
          <div className={`circle ${item.danger ? 'danger' : ''}`}>{item.danger && <Icon name={ICONS.Warning} />}</div>
          {!isLast && <div className={`line ${item.danger ? 'danger' : isNextDanger ? 'danger-prev' : ''}`} />}
          {isLast && notFull && (
            <>
              <div className={'line last'} />
              <div className={'circle last'} />
            </>
          )}
        </div>
        <div className={'trailing'}>{trailing(item, index)}</div>
      </div>
    );
  };

  return (
    <div className={'timeline'} ref={containerRef}>
      {(data || []).map(renderItem)}
      {notFull && (data || []).length === 0 ? (
        <div className={'timeline_item'}>
          <div className={'leading'}></div>
          <div className={`line_container last`}>
            <div className={'line last'} />
            <div className={'circle last'} />
          </div>
          <div className={'trailing'}></div>
        </div>
      ) : null}
    </div>
  );
};

export default Timeline;
