import { Marker as GoogleMarker, MarkerProps as GoogleMarkerProps } from '@react-google-maps/api';

export enum MarkerType {
  BRANCH = 'branch',
  LOCATION = 'location',
  CAR = 'car',
  BRANCH_NDF_LOW = 'branch_ndf_low',
  BRANCH_NDF_MEDIUM = 'branch_ndf_medium',
  BRANCH_NDF_HIGH = 'branch_ndf_high',
}

export interface MarkerProps extends GoogleMarkerProps {
  type?: MarkerType;
  selected?: boolean;
}

const Marker = ({ type = MarkerType.LOCATION, selected, ...rest }: MarkerProps) => {
  const url = type === MarkerType.CAR ? '/images/map/car.svg' : `/images/map/${type}${selected ? '_selected' : ''}.png`;

  const scaledSize =
    type === MarkerType.CAR
      ? new globalThis.google.maps.Size(14, 14)
      : [MarkerType.BRANCH_NDF_LOW, MarkerType.BRANCH_NDF_MEDIUM, MarkerType.BRANCH_NDF_HIGH].includes(type)
      ? new window.google.maps.Size(39, 39)
      : new window.google.maps.Size(26, 26);

  return (
    <GoogleMarker
      icon={{
        url,
        scaledSize,
      }}
      {...rest}
    />
  );
};

export default Marker;
