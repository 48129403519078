import './style.scss';

import React, { ChangeEvent, forwardRef, memo, Ref, useCallback, useImperativeHandle, useState } from 'react';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import { localesStore } from '~/mobx';
import theme from '~/utils/styles';
import CustomPlaceholder from '~/view/components/Controls/SearchInput/components/CustomPlaceholder/CustomPlaceholder';
import Spinner from '~/view/components/Controls/Spinner/Spinner';

import { useDebouncedCallback } from '~/utils';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  loading: boolean;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  customPlaceholder?: boolean;
  customPlaceholderClassName?: string;
  customPlaceholderIcon?: IconsId;
  customPlaceholderIconSize?: number;
  customPlaceholderIconClassName?: string;
  customPlaceholderTextClassName?: string;
  withCloseIcon?: boolean;
  debounceTime?: number;
  onChangeText: (text: string) => void;
}

const SearchInput = forwardRef(
  (
    {
      loading = false,
      placeholder = '',
      customPlaceholder,
      customPlaceholderIcon,
      customPlaceholderIconSize,
      customPlaceholderIconClassName,
      customPlaceholderTextClassName,
      inputClassName = '',
      withCloseIcon,
      debounceTime = 0,
      onChangeText,
      value: initialValue,
      className = '',
      ...props
    }: Props,
    ref: Ref<any>,
  ) => {
    const [value, setValue] = useState(initialValue);

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const debouncedHandleChange = useDebouncedCallback((text) => {
      onChangeText(text);
    }, debounceTime);

    const handleChangeText = useCallback(
      (data: ChangeEvent<HTMLInputElement>) => {
        setValue(data.target.value);
        if (typeof debounceTime === 'number' && debounceTime > 0) {
          debouncedHandleChange(data.target.value);
        } else {
          onChangeText(data.target.value);
        }
      },
      [debounceTime, debouncedHandleChange, onChangeText],
    );

    const handleClearText = useCallback(() => {
      setValue('');
      onChangeText('');
    }, [onChangeText]);

    useImperativeHandle(ref, () => ({
      clearText: () => {
        setValue('');
      },
    }));

    return (
      <div className={`input-search ${className}`}>
        <input
          placeholder={customPlaceholder ? '' : localesStore.t(placeholder)}
          onChange={handleChangeText}
          value={value}
          className={`${inputClassName} ${withCloseIcon ? 'input-search_with-close-icon' : ''}`}
          {...props}
        />
        {customPlaceholder && !value ? (
          <CustomPlaceholder
            iconSize={customPlaceholderIconSize}
            placeholder={placeholder}
            icon={customPlaceholderIcon}
            iconClassName={customPlaceholderIconClassName}
            textClassName={customPlaceholderTextClassName}
          />
        ) : null}
        <div className={`input-close ${withCloseIcon && value && !loading ? 'd-flex' : 'd-none'}`} onClick={handleClearText}>
          <Icon name={ICONS.CloseSmall} size={16} className="color-dark my-auto" />
        </div>
        {loading ? (
          <div className="input-spinner">
            <Spinner color={theme.colors.gray} />
          </div>
        ) : null}
      </div>
    );
  },
);

export default memo(SearchInput);
