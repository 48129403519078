import React from 'react';
import './style.scss';

interface Props {
  children?: React.ReactNode;
}

export const Table = (props: Props) => {
  return <div className="table-wrapper flex-grow-1 d-flex flex-column bg-white">{props.children}</div>;
};
