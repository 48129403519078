import { observer } from 'mobx-react';
import { Component } from 'react';
import { archiveStore } from '~/mobx';
import Pagination from '~/view/components/Controls/Pagination/Pagination';

export const DeliveryRequestsTableFooter = observer(
  class DeliveryRequestsTableFooter extends Component {
    render() {
      return <Pagination pagination={archiveStore.pagination} onChange={(page) => archiveStore.getRequests(page)} overlayClassName="overlay_white" />;
    }
  },
);
