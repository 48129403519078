import { observer } from 'mobx-react';
import React from 'react';
import { generatePath } from 'react-router-dom';
import DeliveryRequestsTable from './DeliveryRequestsTable';
import { DEBOUNCE_TIME } from '~/constants/main';
import { COLORS, FONT_FAMILY } from '~/constants/styles';
import { localesStore, modalStore } from '~/mobx';
import { archiveStore } from '~/mobx';
// import { toCamelcase } from '~/utils/formatter';
import theme from '~/utils/styles';
import CustomTooltip from '~/view/components/Controls/CustomTooltip/CustomTooltip';
import Icon from '~/view/components/Controls/Icon/Icon';
import { SearchInput } from '~/view/components/Controls/SearchInput';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
// import HeaderTitle from '~/view/components/Headers/components/HeaderTitle/HeaderTitle';
import Headers from '~/view/components/Headers/Headers';
import Text from '~/view/components/Typo/Text/Text';
import './style.scss';
import { RouterComponentProps } from '~/types/main';
import withRouter from '~/utils/withRouter';

type IProps = RouterComponentProps<{ id: string | undefined }>;

export const DeliveryRequestsScreen = withRouter(
  observer(
    class DeliveryRequestsScreen extends React.Component<IProps, any> {
      onCloseAchivePopup = () => {
        this.props.router.navigate(generatePath('/requests'));
      };

      handleId = async () => {
        const id = this.props.router.params.id;

        if (!id) {
          return;
        }

        if (archiveStore.popupLockAnotherArea) {
          return;
        }

        await archiveStore.getRequestForAchivePopup(id);

        if (archiveStore.trackingRequest === null) {
          modalStore.openErrorModal({
            text: localesStore.t('no_permission_or_not_found'),
          });
          return;
        }

        modalStore.openArchiveRequestInfoModal({
          id,
          onClose: this.onCloseAchivePopup,
        });
      };

      componentDidMount = async () => {
        await archiveStore.getRequests(1);
        this.handleId();
      };

      handleSearch = (value: string) => {
        archiveStore.setSelectedFilter('search', value);
      };

      render() {
        return (
          <div className="d-flex flex-column full-width">
            <Headers>
              <div style={{ width: 360 }}>
                <SearchInput
                  loading={archiveStore.requestsLoading}
                  className={'input-wrapper mt-2 border-gray'}
                  inputClassName={'color-dark bg-white text-size-16 family-medium'}
                  onChangeText={this.handleSearch}
                  withCloseIcon
                  placeholder={localesStore.t('search_box')}
                  value={archiveStore.selectedFilters.search || ''}
                  debounceTime={DEBOUNCE_TIME.LARGE}
                  // ref={archiveStore.sRef}
                />
              </div>
            </Headers>
            <DeliveryRequestsTable />
            <div dir="ltr" className="d-flex flex-row export-wrapper">
              <div className="export-text-wrapper" onClick={archiveStore.exportExcel}>
                <Text
                  className={`p-1 export-text ${archiveStore.isExcelExportAvailable ? '' : 'export-text__disabled'}`}
                  color={COLORS.MAINBLUE}
                  size={14}
                  family={FONT_FAMILY.MEDIUM}>
                  {localesStore.t('export_excel_button')}
                </Text>
                {archiveStore.isExcelExporting && <Spinner color={theme.colors.mainblue} className="export-spinner" />}
              </div>
              <CustomTooltip
                onOpen={() => archiveStore.setLockedPopupAnotherArea(true)}
                onClose={() => archiveStore.setLockedPopupAnotherArea(false)}
                duration={5000}
                type="info"
                tooltipClassName="export-tooltip p-1"
                message={localesStore.t('export_excel_tooltip')}>
                <Icon name="info" className="export-info-icon" />
              </CustomTooltip>
            </div>
            <div dir="ltr" className="d-flex flex-row numbers-wrapper">
              <Text color={COLORS.GRAY5} size={12} family={FONT_FAMILY.REGULAR}>
                {localesStore.t('number_of_entries', {
                  ordinal_number_of_the_first_row_on_the_page:
                    archiveStore.trackingRequests.pagination.total > 0
                      ? archiveStore.trackingRequests.pagination.current_page * archiveStore.trackingRequests.pagination.per_page -
                        archiveStore.trackingRequests.pagination.per_page +
                        1
                      : 0,
                  ordinal_number_of_the_last_row_on_the_page:
                    archiveStore.trackingRequests.pagination.total <
                    archiveStore.trackingRequests.pagination.current_page * archiveStore.trackingRequests.pagination.per_page
                      ? archiveStore.trackingRequests.pagination.total
                      : archiveStore.trackingRequests.pagination.current_page * archiveStore.trackingRequests.pagination.per_page,
                  total_number_of_rows_in_the_table: archiveStore.trackingRequests.pagination.total,
                })}
              </Text>
            </div>
          </div>
        );
      }
    },
  ),
);
