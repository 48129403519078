import { GoogleMap, GoogleMapProps, MarkerClusterer } from '@react-google-maps/api';
import { Clusterer, ClustererOptions, ClusterIconInfo } from '@react-google-maps/marker-clusterer';
import React, { ReactNode, RefObject } from 'react';
import Marker, { MarkerType } from './components/Marker';
import mapStyle from './mapStyle';
import MapDnfStatisticsControl from './components/DNF/MapDnfStatisticsControl';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import { trackingStore } from '~/mobx';
import { GoogleMapsContext } from '~/utils/GoogleMapsProvider';
import './style.scss';
import { deliveryZonesStore } from '~/mobx';

export type Coords = { lat: number; lng: number };

export interface CoordsWithType extends Coords {
  type?: MarkerType;
  requestId?: string;
}

export type TClusteringSelected = {
  branch: CoordsWithType | null;
  customer: CoordsWithType | null;
};

export type TClustering = {
  locations: CoordsWithType[];
  selected: TClusteringSelected;
  options?: ClustererOptions;
  onMarkerClick?: (event: globalThis.google.maps.MapMouseEvent, data: CoordsWithType) => void;
};

interface MapProps extends GoogleMapProps {
  className?: string;
  // center?: Coords;
  zoom?: number;
  options?: Partial<google.maps.MapOptions>;
  clustering?: TClustering;
  mapRef?: React.RefObject<GoogleMap> | null;
  children?: ReactNode;
  isStatistics?: boolean;
}

type MapState = {
  loaded: boolean;
  map: globalThis.google.maps.Map | undefined;
};

class Map extends React.Component<MapProps, MapState> {
  state = {
    loaded: false,
    map: undefined,
  };

  map: globalThis.google.maps.Map | undefined = this.state.map;
  private mapRef: RefObject<GoogleMap> = React.createRef<GoogleMap>();

  center = this.props.center || {
    lat: 24.7187496,
    lng: 46.7,
  };

  defaultCenter: Coords = { lat: 24.709022, lng: 46.703354 };
  clustererOptions: ClustererOptions = {
    averageCenter: true,
    maxZoom: 6,
    // imageSizes: [22, 33, 44, 55],
    zoomOnClick: true,
    calculator: (markers) => ({ text: `${Math.floor(markers.length / 2)}`, index: 1, title: 'Cluster' } as ClusterIconInfo),
    styles: [
      {
        textColor: 'white',
        url: '/images/map/cluster.png',
        height: 35,
        width: 35,
        className: 'd-flex align-items-center justify-content-center text-size-16',
      },
    ],
    ...(this.props.clustering?.options ? this.props.clustering?.options : {}),
  };

  options: globalThis.google.maps.MapOptions = {
    fullscreenControl: false,
    gestureHandling: 'greedy',
    zoomControl: false,
    scrollwheel: true,
    disableDefaultUI: true,
    styles: mapStyle,
    minZoom: 1,
    maxZoom: 15,
    scaleControl: false,
    ...(this.props.options ? this.props.options : {}),
  };

  getMidPointOfTwoMarkers(branchLocation: Coords, customerLocation: Coords): Coords {
    return {
      lat: branchLocation.lat + (customerLocation.lat - branchLocation.lat) * 0.3,
      lng: branchLocation.lng + (customerLocation.lng - branchLocation.lng) * 0.5,
    };
  }

  getDistanceBetweenTwoMarkers(first: Coords, second: Coords) {
    const _first = new window.google.maps.LatLng(first);
    const _second = new window.google.maps.LatLng(second);

    return globalThis.google.maps.geometry.spherical.computeDistanceBetween(_first, _second);
  }

  setCameraOnMidOfTwoMarkers(first: Coords, second: Coords, zoom = 10) {
    const mid = this.getMidPointOfTwoMarkers(first, second);

    trackingStore.lastMapPosition.pos = mid;
    trackingStore.lastMapPosition.zoom = zoom;
    this.setCamera(mid, zoom || 0);
  }

  public getCenter = () => {
    const center = this.map?.getCenter();

    if (!center) {
      return this.center;
    }

    return { lat: center.lat(), lng: center.lng() };
  };

  public setCenter = (center?: Coords) => {
    this.map?.setCenter(center || this.center);
  };

  public getCurrentZoom = (): number => {
    const currentZoom = this.map?.getZoom();

    return currentZoom || 0;
  };

  public setZoom = (zoom: number): void => {
    return this.map?.setZoom(zoom);
  };

  public setCamera = (location: Coords, zoom = 13) => {
    this.setCenter(location);
    this.setZoom(zoom);
  };

  private createKey(location: Coords, index: number, type?: MarkerType) {
    return `${location.lat},${location.lng}_${type ? type : MarkerType.LOCATION}-${index}`;
  }

  private handleLoad = (map: globalThis.google.maps.Map) => {
    deliveryZonesStore.mapStore.map = map;
    this.setState({ loaded: true, map }, () => {
      this.map = this.state.map;
    });

    const { clustering } = this.props;

    if (clustering && clustering.selected.customer) {
      this.setCamera(clustering.selected.customer);
    }
  };

  private handleUnload = (map: globalThis.google.maps.Map) => {
    this.setState({ loaded: false, map: undefined }, () => {
      this.map = this.state.map;
    });

    if (this.props.onUnmount) {
      this.props.onUnmount(map);
    }
  };

  private handleZoom = (zoom: number) => () => {
    const currentZoom = this.getCurrentZoom();

    this.setZoom(currentZoom + zoom);
  };

  // private handleFocusOnCenter = () => {
  //   this.setCenter(this.center);
  // };

  private handleClustererMarkerClick = (event: globalThis.google.maps.MapMouseEvent, data: CoordsWithType) => {
    if (this.props.clustering?.onMarkerClick) {
      this.props.clustering?.onMarkerClick(event, data);
    }
  };

  renderClusterer = (clusterer: Clusterer): ReactNode => {
    if (!this.props.clustering) return null;

    const {
      clustering: { locations, selected },
    } = this.props;

    return locations.map((location: CoordsWithType, index: number) => {
      const { lat, lng, type } = location;

      let isSelected = false;

      switch (type) {
        case MarkerType.BRANCH:
          const selectedBranch = selected.branch;

          if (!selectedBranch) break;

          isSelected = selectedBranch.lng === lng && selectedBranch.lat === lat;
          break;
        case MarkerType.LOCATION:
          const selectedLocation = selected.customer;

          if (!selectedLocation) break;

          isSelected = selectedLocation.lng === lng && selectedLocation.lat === lat;
          break;
        default:
          break;
      }

      // if (isSelected) return null;
      const label = type === MarkerType.CAR ? { className: 'pulse-label', text: ' ' } : undefined;

      return (
        <Marker
          type={type}
          selected={isSelected}
          key={this.createKey(location, index, type)}
          position={location}
          label={label}
          // zIndex={isSelected ? 10 : 1}
          // opacity={isSelected ? 0 : 1}
          // visible={isSelected ? true : false}
          clusterer={clusterer}
          onClick={(e) => this.handleClustererMarkerClick(e, location)}
        />
      );
    });
  };

  render() {
    const { clustering, className = '', children, ...props } = this.props;

    return (
      <div className={'map-wrapper'}>
        <GoogleMapsContext.Consumer>
          {({ isLoaded }) =>
            isLoaded ? (
              <GoogleMap
                zoom={this.props.zoom || 5.5}
                onLoad={this.handleLoad}
                onUnmount={this.handleUnload}
                center={this.center}
                options={this.options}
                mapContainerClassName={`map ${className}`}
                // ref={this.mapRef}
                {...props}>
                {clustering && (
                  <MarkerClusterer options={this.clustererOptions} zoomOnClick={true}>
                    {(clusterer) => this.renderClusterer(clusterer) as any}
                  </MarkerClusterer>
                )}
                {children}
              </GoogleMap>
            ) : null
          }
        </GoogleMapsContext.Consumer>

        {props.isStatistics === true ? <MapDnfStatisticsControl></MapDnfStatisticsControl> : null}

        <div className={'map-controls-wrapper'}>
          <div className={'bundled-control'}>
            <div className={'map-control'} onClick={this.handleZoom(1)}>
              <Icon name={ICONS.Plus} />
            </div>
            <div className={'map-control'} onClick={this.handleZoom(-1)}>
              <Icon name={ICONS.Minus} />
            </div>
          </div>
          <div
            className={'map-control'}
            onClick={() => {
              this.setCenter(this.defaultCenter);
              this.setZoom(6);
            }}>
            <Icon name={ICONS.IcTarget} />
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
