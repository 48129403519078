import React, { Component } from 'react';
import DropDownPortal from '~/view/components/DropDownPortal/DropDownPortal';
import { WINDOW_SIZES } from '~/constants/main';
import { localesStore } from '~/mobx';
import './style.scss';

interface IPopupModalListProps {
  content: ({ closeModal }: { closeModal: () => void }) => React.ReactNode;
}

export interface IPopupModalProps extends IPopupModalListProps {
  content: IPopupModalListProps['content'];
  selfClose?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  position?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'center';
  justify?: 'center' | 'start' | 'end';
  backdrop?: boolean;
  visible?: boolean;
  mobile?: {
    popup?: boolean;
    sheet?: boolean;
  };
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
}

type Visibility = 'visible' | 'hidden' | 'default';

interface PopupModalState {
  visibility: Visibility;
}
export default class PopupModal extends Component<IPopupModalProps, PopupModalState> {
  direction = this.props.direction || 'right';
  position = this.props.position || 'bottom';
  align = this.props.align;
  justify = this.props.justify;
  modal: HTMLDivElement | null = null;
  selfClose: boolean = this.props.selfClose === undefined ? true : this.props.selfClose;
  animations = {
    left:
      localesStore.language === 'en'
        ? { default: '', visible: 'animate__fadeInLeft', hidden: 'animate__fadeOutRight' }
        : { default: '', visible: 'animate__fadeInRight', hidden: 'animate__fadeOutLeft' },
    right:
      localesStore.language === 'en'
        ? { default: '', visible: 'animate__fadeInRight', hidden: 'animate__fadeOutLeft' }
        : { default: '', visible: 'animate__fadeInLeft', hidden: 'animate__fadeOutRight' },
    up: { default: '', visible: 'animate__fadeInUp', hidden: 'animate__fadeOutUp' },
    down: { default: '', visible: 'animate__fadeInDown', hidden: 'animate__fadeOutDown' },
  };

  state: PopupModalState = {
    visibility: 'default',
  };

  componentDidUpdate = (props: IPopupModalProps) => {
    if (this.props.visible !== props.visible) {
      if (this.props.visible) {
        if (this.state.visibility !== 'visible') {
          this.openModal();
        }
      } else if (!this.props.visible) {
        if (this.state.visibility === 'visible') {
          this.closeModal();
        }
      }
    }
  };

  openModal = () => {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ visibility: 'visible' });
    setTimeout(() => document.addEventListener('click', this.closeModalHandler), 30);
  };

  closeModalHandler = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const pressedElelement: any = event.target;
    if (!this.selfClose) {
      const isInside = this.modal?.contains(pressedElelement);
      if (isInside) return;
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ visibility: 'hidden' });
    if (this.props.onClose) this.props.onClose();
    document.removeEventListener('click', this.closeModalHandler);
  };

  renderModal = () => {
    // if (this.props.disabled) return null;
    const { visibility } = this.state;
    const animation = this.animations[this.direction][visibility];

    return (
      <div
        ref={(ref) => (this.modal = ref)}
        className={`
    dropdown-modal

    ${this.props.mobile?.sheet ? 'modal-bottom-sheet' : ''}
    ${this.align ? `dropdown-modal-align-${this.align}` : ''}
    ${this.justify ? `dropdown-modal-justify-${this.justify}` : ''}
    ${this.props.mobile?.popup ? 'popup_in_mobile' : ''}
    hide-scrollbar
    dropdown-modal-${this.position}
    animate__faster animate__animated
    ${visibility === 'visible' ? 'active' : 'inactive'} animation ${animation}`}>
        {this.props.content({ closeModal: this.closeModal })}
        {this.props.mobile?.popup ? <PopupModalPane onClick={this.closeModal} /> : null}
      </div>
    );
  };

  renderModalWrapper = () => {
    if (!this.props.visible) return null;
    if (window.innerWidth < WINDOW_SIZES.md && this.props.mobile?.popup) {
      return <DropDownPortal>{this.renderModal()}</DropDownPortal>;
    }
    return this.renderModal();
  };

  render() {
    const { visibility } = this.state;
    const { className } = this.props;

    return (
      <div className={`dropdown ${visibility} ${this.props.backdrop ? 'dropdown-backdrop' : ''} ${className ? className : ''}`}>
        {this.props.children}
        {this.renderModalWrapper()}
      </div>
    );
  }
}

export const PopupModalPane = ({ onClick }: { onClick: (event: any) => void }) => <div onClick={onClick} className={'modal-pane'}></div>;
