import React from 'react';
import Modal from 'react-modal';
import ResponsiveContent from '~/view/components/ResponsiveContent/ResponsiveContent';
import Icon from '~/view/components/Controls/Icon/Icon';
import { ICONS } from '~/constants/icons';
import { debounce } from '~/utils/perfomance';
import './style.scss';

Modal.setAppElement('#root');

export interface IModalComponentProps {
  isOpen: boolean;
  style?: object;
  children?: React.ReactNode;
  withCloseIcon?: boolean;
  withPane?: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  className?: string;
  defaultAnimation?: string;
  iconClassName?: string;
  disableSlideDownFor?: string[];
  [x: string]: any; // rest props
}

interface State {
  height: number;
}
export default class CustomModalComponent extends React.Component<IModalComponentProps, State> {
  x: number[] = [];
  toushStart = null;
  touchEnd = null;

  state = {
    height: 0,
  };

  handleTouchStart = (e: any) => {
    this.x = [e.targetTouches[0].clientY];
    this.toushStart = e.targetTouches[0].clientY;
  };

  handleTouchMove = (e: any) => {
    this.x.push(e.targetTouches[0].clientY);
    if (e.target.classList.contains('ReactModal__Overlay')) {
      this.touchEnd = e.targetTouches[0].clientY;
      const dx = this.touchEnd! - this.toushStart!;
      if (dx >= 0) this.setState({ height: dx });
    }
  };

  handleTouchEnd = (e: any) => {
    if (e.target.classList.contains('ReactModal__Overlay')) {
      const dx = this.touchEnd! - this.toushStart!;
      if (dx <= 50) {
        this.setState({ height: 0 });
      } else {
        if (this.props.onRequestClose) {
          debounce(this.props.onRequestClose, 300)();
          this.toggleAnimation();
        }
      }
    }
  };

  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  toggleAnimation() {
    const wrapper = this.wrapperRef.current;
    if (wrapper) {
      wrapper.classList.remove('slideup');
      wrapper.classList.toggle('slide_bottom');
    }
  }

  public render() {
    const {
      isOpen = false,
      style = {},
      withCloseIcon = false,
      withPane = false,
      children = null,
      onAfterOpen,
      onRequestClose,
      className = '',
      defaultAnimation = 'slideup',
      iconClassName,
      // disableSlideDownFor,
      ...props
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        overlayElement={(_props, contentElement) => (
          <div onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} onTouchMove={this.handleTouchMove} {..._props}>
            {contentElement}
          </div>
        )}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        style={{
          overlay: {
            background: 'rgba(0,0,0,.25)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
          },
          ...style,
        }}
        contentElement={() => (
          <div
            ref={this.wrapperRef}
            onClick={(e) => e.stopPropagation()}
            className={`modal-content ${defaultAnimation} ${className} ${withPane ? 'modal_pane' : ''}`}
            style={{ marginTop: this.state.height }}>
            {children}
            {withCloseIcon && (
              <button className="modal-close-icon " onClick={onRequestClose}>
                <Icon className={iconClassName} name={ICONS.Close} />
              </button>
            )}
            <ResponsiveContent max={1024}>{withPane && <div onClick={onRequestClose} className={'modal-pane'}></div>}</ResponsiveContent>
          </div>
        )}
        {...props}
      />
    );
  }
}
