import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import DspLabels from '~/view/screens/DspManagementScreen/containers/DspLabels/DspLabels';
import OrganizationToggles from '~/view/screens/DspManagementScreen/containers/OrganizationToggles/OrganizationToggles';
import { dspStore } from '~/mobx';
import styles from '~/utils/styles';
import { DSP_C_APPROVED, DSP_C_WORKING } from '~/constants/dsp';
import { localesStore } from '~/mobx';
import { messagesStore } from '~/mobx';
import { IOrg } from '~/types/dsp';
import InputOld from '~/view/components/Controls/InputOld/InputOld';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import Sizes from '~/view/components/Controls/Sizes/Sizes';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import './style.scss';
import { withRouter } from '~/utils';
import { RouterComponentProps } from '~/types/main';

interface IState {
  searchPhrase: string;
  acceptance_timeout: number | string;
  prevAcceptanceTimeout: number | string;
}

class DspOrganizations extends Component<RouterComponentProps<{ id: string; type: string }>, IState> {
  state: IState = {
    searchPhrase: '',
    acceptance_timeout: dspStore.dsp?.acceptance_timeout || 0,
    prevAcceptanceTimeout: dspStore.dsp?.acceptance_timeout || 0,
  };

  componentWillUnmount() {
    if (dspStore.dsp?.acceptance_timeout !== this.state.acceptance_timeout && !this.validateTimeout()) {
      dspStore.globalDspUpdate({ acceptance_timeout: this.state.acceptance_timeout });
    }
  }

  componentDidMount() {
    // this.setState({ acceptance_timeout: dspStore.dsp?.acceptance_timeout || 0 });
  }

  componentDidUpdate() {
    if (dspStore.dsp?.acceptance_timeout && this.state.prevAcceptanceTimeout !== dspStore.dsp?.acceptance_timeout) {
      this.setState({
        acceptance_timeout: dspStore.dsp?.acceptance_timeout || 0,
        prevAcceptanceTimeout: dspStore.dsp?.acceptance_timeout || 0,
      });
    }
  }

  bulkStatusChange = (active: boolean) => {
    if (!dspStore.dsps.length || !dspStore.orgs.length) {
      return;
    }

    const organizationsIds = dspStore.orgs.map((org) => {
      return org.id;
    });

    dspStore.updateOrganizationAvailability(dspStore.dsp?.id ?? dspStore.dsps[0].id, organizationsIds, active, true);
  };

  getFilteredOrganizations = (): IOrg[] => {
    return (
      dspStore.orgs.filter(({ name = '' }: IOrg) => {
        return String(name).toLowerCase().includes(this.state.searchPhrase.toLowerCase());
      }) ?? []
    );
  };

  onSearch = (searchPhrase: string) => {
    this.setState({
      searchPhrase,
    });
  };

  workStatusChangeApprove = (working: number) => {
    if (dspStore.dsp?.working === working) {
      return;
    }

    messagesStore.addMessage({
      type: 'modal',
      title: localesStore.t('you_sure_confirmation'),
      message: localesStore.t('status_switch_confirmation', {
        dsp_name: dspStore.dsp?.provider,
        dsp_status: working === DSP_C_WORKING.WORKING ? localesStore.t('working_filter_tab') : localesStore.t('dormant_filter_tab'),
      }),
      options: [
        {
          text: localesStore.t('yes_button'),
          onPress: () =>
            dspStore.globalDspUpdate({
              working,
            }),
          appearance: 'primary',
        },

        {
          text: localesStore.t('no_button'),
          appearance: 'tertiary',
        },
      ],
    });
  };

  validateTimeout = (): string | boolean => {
    if (typeof this.state.acceptance_timeout === 'string') return false;

    if (!this.state.acceptance_timeout || this.state.acceptance_timeout < 1 || this.state.acceptance_timeout > 1440) {
      return localesStore.t('dat_field_error');
    } else {
      return false;
    }
  };

  setTimeout = (value: string) => {
    let timeout = Number(value.replace(/\D/, ''));

    if (!timeout) timeout = 0;

    this.setState({ acceptance_timeout: timeout });
  };

  handleChangeTimeout = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (event.target.value.length > 4) {
      event.preventDefault();
      return;
    }

    this.setTimeout(value);
  };

  handleBlurTimeoutInput = () => {
    if (!this.validateTimeout() && typeof this.state.acceptance_timeout !== 'string') {
      dspStore.globalDspUpdate({ acceptance_timeout: this.state.acceptance_timeout });
    } else {
      this.setState({
        acceptance_timeout: dspStore.dsp?.acceptance_timeout || 0,
      });
    }
  };

  render() {
    if ((dspStore.dspLoading || dspStore.dsps.length === 0) && !dspStore.dsp) {
      return (
        <div className="d-flex flex-column px-4 full-width full-height justify-content-center align-items-center" style={{ zIndex: 999 }}>
          <Spinner color={styles.colors.mainblue} size={24} style={{ zIndex: 9999 }} />
        </div>
      );
    }

    const dsp = dspStore.dsp || dspStore.dsps[0];
    const blur = dspStore.dsp?.approved === DSP_C_APPROVED.BETA || dspStore.dspUpdating || dsp.working === DSP_C_WORKING.DORMANT;

    return (
      <div className="d-flex flex-column px-4 full-width">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-title__title text-size-32 family-bold">
            {dsp.provider}
            <DspLabels />
          </h2>

          {dsp.approved === DSP_C_APPROVED.APPROVED ? (
            <Sizes
              onChange={this.workStatusChangeApprove}
              selected={dsp.working}
              options={[
                { name: localesStore.t('working_filter_tab'), value: DSP_C_WORKING.WORKING, key: DSP_C_WORKING.WORKING },
                { name: localesStore.t('dormant_filter_tab'), value: DSP_C_WORKING.DORMANT, key: DSP_C_WORKING.DORMANT },
              ]}
            />
          ) : null}
        </div>

        <div className={`organizations-section ${blur ? 'blur' : ''}`}>
          {dspStore.dspUpdating ? (
            <div className="loader">
              <Spinner color={styles.colors.mainblue} size={24} />
            </div>
          ) : null}
          <div className="organizations-list-controls-wrapper d-flex flex-column full-width">
            <div className={'organizations-inputs-wrapper d-flex flex-row full-width'}>
              <SearchInput
                className="input-wrapper organization-filter-search color-gray5 organization-filter-search-field bg-transparent "
                onChangeText={this.onSearch}
                placeholder={localesStore.t('search_box_clients')}
                customPlaceholder
                withCloseIcon
                loading={false}
                value={this.state.searchPhrase}
              />
              <div className={'dat-input-wrapper d-flex flex-row'}>
                <InputOld
                  small
                  labelLeft={`${localesStore.t('optimised_dat_field')} (${localesStore.t('min')}):`}
                  className={'text-size-16'}
                  containerClass={'dat-container'}
                  value={this.state.acceptance_timeout}
                  onPaste={(ev) => {
                    ev.preventDefault();

                    const value = ev.clipboardData.getData('text/plain');

                    this.setTimeout(value);
                  }}
                  onChange={this.handleChangeTimeout}
                  onFocus={() => this.setState({ acceptance_timeout: '' })}
                  onBlur={this.handleBlurTimeoutInput}
                  error={this.validateTimeout()}
                  pattern="[0-9]*"
                />
                <div className={'loader-container'}></div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <div className="bulk-status-update-button" onClick={() => this.bulkStatusChange(true)}>
                {localesStore.t('enable_all_button')}
              </div>
              <div className="bulk-status-update-button" onClick={() => this.bulkStatusChange(false)}>
                {localesStore.t('disable_all_button')}
              </div>
            </div>
          </div>
          <OrganizationToggles organizations={this.getFilteredOrganizations()} dspId={dspStore.dsp?.id} />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(DspOrganizations));
