import { LatLng } from '~/types/main';

export const getCenterOfBounds = (paths: google.maps.LatLngLiteral[]): LatLng => {
  const bounds = new google.maps.LatLngBounds();
  for (const path of paths) {
    bounds.extend(new google.maps.LatLng(path));
  }
  const center = bounds.getCenter();
  if (!center) return { lat: 0, lng: 0 };
  return {
    lat: center.lat(),
    lng: center.lng(),
  };
};
