import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SettingsScreen } from '../screens/SettingsScreen';
import { DeliveryZones } from '../screens/SettingsScreen/DeliveryZones';
import { DriverNotFound } from '../screens/SettingsScreen/DriverNotFound';
import { ProtectedRoute } from '~/view/components/CustomRoute';
import DeliveryTrackingScreen from '~/view/screens/DeliveryTrackingScreen/DeliveryTrackingScreen';
import LoginScreen from '~/view/screens/LoginScreen/LoginScreen';
import ModalMessages from '~/view/containers/ModalMessages/ModalMessages';
import Version from '~/view/containers/Version/Version';
import Sidebar from '~/view/containers/Sidebar/Sidebar';
import { localesStore } from '~/mobx';
import { userStore } from '~/mobx';
import { appStore } from '~/mobx';
import ModalsContainer from '~/view/containers/ModalsContainer/ModalsContainer';
import DashboardScreen from '~/view/screens/DashboardScreen/DashboardScreen';
import DspManagementScreen from '~/view/screens/DspManagementScreen/DspManagementScreen';
import GuidelineScreen from '~/view/screens/GuidelineScreen/GuidelineScreen';
import { USER_PERMISSIONS, USER_ROLES } from '~/constants/user';
import DeliveryRequestsScreen from '~/view/screens/DeliveryRequestsScreen';
import LogScreen from '~/view/screens/LogScreen/LogScreen';
import './style.scss';

const RootView = () => {
  useEffect(() => {
    if (localesStore.language === 'ar') {
      window.document.dir = 'rtl';
    }
    if (window.IS_MOBILE) {
      window.document.body.setAttribute('aria-mobile', 'yes');
    }
  }, []);

  return userStore.isHydrated ? (
    <BrowserRouter>
      {userStore.isLogin ? (
        <div className="page-container bg-graysoft">
          <Sidebar />
          <div className={`page-wrapper ${appStore.sidebar ? 'page-wrapper_scale' : ''}`}>
            <div className="router-wrapper">
              <React.Fragment>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute permissions={USER_PERMISSIONS.DASHBOARD} roles={[USER_ROLES.TG_OPERATION_MANAGER]}>
                        <DashboardScreen />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/tracking/:group">
                    <Route
                      path=":id"
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_OPERATOR]}
                          permissions={USER_PERMISSIONS.DASHBOARD}>
                          <DeliveryTrackingScreen />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=""
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_OPERATOR]}
                          permissions={USER_PERMISSIONS.DASHBOARD}>
                          <DeliveryTrackingScreen />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="/dsp_management/:type">
                    <Route
                      path=":id"
                      element={
                        <ProtectedRoute roles={[USER_ROLES.TG_OPERATION_MANAGER]} permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DspManagementScreen />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=""
                      element={
                        <ProtectedRoute roles={[USER_ROLES.TG_OPERATION_MANAGER]} permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DspManagementScreen />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="/requests">
                    <Route
                      path=""
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.TG_OPERATOR]}
                          permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DeliveryRequestsScreen />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=":id"
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.TG_OPERATOR]}
                          permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DeliveryRequestsScreen />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER]} permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                        <SettingsScreen />
                      </ProtectedRoute>
                    }>
                    <Route
                      path="/settings/unavailable-zones"
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER]}
                          permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DeliveryZones />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/settings/driver-not-found"
                      element={
                        <ProtectedRoute
                          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER]}
                          permissions={USER_PERMISSIONS.DSP_MANAGEMENT}>
                          <DriverNotFound />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="/:id/log"
                    element={
                      <ProtectedRoute permissions={USER_PERMISSIONS.LOG}>
                        <LogScreen />
                      </ProtectedRoute>
                    }
                  />

                  {process.env.NODE_ENV === 'development' ? <Route path="/guideline" element={<GuidelineScreen />} /> : null}
                  <Route path="*" element={<Navigate to="/tracking/all/" replace />} />
                  {/* <Route path="*" element={<Navigate to="/oops" replace />} /> */}
                </Routes>
                <ModalsContainer />
              </React.Fragment>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-wrapper">
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </div>
      )}
      <Version />
      <ModalMessages />
    </BrowserRouter>
  ) : null;
};

export default observer(RootView);
