import React from 'react';
import { observer } from 'mobx-react';
import { Spinner } from 'taker-web-ui';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';
import theme, { FONT_FAMILY } from '~/constants/styles';
import Switcher from '~/view/components/Controls/Switcher/Switcher';
import NoResults from '~/view/components/NoResults/NoResults';
import Text from '~/view/components/Typo/Text/Text';
import { driverNotFoundStore, localesStore } from '~/mobx';
import { SearchInput } from '~/view/components/Controls/SearchInput';
import InputOld from '~/view/components/Controls/InputOld/InputOld';
import './style.scss';
import { LoadingStatus } from '~/types/main';
import { IDNFOrganization } from '~/types/dnf';

class DriverNotFound extends React.Component {
  state = {
    search: '',
    cutoffTime: '',
    error: false,
    edited: false,
  };

  timeoutId: NodeJS.Timeout | undefined = undefined;

  componentDidMount() {
    driverNotFoundStore.getOrganizations();
    driverNotFoundStore.getCutoffTime();
  }

  componentDidUpdate(_: any, prevState: any) {
    const { cutoffTime } = this.state;
    if (cutoffTime === '' && prevState.cutoffTime === '' && driverNotFoundStore.cutoffTime) {
      this.setState({ cutoffTime: driverNotFoundStore.cutoffTime });
    }
  }

  handleChangeAllOrganizations = async (isOptedIn: boolean) => {
    await driverNotFoundStore.setOrganizations(isOptedIn);
  };

  changeStatus = async (organization: IDNFOrganization, status: boolean) => {
    await driverNotFoundStore.setOrganization(organization, status);
  };

  switcher = (org: IDNFOrganization) => {
    return (
      <div className="organization-toggle" key={`organization-${org.id}`}>
        <Text size={16} className="mr-3" family={FONT_FAMILY.SEMIBOLD}>
          {org.name}
        </Text>
        <Switcher status={org.isOptedIn} onChange={(status) => this.changeStatus(org, status)} />
      </div>
    );
  };

  isValueNotValid = (value: string) => {
    return Number(value) < 1 || Number(value) > 1440;
  };

  handleChangeCutoffTime = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    this.handleSaveCutoffTime({ cutoffTime: value, withTimeout: true });
  };

  handleSearch = (search: string) => this.setState({ search });

  handlePasteCutoffTime = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const value = ev.clipboardData.getData('text/plain');

    this.handleSaveCutoffTime({ cutoffTime: value, withTimeout: false });
  };

  handleClearCutoffTimeField = () => {
    clearTimeout(this.timeoutId);
    this.setState({ cutoffTime: '', error: false, edited: false });
  };

  handleBlurCutoffTime = () => {
    const { edited, cutoffTime } = this.state;

    if (this.isValueNotValid(cutoffTime)) {
      this.setState({ cutoffTime: driverNotFoundStore.cutoffTime, edited: false, error: false });
    } else if (edited) {
      this.handleSaveCutoffTime({ cutoffTime: this.state.cutoffTime, withTimeout: false });
    } else if (cutoffTime === '') {
      this.setState({ cutoffTime: driverNotFoundStore.cutoffTime });
    }
  };

  handleSaveCutoffTime = ({ cutoffTime, withTimeout = false }: { cutoffTime: string; withTimeout: boolean }) => {
    clearTimeout(this.timeoutId);

    if (/\D/.test(cutoffTime) || /^0/.test(cutoffTime)) {
      return;
    }

    const error = this.isValueNotValid(cutoffTime);

    if (!error && !withTimeout) {
      this.setState({ edited: false, cutoffTime, error: false }, () => {
        driverNotFoundStore.setCutoffTime(cutoffTime);
      });
    } else if (!error && withTimeout) {
      this.setState({ edited: true, cutoffTime, error: false }, () => {
        this.timeoutId = setTimeout(() => {
          if (this.state.edited) {
            this.setState({ edited: false }, () => {
              driverNotFoundStore.setCutoffTime(cutoffTime);
            });
          }
        }, 3000);
      });
    } else if (error) {
      this.setState({ error, cutoffTime });
    }
  };

  render() {
    const { search, cutoffTime, error } = this.state;
    const filteredOrganizations = driverNotFoundStore.organizations
      .filter(({ name }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="d-flex flex-column flex-1">
        {/* <div> */}
        <div className={'cutofftime-input-wrapper d-flex flex-row'}>
          <InputOld
            small
            labelLeft={`${localesStore.t('driver_search_cutoff')} (${localesStore.t('min')}):`}
            className={'text-size-16'}
            containerClass={'cutoff-container'}
            value={cutoffTime}
            onPaste={this.handlePasteCutoffTime}
            onChange={this.handleChangeCutoffTime}
            onFocus={this.handleClearCutoffTimeField}
            onBlur={this.handleBlurCutoffTime}
            error={error ? localesStore.t('cutoff_time_field_error') : false}
            pattern="[0-9]*"
            maxLength={4}
          />
          <div className={'loader-container'}></div>
        </div>
        {/* </div> */}
        <div className="d-flex flex-row pr-4">
          <div className={'organizations-search d-flex flex-row full-width'}>
            <SearchInput
              className="input-wrapper organization-filter-search color-gray5 organization-filter-search-field bg-transparent mt-4"
              onChangeText={this.handleSearch}
              placeholder={localesStore.t('search_box_clients')}
              customPlaceholder
              withCloseIcon
              loading={false}
              value={search}
            />
          </div>
          {/* <div className="mt-4 d-flex justify-content-end align-items-center">
            <div className="bulk-status-update-button" onClick={() => this.handleChangeAllOrganizations(true)}>
              {localesStore.t('enable_all_button')}
            </div>
            <div className="bulk-status-update-button" onClick={() => this.handleChangeAllOrganizations(false)}>
              {localesStore.t('disable_all_button')}
            </div>
          </div> */}
        </div>
        {[LoadingStatus.Error, LoadingStatus.Success, LoadingStatus.Loading].includes(driverNotFoundStore.organizationsStatus) &&
        filteredOrganizations.length > 0 ? (
          <ScrollableBlock className="flex-1 max-height color-gray5 mt-4">
            <div className="organization-toggles-wrapper hide-scrollbar">{filteredOrganizations.map(this.switcher)}</div>
          </ScrollableBlock>
        ) : (
          <div className="requests-table__empty pos-absolute">
            <NoResults />
          </div>
        )}
        {(driverNotFoundStore.cutoffTimeStatus === LoadingStatus.Loading || driverNotFoundStore.organizationsStatus === LoadingStatus.Loading) && (
          <div className={`scroll-list-refreshing d-flex dnf-spinner-overlay`}>
            <div className="d-flex flex-grow-1 justify-content-center m-auto">
              <Spinner color={theme.colors.mainblue} size={24} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default observer(DriverNotFound);
