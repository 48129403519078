import { Component } from 'react';
import Calendar, {
  CalendarProps,
  CalendarType,
  DateCallback,
  Detail,
  FormatterCallback,
  OnChangeDateCallback,
  OnChangeDateRangeCallback,
} from 'react-calendar';
import { ICONS } from '~/constants/icons';
import { LOCALES_DOUBLE } from '~/constants/main';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

interface IProps extends CalendarProps {
  locale: string;
  calendarType?: CalendarType;
  selectRange?: boolean;
  onChange?: OnChangeDateCallback | OnChangeDateRangeCallback | undefined;
  onClickDay?: DateCallback | undefined;
  defaultValue?: Date | Date[];
  minDetail?: Detail;
  prevLabel?: string | JSX.Element | null | undefined;
  nextLabel?: string | JSX.Element | null | undefined;
  formatMonth?: FormatterCallback;
  formatShortWeekday?: FormatterCallback;
  className?: string;
}

export default class ReactCalendar extends Component<IProps, {}> {
  render() {
    return (
      <Calendar
        {...this.props}
        locale={this.props.locale}
        calendarType={this.props.calendarType}
        selectRange={this.props.selectRange}
        onChange={this.props.onChange}
        onClickDay={this.props.onClickDay || undefined}
        defaultValue={this.props.defaultValue}
        minDetail={this.props.minDetail}
        prevLabel={
          this.props.locale === LOCALES_DOUBLE.ar
            ? this.props.nextLabel || <Icon name={ICONS.SliderRight} />
            : this.props.prevLabel || <Icon name={ICONS.SliderLeft} />
        }
        nextLabel={
          this.props.locale === LOCALES_DOUBLE.ar
            ? this.props.prevLabel || <Icon name={ICONS.SliderLeft} />
            : this.props.nextLabel || <Icon name={ICONS.SliderRight} />
        }
        formatMonth={this.props.locale === LOCALES_DOUBLE.ar ? undefined : this.props.formatMonth}
        formatShortWeekday={this.props.locale === LOCALES_DOUBLE.ar ? undefined : this.props.formatShortWeekday}
        className={this.props.className}
      />
    );
  }
}
