export const DnfTopStatisticsCast = (top: any) => ({
  name: String(top.name),
  count: Number(top.count),
});

export const DnfCoordinatesStatisticsCast = (coordinate: any) => ({
  name: String(coordinate.name),
  count: Number(coordinate.count),
  latitude: Number(coordinate.latitude),
  longitude: Number(coordinate.longitude),
});

type DnfCoordinatesStatistics = ReturnType<typeof DnfCoordinatesStatisticsCast>;

export const DnfStatisticsCast = (statistics: any) => ({
  count: Number(statistics.count),
  top_organizations: statistics.top_organizations.map(DnfTopStatisticsCast),
  top_branches: statistics.top_branches.map(DnfTopStatisticsCast),
  coordinates: (statistics?.coordinates || []).map(DnfCoordinatesStatisticsCast) as DnfCoordinatesStatistics[],
});

export type IDNFTopStatistics = ReturnType<typeof DnfTopStatisticsCast>;
export type IDNFCoordinatesStatistics = ReturnType<typeof DnfCoordinatesStatisticsCast>;
export type IDNFStatistics = ReturnType<typeof DnfStatisticsCast>;
