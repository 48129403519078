import { action, observable, makeObservable, flow, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import client from '~/services/client';
import { DnfStatisticsCast, IDNFStatistics } from '~/types/dnfStatistics';
import { LoadingStatus } from '~/types/main';

interface TopLegend {
  name: string;
  count: number;
}

interface Coordinates {
  name: string;
  count: number;
  latitude: number;
  longitude: number;
}

interface GetStatisticsResponse {
  status: string;
  data: {
    count: number;
    top_organizations: TopLegend[];
    top_branches: TopLegend[];
    coordinates: Coordinates[];
  };
}

class DNFStatisticsStore {
  constructor() {
    makeObservable(this, {
      statistics: observable,
      statisticsStatus: observable,
      statisticMapIsVisible: observable,
      getStatistics: action,
    });

    makePersistable(this, {
      storage: window.localStorage,
      name: 'DNFStatisticsStore',
      properties: ['statisticMapIsVisible'],
    }).catch(console.error);

    reaction(
      () => this.statisticMapIsVisible,
      (statisticMapIsVisible) => {
        if (statisticMapIsVisible) {
          setTimeout(() => {
            this.getStatistics();
          }, 1000);
          this.statisticsAutoUpdateInterval = setInterval(() => {
            this.getStatistics();
          }, 60000);
        } else {
          clearInterval(this.statisticsAutoUpdateInterval);
        }
      },
    );
  }

  public statistics: IDNFStatistics | undefined = undefined;
  public statisticsStatus: LoadingStatus = LoadingStatus.Init;
  public statisticsAutoUpdateInterval: any;
  public statisticMapIsVisible = false;

  public getStatistics = flow(function* (this: DNFStatisticsStore) {
    try {
      this.statisticsStatus = LoadingStatus.Loading;

      const response: GetStatisticsResponse = yield client.get('/v1/deliveryRequestNdf');

      this.statistics = DnfStatisticsCast(response.data);

      this.statisticsStatus = LoadingStatus.Success;
    } catch (error) {
      this.statisticsStatus = LoadingStatus.Error;
    }
  }).bind(this);
}

const dnfStatisticsStore = new DNFStatisticsStore();

export default dnfStatisticsStore;
