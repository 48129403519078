import React, { Component } from 'react';

import { observer } from 'mobx-react';
import LogListColumnTitle from './components/LogListColumnTitle/LogListColumnTitle';
import LogListItem from './components/LogListItem/LogListItem';
import NoResults from '~/view/components/NoResults/NoResults';
import { localesStore, logStore } from '~/mobx';
import { LOG_SORT_COLUMN, TLog } from '~/types/log';
import Pagination from '~/view/components/Controls/Pagination/Pagination';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';

class Log extends Component {
  componentDidMount = () => {
    logStore.fetchLog(1);
  };

  renderLogItem = (logItem: TLog) => {
    return <LogListItem key={logItem.id} log={logItem} />;
  };

  render() {
    return (
      <div className="d-flex flex-column flex-grow-1 mt-4">
        <div className="flex-grow-1 pt-4 d-flex flex-column mr-4">
          <ul className="d-flex flex-row mb-4">
            <LogListColumnTitle
              className="w-19 d-flex align-items-center"
              itemName={localesStore.t('time_column_header')}
              columnName={LOG_SORT_COLUMN.DATE}
              withFilter={true}
            />
            <LogListColumnTitle
              className="w-20 d-flex align-items-center"
              itemName={localesStore.t('author_column_header')}
              columnName={LOG_SORT_COLUMN.AUTHOR}
              withFilter={true}
            />
            <LogListColumnTitle
              className="w-15 d-flex align-items-center"
              itemName={localesStore.t('object_column_header')}
              columnName={LOG_SORT_COLUMN.OBJECT}
              withFilter={true}
            />
            <LogListColumnTitle
              className="w-17 d-flex align-items-center"
              itemName={localesStore.t('event_column_header')}
              columnName={LOG_SORT_COLUMN.EVENT}
              withFilter={true}
            />
            <LogListColumnTitle
              className="w-17 d-flex align-items-center"
              itemName={localesStore.t('old_value_column_header')}
              columnName={LOG_SORT_COLUMN.OLD_VALUE}
              withFilter={true}
            />
            <LogListColumnTitle
              className="w-17 d-flex align-items-center"
              itemName={localesStore.t('new_value_column_header')}
              columnName={LOG_SORT_COLUMN.NEW_VALUE}
              withFilter={true}
            />
          </ul>

          <ScrollableBlock scrollClassName="d-flex flex-column full-height" className="mb-3">
            {logStore.pagination.total === 0 && !logStore.pagination.loading ? (
              logStore.filtersWasSelected ? (
                <div className={'my-auto mx-auto text-center'}>
                  <p className="text-size-18 my-auto color-gray5 font-weight-600">
                    <span>{localesStore.t('filters_no_results')}</span>
                  </p>
                  <p className="text-size-16 my-auto color-gray5 font-weight-500">
                    <span>{localesStore.t('adjust_filters')}</span>
                  </p>
                </div>
              ) : (
                <NoResults />
              )
            ) : (
              logStore.pagination.data.map(this.renderLogItem)
            )}
          </ScrollableBlock>
          {logStore.pagination.total !== 0 ? (
            <Pagination pagination={logStore.pagination} onChange={(page) => logStore.fetchLog(page)} overlayClassName="overlay_white" />
          ) : null}
        </div>
      </div>
    );
  }
}

export default observer(Log);
