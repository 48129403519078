import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import ReactCalendar from '~/view/components/ReactCalendar/ReactCalendar';
import { LOCALES, LOCALES_DOUBLE } from '~/constants/main';
import { IDateFilter } from '~/types/log';
import { localesStore } from '~/mobx';
import './style.scss';

interface IProps {
  values?: IDateFilter;
  rawValues: Date[] | null;
  close: () => void;
  onChange: (value: any[]) => void;
  bottom?: React.ReactNode;
  maxPeriod?: number;
}

type State = {
  firstDate: Date | null;
  lastDate: Date | null;
};

class ColumnCalendarFilter extends React.Component<IProps, State> {
  state = {
    firstDate: this.props.rawValues ? this.props.rawValues[0] : null,
    lastDate: this.props.rawValues ? this.props.rawValues[1] : null,
  };

  handleClickDay = (date: Date) => {
    if (this.isSelectingFirstDate()) {
      this.setState({ firstDate: date, lastDate: null });
    } else if (this.isSelectingLastDate()) {
      if (this.state.firstDate && date > this.state.firstDate) {
        this.setState({ lastDate: date });
      } else {
        this.setState(({ firstDate }) => ({
          lastDate: firstDate,
          firstDate: date,
        }));
      }
    }
  };

  isSelectingFirstDate = () => !this.state.firstDate || this.state.lastDate;
  isSelectingLastDate = () => !this.state.lastDate || this.state.firstDate;

  displayFirstDate = () => {
    if (this.props.values?.from_date) {
      return this.props.values.from_date || '-';
    }

    return this.state.firstDate ? moment(this.state.firstDate).format('DD/MM/YYYY') : '-';
  };

  displayLastDate = () => {
    if (this.props.values?.to_date) {
      return this.props.values.to_date || '-';
    }

    return this.state.lastDate ? moment(this.state.lastDate).format('DD/MM/YYYY') : '-';
  };

  public render() {
    const { onChange, rawValues, bottom = null, maxPeriod } = this.props;
    const { firstDate, lastDate } = this.state;

    const minDate = maxPeriod && firstDate ? moment(firstDate).subtract(maxPeriod, 'days').toDate() : undefined;
    const maxDate =
      maxPeriod && firstDate && moment(firstDate).add(maxPeriod, 'days').toDate() < new Date()
        ? moment(firstDate).add(maxPeriod, 'days').toDate()
        : new Date();

    return (
      <div className="filter-dropdown-calendar-modal">
        <div className="selected-dates-wrapper">
          <div className={`filter-date ${firstDate && lastDate ? 'light' : ''}`}>
            <div className="filter-date__title">{localesStore.t('start_date_calendar')}</div>
            <div className="filter-date__value">{this.displayFirstDate()}</div>
          </div>
          <div className={`filter-date ${lastDate ? 'light' : ''}`}>
            <div className="filter-date__title">{localesStore.t('end_date_calendar')}</div>
            <div className="filter-date__value">{this.displayLastDate()}</div>
          </div>
        </div>
        <ReactCalendar
          className="filter-dropdown-calendar"
          maxDate={maxDate}
          minDate={minDate}
          locale={localesStore.language === LOCALES.EN ? LOCALES_DOUBLE.en : LOCALES_DOUBLE.ar}
          selectRange={true}
          onChange={onChange}
          // onClickMonth={(date) => console.log(date)}
          onClickDay={this.handleClickDay}
          defaultValue={rawValues || undefined}
          // minDetail="decade"
          formatMonth={(locale, date) => moment(date).format('MMM')}
          formatShortWeekday={(locale, date) => moment(date).format('dd')}
        />
        {bottom}
      </div>
    );
  }
}

export default observer(ColumnCalendarFilter);
