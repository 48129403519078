import { observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';
import TopBar from './containers/TopBar';
import styles from '~/constants/styles';
import { RequestStatus, REQUESTS_STATUSES_LABELS, TDriver, TDsp, TimelineTypes } from '~/constants/tracking';
import { formatPhoneNumber, getOrderDspId, getRequestId, truncateLongString } from '~/utils/formatter';
import Modal from '~/view/components/Controls/Modal/Modal';
import Sizes from '~/view/components/Controls/Sizes/Sizes';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import Label from '~/view/components/Label/Label';
import Timeline, { TimelineItem } from '~/view/components/Timeline/Timeline';
import CopyButton from '~/view/screens/DeliveryTrackingScreen/components/CopyButton/CopyButton';
import { localesStore, trackingStore } from '~/mobx';
import ModalHeader from '~/view/components/Controls/Modal/ModalHeader/ModalHeader';
import ModalContent from '~/view/components/Controls/Modal/ModalContent/ModalContent';
import ModalBackground from '~/view/components/Controls/Modal/ModalBackground/ModalBackground';
import { modalStore } from '~/mobx';
import Icon from '~/view/components/Controls/Icon/Icon';
import CustomTooltip from '~/view/components/Controls/CustomTooltip/CustomTooltip';
import { showNAInsteadNull } from '~/utils';
import './style.scss';
import { USER_ROLES, USER_PERMISSIONS } from '~/constants/user';
import ExternalOpenButton from '~/view/screens/DeliveryTrackingScreen/components/ExternalOpenButton/ExternalOpenButton';
import PopupTimers from '~/view/screens/DeliveryTrackingScreen/components/RequestItem/components/PopupTimers';

type State = {
  id: string;
  assigned_at: string | null;
  picked_up_at: string | null;
  final_at: string | null;
  timelineType: TimelineTypes;
  orderId: string;
  driver: TDriver | null;
  dspName: string;
  status: RequestStatus;
  withoutDataUpdate: boolean;
};

class RequestInfoModal extends Component<{}, State> {
  state = {
    id: trackingStore.trackingRequest?.request.id!,
    assigned_at: trackingStore.trackingRequest?.request.assigned_at!,
    picked_up_at: trackingStore.trackingRequest?.request.picked_up_at!,
    final_at: trackingStore.trackingRequest?.request.final_at!,
    timelineType: TimelineTypes.request,
    dspName: trackingStore.trackingRequest?.dsp.name!,
    orderId: String(trackingStore.trackingRequest?.dsp.order_id),
    status: trackingStore.trackingRequest?.request.status!,
    driver: trackingStore.trackingRequest?.driver || null,
    withoutDataUpdate: false,
  };

  timeLineTypes = [
    {
      value: TimelineTypes.request,
      name: localesStore.t('request_timeline_switcher'),
      key: TimelineTypes.request,
    },
    {
      value: TimelineTypes.full,
      name: localesStore.t('full_timeline_switcher'),
      key: TimelineTypes.full,
    },
  ];

  componentDidMount() {
    trackingStore.getTimeline();

    this.setState({ orderId: getOrderDspId(trackingStore.trackingRequest?.dsp!) });
  }

  componentDidUpdate() {
    if (this.state.withoutDataUpdate) {
      this.state.withoutDataUpdate = false;
      return;
    }

    let isUpdateTimeline = false;

    if (trackingStore.trackingRequest?.request.status !== this.state.status) {
      isUpdateTimeline = true;

      this.setState({
        id: trackingStore.trackingRequest?.request.id!,
        status: trackingStore.trackingRequest?.request.status!,
        assigned_at: trackingStore.trackingRequest?.request.assigned_at ?? null,
        picked_up_at: trackingStore.trackingRequest?.request.picked_up_at ?? null,
        final_at: trackingStore.trackingRequest?.request.final_at ?? null,
      });
    }

    if (trackingStore.trackingRequest?.driver !== this.state.driver) {
      isUpdateTimeline = true;
      this.setState({ driver: trackingStore.trackingRequest?.driver! });
    }

    if (isUpdateTimeline) {
      trackingStore.getTimeline();
    }
  }

  closeModal = () => {
    modalStore.closeRequestInfoModal();
  };

  handleSetOrderIdAndDriver = async (item: { id: number; dsp: TDsp }) => {
    const {
      dsp: { name: dspName },
    } = item;
    const orderId = getOrderDspId(item.dsp);
    const driver: TDriver | undefined | undefined = trackingStore.timeline.find((v) => v.dsp.order_id === orderId && v.driver)?.driver;

    // Find last status for this DSP
    const status = [...trackingStore.timeline].reverse().find((v) => getOrderDspId(v.dsp) === orderId)?.status || this.state.status;

    this.setState({ dspName, orderId, driver: driver || null, status, withoutDataUpdate: true });

    const nextRequest = await trackingStore.getSingleRequest(item.id.toString());

    if (nextRequest) {
      this.setState({
        id: nextRequest.request.id,
        assigned_at: nextRequest.request.assigned_at,
        picked_up_at: nextRequest.request.picked_up_at,
        final_at: nextRequest.request.final_at,
        driver: nextRequest.driver,
        withoutDataUpdate: true,
      });
    }
  };
  getTimeline = () => {
    let timeline: TimelineItem<any>[] = trackingStore.timeline.map((t) => ({
      item: t,
      danger: t.is_wrong,
    }));

    timeline.forEach((timelineItem) => {
      if (timelineItem.danger) {
        timeline = timeline.map((tItem) => {
          if (timelineItem.item.dsp.order_id === tItem.item.dsp.order_id) {
            return { ...tItem, danger: true };
          }

          return tItem;
        });
      }
    });

    return timeline;
  };

  makeAdditionalInformationString = ({ from, user, reason }: { from: string; user: string; reason: string }) => (
    <div className={'d-flex flex-column text-uppercase text-size-12 family-medium color-gray2'}>
      <div style={{ paddingLeft: 15 }}>
        {user && `${localesStore.t('by')} ${localesStore.t(user)} `}
        {from && localesStore.t(from)}
      </div>
      {reason && <div style={{ paddingLeft: 15 }}>{localesStore.t(reason)}</div>}
    </div>
  );

  renderTimeline = () => {
    const transformedTimeline: TimelineItem<any>[] = this.getTimeline(); // this.getTransformedTimeline()
    const getRequestTimeLine = (): TimelineItem<any>[] => {
      const requestTimeline = transformedTimeline.filter((t) => {
        const id = getOrderDspId(t.item.dsp);

        return id === this.state.orderId;
      });

      return requestTimeline;
    };

    // const currentStatus: RequestStatus = this.state.status;
    const currentDspOrderIdItems = transformedTimeline.filter((v) => {
      const id = getOrderDspId(v.item.dsp);

      return id === this.state.orderId;
    });

    const lastItemByCurrentOrderId = currentDspOrderIdItems[currentDspOrderIdItems.length - 1]?.item;

    const isNotFull = (status: RequestStatus) => {
      return status !== RequestStatus.DELIVERED && status !== RequestStatus.CANCELED && status !== RequestStatus.RETURNED;
    };

    return (
      <Timeline
        data={this.state.timelineType === TimelineTypes.full ? transformedTimeline : getRequestTimeLine()}
        notFull={
          this.state.timelineType === TimelineTypes.full && transformedTimeline.length > 0
            ? isNotFull(transformedTimeline[transformedTimeline.length - 1].item.status)
            : isNotFull(lastItemByCurrentOrderId?.status)
        }
        leading={({ item }) => {
          const id = getOrderDspId(item.dsp);

          return (
            <div className={'d-flex direction-column align-items-center'} style={{ height: 'min-content' }}>
              <div className={'text-size-16 mr-3 color-gray2'}>{item.index}</div>
              <div>
                <div className={'text-size-14 font-weight-600'}>{item.dsp.name}</div>
                <div className={'text-size-12 color-mainblue cursor-pointer'} onClick={() => this.handleSetOrderIdAndDriver(item)}>
                  {truncateLongString(showNAInsteadNull(id))}
                </div>
              </div>
            </div>
          );
        }}
        trailing={({ item, danger }, i: number) => (
          <div className={'d-flex flex-column flex-1'}>
            <div className={'d-flex flex-row mb-2 flex-1 justify-content-between'}>
              <Label
                className={'text-size-12 height-min letter-spacing-5'}
                text={localesStore.t(REQUESTS_STATUSES_LABELS[item.status as RequestStatus])}
                color={danger ? 'red' : undefined}
              />
              {item.raw_response && item.status === 0 && (
                <CustomTooltip
                  messageClassName="text-wrap-anywhere"
                  duration={5000}
                  type="info"
                  tooltipClassName={i === 0 ? 'info-tooltip-bottom' : 'info-tooltip'}
                  message={`${localesStore.t('request_rejected_dsp_response', { dsp_name: item.dsp.name })} ${JSON.stringify(item.raw_response)}`}>
                  <Icon name="info" className="info-icon text-pointer" />
                </CustomTooltip>
              )}
              <div className={'d-flex flex-1 justify-content-end align-items-center text-size-14 color-black font-weight-600 family-semibold'}>
                {moment(item.created_at).format('D/M/Y, h:mm:ss A')}
              </div>
            </div>
            {item.driver ? (
              <div className={'d-flex flex-row text-uppercase text-size-12 family-medium color-gray2'} style={{ position: 'relative' }}>
                <div style={{ paddingLeft: 15 }}>
                  {item.driver?.name}, {item.driver?.phone || ''}
                </div>
              </div>
            ) : null}
            {this.makeAdditionalInformationString(item)}
          </div>
        )}
      />
    );
  };

  handleChangeTimelineType = (option: TimelineTypes) => {
    this.setState({ timelineType: option, withoutDataUpdate: true });
  };

  getColorForStatus = (status: RequestStatus): string => {
    switch (status) {
      case RequestStatus.NO_STATUS:
        return 'grey';
      case RequestStatus.DELIVERED:
        return 'green';
      case RequestStatus.CANCELED:
        return 'red';
      case RequestStatus.RETURNED:
        return 'red';
      case RequestStatus.DSP_ISSUE:
        return 'grey';
      case RequestStatus.NOT_SENT:
        return 'grey';
      default:
        return 'blue';
    }
  };

  render() {
    const request = trackingStore.trackingRequest;

    if (!request) return null;

    return (
      <Modal
        className="order-info-modal"
        isOpen={modalStore.requestInfoModal !== null}
        onRequestClose={this.closeModal}
        withCloseIcon
        iconClassName={'color-gray2'}>
        <ModalBackground>
          <ModalHeader title={`${this.state.dspName}:`} className={'align-items-center'}>
            <div className={'text-size-24 ml-1 color-gray3 font-weight-500'}>{truncateLongString(showNAInsteadNull(this.state.orderId))}</div>
            <div className={'ml-3'}>{this.state.orderId !== '' && <CopyButton data={this.state.orderId} />}</div>
            {request.dsp.dsp_url !== null && (
              <div className={'ml-3 external-open-button-header'}>
                <ExternalOpenButton
                  url={request.dsp.dsp_url}
                  roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.TG_OPERATOR]}
                  permissions={USER_PERMISSIONS.DASHBOARD}
                />
              </div>
            )}
            <Label
              text={localesStore.t(`${REQUESTS_STATUSES_LABELS[this.state.status as RequestStatus]}`)}
              color={this.getColorForStatus(this.state.status)}
              className={'margin-15'}
            />
            <PopupTimers assignedAt={this.state.assigned_at} pickedUpAt={this.state.picked_up_at} finalAt={this.state.final_at} />
          </ModalHeader>
          <ModalContent className={'h-100'} contentClassName={'h-100'}>
            <div className={'d-flex flex-column h-100'} style={{ overflow: 'hidden' }}>
              <TopBar
                clientOrder={getRequestId(request.organization)}
                createdAt={request.request.external_created_at}
                paymentType={request.request.payment_type}
                distance={request.request.distance}
                orderDueTime={request.request.due_at}
                orderTotal={request.request.total}
                orderUrl={request.organization.order_url}
              />

              <div className={'d-flex flex-row mb-5'}>
                <Sizes
                  onChange={this.handleChangeTimelineType}
                  options={this.timeLineTypes}
                  itemStyle={{ whiteSpace: 'nowrap' }}
                  selected={this.state.timelineType}
                />
              </div>

              <div className={'d-flex flex-row flex-5 flex-grow-1'} style={{ overflow: 'hidden' }}>
                <div className={'flex-3'} style={{ overflowY: 'auto', marginRight: 40 }}>
                  {trackingStore.timelineLoading ? (
                    <div className="d-flex flex-grow-1 justify-content-center m-auto">
                      <Spinner color={styles.colors.mainblue} size={24} />
                    </div>
                  ) : (
                    this.renderTimeline()
                  )}
                </div>

                <div className={'info-blocks d-flex flex-column flex-2 justify-content-between'}>
                  <div className={'d-flex flex-column'}>
                    <div className={'text-size-18 color-gray2 mb-4 text-uppercase'}>{localesStore.t('delivery_from')}</div>
                    <div className={'text-size-16 color-black family-bold mb-2'}>
                      {`${request.organization.name}${request.organization.branch ? `, ${request.organization.branch}` : ``}`}
                    </div>
                    <div className={'text-size-14 color-black'}>
                      <div>{`${request.organization.phone ? formatPhoneNumber(request.organization.phone) : ''}`}</div>
                      {request.organization.address}
                    </div>
                  </div>

                  <div className={'d-flex flex-column'}>
                    <div className={'text-size-18 color-gray2 mb-4 text-uppercase'}>{localesStore.t('delivery_to')}</div>
                    <div className={'text-size-16 color-black family-bold mb-2'}>{request.customer.name}</div>
                    <div className={'text-size-14 color-black'}>
                      <div>{`${request.customer.phone ? formatPhoneNumber(request.customer.phone) : ''}`}</div>
                      {request.customer.address}
                    </div>
                  </div>

                  <div className={'d-flex flex-column'}>
                    <div className={'text-size-18 color-gray2 mb-4 text-uppercase'}>{localesStore.t('driver_info')}</div>
                    <div className={'text-size-16 color-black family-bold mb-2'}>{this.state.driver?.name}</div>
                    <div className={'text-size-14 color-black'}>
                      <div>{`${this.state.driver?.phone ? formatPhoneNumber(this.state.driver.phone) : ''}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalContent>
        </ModalBackground>
      </Modal>
    );
  }
}

export default observer(RequestInfoModal);
