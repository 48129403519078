import { observer } from 'mobx-react';
import { Component } from 'react';

import { DSP_GROUPS, DSP_STATUS_LABELS, DSP_TYPE, DSP_TYPES_GROUPS } from '~/constants/dsp';
import { dspStore } from '~/mobx';
import { localesStore } from '~/mobx';
import { TabLabel } from '~/view/components/Controls/TabLabel/TabLabel';
import { RouterComponentProps } from '~/types/main';
import { withRouter } from '~/utils';
import './style.scss';

type IProps = RouterComponentProps<
  { id?: string; type: string },
  {
    onStatusChange: (newStatus: string) => void;
    status: DSP_GROUPS;
  }
>;

class DspStatuses extends Component<IProps, {}> {
  async componentDidMount() {
    await dspStore.setDspsFilters({ status: this.props.status, value: '' }, false);
  }

  onTabPress = async (status: DSP_GROUPS) => {
    await dspStore.setDspsFilters({ status, value: '' }, false);

    this.props.onStatusChange(status);
  };

  renderStatus = (status: DSP_GROUPS) => (
    <TabLabel
      type={DSP_TYPE.ALL}
      active={status === dspStore.dspsFilters.status}
      key={status}
      value={status}
      label={localesStore.t(DSP_STATUS_LABELS[status])}
      onPress={this.onTabPress}
    />
  );

  render() {
    const groups = Object.keys(DSP_TYPES_GROUPS[DSP_TYPE.ALL]) as DSP_GROUPS[];
    if (!groups.length) return null;

    return <div className="dsp-statuses d-flex flex-row align-items-center">{groups.map(this.renderStatus)}</div>;
  }
}

export default withRouter(observer(DspStatuses));
