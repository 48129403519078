import { observer } from 'mobx-react';
import * as React from 'react';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import { localesStore } from '~/mobx';
import { userStore } from '~/mobx';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

type TLink = {
  icon?: IconsId;
  iconSize: number;
  className?: string;
  name: string;
  onClick: () => void;
  permissions: string[];
  leftComponent?: React.ReactElement | null;
  rightComponent?: React.ReactElement | null;
};

class NavigationButton extends React.Component<TLink> {
  static defaultProps = {
    link: '',
    iconSize: 22,
    leftComponent: null,
    rightComponent: null,
    className: '',
  };

  public render() {
    if (
      userStore.user.permissions.length &&
      this.props.permissions.length &&
      !this.props.permissions.some((permission) => userStore.user.permissions.includes(permission))
    )
      return null;

    const { onClick, className = '', leftComponent, icon, iconSize, name, rightComponent } = this.props;

    return (
      <button onClick={onClick} className={`sidebar_link sidebar_button py-3 ${className}`}>
        <div className="sidebar_link-bg animate__faster animate__animated animate__slideInLeft"></div>
        {leftComponent}
        {icon ? <Icon name={icon} size={iconSize} className={`sidebar_link-icon ml-4 text-size-22`} /> : null}
        <p className={`sidebar_link-text ml-4 family-semibold text-size-18`}>{localesStore.t(name)}</p>
        {rightComponent}
      </button>
    );
  }
}

export default observer(NavigationButton);
