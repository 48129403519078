// import { clearInterval, setInterval } from 'timers';
import { action, computed, observable, makeObservable, flow } from 'mobx';
import moment from 'moment';
import React from 'react';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { localesStore, userStore, modalStore } from '~/mobx';
import {
  FilterDSP,
  filterDSPCast,
  RequestStatus,
  TDriverLocation,
  timelineCast,
  trackingRequestCast,
  TRACKING_GROUPS,
  TRequestsCountersAllResponse,
  TTrackingRequest,
  TTrackingRequestTimeline,
  TTrackingSingleRequest,
} from '~/constants/tracking';
import { MarkerType } from '~/view/components/Map/components/Marker';
import { Coords, CoordsWithType, TClustering, TClusteringSelected } from '~/view/components/Map/Map';
import {
  MapNotificationTypes,
  TMapNotificationProps,
} from '~/view/screens/DeliveryTrackingScreen/containers/MapNotificationContainer/components/MapNotification/MapNotification';
import client from '~/services/client';
import { addTimeZoneToRequest, addTimeZoneToTimeline } from '~/utils/handleTimeZone';
import { getRequestId } from '~/utils/formatter';
import { isReqEqualBySearch } from '~/utils';

type LastMapPos = {
  zoom: number;
  pos: Coords;
};

export type TTrackingRequestsFilters = {
  search?: string;
  category?: TRACKING_GROUPS;
  dsp?: string[];
};

export const defaultTrackingRequestsFilters: TTrackingRequestsFilters = {
  // search: '',
  category: TRACKING_GROUPS.ALL,
  dsp: [],
};

type TTrackingPagination = {
  pagination: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
  data: TTrackingRequest[];
};

export const defaultPagination = {
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  },
  data: [],
};

export class TrackingStore {
  constructor() {
    makeObservable<TrackingStore, 'driverLocationTimer'>(this, {
      addNotification: action,
      addRequest: action,
      addRequestBySocket: action,
      allCounters: observable,
      availableDriverLocation: observable,
      availableDSPs: observable,
      cancelDelivery: action,
      clustering: computed,
      deleteRequest: action,
      deliveryCancelLoading: observable,
      driverLocation: observable,
      driverLocationTimer: observable,
      getAllCounters: action,
      getAvailableDSPs: action,
      getRequest: action,
      getRequests: action,
      getSingleRequest: action,
      getTimeline: action,
      isAvailableDSPsLoading: observable,
      lastMapPosition: observable,
      notifications: observable,
      pages: observable,
      removeNotification: action,
      requestDriverLocation: action,
      requestLoading: observable,
      requestNextDsp: action,
      requestNextDspLoading: observable,
      requestsFilters: observable,
      requestsLoading: observable,
      selectedRequestId: computed,
      setLastMapPos: action,
      setRequestsFilters: action,
      sidebar: observable,
      sRef: observable,
      timeline: observable,
      timelineLoading: observable,
      trackingRequest: observable,
      trackingRequests: observable,
      updateAllCounters: action,
      updateRequestById: action,
    });

    makePersistable(this, {
      storage: window.localStorage,
      name: 'TrackingStore',
      properties: ['requestsFilters'],
    })
      .then(() => {
        this.requestsFilters.search = '';
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get isHydrated() {
    return isHydrated(this);
  }

  public trackingRequests: TTrackingPagination = defaultPagination;

  public pages: number[] = [];

  public trackingRequest: TTrackingSingleRequest | null = null;
  public timeline: TTrackingRequestTimeline = [];

  public allCounters: TRequestsCountersAllResponse = {
    all: {
      need_action: {
        count: 0,
        ids: [],
      },
      need_attention: {
        count: 0,
        ids: [],
      },
    },
  };

  public driverLocation: TDriverLocation | null = null;
  public availableDriverLocation = true;
  private driverLocationTimer: NodeJS.Timeout | null = null;

  public sidebar = true;

  public requestsLoading = false;
  public requestLoading = false;
  public timelineLoading = false;
  public deliveryCancelLoading = false;
  public requestNextDspLoading = false;

  public requestsFilters: TTrackingRequestsFilters = defaultTrackingRequestsFilters;

  public availableDSPs: FilterDSP[] = [];
  public isAvailableDSPsLoading = false;

  public notifications: TMapNotificationProps[] = [];

  public sRef = React.createRef();

  public lastMapPosition: LastMapPos = {
    zoom: 6,
    pos: { lat: 24.709022, lng: 46.703354 },
  };

  requestsAbortController: AbortController = new AbortController();
  countAbortController: AbortController = new AbortController();
  trackingAbortController: AbortController = new AbortController();

  requestsTimeoutId: any;

  public get selectedRequestId(): string | undefined {
    return this.trackingRequest?.request.id || undefined;
  }

  public get clustering(): TClustering {
    const locations: CoordsWithType[] = [];
    const selected: TClusteringSelected = {
      customer: null,
      branch: null,
    };

    this.trackingRequests.data.forEach((trackingRequest) => {
      const { id: requestId } = trackingRequest.request;
      const { lat: orgLat, lng: orgLng } = trackingRequest.organization;
      const { lat, lng } = trackingRequest.customer;

      const organization: CoordsWithType = { lat: orgLat, lng: orgLng, type: MarkerType.BRANCH, requestId };
      const customer: CoordsWithType = { lat, lng, type: MarkerType.LOCATION, requestId };

      locations.push(customer);
      locations.push(organization);
    });

    if (this.trackingRequest) {
      const { id: requestId } = this.trackingRequest.request;
      const { lat: orgLat, lng: orgLng } = this.trackingRequest.organization;
      const { lat, lng } = this.trackingRequest.customer;

      const selectedOrganization: CoordsWithType = {
        lat: orgLat === orgLng ? orgLat + 0.00001 : orgLat,
        lng: orgLng,
        type: MarkerType.BRANCH,
        requestId,
      };
      const selectedCustomer: CoordsWithType = { lat: lat === lng ? lat + 0.00001 : lat, lng, type: MarkerType.LOCATION, requestId };

      selected.customer = selectedCustomer;
      selected.branch = selectedOrganization;
    }

    return { locations, selected };
  }

  setLastMapPos = (pos: LastMapPos) => (this.lastMapPosition = pos);

  getAllCounters = flow(function* (this: TrackingStore) {
    try {
      this.countAbortController.abort();
      this.countAbortController = new AbortController();

      const response: TRequestsCountersAllResponse = yield client.get('/v1/requests/count', {
        signal: this.countAbortController.signal,
      });

      if (userStore.user.organization_ids?.length) {
        this.updateAllCounters(response);
      } else {
        this.allCounters = response;
      }
    } catch (error) {
      console.log(error);
    }
  }).bind(this);

  requestDriverLocation = flow(function* (this: TrackingStore) {
    if (!this.trackingRequest || !this.availableDriverLocation) return;

    this.trackingAbortController.abort();
    const trackingAbortController = new AbortController();
    this.trackingAbortController = trackingAbortController;

    try {
      const location: { lat: number | null; lng: number | null } = yield client.get(`/v1/requests/${this.trackingRequest.request.id}/tracking`, {
        signal: trackingAbortController.signal,
      });

      if (!location.lng || !location.lat) {
        this.availableDriverLocation = false;
        throw new Error(localesStore.t('tracking_not_available_notification'));
      }

      this.driverLocation = location as Coords;
      this.availableDriverLocation = true;

      if (!this.driverLocationTimer) {
        this.driverLocationTimer = setInterval(() => this.requestDriverLocation(), 15000);
      }
    } catch (error) {
      console.log(error);
      if (!trackingAbortController.signal.aborted) {
        this.driverLocation = null;
        this.availableDriverLocation = false;

        if (this.driverLocationTimer) {
          clearInterval(this.driverLocationTimer);
          this.driverLocationTimer = null;
        }

        this.addNotification({
          id: this.trackingRequest?.request.id,
          content: <div>{localesStore.t('tracking_not_available_notification')}</div>,
          onRequestClose: (notifyId: string) => {
            trackingStore.removeNotification(notifyId);
          },
        });
      }
    }
  }).bind(this);

  addNotification = (notification: TMapNotificationProps) => {
    if (!Boolean(this.notifications.find((n) => n.id === notification.id))) {
      this.notifications.push(notification);
    }
  };

  removeNotification = (id: string) => {
    this.notifications = this.notifications.filter((n) => n.id !== id);
  };

  getTimeline = flow(function* (this: TrackingStore) {
    if (!this.trackingRequest) return;

    this.timelineLoading = true;

    const id = this.trackingRequest?.request.id;

    try {
      const response: any[] = yield client.get(`/v1/requests/${id}/timeline`);
      this.timeline = addTimeZoneToTimeline(response.map(timelineCast));
    } catch (error) {
      console.log(error);
    } finally {
      this.timelineLoading = false;
    }
  }).bind(this);

  requestNextDsp = flow(function* (this: TrackingStore) {
    if (!this.trackingRequest) return;

    this.requestNextDspLoading = true;

    const id = this.trackingRequest.request.id;

    try {
      yield client.post(`/v1/requests/${id}/next`);

      trackingStore.addNotification({
        id: '228',
        content: (
          <div>{localesStore.t('delivery_request_success_notification', { client_order_id: getRequestId(this.trackingRequest?.organization) })}</div>
        ),
        onRequestClose: (notifyId: string) => {
          trackingStore.removeNotification(notifyId);
        },
      });
    } catch (error) {
      console.log(error);
      trackingStore.addNotification({
        id: '226',
        type: MapNotificationTypes.warning,
        content: (
          <div>{localesStore.t('delivery_request_failure_notification', { client_order_id: getRequestId(this.trackingRequest?.organization) })}</div>
        ),
        onRequestClose: (notifyId: string) => {
          trackingStore.removeNotification(notifyId);
        },
      });
    } finally {
      this.requestNextDspLoading = false;
    }
  }).bind(this);

  cancelDelivery = flow(function* (this: TrackingStore) {
    if (!this.trackingRequest) return;

    this.deliveryCancelLoading = true;

    const id = this.trackingRequest.request.id;

    try {
      yield client.delete(`/v1/requests/${id}`);

      trackingStore.addNotification({
        id: '2281',
        content: (
          <div>{localesStore.t('cancel_delivery_success_notification', { client_order_id: getRequestId(this.trackingRequest?.organization) })}</div>
        ),
        onRequestClose: (notifyId: string) => {
          trackingStore.removeNotification(notifyId);
        },
      });

      this.trackingRequest = null;

      setTimeout(async () => {
        this.trackingRequests = defaultPagination;

        await this.getRequests();
      }, 1000);
    } catch (error) {
      console.log(error);
      trackingStore.addNotification({
        id: '2261',
        type: MapNotificationTypes.warning,
        content: (
          <div>
            {localesStore.t('cancel_delivery_failure_notification', {
              client_order_id: getRequestId(this.trackingRequest?.organization!),
              dsp_name: this.trackingRequest?.dsp.name,
            })}
          </div>
        ),
        onRequestClose: (notifyId: string) => {
          trackingStore.removeNotification(notifyId);
        },
      });
    } finally {
      this.deliveryCancelLoading = false;
    }
  }).bind(this);

  deleteRequest = (requestId: string) => {
    this.removeNotification(requestId);
    if (this.trackingRequest?.request.id === requestId) {
      modalStore.closeRequestInfoModal();
    }
    this.trackingRequests.data = this.trackingRequests.data.filter((v) => v.request.id !== requestId);
    if (this.trackingRequest?.request.id === requestId) {
      this.trackingRequest = null;
    }
  };

  addRequest = (tRequest: TTrackingRequest, fixTimeZone = true) => {
    const trackingRequest: TTrackingRequest = fixTimeZone ? addTimeZoneToRequest(tRequest) : tRequest;

    if (this.trackingRequests.data.find(({ request: { id } }) => id === trackingRequest.request.id) === undefined) {
      this.trackingRequests.data = [trackingRequest, ...this.trackingRequests.data];
    }
  };

  updateAllCounters = (counters: TRequestsCountersAllResponse) => {
    this.allCounters.all.need_action.count = 0;
    this.allCounters.all.need_attention.count = 0;
    if (userStore.user.organization_ids?.length) {
      Object.keys(counters).forEach((key: string) => {
        const counter = counters[key];
        if (userStore.user.organization_ids.includes(key)) {
          this.allCounters.all.need_action.count += counter.need_action.count;
          this.allCounters.all.need_action.ids.concat(counter.need_action.ids);

          this.allCounters.all.need_attention.count += counter.need_attention.count;
          this.allCounters.all.need_attention.ids.concat(counter.need_attention.ids);
        }
      });
    } else {
      this.allCounters = counters;
    }
  };

  updateRequestById = (data: TTrackingRequest) => {
    const requestIndex = this.trackingRequests.data.findIndex((req) => req.request.id === data.request.id);

    if (requestIndex === -1) return;

    this.trackingRequests.data[requestIndex] = data;

    if (this.trackingRequest?.request.id === data.request.id) {
      this.trackingRequest = data;
    }
  };

  setRequestsFilters = (trackingFilters: TTrackingRequestsFilters, clear: boolean) => {
    this.requestsFilters = clear ? defaultTrackingRequestsFilters : { ...this.requestsFilters, ...trackingFilters };

    clearTimeout(this.requestsTimeoutId);
    this.requestsTimeoutId = setTimeout(async () => {
      await this.getRequests(this.trackingRequests.pagination.current_page, true);
    }, 1000);
  };

  public getRequests = flow(function* (this: TrackingStore, page = 1, withFilter = false) {
    this.requestsAbortController.abort();
    const requestsAbortController = new AbortController();
    this.requestsAbortController = requestsAbortController;

    this.requestsLoading = true;
    try {
      if (this.requestsFilters.category === undefined) {
        this.requestsFilters.category = TRACKING_GROUPS.ALL;
      }

      if (page > this.trackingRequests.pagination.last_page && this.trackingRequests.pagination.last_page !== 0) {
        return;
      }

      const requests = yield client.get(`/v1/requests`, {
        signal: this.requestsAbortController.signal,
        params: {
          page,
          per_page: 250,
          filters: {
            dsp: this.requestsFilters.dsp,
          },
          filter: {
            ...this.requestsFilters,
            gap: {
              start: moment().subtract(2, 'days').format('Y-MM-DD HH:mm'),
            },
          },
        },
      });

      this.trackingRequests = {
        pagination: requests.pagination,
        data: withFilter
          ? requests.data.map(trackingRequestCast).map(addTimeZoneToRequest)
          : page === 1
          ? requests.data.map(trackingRequestCast).map(addTimeZoneToRequest)
          : [...this.trackingRequests.data, ...requests.data.map(trackingRequestCast).map(addTimeZoneToRequest)],
      };

      if (this.trackingRequests.pagination.current_page !== page) {
        this.pages = [...this.pages, this.trackingRequests.pagination.current_page].sort((a, b) => (a > b ? 1 : -1));
      }
      this.requestsLoading = false;
    } catch (error) {
      console.log(error);
      if (!requestsAbortController.signal.aborted) {
        this.requestsLoading = false;
      }
    }
  }).bind(this);

  public getSingleRequest = flow(function* (this: TrackingStore, id: string) {
    try {
      const response: TTrackingSingleRequest = yield client.get(`/v1/requests/${id}`, { params: {} });

      return addTimeZoneToRequest(trackingRequestCast(response));
    } catch (error) {
      console.log(error);
    }
  }).bind(this);

  public getRequest = flow(function* (this: TrackingStore, id: string) {
    this.requestLoading = true;

    this.notifications = [];
    this.driverLocation = null;
    this.availableDriverLocation = true;

    if (this.driverLocationTimer) {
      clearInterval(this.driverLocationTimer);
      this.driverLocationTimer = null;
    }

    try {
      let trackingRequest = this.trackingRequests.data.find((r) => r.request.id === id);

      if (!trackingRequest) {
        trackingRequest = yield client.get(`/v1/requests/${id}`, { params: {} });

        if (!trackingRequest) return;

        this.trackingRequest = addTimeZoneToRequest(trackingRequestCast(trackingRequest));
      } else {
        this.trackingRequest = trackingRequest;
      }

      this.requestDriverLocation();
    } catch (error) {
      console.log(error);
    } finally {
      this.requestLoading = false;
    }
  }).bind(this);

  public getAvailableDSPs = flow(function* (this: TrackingStore) {
    this.isAvailableDSPsLoading = true;

    try {
      const { data = [] } = yield client.get(`/v1/requests/filters/dsp`);
      this.availableDSPs = data.map(filterDSPCast);
    } catch (error) {
      console.log(error);
    }
    this.isAvailableDSPsLoading = false;
  }).bind(this);

  public addRequestBySocket = (data: TTrackingRequest) => {
    if (
      this.requestsLoading ||
      !isReqEqualBySearch(data, this.requestsFilters.search || '') ||
      this.trackingRequests.data.findIndex(({ request }) => request.id === data.request.id) >= 0 ||
      ![TRACKING_GROUPS.ALL, TRACKING_GROUPS.NEW].includes(this.requestsFilters.category as any) ||
      (this.requestsFilters.category === TRACKING_GROUPS.NEW &&
        ![RequestStatus.NO_STATUS, RequestStatus.PENDING, RequestStatus.DSP_ISSUE, RequestStatus.NOT_SENT].includes(data.request.status)) ||
      (this.requestsFilters?.dsp?.length && !this.requestsFilters.dsp.find((value) => value === data.dsp.id))
    ) {
      return;
    }

    this.addRequest(data);
  };
}

const trackingStore = new TrackingStore();
export default trackingStore;
