import * as React from 'react';
import { CSSProperties } from 'react';
import './style.scss';

export interface IPickerSize<T> {
  value: T;
  name: string;
  key: string | number | null | undefined;
}
export interface ISizePickerProps<T> {
  options: IPickerSize<T>[];
  selected?: IPickerSize<T>['key'];
  onChange?: (option: IPickerSize<T>['value']) => void;
  elipsis?: boolean;
  itemStyle?: CSSProperties;
}

export default class Sizes extends React.Component<ISizePickerProps<any>> {
  onSizePress = (option: IPickerSize<any>) => () => {
    if (this.props.onChange) this.props.onChange(option.value);
  };

  public render() {
    if (!this.props.options || this.props.options.length <= 1) return null;
    return (
      <div className="sizes">
        {this.props.options.map((option) => (
          <div
            onClick={this.onSizePress(option)}
            style={this.props.itemStyle}
            className={`sizes-size ${this.props.elipsis ? 'text-elipsis' : ''} ${this.props.selected === option.key ? 'active' : ''}`}
            key={option.key}
          >
            <p className={`text-size-14 family-semibold color-darkgray ${this.props.elipsis ? 'text-elipsis' : ''}`}>{option.name}</p>
          </div>
        ))}
      </div>
    );
  }
}
