import './style.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';

interface Props {
  color?: string;
  text?: string;
  className?: string;
}

class Label extends Component<Props> {
  render() {
    return <b className={`label label-${this.props?.color || 'grey'} ${this.props.className || ''}`}>{this.props?.text || null}</b>;
  }
}

export default observer(Label);
