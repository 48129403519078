import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import './style.scss';
import { ColorBox } from '../ColorBox/ColorBox';
import { PALETTE_COLORS } from '~/constants/styles';

interface Props {
  colors?: string[];
  onSelect?: (color: string) => void;
  selected?: string;
}

const Palette = ({ colors = PALETTE_COLORS, ...props }: Props): ReactElement => {
  const [selectedColor, selectColor] = useState(props.selected || colors[0]);

  const onSelect = useCallback((color: string) => {
    selectColor(color);
    if (props.onSelect) props.onSelect(color);
  }, []);

  useEffect(() => {
    if (props.selected && props.selected !== selectedColor) {
      selectColor(props.selected);
    }
  }, [props.selected]);
  return (
    <div className="palette">
      {colors.map((color) => (
        <ColorBox key={color} selected={color === selectedColor} color={color} onSelect={onSelect} size={46} />
      ))}
    </div>
  );
};

export default Palette;
