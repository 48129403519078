import { PAYMENT_TYPES } from '~/constants/main';
import { Coords } from '~/view/components/Map/Map';

export enum TRACKING_GROUPS {
  ALL = 'all',
  NEW = 'new',

  // IN_DELIVERY = 'in_delivery',
  // NEED_ATTENTION = 'attention',
  // NEED_ACTION = 'action',

  TO_STORE = 'to_store',
  TO_CUSTOMER = 'to_customer',
  ACTION_REQUIRE = 'action_require',
}

export const TRACKING_GROUPS_LABELS: { [key in TRACKING_GROUPS]: string } = {
  all: 'all_filter_tab',
  new: 'new_filter_tab',

  // in_delivery: 'in_delivery_filter_tab',
  // attention: 'need_attention_filter_tab',
  // action: 'need_action_filter_tab',

  to_store: 'to_store_filter_tab',
  to_customer: 'to_customer_filter_tab',
  action_require: 'action_require_filter_tab',
};

export enum RequestStatus {
  NO_STATUS,
  PENDING = 11,
  ASSIGNED,
  WAY_TO_STORE,
  ARRIVED_AT_STORE,
  WAY_TO_CUSTOMER,
  NEAR_CUSTOMER,
  NEAR_STORE,
  ARRIVED_AT_CUSTOMER,
  DELIVERED = 21,
  CANCELED = 31,
  RETURNED,
  DSP_ISSUE,
  NOT_SENT,
  RETURNING,
}

export const REQUESTS_STATUSES_LABELS: { [key in RequestStatus]: string } = {
  0: 'no_status_status',
  11: 'pending_status',
  12: 'assigned_status',
  13: 'way_to_store_status',
  14: 'arrived_at_store_status',
  15: 'way_to_customer_status',
  16: 'near_customer_status',
  17: 'near_store_status',
  18: 'arrived_at_customer_status',
  21: 'delivered_status',
  31: 'canceled_status',
  32: 'returned_status',
  33: 'dsp_issue_status',
  34: 'not_sent_status',
  35: 'returning_status',
};

export const REQUESTS_STATUSES_CLASSNAMES: { [key in RequestStatus]: string } = {
  0: 'label-grey',
  11: 'label-blue',
  12: 'label-blue',
  13: 'label-blue',
  14: 'label-blue',
  15: 'label-blue',
  16: 'label-blue',
  17: 'label-blue',
  18: 'label-blue',
  21: 'label-green',
  31: 'label-red',
  32: 'label-red',
  33: 'label-grey',
  34: 'label-grey',
  35: 'returning_status',
};

export const requestCast = (request: any) => ({
  id: String(request.id),
  status: parseInt(request.status, 10),
  total: parseFloat(request.total),
  payment_type: parseInt(request.payment_type, 10) as PAYMENT_TYPES,
  distance: parseFloat(request.distance),
  created_at: String(request.created_at),
  assigned_at: request.assigned_at !== null ? String(request.assigned_at) : null,
  picked_up_at: request.picked_up_at !== null ? String(request.picked_up_at) : null,
  final_at: request.final_at !== null ? String(request.final_at) : null,
  external_created_at: String(request.external_created_at),
  first_created_at: String(request.first_created_at),
  due_time: String(request.due_time),
  due_at: String(request.due_at),
});
export type TRequest = ReturnType<typeof requestCast>;

export const organizationCast = (organization: any) => ({
  id: String(organization.id),
  order_id: String(organization.order_id),
  order_url: organization.order_url !== null ? String(organization.order_url) : null,
  reference: String(organization.reference),
  name: String(organization.name),
  branch: String(organization.branch),
  city: String(organization.city),
  phone: String(organization.phone),
  address: String(organization.address),
  lat: parseFloat(organization.lat),
  lng: parseFloat(organization.lng),
});
export type TOrganization = ReturnType<typeof organizationCast>;

export const customerCast = (customer: any) => ({
  id: parseInt(customer.id, 10),
  name: String(customer.name),
  phone: String(customer.phone),
  address: String(customer.address),
  lat: parseFloat(customer.lat),
  lng: parseFloat(customer.lng),
});
export type TCustomer = ReturnType<typeof customerCast>;

export const dspCast = (dsp: any) => ({
  id: String(dsp.id),
  name: String(dsp.name),
  order_id: dsp.order_id === null ? '' : String(dsp.order_id),
  order_booking_id: dsp.order_booking_id === null ? '' : String(dsp.order_booking_id),
  dsp_url: dsp.dsp_url !== null ? String(dsp.dsp_url) : null,
});
export type TDsp = ReturnType<typeof dspCast>;

export const driverCast = (driver: any) => ({
  name: String(driver.name),
  phone: String(driver.phone),
});
export type TDriver = ReturnType<typeof driverCast>;

export type TTrackingSingleRequest = {
  request: TRequest;
  organization: TOrganization;
  customer: TCustomer;
  driver?: TDriver;
  dsp: TDsp;
};

export const timelineCast = ({ dsp = {}, driver = undefined, ...item }) => ({
  id: String(item.id),
  index: String(item.index),
  status: parseInt(item.status, 10),
  created_at: String(item.created_at),
  is_wrong: Boolean(item.is_wrong),
  dsp: dspCast(dsp),
  driver: driver ? driverCast(driver) : driver,
  raw_response: String(item.raw_response),
  user: item.user === null ? '' : String(item.user),
  from: item.from === null ? '' : String(item.from),
  reason: item.reason === null ? '' : String(item.reason),
});

export type TTrackingRequestTimelineItem = ReturnType<typeof timelineCast>;

export const trackingRequestCast = ({ request = {}, organization = {}, customer = {}, driver = {}, dsp = {} }: any) => ({
  request: requestCast(request),
  organization: organizationCast(organization),
  customer: customerCast(customer),
  driver: driver ? driverCast(driver) : driver,
  dsp: dspCast(dsp),
});

export type TTrackingRequest = ReturnType<typeof trackingRequestCast>;

export type TTrackingRequestTimeline = TTrackingRequestTimelineItem[];

export type ResponseNextDspRequest = {
  id: number;
  dsp_id: number;
  dsp_name: number;
  status: number;
};

export enum TimelineTypes {
  full = 'full',
  request = 'request',
}

export type TDriverLocation = Coords;

export type TRequestsCountersAllResponse = {
  all: {
    need_attention: {
      count: number;
      ids: string[];
    };
    need_action: {
      count: number;
      ids: string[];
    };
  };
  [key: string]: {
    need_attention: {
      count: number;
      ids: string[];
    };
    need_action: {
      count: number;
      ids: string[];
    };
  };
};

export const filterDSPCast = (dsp: any) => ({
  label: String(dsp.name),
  value: String(dsp.value),
});

export type FilterDSP = ReturnType<typeof filterDSPCast>;
