import { Icons } from 'taker-web-ui/dist/assets/fonts/Icons/icons';

export const ICONS = Icons; /*

{
  SPINNER: 'spinner',
  CLOSE: 'close',
  CLOSE_SMALL: 'close-small',
  BACK: 'back',
  ACTIVE_ORDERS: 'active-orders',
  ACTIVE_NEW: 'active-orders_new',
  HISTORY_ORDERS: 'history_orders',
  LOGOUT: 'logout',
  PRODUCTS: 'products',
  BOX: 'box',
  BELL: 'bell-1',
  MENU: 'menu',
  MENU_LIST: 'menu-1',
  SLIDER_RIGHT: 'slider-right',
  SLIDER_LEFT: 'slider-left',
  SHOPPING_CART: 'shoppiing-cart',
  SHOPPING_BAG: 'shopping-bag',
  DELIVERY: 'delivery',
  COPY_NEW: 'copy_new',
  CHEVRON: 'chevron',
  ARROW_TOP: 'arrow-top',
  SEARCH: 'search',
  EMAIL: 'email',
  RESET: 'reset',
  CALENDAR: 'calendar',
  ARROW_HALF: 'arrow-half',
  WARNING: 'warning',
  MAP: 'map',
  GIFT: 'gift',
  SWAP: 'swap',

  MINUS: 'minus',
  PLUS: 'plus',
  INFO: 'info',
  EDIT: 'edit',
  GEO: 'geo',
  CLOCK: 'clock',
  PIN: 'pin',
  PIN_FILLED: 'pin-filled',
  MAP_LOCATION: 'map-location',
  TARGET: 'ic_target',

  DELIVERY_TRACKING: 'ic_delivery-tracking',
  DSP_MANAGEMENT: 'ic_dsp-management',

  CASH: 'ic_cash',
  CARD: 'ic_card',
};

*/
