import { ClustererOptions } from '@react-google-maps/marker-clusterer';
// import { formatImagePath } from '~/utils/formatter';
import { ICONS } from '~/constants/icons';

export const defaultCoordinates = {
  lat: 24.681530159926,
  lng: 46.698331379151,
};

export const clustererOptions: ClustererOptions = {
  maxZoom: 7,
  averageCenter: true,
  zoomOnClick: true,
  calculator: (markers) => ({ text: Math.floor(markers.length / 2).toString(), index: 1, title: 'Cluster' }),
  styles: [
    {
      url: `${process.env.REACT_APP_CLOUD_FRONT || ''}/media/images/${ICONS.Cluster}.svg`,
      height: 35,
      width: 35,
      textColor: 'white',
      className: 'd-flex align-items-center justify-content-center',
    },
  ],
};
