// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function throttle(callback: any, limit: number) {
  let waiting = false; // Initially, we're not waiting
  return function (this: any) {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      // eslint-disable-next-line prefer-rest-params
      callback.apply(this, arguments); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(() => {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function debounce(f: Function, ms: number) {
  let timer: number;
  return (args?: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(f, ms, args);
  };
}
