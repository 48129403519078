import { localesStore } from '~/mobx';

export enum TIME_PERIODS {
  MINUTES_15,
  MINUTES_30,
  HOURS_1,
  HOURS_2,
  HOURS_5,
  HOURS_24,
  OFF,
}

export const TIME_PERIODS_LIST = [
  TIME_PERIODS.MINUTES_15,
  TIME_PERIODS.MINUTES_30,
  TIME_PERIODS.HOURS_1,
  TIME_PERIODS.HOURS_2,
  TIME_PERIODS.HOURS_5,
  TIME_PERIODS.HOURS_24,
  TIME_PERIODS.OFF,
];

export const TIME_PERIODS_TYPE = {
  [TIME_PERIODS.MINUTES_15]: 'blue',
  [TIME_PERIODS.MINUTES_30]: 'blue',
  [TIME_PERIODS.HOURS_1]: 'blue',
  [TIME_PERIODS.HOURS_2]: 'blue',
  [TIME_PERIODS.HOURS_5]: 'blue',
  [TIME_PERIODS.HOURS_24]: 'blue',
  [TIME_PERIODS.OFF]: 'red',
};

// Time in minutes
export const TIME_PERIODS_TIME = {
  [TIME_PERIODS.MINUTES_15]: 15,
  [TIME_PERIODS.MINUTES_30]: 30,
  [TIME_PERIODS.HOURS_1]: 60,
  [TIME_PERIODS.HOURS_2]: 120,
  [TIME_PERIODS.HOURS_5]: 300,
  [TIME_PERIODS.HOURS_24]: 1440,
  [TIME_PERIODS.OFF]: 0,
};

export const timePeriodTrans = (timePeriod: TIME_PERIODS) => {
  switch (timePeriod) {
    case TIME_PERIODS.MINUTES_15:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.MINUTES_15]} ${localesStore.t('mins')}`;
    case TIME_PERIODS.MINUTES_30:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.MINUTES_30]} ${localesStore.t('mins')}`;
    case TIME_PERIODS.HOURS_1:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.HOURS_1] / 60} ${localesStore.t('hour')}`;
    case TIME_PERIODS.HOURS_2:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.HOURS_2] / 60} ${localesStore.t('hours')}`;
    case TIME_PERIODS.HOURS_5:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.HOURS_5] / 60} ${localesStore.t('hours')}`;
    case TIME_PERIODS.HOURS_24:
      return `${TIME_PERIODS_TIME[TIME_PERIODS.HOURS_24] / 60} ${localesStore.t('hours')}`;
    case TIME_PERIODS.OFF:
      return localesStore.t('turn_off_zone');
    default:
      throw new Error('Wrong time period for translation');
  }
};
