import React, { Component, PropsWithChildren } from 'react';

interface Props {
  title: string;
  className?: string;
  children?: React.ReactNode;
}

export default class ModalHeader extends Component<PropsWithChildren<Props>, {}> {
  state = {};

  render() {
    return (
      <div className={`px-8 py-5 modal-header ${this.props.className || ''}`}>
        <h3 className="text-size-26 family-bold">{this.props.title}</h3>
        {this.props.children}
      </div>
    );
  }
}
