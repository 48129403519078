import { observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';
import Label from '~/view/components/Label/Label';
import Timeline from '~/view/components/Timeline/Timeline';
import { ICONS } from '~/constants/icons';
import { WINDOW_SIZES } from '~/constants/main';
import theme, { COLORS, FONT_FAMILY } from '~/constants/styles';
import { USER_PERMISSIONS } from '~/constants/user';
import { localesStore, messagesStore, modalStore } from '~/mobx';
import ButtonOld from '~/view/components/Controls/ButtonOld/ButtonOld';
import Crumbs from '~/view/components/Controls/Crumbs/Crumbs';
import CustomTooltip from '~/view/components/Controls/CustomTooltip/CustomTooltip';
import DateTimePicker from '~/view/components/Controls/DateTimePicker/DateTimePicker';
import Icon from '~/view/components/Controls/Icon/Icon';
import InputOld from '~/view/components/Controls/InputOld/InputOld';
import PhoneInput from '~/view/components/Controls/PhoneInput/PhoneInput';
import Sizes from '~/view/components/Controls/Sizes/Sizes';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import Switcher from '~/view/components/Controls/Switcher/Switcher';
import ModalMessage from '~/view/components/ModalMessage/ModalMessage';
import ModalPopup from '~/view/components/ModalPopup/ModalPopup';
import Permission from '~/view/components/Permission/Permission';
import ResponsiveContent from '~/view/components/ResponsiveContent/ResponsiveContent';
import TabPicker from '~/view/components/TabPicker/TabPicker';
import TabList from '~/view/components/Tabs/TabList';
import Tabs from '~/view/components/Tabs/Tabs';
import Text from '~/view/components/Typo/Text/Text';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';

class GuidelineScreen extends Component {
  modal: ModalPopup | null = null;
  closeModalMessage = () => {
    this.modal?.closeModalPopup();
  };

  icons = () => {
    return Object.values(ICONS).map((value) => {
      return (
        <div className="d-flex align-items-center mb-3">
          <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
            {value}
          </Text>
          <Icon className="color-red" name={value} size={30} />
        </div>
      );
    });
  };

  testData = [
    {
      item: {
        number: 6,
        dspName: 'Careem',
        id: '1337...1488',
        status: {
          label: 'PENDING',
          date: new Date(),
        },
      },
      danger: false,
    },
    {
      item: {
        number: 6,
        dspName: 'Careem',
        id: '1337...1488',
        status: {
          label: 'PENDING',
          date: new Date(),
        },
      },
      danger: false,
    },
  ];

  render() {
    return (
      <div className="d-flex flex-column full-width">
        <ScrollableBlock>
          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Typography
          </Text>
          <div className="d-flex flex-column align-items-start mb-6">
            <Text size={36}>size 36</Text>
            <Text color={COLORS.RED} size={24} family={FONT_FAMILY.BOLD}>
              size 24 | family bold
            </Text>
            <Text color={COLORS.MAINBLUE} size={30} family={FONT_FAMILY.MEDIUM}>
              size 30 | family medium
            </Text>
            <Text color={COLORS.GRAY3} size={40} family={FONT_FAMILY.SEMIBOLD}>
              size 40 | family semibold
            </Text>
            <Text color={COLORS.GREEN} size={12} className="text-uppercase">
              size 12 | text uppercase
            </Text>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Controls
          </Text>
          <div className="d-flex flex-column align-items-start mb-4">
            <Text size={22} className="mb-3" family={FONT_FAMILY.BOLD}>
              Switcher
            </Text>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Switcher active
              </Text>
              {/* @ts-ignore */}
              <Switcher status={true} onChange={(status) => console.info('New status is : ', status)} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Switcher inactive
              </Text>
              <Switcher status={false} onChange={(status) => console.info('New status is : ', status)} />
            </div>
            <div className="d-flex align-items-center">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Switcher disabled
              </Text>
              <Switcher disabled status={false} onChange={(status) => console.info('New status is : ', status)} />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Input
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Switcher active
              </Text>
              <InputOld placeholder={localesStore.t('type')} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Switcher error
              </Text>
              <InputOld placeholder={localesStore.t('type')} error />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                input disabled
              </Text>
              <InputOld placeholder={localesStore.t('type')} disabled />
            </div>
            <div className="d-flex align-items-center">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                input disabled
              </Text>
              <PhoneInput value={'380'} onChange={console.info} placeholder={localesStore.t('type')} />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Button
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Button
              </Text>
              <ButtonOld title={localesStore.t('press')} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Button red
              </Text>
              <ButtonOld className="bg-red px-8" title={localesStore.t('press')} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Button disabled
              </Text>
              <ButtonOld disabled title={localesStore.t('press')} />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Button loading
              </Text>
              <ButtonOld loading className="bg-red" title={localesStore.t('press')} />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Icons
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Icon
              </Text>
              <Icon name={ICONS.Calendar} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Icon red
              </Text>
              <Icon className="color-red" name={ICONS.Calendar} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Icon big
              </Text>
              <Icon className="color-red" name={ICONS.Close} size={30} />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            All Icons
          </Text>

          <div className="d-flex flex-column align-items-start">{this.icons()}</div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Tooltip
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Press me
              </Text>
              <CustomTooltip message="Here is Jonny!">
                <ButtonOld />
              </CustomTooltip>
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Press me and I will copy this message to you buffer
              </Text>
              <CustomTooltip type="copy" copyData="Here is Jonny!" message="Here is Jonny!">
                <ButtonOld />
              </CustomTooltip>
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Modal
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Modal message
              </Text>
              <ButtonOld
                onClick={() =>
                  messagesStore.addMessage({
                    type: 'modal',
                    title: 'Modal title',
                    message: 'Modal description',
                    options: [
                      {
                        text: 'Ok',
                        onPress: () => alert('You pressed ok'),
                        appearance: 'primary',
                      },

                      {
                        text: 'Cancel',
                        onPress: () => alert('You pressed cancel'),
                        appearance: 'tertiary',
                      },
                    ],
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Modal dropdown
              </Text>
              <ModalPopup
                // autoclose on
                autoClose
                vertical={'top'}
                horizontal={'center'}
                ref={(modal) => (this.modal = modal)}
                content={
                  <ModalMessage
                    closeModal={this.closeModalMessage}
                    title="Title"
                    message={'Message'}
                    options={[
                      {
                        text: 'Ok',
                        onPress: () => {
                          alert('You pressed ok');
                        },
                      },

                      {
                        text: 'Cancel',
                        className: 'bg-graysoft border-graysoft color-dark',
                        onPress: () => {
                          alert('You pressed cancel');
                        },
                      },
                    ]}
                  />
                }>
                <ButtonOld onClick={() => this.modal?.openModalPopup()} />
              </ModalPopup>
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Modal screen
              </Text>
              <ButtonOld
                onClick={() =>
                  modalStore.openTestModal({
                    onChange: console.info,
                    id: String(Date.now()),
                  })
                }
              />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Responsivness
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <ResponsiveContent min={WINDOW_SIZES.md} max={WINDOW_SIZES.lg}>
                <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                  Here text will be shown from {WINDOW_SIZES.md} to {WINDOW_SIZES.lg}
                </Text>
              </ResponsiveContent>
            </div>
            <div className="d-flex align-items-center mb-3">
              <ResponsiveContent min={WINDOW_SIZES.xs} max={WINDOW_SIZES.md}>
                <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                  Here text will be shown from {WINDOW_SIZES.xs} to {WINDOW_SIZES.md}
                </Text>
              </ResponsiveContent>
            </div>
            <div className="d-flex align-items-center mb-3">
              <ResponsiveContent min={WINDOW_SIZES.md}>
                <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                  Here text will be shown from {WINDOW_SIZES.md}
                </Text>
              </ResponsiveContent>
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Permission
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Permission permissions={USER_PERMISSIONS.DASHBOARD}>
                <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                  Here text will be shown just if user had "{USER_PERMISSIONS.DASHBOARD}" permission
                </Text>
              </Permission>
            </div>
            <div className="d-flex align-items-center mb-3">
              <Permission permissions={[]}>
                <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                  Here text will be shown for all users
                </Text>
              </Permission>
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Pickers
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Date time
              </Text>
              <DateTimePicker
                disabled={false}
                error={false}
                // onDateChange={checkoutStore.changePreorderDate}
                date={new Date()}
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Crumbs
              </Text>
              <Crumbs
                onClick={console.info}
                activeCrumb={1}
                crumbs={[
                  { value: 1, label: 'text1' },
                  { value: 2, label: 'text3' },
                  { value: 3, label: 'text3' },
                ]}
              />
            </div>
          </div>

          <Text size={36} className="mb-6" family={FONT_FAMILY.BOLD}>
            Others
          </Text>
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Timeline
              </Text>
              <Timeline
                data={this.testData}
                leading={({ item }) => (
                  <div className={'d-flex direction-column align-items-center'} style={{ height: 'min-content' }}>
                    <div className={'text-size-16 mr-3 color-gray2'}>{item.number}</div>
                    <div>
                      <div className={'text-size-14 font-weight-600'}>{item.dspName}</div>
                      <div className={'text-size-12'} style={{ color: '#429eff' }}>
                        {item.id}
                      </div>
                    </div>
                  </div>
                )}
                trailing={({ item }) => (
                  <div className={'d-flex flex-column flex-1'}>
                    <div className={'d-flex flex-row mb-2 flex-1 justify-content-between'}>
                      <Label text={item.status.label} />
                      <div className={'d-flex align-items-center text-size-14 color-black font-weight-600'}>
                        {moment(item.status.date).format('D/M/Y, h:mm:ss A')}
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Pagination
              </Text>
              {/* <Pagination onChange={(args) => { console.log(args) }} pagination={{ ...defaultPagination, last_page: 5, current_page: 1 }} /> */}
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Tab picker
              </Text>
              <TabPicker
                onChange={console.info}
                value={1}
                options={[
                  { label: 'Tab1', value: 1 },
                  { label: 'Tab2', value: 2 },
                  { label: 'Tab3', value: 3 },
                ]}
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Size picker
              </Text>
              <Sizes
                onChange={console.info}
                selected={1}
                options={[
                  { name: 'Tab1', value: 1, key: 1 },
                  { name: 'Tab2', value: 2, key: 2 },
                  { name: 'Tab3', value: 3, key: 3 },
                ]}
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Spinner
              </Text>
              <Spinner color={theme.colors.red} />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Spinner Container
              </Text>
              <Spinner color={theme.colors.red} container />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Spinner Cancelable Container
              </Text>
              <Spinner color={theme.colors.red} container onCancel={() => alert('cancel')} />
            </div>

            <div className="d-flex align-items-center mb-3">
              <Text size={16} className="mr-3" family={FONT_FAMILY.BOLD}>
                Tabs
              </Text>
              <div className="d-flex flex-column bg-graysofy">
                <Tabs
                  onChange={console.info}
                  options={[
                    { label: 'Customer Details', value: 'tab1' },
                    { label: 'items', value: 'tab2' },
                    { label: 'summary_and_checkout', value: 'tab3' },
                  ]}
                  value={'tab3'}
                />
                <TabList value={'tab3'} tabs={['tab1', 'tab2', 'tab3']}>
                  <div className="d-flex flex-1">Content 1</div>
                  <div className="d-flex flex-1">Content 2</div>
                  <div className="d-flex flex-1">Content 3</div>
                </TabList>
              </div>
            </div>
          </div>
        </ScrollableBlock>
      </div>
    );
  }
}

export default observer(GuidelineScreen);
