import { observer } from 'mobx-react';
import { deliveryZonesStore } from '~/mobx';
import theme from '~/constants/styles';
import { Polygon } from '~/view/components/Map2/Polygon/Polygon';

const DeliveryZonesDraftAreas = () => {
  if (!deliveryZonesStore.draft) return null;

  const handleEdit = (bounds: google.maps.LatLngLiteral[]) => deliveryZonesStore.editDraftZone('bounds', bounds);

  if (deliveryZonesStore.draft.bounds.length < 3) return null;

  return (
    <Polygon
      index={deliveryZonesStore.pagination.data.length}
      editable
      draggable={false}
      onEdit={handleEdit}
      color={deliveryZonesStore.draft.color || theme.colors.gray2}
      paths={deliveryZonesStore.draft.bounds}
    />
  );
};

export default observer(DeliveryZonesDraftAreas);
