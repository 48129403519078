import { Location, NavigateFunction } from 'react-router';

export type TLocale = 'en' | 'ar';

export interface IPagination<T> {
  current_page: number;
  last_page: number;
  data: T[];
  from: number;
  to: number;
  total: number;
  loading?: boolean;
  refreshing?: boolean;
}

export type LatLng = {
  lat: number;
  lng: number;
};

export type CoordsPaths = LatLng[];
export type CoordsBounds = [LatLng['lat'], LatLng['lng']][];

export interface IPickerOption {
  label: string;
  value: string;
  status?: TBooleanInNumber;
  out_of_stock?: TBooleanInNumber;
}

export interface IErrorOption {
  text: string;
  background?: string;
  color?: string;
  onPress?: () => void;
}

export interface IError {
  title?: string;
  message?: string;
  options: IErrorOption[];
}

export type TBooleanInNumber = 1 | 0;
export type TTranslate = {
  [key: string]: string;
};

export enum LoadingStatus {
  Init = 'INIT',
  Loading = 'LOADING',
  Refreshing = 'REFRESHING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type RouterComponentProps<K = { [key: string]: string | undefined }, T = {}> = {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<K>;
  };
} & T;
