import './style.scss';

import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

import { ICONS } from '~/constants/icons';
import theme from '~/utils/styles';
import ButtonOld from '~/view/components/Controls/ButtonOld/ButtonOld';
import Icon from '~/view/components/Controls/Icon/Icon';
import ModalPopup from '~/view/components/ModalPopup/ModalPopup';
import ColumnCalendarFilter from '~/view/components/Table/components/ColumnCalendarFilter/ColumnCalendarFilter';
import ColumnValueFilter from '~/view/components/Table/components/ColumnValueFilter/ColumnValueFilter';
import SortingButton from '~/view/components/Controls/SortingButton/SortingButton';
import { IDateFilter, LOG_SORT_COLUMN } from '~/types/log';
import { logStore } from '~/mobx';
import { SORT_DIR } from '~/constants/main';

interface IProps {
  className: string;
  itemName: string;
  withFilter?: boolean;
  columnName: LOG_SORT_COLUMN;
}

type State = {
  selectedDates: null | Date[];
};

class LogListColumnTitle extends React.Component<IProps, State> {
  state = {
    selectedDates: null,
  };

  modal: ModalPopup | null = null;

  closeModalMessage = () => {
    this.modal?.closeModalPopup();
  };

  changeSorting = async (sortField: any, sortDir: SORT_DIR | undefined) => {
    await logStore.setSorting(sortField, sortDir, true);
  };

  onFilterSelected = async (selected: any) => {
    if (this.props.columnName) {
      logStore.setSelectedFilter(this.props.columnName, selected);

      await logStore.fetchLog(logStore.pagination.current_page);
    }
  };

  onDateFilterSelected = async (dates: Date[]) => {
    if (dates.length < 2) {
      return;
    }

    this.setState({ selectedDates: dates });

    logStore.setSelectedFilter(this.props.columnName, {
      from_date: moment(dates[0]).format('D/M/Y'),
      to_date: moment(dates[1]).format('D/M/Y'),
    } as IDateFilter);

    await logStore.fetchLog(logStore.pagination.current_page);
  };

  clearFilter = async () => {
    await logStore.clearFilter(this.props.columnName);

    if (this.state.selectedDates) {
      this.setState({ selectedDates: null });
    }
  };

  isActiveFilter = (): boolean => {
    if (!this.props.withFilter) {
      return false;
    }
    if (this.props.columnName === LOG_SORT_COLUMN.DATE) {
      return logStore.selectedFilters[this.props.columnName].from_date !== null || logStore.selectedFilters[this.props.columnName].to_date !== null;
    }
    return logStore.selectedFilters[this.props.columnName].length > 0;
  };

  render() {
    if (!logStore.filtersData[this.props.columnName] && this.props.columnName !== LOG_SORT_COLUMN.DATE) {
      return null;
    }
    return (
      <li className={this.props.className}>
        <SortingButton
          sortField={this.props.columnName}
          currentField={this.props.columnName}
          currentDir={logStore.sort[this.props.columnName]}
          changeSorting={this.changeSorting}>
          <p className="family-medium color-gray2 text-size-12 text-uppercase letter-spacing-12">{this.props.itemName}</p>
        </SortingButton>
        {this.props.withFilter ? (
          <ModalPopup
            autoClose
            vertical={'bottom'}
            horizontal={this.props.columnName === LOG_SORT_COLUMN.DATE ? 'right' : 'center'}
            ref={(modal) => (this.modal = modal)}
            wrapperClassName="filter-popup d-flex align-items-center justify-content-start"
            content={
              this.props.columnName === LOG_SORT_COLUMN.DATE ? (
                <ColumnCalendarFilter
                  close={this.closeModalMessage}
                  values={logStore.selectedFilters[this.props.columnName]}
                  rawValues={this.state.selectedDates}
                  onChange={this.onDateFilterSelected}
                />
              ) : (
                <ColumnValueFilter
                  values={logStore.filtersData[this.props.columnName]}
                  selected={logStore.selectedFilters[this.props.columnName]}
                  onSelect={this.onFilterSelected}
                  close={this.closeModalMessage}
                />
              )
            }>
            <ButtonOld
              className={`filter-dropdown-btn mx-4 ${this.props.columnName === LOG_SORT_COLUMN.DATE ? 'filter-dropdown-btn__transparent' : ''}`}
              onClick={() => this.modal?.openModalPopup()}>
              {this.props.columnName === LOG_SORT_COLUMN.DATE ? (
                <Icon name={ICONS.Calendar} size={24} color={theme.colors.mainblue} />
              ) : (
                <Icon name={ICONS.Chevron} size={16} color={theme.colors.dark} />
              )}
            </ButtonOld>
            <div className="filter-dropdown-btn__clear__wrapper">
              {this.isActiveFilter() ? (
                <ButtonOld className="filter-dropdown-btn__clear" onClick={this.clearFilter}>
                  <Icon name={ICONS.Close} size={14} color={theme.colors.dark} />
                </ButtonOld>
              ) : null}
            </div>
          </ModalPopup>
        ) : null}
      </li>
    );
  }
}

export default observer(LogListColumnTitle);
