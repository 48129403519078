import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { TRACKING_GROUPS, TRACKING_GROUPS_LABELS } from '~/constants/tracking';
import { TabLabel } from '~/view/components/Controls/TabLabel/TabLabel';
import { localesStore, trackingStore } from '~/mobx';
import { refreshInterval } from '~/constants/requests';
import './style.scss';

type Props = {
  onChange: (type: TRACKING_GROUPS) => void;
  activeGroup?: TRACKING_GROUPS;
};

let intervalId: NodeJS.Timeout;

const TrackingGroups = observer(({ onChange, activeGroup }: Props) => {
  useEffect(() => {
    trackingStore.getAllCounters();
    if ([TRACKING_GROUPS.TO_STORE, TRACKING_GROUPS.TO_CUSTOMER, TRACKING_GROUPS.ACTION_REQUIRE].includes(activeGroup!)) {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        Promise.allSettled([trackingStore.getAllCounters(), trackingStore.getRequests(1, true)]);
      }, refreshInterval);
    } else if ([TRACKING_GROUPS.ALL, TRACKING_GROUPS.NEW].includes(activeGroup!)) {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        Promise.allSettled([trackingStore.getAllCounters()]);
      }, refreshInterval);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [activeGroup]);

  const renderStatus = (status: TRACKING_GROUPS) => (
    <TabLabel
      key={status}
      value={status}
      active={activeGroup === status}
      label={localesStore.t(`${TRACKING_GROUPS_LABELS[status]}`)}
      count={status === TRACKING_GROUPS.ACTION_REQUIRE ? trackingStore.allCounters.all?.need_action.count : undefined}
      onPress={onChange}
    />
  );

  const groups = Object.values(TRACKING_GROUPS) as TRACKING_GROUPS[];
  if (!groups.length) return null;

  return <div className="tracking-groups d-flex flex-row align-items-center">{groups.map(renderStatus)}</div>;
});

export default TrackingGroups;
