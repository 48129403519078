import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';
import { IUnavailableZone, unavailableZonesCast } from '~/types/deliveryManagment';
import { getRandomInt } from '~/utils/math';
import { defaultPagination, FORM_MODE } from '~/constants/main';
import { PALETTE_COLOR_GREY, PALETTE_COLORS } from '~/constants/styles';
import { IPagination, LatLng } from '~/types/main';
import { localesStore, messagesStore } from '~/mobx';
import { getMessageFromError } from '~/utils/formatter';
import MapStore from '~/view/components/Map2/store';
import client from '~/services/client';
import { getCenterOfBounds } from '~/utils/maps';

interface IZonesFilter {
  search?: string;
}

const defaultFilters: IZonesFilter = {
  search: undefined,
};

const generateZoneName = (zones: IUnavailableZone[]) => {
  let number = zones.length + 1;
  let name = '';

  do {
    name = 'Zone ' + String(number);
    number++;
  } while (zones.findIndex((zone) => zone.name === name) !== -1);

  return name;
};

export const initDeliveryZone = (): IUnavailableZone => ({
  id: '0',
  bounds: [],
  color: PALETTE_COLORS[getRandomInt(0, PALETTE_COLORS.length - 1)],
  name: generateZoneName(deliveryZonesStore.pagination.data),
  start_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  end_time: moment().add(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
  timezone_offset: 3,
});

class DeliveryZonesStore {
  constructor() {
    makeObservable(this, {
      tooltip: observable,
      setTooltip: action,
      showDrawHint: observable,
      changeDrawHintVisibility: action,
      filters: observable,
      setFilters: action,
      pages: observable,
      pagination: observable,
      // validation: observable,
      lastUpdate: observable,
      // setValidation: action,
      fetchZones: action,
      createZone: action,
      updateZone: action,
      deleteZone: action,
      zone: observable,
      zones: observable,
      draft: observable,
      mode: observable,
      selectDraft: action,
      clearDraft: action,
      editDraftZone: action,
      selectZone: action,
    });
  }
  public mapStore = new MapStore();

  public tooltip: {
    center: LatLng;
    options: { label: string; value: string }[];
  } | null = null;
  public setTooltip = (center: LatLng, options?: any) => {
    this.tooltip = { center, options };
  };

  /**
   * visibility param for draw area hint modal
   *
   * @memberof DeliveryZonesStore
   */
  public showDrawHint = false;
  public changeDrawHintVisibility = (showDrawHint: boolean) => {
    this.showDrawHint = showDrawHint;
  };

  /**
   * filters for fetchZones request
   *
   * @memberof DeliveryZonesStore
   */
  public filters: IZonesFilter = defaultFilters;
  /**
   * set filters for request
   *
   * @param {ZonesFilter} filters
   * @memberof DeliveryZonesStore
   */
  public setFilters = (filters: IZonesFilter, clear?: boolean) => {
    this.tooltip = null;
    if (clear) {
      this.filters = defaultFilters;
      return;
    }

    this.filters = { ...this.filters, ...filters };
  };

  /**
   * list of loaded pages, required for pagination and lazy loading starting not from the first page
   *
   * @type {number[]}
   * @memberof OrdersStore
   */
  public pages: number[] = [];
  /**
   * pagination list with delivery zones
   *
   * @type {IPagination<IUnavailableZone>}
   * @memberof DeliveryZonesStore
   */
  pagination: IPagination<IUnavailableZone> = defaultPagination;
  zones: IUnavailableZone[] = [];

  lastUpdate = 0;

  public setDefaultZoom = () => {
    // if (!this.zone) this.setTooltip();
    // update tooltip
    // if (this.zone) {
    //   if (this.zone.type === DELIVERY_ZONE_TYPE.RADIUS) {
    //     if (!this.zone.branch) this.setTooltip();
    //     else this.setTooltip(this.getBranchCoordinats(this.zone.branch), { zone_name: this.zone.name, branch_name: this.zone.branch?.name });
    //   } else if (this.zone.type === DELIVERY_ZONE_TYPE.POLYGON) {
    //     if (!this.zone.bounds.length) this.setTooltip();
    //     else this.setTooltip(getCenterOfBounds(this.zone.bounds), { zone_name: this.zone.name, branch_name: this.zone.branch?.name });
    //   }
    // }
    // const availableZones: IUnavailableZone[] = this.pagination.data.filter(
    //   (zone) => zone.status && zone.branch && !getUnavailable(zone.branch, UNAVAILABLE_BRANCHES_FLAG.DELETE)
    // );
    // if (!availableZones.length) this.mapStore.setZoom(DEFAULT_ZOOM);
    // this.mapStore.fitMap(
    //   availableZones.reduce<[number, number][]>(
    //     (bounds: [number, number][], zone: IUnavailableZone) => [
    //       ...bounds,
    //       ...(zone.type === DELIVERY_ZONE_TYPE.POLYGON ? zone.bounds : this.getPoint(zone)),
    //     ],
    //     []
    //   )
    // );
  };

  fetchZones = async () => {
    try {
      // Data cashing if zones fetched less than 10 min
      if (this.lastUpdate && this.pagination.data.length && moment().diff(moment(this.lastUpdate), 'minutes') < 10) {
        return;
      }

      this.pagination.loading = true;
      this.pagination.last_page = this.pagination.last_page || 1;

      // Fetching all zones in parts

      const response: { data: { zones: IUnavailableZone[] } } = await client.get('/v1/unavailable_zones', {
        params: { search: this.filters.search },
      });

      const data = response.data.zones.map(unavailableZonesCast).map(
        (zone: IUnavailableZone): IUnavailableZone => ({
          ...zone,
          color: zone.color || PALETTE_COLORS[getRandomInt(0, PALETTE_COLORS.length - 1)],
        }),
      );
      this.pagination.data = data;

      this.lastUpdate = Date.now();
    } catch (error) {
      console.log(error);
      this.pagination.loading = false;
    } finally {
      this.pagination.loading = false;
    }
  };

  createZone = async (draft: IUnavailableZone) => {
    try {
      const data = {
        name: draft.name,
        bounds: draft.bounds,
        color: draft.color, // hex
        end_time: moment(draft.end_time).format('YYYY-MM-DD HH:mm:ss'),
        start_time: moment(draft.start_time).format('YYYY-MM-DD HH:mm:ss'),
        timezone_offset: draft.timezone_offset,
      };

      const response: { data: { id: any } } = await client.post(`/v1/unavailable_zones`, data);
      // await Promise.resolve();
      // const mockResponse: IUnavailableZone = { id: String(getRandomInt(1, 100)), ...data };
      this.pagination.data = [...this.pagination.data, { ...draft, id: String(response.data.id) }];
      this.clearDraft();
    } catch (error: any) {
      messagesStore.addMessage({
        type: 'error',
        message: getMessageFromError(error),
      });
    }
  };

  updateZone = async ({ id, ...zone }: IUnavailableZone) => {
    try {
      console.log(zone);
      await client.put(`/v1/unavailable_zones/${id}`, zone);
      await Promise.resolve();

      const index = this.pagination.data.findIndex((item) => item.id === id);
      this.pagination.data[index] = { id, ...zone };
      this.clearDraft();
      this.selectZone({ id, ...zone });
    } catch (error: any) {
      console.log(error);
      messagesStore.addMessage({
        type: 'error',
        message: getMessageFromError(error),
      });
    }
  };

  deleteZone = async (id: string) => {
    this.tooltip = null;

    if (id === '0') {
      this.clearDraft();
      return;
    }

    try {
      await client.delete(`/v1/unavailable_zones/${id}`);
      // await Promise.resolve();
      this.pagination.data = this.pagination.data.filter((zone) => zone.id !== id);
      // this.mapStore.ref?.current?.forceUpdate();
      this.selectZone(null);
      this.changeDrawHintVisibility(false);
    } catch (error: any) {
      messagesStore.addMessage({
        type: 'error',
        message: getMessageFromError(error),
      });
    }
  };

  // ZONE

  zone: null | IUnavailableZone = null;

  draft: null | IUnavailableZone = null;
  mode: FORM_MODE = FORM_MODE.HIDDEN;

  selectDraft = (zone: IUnavailableZone | null, mode: FORM_MODE) => {
    this.mode = mode;
    this.draft = zone ? Object.assign({}, zone) : initDeliveryZone();
  };

  clearDraft = () => {
    this.mode = FORM_MODE.HIDDEN;
    this.draft = null;
    this.changeDrawHintVisibility(false);
  };

  editDraftZone = (key: any, value: IUnavailableZone[keyof IUnavailableZone]) => {
    // @ts-ignore
    if (key === 'bounds' && !value?.length) {
      this.tooltip = null;
    }

    if (this.draft) {
      this.draft = {
        ...this.draft,
        [key]: value,
      };
    }
  };

  selectZone = (zone: IUnavailableZone | null) => {
    if (!zone) {
      this.zone = null;
      this.setDefaultZoom();
      return;
    }

    this.zone = zone;

    if (zone.bounds.length) {
      const center = getCenterOfBounds(zone.bounds);

      this.setTooltip(center, [
        {
          label: 'Zone',
          value: zone.name,
        },
        {
          label: localesStore.t('zone_start_datetime'),
          value: moment(zone.start_time)
            .add(zone.timezone_offset * 60, 'minutes')
            .format('DD/MM/YYYY hh:mm A'),
        },
        {
          label: localesStore.t('zone_end_datetime'),
          value: moment(zone.end_time)
            .add(zone.timezone_offset * 60, 'minutes')
            .format('DD/MM/YYYY hh:mm A'),
        },
        {
          label: localesStore.t('timezone'),
          value: `GMT ${zone.timezone_offset >= 0 ? '+' : '-'}${zone.timezone_offset}`,
        },
      ]);
      this.mapStore.fitMap(zone.bounds);
    } else {
      // const point = this.getPoint(zone);
      // const center = getCenterOfBounds(point);
      this.mapStore.fitMap([
        {
          lat: 24.7187496,
          lng: 46.7,
        },
      ]);
      // this.setDefaultZoom();
      // this.setTooltip(this.getBranchCoordinats(zone.branch) || center, { zone_name: zone.name, branch_name: zone.branch?.name });
    }
  };

  public clear = () => {
    this.selectZone(null);
    this.mapStore.clear();
    this.clearDraft();
    this.setFilters({}, true);
  };

  public getColor = (zone: IUnavailableZone): string => {
    // We have timezone issue here. Another solution possible.
    if (zone.start_time === zone.end_time || moment(zone.end_time).add(zone.timezone_offset, 'hours').isBefore(moment())) {
      return PALETTE_COLOR_GREY;
    }

    return zone.color;
  };
}

const deliveryZonesStore = new DeliveryZonesStore();

export default deliveryZonesStore;
