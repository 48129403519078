import { Component } from 'react';
import { Icons } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import { Button } from 'taker-web-ui';
import { generatePath } from 'react-router-dom';
import Modal from '~/view/components/Controls/Modal/Modal';
import { localesStore } from '~/mobx';
import { modalStore } from '~/mobx';
import ModalBackground from '~/view/components/Controls/Modal/ModalBackground/ModalBackground';
import ModalContent from '~/view/components/Controls/Modal/ModalContent/ModalContent';
import './style.scss';
import Icon from '~/view/components/Controls/Icon/Icon';
import withRouter from '~/utils/withRouter';
import { RouterComponentProps } from '~/types/main';

type IProps = RouterComponentProps<{}>;

export default withRouter(
  class ErrorModal extends Component<IProps, {}> {
    closeModal = () => {
      modalStore.closeErrorModal();
      this.props.router.navigate(generatePath('/requests'));
    };

    render() {
      return (
        <Modal className="error-modal" isOpen={true} onRequestClose={this.closeModal}>
          <ModalBackground>
            <ModalContent>
              <Icon className={'error-warring-icon'} name={Icons.Warning} />
              <h3 className={'error-title'}>{modalStore.errorModal?.text ?? localesStore.t('something_went_wrong')}</h3>
              <Button
                stretched
                appearance={'tertiary'}
                title={localesStore.t('close')}
                className={'py-3 ml-1 error-close-button'}
                onClick={this.closeModal}
              />
            </ModalContent>
          </ModalBackground>
        </Modal>
      );
    }
  },
);
