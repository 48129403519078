import React from 'react';
import { ICONS } from '~/constants/icons';
import theme from '~/constants/styles';
import { IPickerOption } from '~/types/main';
// import CustomTooltip, { TOOLTIP_DURATION, TOOLTIP_POSITION } from '~/view/components/Controls/CustomTooltip/CustomTooltip';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

interface IProps {
  item?: IPickerOption;
  isSelected?: boolean;
  className?: string;
  viewedSize?: 'small' | 'normal';
  withoutSelectedIcons?: boolean;
  unavailable?: boolean;
  outOfStock?: boolean;
  onClick?: (item: IPickerOption) => void;
  children?: React.ReactNode;
}

export default class PickerOption extends React.Component<IProps, {}> {
  onClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (
      !this.props.item ||
      !this.props.onClick ||
      // (event.target as HTMLLIElement)?.classList.contains('icons-icon') ||
      (event.target as HTMLLIElement)?.classList.contains('tooltip__overlay')
    ) {
      return;
    }
    this.props.onClick(this.props.item);
  };

  get className(): string {
    let className = 'picker-option';
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.viewedSize === 'small') className += ' picker-option_small';
    return className;
  }

  get markText(): string | null {
    if (this.props.unavailable) return 'not_available';
    if (this.props.outOfStock) return 'out_of_stock';
    return null;
  }

  render(): React.ReactNode {
    return (
      <li className={this.className} onClick={this.onClick}>
        {this.props.children ? (
          this.props.children
        ) : (
          <>
            {this.props.withoutSelectedIcons ? null : (
              <Icon
                name={ICONS.Check}
                size={this.props.viewedSize === 'small' ? 18 : 22}
                color={theme.colors.mainblue}
                className={`mr-3 ${!this.props.isSelected ? 'picker-option_unselected' : ''}`}
              />
            )}
            <p className="picker-option__text text-elipsis w-100">{this.props.item?.label || ''}</p>
            {/* {this.markText && (
              <div className="picker-option__mark ml-2">
                <CustomTooltip message={this.markText} position={TOOLTIP_POSITION.PORTAL} duration={TOOLTIP_DURATION.SMALL}>
                  <Icon name={ICONS.Warning} size={15} color={theme.colors.red} />
                </CustomTooltip>
              </div>
            )} */}
          </>
        )}
      </li>
    );
  }
}
