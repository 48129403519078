import './style.scss';

import { observer } from 'mobx-react';
import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
// import { appStore } from '~/mobx';
import Picker from '~/view/components/Controls/Picker/Picker';
import { localesStore } from '~/mobx';

class LanguageSwitcher extends Component {
  onLanguageChange = (option: any) => {
    localesStore.setLocale(option.value);
  };

  render() {
    if (!localesStore.timestamp) return <Skeleton width={130} height={40} />;

    const options = [];
    let value;
    for (let i = 0; i < localesStore.languages.length; i++) {
      options[i] = {
        label: localesStore.languages[i].name,
        value: localesStore.languages[i].code,
      };

      if (localesStore.language === localesStore.languages[i].code) {
        value = options[i];
      }
    }

    return <Picker className="language-picker" onChange={this.onLanguageChange} options={options} value={value} />;
  }
}

export default observer(LanguageSwitcher);
