import { action, observable, makeObservable, runInAction, flow } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { appStore } from '~/mobx';
import client from '~/services/client';
import cookies from '~/services/cookie';
import { TBooleanInNumber, TTranslate } from '~/types/main';
import { getDefaultLanguage, getDirection, getLanguage } from '~/utils/helpers';

export type TTrans = {
  [key: string]: {
    [key: string]: string;
  };
};

export type TTransOptions = {
  [key: string]: string;
};

export type TLanguage = {
  id: number;
  name: string;
  code: string;
  direction: string;
  status: TBooleanInNumber;
  default: TBooleanInNumber;
  created_at: string;
  updated_at: string;
};
export class LocalesStore {
  constructor() {
    makeObservable(this, {
      loading: observable,
      defaultLanguage: observable,
      fetchLocale: action,
      setLocale: action,
      t: action,
      isSettingsLoaded: observable,
      settings: observable,
      languages: observable,
      trans: observable,
      language: observable,
      direction: observable,
      timestamp: observable,
    });

    makePersistable(this, {
      storage: window.localStorage,
      name: 'LocalesStore',
      properties: ['isSettingsLoaded', 'settings', 'languages', 'trans', 'language', 'direction', 'timestamp'],
    })
      .then(() => {
        this.fetchLocale();
        cookies.setCookie('language', this.language);
        client.headers = {
          'Accept-Language': this.language,
        };
        return;
      })
      .catch(console.error);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  loading = false;
  defaultLanguage = 'en';

  public isSettingsLoaded = false;
  public settings: any | null = null;
  public languages: TLanguage[] = [];
  public trans: TTrans | null = null;

  language: string = getDefaultLanguage();
  direction: string = getDirection(this.language, this.languages);
  timestamp: null | number = null;

  fetchLocale = flow(function* (this: LocalesStore) {
    if (!this.isLoadingNeeded() && appStore.settings) {
      return;
    }
    runInAction(() => {
      this.loading = true;
    });

    try {
      const response = yield client.get(`/v1/settings`);

      runInAction(() => {
        appStore.settings = response.settings;
        this.settings = response.settings;
        this.languages = response.languages;
        this.trans = response.trans;
        this.language = getLanguage(this.language, this.languages);
        this.timestamp = Date.now();
        this.isSettingsLoaded = true;
      });

      this.setLocale(this.language);
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.loading = false;
    });
  }).bind(this);

  public setLocale(language: string) {
    const direction = getDirection(language, this.languages);
    const isDirectionChanged = direction !== this.direction;

    cookies.setCookie('language', language);
    cookies.setCookie('direction', direction);

    this.language = language;
    this.direction = direction;

    client.headers = {
      'Accept-Language': language,
    };

    if (isDirectionChanged) {
      return window.location.reload();
    }

    this.fetchLocale();
  }

  public t = (key: any, options: any = {}): string => {
    if (!this.trans || !this.trans[this.language]) return key;

    let translate = this.trans[this.language][key] || key;

    if (translate === key && this.language !== this.defaultLanguage) {
      translate = this.trans[this.defaultLanguage][key] || key;
    }

    if (options) {
      translate = Object.entries(options).reduce((_translate, [_key, value]) => {
        return _translate.replace(`{{${_key}}}`, value);
      }, translate);
    }

    return translate;
  };

  isLoadingNeeded() {
    if (this.timestamp === null) {
      this.timestamp = Date.now();
    }

    const time = this.timestamp + 300 * 1000;

    if (this.isSettingsLoaded !== true || time < Date.now()) {
      this.timestamp = Date.now();
      return true;
    }

    return false;
  }

  public getTranslate = (translate?: TTranslate) => (translate ? translate[localesStore.language] : '');
}

const localesStore = new LocalesStore();

export default localesStore;
