import { debounce } from './perfomance';
// import { LOCALES } from '~/constants/main';
// import localesStore, { TLanguage } from '~/mobx/localesStore/localesStore';
import cookies from '~/services/cookie';
import { TBooleanInNumber } from '~/types/main';
import { TLanguage } from '~/mobx/localesStore/localesStore';

const delay = async (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

const isObject = (data: any) => {
  if (typeof data !== 'object' || Array.isArray(data) || !(data instanceof Object)) return false;
  return typeof data === 'object';
};

const getUserPositionPromise = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      maximumAge: 50000,
      timeout: 2000,
      enableHighAccuracy: true,
    });
  });

const getUserPosition = async (): Promise<GeolocationPosition | null> => {
  try {
    return await getUserPositionPromise();
  } catch (error) {
    console.log(error);
    return null;
  }
};

const copyToBuffer = (data: string | number | undefined) => {
  if (typeof data !== 'string') return;
  navigator.clipboard.writeText(data);
};

const booleanToNumber = (boolean: boolean | TBooleanInNumber | number): TBooleanInNumber => (boolean ? 1 : 0);

const getBrowserLanguage = (): string => {
  return navigator.language.substring(0, 2);
};

const getDefaultLanguage = (): string => {
  const userLanguage = cookies.getCookie('language');

  if (userLanguage !== undefined && userLanguage.length > 0) {
    return userLanguage;
  }

  return getBrowserLanguage();
};

const getLanguage = (code: string, languages?: TLanguage[]): string => {
  if (languages !== undefined) {
    for (const language of languages) {
      if (language.code === code) {
        return language.code;
      }
    }

    for (const language of languages) {
      if (language.default === 1) {
        return language.code;
      }
    }
  }

  return 'en';
};

const getDirection = (code: string, languages?: TLanguage[]): string => {
  if (languages !== undefined) {
    for (const language of languages) {
      if (language.code === code) {
        return language.direction;
      }
    }
  }

  return cookies.getCookie('direction') ?? 'ltr';
};

const getGoogleMapAddress = (lat: number, lng: number) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
};

const _isNaN = (value: any) => {
  if (typeof value !== 'number') return true;
  return isNaN(value);
};

const getTimeZoneDiffInHours = () => (new Date().getTimezoneOffset() / 60) * -1;

const isEqAsString = (a: any, b: any) => String(a) === String(b);

// /**
//  * return translate for selected language or will return incoming parameter
//  *
//  * @param {(TTranslate | string)} [translate]
//  * @return {*}
//  */
// const getTranslate = (translate?: TTranslate | string, locale?: LOCALES.AR | LOCALES.EN) => {
//   if (locale) {
//     if (!translate) return '';
//     return typeof translate === 'string' ? localesStore.trans?.[locale][translate] || translate : translate[locale];
//   }

//   if (typeof translate === 'string') return translate;
//   return translate ? translate[localesStore.language] : '';
// };

// cuts off unnecessary 0 in front, replaces "," with "." (if this is not done, then when converted to a number it will give NaN)
const stringTransform = (value: string): string => {
  const _value: string = value.split(',').join('.').trim();
  return _value.length > 1 && _value[1] !== '.' ? _value.replace(/^0+/, '') : _value;
};

const scrollToErrorElement = debounce(() => {
  const element = document.querySelector('.input-error');
  if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });
}, 100);

export {
  _isNaN,
  // hasPermissions,
  // hasRole,
  booleanToNumber,
  copyToBuffer,
  delay,
  getBrowserLanguage,
  getDefaultLanguage,
  getDirection,
  getGoogleMapAddress,
  getLanguage,
  getTimeZoneDiffInHours,
  getUserPosition,
  getUserPositionPromise,
  isEqAsString,
  isObject,
  scrollToErrorElement,
  stringTransform,
};
