import './style.scss';

import * as React from 'react';

interface IFloatingButtonProps {
  title: string;
  onClick: () => void;
}

export default class FloatingButton extends React.Component<IFloatingButtonProps> {
  public render() {
    return (
      <div className="floating-button" onClick={this.props.onClick}>
        {this.props.title}
      </div>
    );
  }
}
