import { observer } from 'mobx-react';
import { Component } from 'react';
import CustomPicker from '~/view/components/Controls/CustomPicker/CustomPicker';
import { localesStore } from '~/mobx';
import { trackingStore } from '~/mobx';
import { IPickerOption } from '~/types/main';
import './style.scss';

class SelectDSP extends Component {
  handleChangeDSP = (options: IPickerOption[]) => {
    trackingStore.setRequestsFilters(
      {
        dsp: options.map(({ value }) => value),
      },
      false,
    );
  };

  render() {
    // @ts-ignore
    const selectedOptions: IPickerOption[] = trackingStore.availableDSPs?.filter((item) => trackingStore.requestsFilters.dsp?.includes(item.value));

    return (
      <CustomPicker
        className="mr-2"
        onChange={this.handleChangeDSP}
        options={trackingStore.availableDSPs}
        selectedOptions={selectedOptions}
        defaultPlaceholder={localesStore.t('dsp_info')}
        loading={trackingStore.isAvailableDSPsLoading}
        searchable
        isMulti
      />
    );
  }
}

export default observer(SelectDSP);
