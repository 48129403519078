import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import NoResults from '~/view/components/NoResults/NoResults';
import { TRACKING_GROUPS, TTrackingRequest } from '~/constants/tracking';
import { localesStore, trackingStore } from '~/mobx';
import ScrollableList from '~/view/components/ScrollableList/ScrollableList';
import { IDsp } from '~/types/dsp';
import { DEBOUNCE_TIME } from '~/constants/main';
import { RouterComponentProps } from '~/types/main';
import './style.scss';
import { withRouter } from '~/utils';
import { TTrackingRequestsFilters } from '~/mobx/trackingStore/trackingStore';
import RequestItem from '~/view/screens/DeliveryTrackingScreen/components/RequestItem/RequestItem';

interface IProps extends RouterComponentProps<{ id: string | undefined; group: TRACKING_GROUPS }, IDsp> {
  selectedId?: string;
  onSelect: (request: TTrackingRequest, ref: RefObject<HTMLDivElement>) => void;
}

class RequestsList extends React.Component<IProps> {
  // @ts-ignore
  listRef: RefObject<ScrollableList> = React.createRef();

  handleSelectItem = (request: TTrackingRequest, ref: RefObject<HTMLDivElement>) => {
    this.props.onSelect(request, ref);

    const wrapper = this.listRef.current?.wrapper;
    const listWrapper = wrapper?.current;

    const item = ref.current;

    if (!listWrapper || !item) return;

    if (listWrapper.scrollTop > item.offsetTop || +listWrapper.scrollTop + +listWrapper.offsetHeight < item.offsetTop + item.clientHeight) {
      item.scrollIntoView();
    }
  };

  renderRequestItem = (request: TTrackingRequest | undefined) => {
    if (request === undefined) return;

    const { selectedId } = this.props;

    const isSelected = selectedId ? selectedId === request.request.id : false;

    return <RequestItem selected={isSelected} request={request} key={request.request.id} onSelect={this.handleSelectItem} />;
  };

  handleFetch = async (page = 1) => {
    await trackingStore.getRequests(page);
  };

  handleSearch = (search: string) => {
    trackingStore.setRequestsFilters({ search } as TTrackingRequestsFilters, false);
  };

  render() {
    return (
      <div className="requests-list d-flex flex-column full-height">
        <div className="d-flex flex-column full-width mb-2">
          <SearchInput
            loading={trackingStore.requestsLoading}
            className={'input-wrapper bg-graysoft border-transparent'}
            inputClassName={'color-dark bg-graysoft text-size-16 family-medium'}
            onChangeText={this.handleSearch}
            withCloseIcon
            placeholder={localesStore.t('search_box')}
            value={trackingStore.requestsFilters.search || ''}
            debounceTime={DEBOUNCE_TIME.LARGE}
            ref={trackingStore.sRef}
          />
        </div>
        <ScrollableList
          // listClassName={''}
          autoFetch={false}
          fetch={this.handleFetch}
          list={trackingStore.trackingRequests.data}
          loading={trackingStore.requestsLoading || trackingStore.requestLoading}
          current_page={trackingStore.trackingRequests.pagination.current_page}
          last_page={trackingStore.trackingRequests.pagination.last_page}
          renderItem={this.renderRequestItem}
          scrollToTop
          pages={trackingStore.pages}
          scrollbar={true}
          scrollbarClassName="requests-list__scrollbar"
          emptyListText={localesStore.t('no_results_found')}
          lastPageText={' '}
          emptyListComponent={
            <NoResults>
              {this.props.router.params.group === TRACKING_GROUPS.ALL ? (
                <div
                  onClick={() => {
                    this.props.router.navigate('/requests');
                  }}
                  className={'cursor-pointer mt-2'}>
                  {localesStore.t('search_suggestion', { section_name: '' })}
                  <div className={'color-mainblue'}>{localesStore.t('delivery_requests_section')}</div>
                </div>
              ) : null}
            </NoResults>
          }
          ref={this.listRef}
        />
      </div>
    );
  }
}

export default withRouter(observer(RequestsList));
