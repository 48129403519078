import './style.scss';

import { observer } from 'mobx-react';
import {useCallback, useEffect, useRef, useState} from 'react';
import { DeliveryZoneComponent } from '../../components/DeliveryZoneComponent/DeliveryZoneComponent';
import { deliveryZonesStore } from '~/mobx';
import { ICONS } from '~/constants/icons';
import theme from '~/constants/styles';
import { IUnavailableZone } from '~/types/deliveryManagment';
import { ColorBox } from '~/view/components/Controls/ColorBox/ColorBox';
import Icon from '~/view/components/Controls/Icon/Icon';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import ScrollableList from '~/view/components/ScrollableList/ScrollableList';
import { DEBOUNCE_TIME, FORM_MODE } from '~/constants/main';

export const DeliveryZonesSidebarList = observer(() => {
  const wrapper = useRef<ScrollableList<IUnavailableZone> | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (deliveryZonesStore.zone) {
      deliveryZonesStore.selectZone(deliveryZonesStore.zone);
    }
  }, []);

  useEffect(() => {
    const list = wrapper.current?.wrapper.current;
    const zoneItem = document.getElementById(`zone_${deliveryZonesStore.zone?.id || ''}`);

    if (!list || !zoneItem) return;

    zoneItem.scrollIntoView({ block: 'center', behavior: 'smooth' });

  }, [deliveryZonesStore.zone?.id]);

  const onChangeText = useCallback((value: string) => {
    deliveryZonesStore.setFilters({ search: value });
    setSearch(value);
  }, []);

  const renderZone = (zone: IUnavailableZone | undefined, index: number) => {
    if (!zone) return null;
    return <DeliveryZoneComponent id={zone.id} key={zone.id} zone={zone} index={index} />;
  };

  return (
    <div className="delivery-zone-list">
      <div className="px-2 d-flex flex-row">
        <SearchInput
          customPlaceholder
          customPlaceholderIconClassName="delivery-zone-list__search-icon"
          value={deliveryZonesStore.filters.search}
          loading={!!deliveryZonesStore.pagination.loading}
          debounceTime={DEBOUNCE_TIME.LARGE}
          onChangeText={onChangeText}
          // placeholder={localesStore.t('branch_or_zone')}
          className="delivery-zone-list__search"
          // inputClassName="delivery-zone-list__search-input"
          withCloseIcon
        />

        <div className="ml-1">
          <ColorBox
            onSelect={() => deliveryZonesStore.selectDraft(null, FORM_MODE.EDIT)}
            className="cursor-pointer"
            color={theme.colors.bluelight}
            size={46}>
            <Icon name={ICONS.Plus} size={16} className="color-mainblue " />
          </ColorBox>
        </div>
      </div>
      <div className="delivery-zone-list__container d-flex">
        <ScrollableList<IUnavailableZone>
          autoFetch
          fetch={deliveryZonesStore.fetchZones}
          list={deliveryZonesStore.pagination.data.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))}
          loading={!!deliveryZonesStore.pagination.loading}
          refreshing={deliveryZonesStore.pagination.refreshing}
          current_page={deliveryZonesStore.pagination.current_page}
          last_page={deliveryZonesStore.pagination.last_page}
          scrollToTop={false}
          scrollbar
          // scrollbarSize="normal"
          scrollbarClassName="delivery-zone-list__scroll"
          lastPageText={' '}
          emptyListText={'no_more_zones'}
          pages={deliveryZonesStore.pages}
          renderItem={renderZone}
          ref={wrapper}
        />
      </div>
    </div>
  );
});
