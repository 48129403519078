import { USER_ROLES } from '~/constants/user';

export enum USER_HINT_MESSAGES {
  EDIT_PROFILES = 'change_profile_branch_dispatching_rules',
  DRAW_POLYGON = 'draw_polygon',
}

export const userCast = ({ permissions = [], organization_ids = [], ...item }) => ({
  id: String(item.id),
  name: String(item.name),
  login: String(item.name),
  image: String(item.image),
  token: String(item.token),
  permissions: (permissions ?? []).map((permission) => String(permission)),
  role: parseInt(item.role, 10) as USER_ROLES,
  role_txt: String(item.role_txt),
  organization_ids: (organization_ids ?? []).map((organization_id) => String(organization_id)),
  hint_messages: {
    change_profile_branch_dispatching_rules: 0,
    draw_polygon: 0,
  },
});

export type IUser = ReturnType<typeof userCast>;
