import { Component } from 'react';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import './style.scss';

interface Props {
  lat?: number;
  lng?: number;
  name: IconsId;
  disabled?: boolean;
  color?: string;
  size?: number;
  className?: string;
  onPress?: () => void;
  style?: object;
  shouldBeReversed?: boolean;
  children?: React.ReactNode;
}

export default class Icon extends Component<Props> {
  onPress = () => {
    if (this.props.disabled) return;
    if (this.props.onPress) this.props.onPress();
  };

  render() {
    const { size, color, style, shouldBeReversed = false, name, className = '', children } = this.props;

    return (
      <i
        onClick={this.onPress}
        style={{
          fontSize: size,
          width: size,
          height: size,
          ...(color ? { color } : {}),
          ...style,
        }}
        className={`icons-icon ${shouldBeReversed ? 'icons-reversed' : ''} icon-${name} ${className}`}>
        {children}
      </i>
    );
  }
}
