import React, { Component } from 'react';
import { LogoutButton } from '~/view/components/LogoutButton/LogoutButton';
import { SelectDSP } from '~/view/components/Controls/SelectDSP';
import Bluebell from '~/view/components/Bluebell/Bluebell';
import { withRouter } from '~/utils';
import LanguageSwitcher from '~/view/components/Controls/LanguageSwitcher/LanguageSwitcher';
import './style.scss';
import { RouterComponentProps } from '~/types/main';

type TProps = RouterComponentProps<{}, { children: React.ReactNode }>;

class Headers extends Component<TProps, {}> {
  render() {
    return (
      <header className="header bg-white d-flex justify-content-between pr-4">
        <div className="full-width">{this.props.children}</div>

        <div className="d-flex align-items-center ml-2">
          {this.props.router.location.pathname.startsWith('/tracking/') && <SelectDSP />}
          <LanguageSwitcher />
          <div className="ml-4 text-pointer">
            <Bluebell />
          </div>
          <div className="ml-4 text-pointer">
            <LogoutButton />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Headers);
