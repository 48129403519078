import { AppStore } from '~/mobx/appStore/appStore';
import { LocalesStore } from '~/mobx/localesStore/localesStore';
import { TrackingStore } from '~/mobx/trackingStore/trackingStore';
import { UserStore } from '~/mobx/userStore/userStore';
import { localesStore, userStore, trackingStore, appStore } from '~/mobx';

export enum STORES {
  APP_STORE = 'takergo:appStore',
  USER_STORE = 'takergo:userStore',
  LOCALE_STORE = 'takergo:localesStore',
  TRACKING_STORE = 'takergo:trackingStore',
}

export interface IMainStore {
  [STORES.APP_STORE]: AppStore;
  [STORES.USER_STORE]: UserStore;
  [STORES.LOCALE_STORE]: LocalesStore;
  [STORES.TRACKING_STORE]: TrackingStore;
}

const mainStore: IMainStore = {
  [STORES.APP_STORE]: appStore,
  [STORES.USER_STORE]: userStore,
  [STORES.LOCALE_STORE]: localesStore,
  [STORES.TRACKING_STORE]: trackingStore,
};

export default mainStore;
