import './style.scss';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button } from 'taker-web-ui';
import LanguageSwitcher from '~/view/components/Controls/LanguageSwitcher/LanguageSwitcher';
import { appStore, localesStore, userStore } from '~/mobx';
import InputOld from '~/view/components/Controls/InputOld/InputOld';

interface IState {
  login: string;
  password: string;

  loading: boolean;
  errors: any;
}

class LoginScreen extends Component {
  params: URLSearchParams = new URLSearchParams(window.location.search);
  alternative = this.params.get('alternative');
  state: IState = {
    login: '',
    password: '',
    loading: false,
    errors: {},
  };

  onChangeField = (type: 'login' | 'password') => (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      this.setState((state) => ({ ...state, [type]: event.target.value }));
    } catch (error) {
      console.log(error);
    }
  };

  submit = async (event?: React.FormEvent) => {
    try {
      if (event) event.preventDefault();

      if (!this.state.login && !this.state.password) {
        this.setState({
          errors: {
            error_message: localesStore.t('fields_required'),
            login: true,
            password: true,
          },
        });
        return;
      } else if (!this.state.login || !this.state.password) {
        this.setState({
          errors: {
            error_message: localesStore.t('field_required'),
            login: !this.state.login,
            password: !this.state.password,
          },
        });
        return;
      }

      this.setState(() => ({ loading: true }));
      const data: any = {
        login: this.state.login,
        password: this.state.password,
      };
      await userStore.login(data);
      this.setState(() => ({ loading: false }));
    } catch (errorsResponse: any) {
      console.log(errorsResponse);
      let errors = {};
      if (errorsResponse.error) {
        errors = {
          loading: false,
          errors: {
            error_message: localesStore.t('incorrect_username_password'),
            login: true,
            password: true,
          },
        };
      } else {
        switch (Object.keys(errorsResponse).length) {
          case 1:
            errors = {
              loading: false,
              errors: {
                error_message: localesStore.t('field_required'),
                login: !!errorsResponse.login,
                password: !!errorsResponse.password,
              },
            };
            break;
          case 2:
            errors = {
              loading: false,
              errors: {
                error_message: localesStore.t('fields_required'),
                login: true,
                password: true,
              },
            };
            break;
        }
      }

      this.setState(() => errors);
      return this.setState({ password: '' });
    }
  };
  checkEmptyInputs = () => {
    if (!this.state.errors.error_message) {
      if (!this.state.login.length && !this.state.password.length) {
        return this.setState(() => ({ loading: false, errors: { login: true, password: true } }));
      }

      if (!this.state.login.length) {
        return this.setState(() => ({ loading: false, errors: { login: true } }));
      }

      if (!this.state.password.length) {
        return this.setState(() => ({ loading: false, errors: { password: true } }));
      }
    }
  };
  inputOnFocus = () => {
    return this.setState(() => ({ loading: false, errors: {} }));
  };
  clear = () => {
    if (!this.state.errors.error_message) {
      this.setState(() => ({ loading: false, errors: { login: false, password: false } }));
    }
  };

  render() {
    return (
      <div className="login d-flex align-items-center justify-content-center flex-grow-1 full-height">
        <div className="login__language-switcher">
          <LanguageSwitcher />
        </div>
        <div className="login__content">
          <h1 className="color-mainblue text-size-36 text-center mb-5">
            <img className={'logo'} src={'/images/logo.png'} alt={'user_img'} />
          </h1>
          <form onSubmit={this.submit} className="d-flex flex-column pos-relative">
            {this.state.errors.error_message ? <span className={'error-message mt-5'}>{this.state.errors.error_message}</span> : null}
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <InputOld
                  onChange={this.onChangeField('login')}
                  disabled={this.state.loading}
                  onFocus={this.inputOnFocus}
                  error={this.state.errors?.login}
                  message={this.state.errors?.error_message}
                  value={this.state.login}
                  placeholder={localesStore.t('username_field')}
                  type="text"
                  className="border-gray text-size-18 family-medium"
                  autoFocus
                />
                <span className="pr-3"></span>
                <InputOld
                  onChange={this.onChangeField('password')}
                  onFocus={this.inputOnFocus}
                  disabled={this.state.loading}
                  error={this.state.errors?.password}
                  value={this.state.password}
                  placeholder={localesStore.t('password_field')}
                  type={'password'}
                  className="border-gray text-size-18 family-medium"
                />
                {appStore.isCapsOn ? <span className="arrowed-tooltip caps-tooltip">{localesStore.t('capslock_on_tooltip')}</span> : null}
              </div>
            </div>
            <Button
              disabled={this.state.loading}
              loading={this.state.loading}
              onMouseEnter={this.checkEmptyInputs}
              onMouseLeave={this.clear}
              type="submit"
              className="mt-4"
              title={localesStore.t('login_button')}
              size="large"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default observer(LoginScreen);
