import moment from 'moment';
import React from 'react';
import { PAYMENT_TYPES, PAYMENT_TYPES_LOCALES } from '~/constants/main';
import Block from '~/view/containers/Modals/ArchiveRequestInfoModal/components/Block';
import { localesStore } from '~/mobx';

type Props = {
  clientOrder: string;
  createdAt: string;
  paymentType: PAYMENT_TYPES;
  orderTotal: number;
  orderUrl: string | null;
  orderDueTime: string;
  distance: number;
};

const TopBar = ({ clientOrder, createdAt, paymentType, orderDueTime, orderTotal, orderUrl, distance }: Props) => (
  <div className={'d-flex flex-row justify-content-between mb-5'} style={{ height: 'min-content' }}>
    <Block title={localesStore.t('client_order')} value={`${clientOrder}`} url={orderUrl} />
    <Block title={localesStore.t('order_created_at')} value={moment(createdAt).format('DD/MM/YYYY, h:mm A')} />
    <Block title={localesStore.t('payment_type')} value={`${localesStore.t(PAYMENT_TYPES_LOCALES[paymentType])}`} />
    <Block title={localesStore.t('order_total')} value={`SAR ${orderTotal}`} />
    <Block title={localesStore.t('order_due_time')} value={moment(orderDueTime).format('DD/MM/YYYY, h:mm A')} />
    <Block title={localesStore.t('distance')} value={`${distance} ${localesStore.t('km')}`} />
  </div>
);

export default TopBar;
