import { observer } from 'mobx-react';
import React from 'react';
import { deliveryZonesStore } from '~/mobx';
import { IUnavailableZone } from '~/types/deliveryManagment';
import MapPin from '~/view/components/Map2/MapTooltip/MapTooltip';

const DeliveryZoneTooltip = observer(() => {
  if (!deliveryZonesStore.tooltip) return null;

  return (
    <div>
      <MapPin<IUnavailableZone>
        key={deliveryZonesStore.zone?.id}
        options={deliveryZonesStore.tooltip.options}
        tooltip
        latitude={deliveryZonesStore.tooltip.center?.lat}
        longitude={deliveryZonesStore.tooltip.center?.lng}
      />
    </div>
  );
});

export default DeliveryZoneTooltip;
