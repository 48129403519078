import './style.scss';

import React, { PropsWithChildren, useCallback } from 'react';
import { InfoBox } from '@react-google-maps/api';
import { localesStore } from '~/mobx';

interface Props<T> {
  latitude: number;
  longitude: number;
  value?: T;
  options?: { label: string; value: string }[];
  tooltip?: boolean;
  zIndex?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSelect?: (value?: T) => void;
  Icon?: JSX.Element;
}

const MapPin = <T,>(props: PropsWithChildren<Props<T>>) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (props.onClick) props.onClick(event);
      if (props.onSelect) props.onSelect(props.value);
    },
    [props.onClick, props.value, props.onSelect],
  );

  return (
    <InfoBox
      position={new google.maps.LatLng(props.latitude, props.longitude)}
      options={{
        disableAutoPan: true,
        closeBoxURL: ``,
        enableEventPropagation: true,
        zIndex: props.zIndex,
        boxStyle: { backgroundColor: 'transparent', position: 'relative', overflow: 'visible', cursor: 'pointer' },
      }}>
      <div onClick={onClick} style={{ position: 'relative', width: 40, height: 40 }}>
        {/* <div style={{ position: 'absolute', bottom: '100%', width: 40, height: 40, left: -20 }}>
          <Icon name={ICONS.MAP_LOCATION} size={40} color={theme.colors.mainblue} />
        </div> */}
        {props.Icon}

        {props.tooltip ? (
          <div className="map-tooltip animate__animated animate__faster animate__fadeInUp">
            <div className="d-flex text-size-12 flex-row">
              <div className="d-flex text-size-12 color-gray2 flex-column mr-2 w-50">
                {props.options?.map((option, index: number) => (
                  <span key={index} className="map-tooltip-option map-tooltip-option-label family-medium">
                    {localesStore.t(option.label)}
                  </span>
                ))}
              </div>
              <div className="d-flex flex-column color-dark text-overflow w-50">
                {props.options?.map((option, index: number) => (
                  <span key={index} className="map-tooltip-option family-semibold text-elipsis">
                    {option.value}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </InfoBox>
  );
};

export default MapPin;
