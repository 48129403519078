import { getRequestId } from './formatter';
import { TTrackingRequest } from '~/constants/tracking';

// order.organization_order_id -> для сетндалонов
// order.reference -> для обычных клиентов
// order.dsp_order_id
// order.dsp_booking_id
// customer.phone
// organization.name
// dsp.provider (name)

/**
 * Check equal of the request to the search phrase
 *
 * @param request Delivery request
 * @param search search phrase
 */
const isReqEqualBySearch = (request: TTrackingRequest, search: string) => {
  if (!search) return true;

  const regexp = new RegExp(search.match(/[a-z0-9 \-]+/gi)?.[0] || '', 'i');

  return (
    regexp.test(getRequestId(request.organization)) ||
    regexp.test(request.organization.name) ||
    regexp.test(request.customer.phone) ||
    regexp.test(request.dsp.name) ||
    regexp.test(request.dsp.order_id) ||
    regexp.test(request.dsp.order_booking_id || '')
  );
};

export default isReqEqualBySearch;
