import app from '~/../package.json';
import './style.scss';

const Version = () => {
  return (
    <div className="app--version">
      <p className="text-size-10 color-mainblue family-bold">{app.version}</p>
    </div>
  );
};
export default Version;
