import './style.scss';

import moment from 'moment';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { TIME_PERIODS, TIME_PERIODS_TIME, TIME_PERIODS_TYPE, timePeriodTrans } from '~/constants/period';
import { deliveryZonesStore } from '~/mobx';

interface Props {
  timePeriod: TIME_PERIODS;
}

export const ButtonPeriod: React.FunctionComponent<Props> = observer(({ timePeriod }) => {
  const onClick = useCallback(() => {
    if (deliveryZonesStore.draft === null) {
      return;
    }

    if (timePeriod === TIME_PERIODS.OFF) {
      deliveryZonesStore.draft.start_time = moment().utc().format('YYYY-MM-DD HH:mm:ss');
      deliveryZonesStore.draft.end_time = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      deliveryZonesStore.draft.start_time = moment().utc().format('YYYY-MM-DD HH:mm:ss');
      deliveryZonesStore.draft.end_time = moment().utc().add(TIME_PERIODS_TIME[timePeriod], 'minutes').format('YYYY-MM-DD HH:mm:ss');
    }
  }, []);

  return (
    <button className={`button-period ${TIME_PERIODS_TYPE[timePeriod]}`} onClick={onClick} data-time={TIME_PERIODS_TIME[timePeriod]}>
      {timePeriodTrans(timePeriod)}
    </button>
  );
});
