import React from 'react';
import { observer } from 'mobx-react';
import { DeliveryZoneMapControls } from '../../components/DeliveryZoneMapControls/DeliveryZoneMapControls';
import DeliveryZonesDraftAreas from '../DeliveryZonesDraftAreas/DeliveryZonesDraftAreas';
import DeliveryZonesMapAreas from '../DeliveryZonesMapAreas/DeliveryZonesMapAreas';
import DeiveryZoneDrawingTools from '../DeiveryZoneDrawingTools/DeiveryZoneDrawingTools';
import DeliveryZoneTooltip from '../DeliveryZoneTooltip/DeliveryZoneTooltip';
import { deliveryZonesStore } from '~/mobx';
import Map from '~/view/components/Map/Map';
import './style.scss';

const DeliveryZonesMapContainer = () => {
  return (
    <div className="delivery-zone-map">
      <Map mapRef={deliveryZonesStore.mapStore.ref} {...deliveryZonesStore.mapStore.props}>
        <DeliveryZonesDraftAreas />
        <DeliveryZonesMapAreas />
        <DeiveryZoneDrawingTools />
        <DeliveryZoneTooltip />
      </Map>
      <DeliveryZoneMapControls />
    </div>
  );
};

export default observer(DeliveryZonesMapContainer);
