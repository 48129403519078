import React from 'react';
import { localesStore } from '~/mobx';
import './style.scss';

export interface ICrumbsItem {
  label: string;
  value: string | number;
}

interface IProps {
  crumbs: ICrumbsItem[];
  activeCrumb: string | number;
  wrapperClassName?: string;
  crumbClassName?: string;
  onClick: (value: string | number) => void;
}

export default class Crumbs extends React.Component<IProps, {}> {
  renderItem(crumb: ICrumbsItem) {
    return (
      <li
        className={`crumbs__crumb ${this.props.crumbClassName || ''} ${this.props.activeCrumb === crumb.value ? 'crumbs__crumb_active' : ''}`}
        onClick={() => this.props.onClick(crumb.value)}>
        {localesStore.t(crumb.label)}
      </li>
    );
  }

  render() {
    return <ul className={`crumbs ${this.props.wrapperClassName || ''}`}>{this.props.crumbs.map((crumb) => this.renderItem(crumb))}</ul>;
  }
}
