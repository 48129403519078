import { observer } from 'mobx-react';
import React from 'react';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

class Bluebell extends React.Component {
  handleClick = () => {
    /*
    if (player.isSoundPlaying) {
      player.stop();
    }
    */
  };

  render() {
    return (
      <Icon
        name={ICONS.Bell_1}
        className="color-gray2 notification-bell"
        size={25}
        onPress={this.handleClick}
        children={false ? <span className="bg-red notification-bell__point" /> : null}
      />
    );
  }
}

export default observer(Bluebell);
