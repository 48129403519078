import React, { Component } from 'react';
import { generatePath, Outlet } from 'react-router';
import Headers from '~/view/components/Headers/Headers';
import HeaderTitle from '~/view/components/Headers/components/HeaderTitle/HeaderTitle';
import { localesStore } from '~/mobx';
import { TabLabel } from '~/view/components/Controls/TabLabel/TabLabel';
import { withRouter } from '~/utils';
import { RouterComponentProps } from '~/types/main';
// import './style.scss';

type IProps = RouterComponentProps<{}, {}>;

class SettingsScreen extends Component<IProps, {}> {
  handleNavigate = (path: string) => {
    this.props.router.navigate(generatePath(path));
  };

  render() {
    return (
      <div className="d-flex flex-column full-width pl-4 pr-4">
        <Headers>
          <HeaderTitle title={localesStore.t('settings')} />
        </Headers>
        <div className="d-flex flex-row justify-content-between pt-6 pr-4">
          <div className="dsp-statuses d-flex flex-row align-items-center">
            {[
              {
                label: localesStore.t('takergo_availability'),
                path: '/settings/unavailable-zones',
              },
              {
                label: localesStore.t('driver_not_found'),
                path: '/settings/driver-not-found',
              },
            ].map((tab) => (
              <TabLabel
                key={tab.path}
                value={tab.path}
                active={tab.path === this.props.router.location.pathname}
                label={tab.label}
                onPress={this.handleNavigate}
              />
            ))}
          </div>
        </div>
        <div className="delivery-zone pt-6">
          <Outlet />
        </div>
      </div>
    );
  }
}

export default withRouter(SettingsScreen);
