import './style.scss';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ICONS } from '~/constants/icons';
import { SCREENS } from '~/constants/screens';
import Icon from '~/view/components/Controls/Icon/Icon';

interface Props {
  url?: string;
  backToDspId?: number;
  backToDspType?: string;
}

export default class GoBackButton extends Component<Props> {
  render() {
    return (
      <div className={'mx-4'}>
        <Link to={SCREENS.DSP_MANAGEMENT(this.props.backToDspType, String(this.props.backToDspId) || '1')}>
          <Icon name={ICONS.Back} shouldBeReversed className="color-mainblue" size={25} />
        </Link>
      </div>
    );
  }
}
