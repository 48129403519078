import { action, observable, makeObservable, flow } from 'mobx';
import moment from 'moment';

import { IDateFilter, IFilter, ILogFilters, ILogSelectedFilters, ILogSorting, IWasSelectedFilters, LOG_SORT_COLUMN, TLog } from '~/types/log';
import { defaultLogFilters, defaultLogSorting, defaultSelectedFilters, defaultWasSelected } from '~/constants/log';
import { defaultPagination, SORT_DIR } from '~/constants/main';
import client from '~/services/client';
import { IPagination } from '~/types/main';

export class LogStore {
  constructor() {
    makeObservable(this, {
      pagination: observable,
      sort: observable,
      dspId: observable,
      isEdit: observable,
      loading: observable,
      filtersData: observable,
      selectedFilters: observable,
      wasSelected: observable,
      filtersWasSelected: observable,
      setSorting: action,
      setDspId: action,
      fetchLog: action,
      setSelectedFilter: action,
      clearFilter: action,
    });
  }

  pagination: IPagination<TLog> = defaultPagination;
  sort: ILogSorting = defaultLogSorting;
  dspId = '0';
  isEdit = false;
  loading = false;
  filtersData: ILogFilters = defaultLogFilters;
  selectedFilters: ILogSelectedFilters = defaultSelectedFilters;
  wasSelected: IWasSelectedFilters = defaultWasSelected;
  filtersWasSelected = false;

  setSorting = flow(function* (this: LogStore, field: LOG_SORT_COLUMN, direction: SORT_DIR | undefined, clear: boolean) {
    if (clear) {
      this.sort = defaultLogSorting;
    }
    this.sort[field] = direction;
    this.pagination.current_page = 0;
    this.fetchLog();
  }).bind(this);

  setDspId = (id: string) => {
    this.dspId = id;
  };

  fetchLog = flow(function* (this: LogStore, page: number = this.pagination.current_page + 1) {
    if (!this.dspId) {
      return;
    }
    try {
      if (typeof page !== 'number') page = this.pagination.current_page + 1;

      this.pagination.loading = true;
      this.pagination.refreshing = true;
      const data = yield client.get(`/v1/event_logs/dsp/${this.dspId}`, {
        params: {
          page,
          filter: this.prepareFilters(),
          sort: this.prepareSorting(),
        },
      });
      this.pagination = {
        ...data.pagination,
        loading: false,
        refreshing: false,
        data: data.data,
      };
      this.filtersData = data.tabs;
    } catch (error: any) {
      console.log(error);
      this.pagination.loading = false;
      this.pagination.refreshing = false;
    }
  });

  prepareFilters() {
    const filters: any = {};
    const newWasSelected = this.wasSelected;
    for (const [key, value] of Object.entries(this.selectedFilters)) {
      if (key === LOG_SORT_COLUMN.DATE) {
        if ((value as IDateFilter).from_date === null || (value as IDateFilter).to_date === null) {
          continue;
        }
        filters.created_at_start = moment((value as IDateFilter).from_date, 'D/M/Y').format('Y-M-D') + ' 00:00:00';
        filters.created_at_end = moment((value as IDateFilter).to_date, 'D/M/Y').format('Y-M-D') + ' 23:59:59';
        continue;
      }
      if ((value as IFilter[]).length <= 0) {
        newWasSelected[key as LOG_SORT_COLUMN] = false;
        continue;
      }
      this.filtersWasSelected = true;
      filters[key as LOG_SORT_COLUMN] = (value as IFilter[]).map((item) => item.value);
      newWasSelected[key as LOG_SORT_COLUMN] = true;
    }
    this.wasSelected = newWasSelected;
    return filters;
  }

  prepareSorting() {
    const sort: any = {};
    for (const [key, value] of Object.entries(this.sort)) {
      if (!value) {
        continue;
      }
      sort[key] = value;
    }
    return sort;
  }

  setSelectedFilter = (field: LOG_SORT_COLUMN, value: any) => {
    if (field === LOG_SORT_COLUMN.DATE) {
      this.selectedFilters[field].from_date = value.from_date;
      this.selectedFilters[field].to_date = value.to_date;
    } else {
      const exists = this.selectedFilters[field].findIndex((filter) => filter.value === value) !== -1;
      if (exists) {
        this.selectedFilters[field] = this.selectedFilters[field].filter((filter) => filter.value !== value);
      } else {
        const newFilter = this.filtersData[field].find((filter) => filter.value === value);
        if (newFilter) {
          this.selectedFilters[field].push(newFilter);
        }
      }
    }
    this.pagination.current_page = 0;
  };

  clearFilter = flow(function* (this: LogStore, field: LOG_SORT_COLUMN) {
    if (field === LOG_SORT_COLUMN.DATE) {
      this.selectedFilters[field] = defaultSelectedFilters[LOG_SORT_COLUMN.DATE];
    } else {
      this.selectedFilters[field] = [] as any[];
    }
    this.pagination.current_page = 0;
    yield this.fetchLog();
  }).bind(this);
}

const logStore = new LogStore();
export default logStore;
