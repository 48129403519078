import React, { ReactNode } from 'react';
import PopupArrowBg from 'taker-web-ui/dist/assets/images/arrow-bg.png';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

type MapPopupProps = {
  children?: ReactNode;
  className?: string;
  opened: boolean;
  onToggleFullOpen?: (value: boolean) => void;
};

type State = {
  fullOpened: boolean;
};

class MapPopup extends React.Component<MapPopupProps, State> {
  state = {
    fullOpened: false,
  };

  get isFullOpened() {
    return this.state.fullOpened;
  }

  toggleFullOpen = () => {
    this.setState({ fullOpened: !this.state.fullOpened }, () => {
      if (this.props.onToggleFullOpen) this.props.onToggleFullOpen(this.state.fullOpened);
    });
  };

  render() {
    const { opened, className = '' } = this.props;
    const { fullOpened } = this.state;

    return (
      <div className={`map-popup_wrapper`}>
        <div className={`map-popup ${className} ${opened ? 'show' : ''} ${fullOpened ? 'full' : ''}`}>
          <div className={'arrow-container'} style={{ backgroundImage: `url(${PopupArrowBg})` }}>
            <Icon className={'arrow'} name={ICONS.ArrowTop} size={24} onPress={this.toggleFullOpen} />
          </div>
          <div className={'content'}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default MapPopup;
