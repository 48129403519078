import React, { Component } from 'react';
import { localesStore } from '~/mobx';
import { copyToBuffer } from '~/utils/helpers';
import './style.scss';

export enum TOOLTIP_TRIGGERS {
  HOVER = 'hover',
  CLICK = 'click',
  NONE = 'none',
}

export enum TOOLTIP_TYPE {
  COPY = 'copy',
  INFO = 'info',
}

export enum TOOLTIP_POSITION {
  ABSOLUTE = 'absolute',
  FIXED = 'fixed',
  PORTAL = 'portal',
}

export enum TOOLTIP_DURATION {
  SMALL = 2000,
  MEDIUM = 3000,
  LARGE = 5000,
}

interface IProps {
  copyData?: string | number;
  message?: string;
  duration?: number;
  wrapperClassName?: string;
  messageClassName?: string;
  tooltipClassName?: string;
  type?: 'copy' | 'info';
  hover?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

interface IState {
  open: boolean;
  duration: number;
}

export default class CustomTooltip extends Component<IProps, IState> {
  timerId: ReturnType<typeof setTimeout> | undefined = undefined;
  wrapperRef = React.createRef<HTMLDivElement>();

  state = {
    open: false,
    duration: this.props.duration || 2000,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleWindowClick);

    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  handleWindowClick = (e: any) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(e?.target)) {
      this.handleClose();
    }
  };

  handleOpen = () => {
    if (this.props.hover) return;

    if (this.props.copyData && this.props.type === 'copy') {
      copyToBuffer(this.props.copyData);
    }

    const onOpen = this.props.onOpen;

    this.setState(
      ({ open }) => ({ open: !open }),
      () => {
        if (onOpen) onOpen();

        document.addEventListener('click', this.handleWindowClick);
        this.hideTooltip();
      },
    );
  };

  hideTooltip = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => this.handleClose(), this.state.duration);
  };

  handleClose = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    const onClose = this.props.onClose;

    this.setState({ open: false }, () => {
      if (onClose) onClose();

      document.removeEventListener('click', this.handleWindowClick);
    });
  };

  render() {
    return (
      <div
        ref={this.wrapperRef}
        className={`tooltip__wrapper ${this.props.type === 'copy' ? ' ml-4' : ''} ${this.props.wrapperClassName || ''}`}
        onClick={this.handleOpen}>
        {this.props.children}
        {this.state.open ? (
          <div
            // eslint-disable-next-line max-len
            className={`tooltip ${this.props.hover ? 'tooltip_none' : ''} ${this.props.type === 'copy' ? 'tooltip-copied' : 'tooltip-information'} ${
              this.props.tooltipClassName || ''
            }`}>
            <p className={`tooltip__message ${this.props.messageClassName || ''}`}>{localesStore.t(this.props.message)}</p>
          </div>
        ) : null}
      </div>
    );
  }
}
