import React, { Component } from 'react';

type Props = {
  className?: string;
  contentClassName?: string;
  contentPX?: string;
  contentPY?: string;
  children?: React.ReactNode;
};

export default class ModalContent extends Component<Props, {}> {
  state = {};

  render() {
    const { className = '', contentClassName = '', contentPX = 'px-8', contentPY = 'py-8' } = this.props;

    return (
      <div className={`bg-white modal-main d-flex flex-column flex-1 hide-scrollbar ${className}`}>
        <div className={`bg-white modal-main-content d-flex flex-column flex-1 ${contentPX} ${contentPY} ${contentClassName}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
