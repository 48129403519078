import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { deliveryZonesStore } from '~/mobx';
import { ICONS } from '~/constants/icons';
import { FORM_MODE } from '~/constants/main';
import { IUnavailableZone } from '~/types/deliveryManagment';
import theme, { getDurations } from '~/utils/styles';
import { ColorBox } from '~/view/components/Controls/ColorBox/ColorBox';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

interface IProps {
  id: string;
  zone: IUnavailableZone;
  index: number;
  overlay?: boolean;
  className?: string;
}

export const DeliveryZoneComponent = observer(({ zone, index, overlay, ...props }: IProps) => {
  const selectZone = useCallback(() => {
    deliveryZonesStore.selectZone(zone);
  }, [zone]);

  const editZone = useCallback(() => deliveryZonesStore.selectDraft(zone, FORM_MODE.EDIT), [zone]);

  const getClassName = useCallback(() => {
    let className = 'delivery-zone-item';

    if (zone.id === deliveryZonesStore.zone?.id) className += ' active';
    if (props.className) className += ` ${props.className}`;
    return className;
  }, [overlay, zone.id, props.className]);

  return (
    <div
      id={`zone_${zone.id}`}
      style={{ animationDelay: `${getDurations({ max: 6, index, diff: 75 })}ms` }}
      onClick={selectZone}
      className={getClassName()}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center flex-1">
          <span className="ml-2 delivery-zone-item__name">
            <p className="text-size-20 family-bold text-elipsis">{zone.name}</p>
          </span>
        </div>
        <span className="delivery-zone-item__color ml-4">
          <ColorBox color={deliveryZonesStore.getColor(zone)} />
        </span>
        <span className="ml-4" onClick={editZone}>
          <Icon name={ICONS.Pencil} color={theme.colors.mainblue} />
        </span>
      </div>
    </div>
  );
});
