import 'allsettled-polyfill';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import React from 'react';
import * as Sentry from '@sentry/react';
import 'taker-web-ui/dist/styles.scss';
import 'taker-web-ui/dist/assets/fonts/Icons/icons.css';
import { configurePersistable } from 'mobx-persist-store';
import { configure } from 'mobx';
import App from './App';
import reportWebVitals from './reportWebVitals';

configure({
  enforceActions: 'observed',
});

configurePersistable(
  {
    // storage: window.localStorage,
    // expireIn: 86400000,
    // removeOnExpiration: true,
    // stringify: false,
    debugMode: process.env.NODE_ENV === 'development',
  },
  // { delay: 200, fireImmediately: false }
);

declare global {
  interface Window {
    IS_MOBILE: boolean | undefined;
    [key: string]: any;
  }
}
if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  });
}

// const container = document.getElementById('root');
// const root = createRoot(container!);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
