import * as React from 'react';
import './style.scss';

export interface IInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string | boolean;
  message?: string;
  editable?: boolean;
  small?: boolean;
  wrapperClassName?: string;
  containerClass?: string;
  labelLeft?: string;
  labelRight?: string;
  labelClassName?: string;
}

export default class Input extends React.Component<IInputProps> {
  static defaultProps = {
    editable: true,
    wrapperClassName: '',
    small: false,
  };

  onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!this.props.onClick || this.props.disabled) {
      return;
    }
    this.props.onClick(event);
  };

  public render() {
    const {
      className = '',
      wrapperClassName = '',
      error,
      // onClick,
      children,
      editable,
      onChange,
      labelLeft,
      labelRight,
      labelClassName = '',
      containerClass = '',
      small,
      ...props
    } = this.props;

    return (
      <div className={`input-container ${containerClass}`}>
        <div
          onClick={this.onClick}
          className={`${small ? 'input-wrapper-small' : 'input-wrapper'} 
          d-flex 
          ${wrapperClassName} 
          ${error ? 'input-error' : ''} `}>
          {labelLeft && <span className={`side-label color-black flex-4 ${labelClassName}`}>{labelLeft}</span>}
          <input
            {...props}
            onChange={onChange}
            className={`input flex-2 flex-grow-1 ${labelLeft || labelRight ? 'input-with-label' : ''} ${className}`}
          />
          {!editable ? <div className="input-noteditable"></div> : ''}
          {children}
          {labelRight && <span className={`side-label color-black flex-2 ${labelClassName}`}>{labelLeft}</span>}
        </div>
        {typeof error === 'string' ? <div className={'error-text'}>{error}</div> : null}
      </div>
    );
  }
}
