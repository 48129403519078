import { TRequestsCountersAllResponse, TTrackingRequest } from '~/constants/tracking';

export type TTopicKeys = 'REQUESTS_COUNT' | 'REQUESTS_CREATE' | 'REQUESTS_UPDATE' | 'REQUESTS_DELETE';

export type TTopicValues = 'requests_count' | 'requests_create' | 'requests_update' | 'requests_delete';

type TTopics = {
  [key in TTopicKeys]: TTopicValues;
};

export const TOPICS: TTopics = {
  REQUESTS_COUNT: 'requests_count',
  REQUESTS_CREATE: 'requests_create',
  REQUESTS_UPDATE: 'requests_update',
  REQUESTS_DELETE: 'requests_delete',
};

export type TTopicRequestUpdateEvent = {
  data: TTrackingRequest;
};

export type TTopicRequestCreateEvent = {
  data: TTrackingRequest;
};

export type TTopicRequestDeleteEvent = {
  data: TTrackingRequest;
};

export type TTopicRequestCountEvent = {
  data: TRequestsCountersAllResponse;
};
