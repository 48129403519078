import { observer } from 'mobx-react';
import { Component } from 'react';
import { messagesStore } from '~/mobx';
import { IMessageModal } from '~/mobx/messagesStore/messagesStore';
import MessagesPopup from '~/view/components/MessagesPopup/MessagesPopup';

class ModalMessages extends Component {
  renderMessage = (message: IMessageModal) => {
    return <MessagesPopup message={message} />;
  };

  render() {
    return <div>{messagesStore.messages.map(this.renderMessage)}</div>;
  }
}

export default observer(ModalMessages);
