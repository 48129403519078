import { Component } from 'react';
import { debounce } from '~/utils/perfomance';

interface Props {
  min?: number;
  max?: number;
  children?: React.ReactNode;
}

export default class ResponsiveContent extends Component<Props, {}> {
  state = {
    currentWidth: window.innerWidth,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.onResize);
  };

  handleResize = () => {
    this.setState({ currentWidth: window.innerWidth });
  };

  onResize = debounce(this.handleResize, 100);

  render() {
    if (this.props.min && this.state.currentWidth < this.props.min) return null;
    if (this.props.max && this.state.currentWidth >= this.props.max) return null;
    // if (this.props.children) this.props.children;
    return this.props.children || null;
  }
}
