import { observer } from 'mobx-react';
import React from 'react';
import MapNotification from './components/MapNotification/MapNotification';
import { trackingStore } from '~/mobx';
import './style.scss';

class MapNotificationContainer extends React.Component<any, any> {
  render() {
    return (
      <div className={'map-notifications_container'}>
        {trackingStore.notifications.map((notification) => (
          <MapNotification
            id={notification.id}
            type={notification.type}
            content={notification.content}
            onRequestClose={notification.onRequestClose}
            key={notification.id}
          />
        ))}
      </div>
    );
  }
}

export default observer(MapNotificationContainer);
