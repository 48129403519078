import React, { ReactNode, useState } from 'react';
import Popover from 'react-popover-lite';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

type Props = {
  size?: number;
  color?: string;
  iconName: IconsId;
  onPress?: () => void;
  content: ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const PopoverButton = ({ size = 20, iconName, color, onPress, content }: Props) => {
  const [popoverOpened, setPopoverOpened] = useState(false);

  const handleClick = () => {
    setPopoverOpened(true);

    if (onPress) onPress();
  };

  return (
    <div className={'popover-button'}>
      {/* @ts-ignore */}
      <Popover position={'top'} isOpen={popoverOpened} content={content} zIndex={99999} onClickOutside={() => setPopoverOpened(false)}>
        <Icon name={iconName} size={size} color={color} className={'cursor-pointer'} onPress={handleClick} />
      </Popover>
    </div>
  );
};

export default PopoverButton;
