import { AxiosResponse } from 'axios';
import client from '~/services/client';

/**
 * Download file from API
 *
 * @param {string} params - GET query for request.
 * @param {string} filename - File name for downloading.
 * @param {string} filetype - File type for Blob.
 */
const downloadFile = async (url: string, params: any) => {
  const response: AxiosResponse = await client.get(url, {
    params,
    responseType: 'blob',
    timeout: 5 * 60 * 1000, // For long requests
  });

  const filename = response.headers['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '').trim();
  const type = response.headers['content-type'];

  const blob = new Blob([response.data], { type });
  const link = document.createElement('a');
  const objectURL = window.URL.createObjectURL(blob);
  link.href = objectURL;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(objectURL);
};

export default downloadFile;
