import React, { PureComponent } from 'react';
import DeliveryRequestsTableHeaderItem from './DeliveryRequestsTableHeaderItem';
import { localesStore } from '~/mobx';
import { userStore } from '~/mobx';
import { REQ_SORT_COL } from '~/types/requests';

export class DeliveryRequestsTableHeader extends PureComponent {
  render() {
    return (
      <thead className="mb-4">
        <tr>
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('time_column_header')}
            columnName={REQ_SORT_COL.DATE}
            withFilter
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('status_column_header')}
            columnName={REQ_SORT_COL.STATUS}
            withFilter
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('order_column_header')}
            columnName={REQ_SORT_COL.REFERENCE}
            withFilter
            truncateItemName
            preventLoadOnShow
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('client_column_header')}
            columnName={REQ_SORT_COL.ORGANIZATION}
            withFilter={!userStore.user.organization_ids?.length}
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('branch_column_header')}
            columnName={REQ_SORT_COL.BRANCH}
            withFilter
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('request_column_header')}
            columnName={REQ_SORT_COL.REQUEST}
            withFilter
            truncateItemName
            preventLoadOnShow
          />
          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('dsp_column_header')}
            columnName={REQ_SORT_COL.DSP}
            withFilter
          />

          <DeliveryRequestsTableHeaderItem
            className="d-flex align-items-center"
            itemName={localesStore.t('city_column_header')}
            columnName={REQ_SORT_COL.CITY}
            withFilter
          />
        </tr>
      </thead>
    );
  }
}
