import React, { ReactNode } from 'react';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';

export enum MapNotificationTypes {
  none,
  warning,
}

export type TMapNotificationProps = {
  id: string;
  content?: ReactNode;
  type?: MapNotificationTypes;
  onRequestClose?: (id: string) => void;
};

const MapNotification = ({ id, content, type = MapNotificationTypes.none, onRequestClose }: TMapNotificationProps) => {
  const handleRequestClose = (notificationId: string) => {
    if (onRequestClose) {
      onRequestClose(notificationId);
    }
  };

  const renderIcon = (notificationType: MapNotificationTypes) => {
    switch (notificationType) {
      case MapNotificationTypes.warning:
        return <Icon name={ICONS.Warning} size={20} color={'red'} />;
      default:
        return null;
    }
  };

  return (
    <div className={'map__notification'}>
      {type !== MapNotificationTypes.none ? <div className={'icon'}>{renderIcon(type)}</div> : null}
      <div className={'content'}>{content}</div>
      <Icon name={ICONS.Close} color={'gray'} size={10} onPress={() => handleRequestClose(id)} />
    </div>
  );
};

export default MapNotification;
