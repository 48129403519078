import './style.scss';

import moment from 'moment';
import { Component } from 'react';
import { TLog } from '~/types/log';

interface IItem {
  text?: string | number;
  className: string;
}

interface IProps {
  log?: TLog;
}

const Item = (item: IItem) => {
  return (
    <li className={`d-flex flex-row align-items-center ${item.className}`}>
      <p dir={'ltr'} className="family-medium text-size-16 color-dark">
        {item.text}
      </p>
    </li>
  );
};

export default class LogListItem extends Component<IProps, {}> {
  render() {
    if (!this.props.log) return null;
    return (
      <ul className="promocode-item d-flex flex-row full-width py-4">
        <Item text={moment(this.props.log.created_at).format('D/M/Y, h:mm:ss A')} className="pr-4 w-19" />
        <Item text={this.props.log.author} className="pr-4 w-20" />
        <Item text={this.props.log.object} className="pr-4 w-15" />
        <Item text={this.props.log.event_txt} className="pr-4 w-17" />
        <Item text={this.props.log.old_value_txt} className="pr-4 w-17" />
        <Item text={this.props.log.new_value_txt} className="pr-4 w-17" />
      </ul>
    );
  }
}
