import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ICONS } from '~/constants/icons';
import { USER_ROLES } from '~/constants/user';
import { localesStore, modalStore, userStore } from '~/mobx';
import dnfStatisticsStore from '~/mobx/driverNotFoundStore/DnfStatisticsStore';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

class MapDnfStatisticsControl extends React.Component {
  ndfStatisticsPopup = () => {
    dnfStatisticsStore.getStatistics();
    modalStore.openDnfModal({});
  };

  toggleStatisticsMap = () => {
    dnfStatisticsStore.statisticMapIsVisible = !dnfStatisticsStore.statisticMapIsVisible;

    if (dnfStatisticsStore.statisticMapIsVisible) {
      dnfStatisticsStore.getStatistics();
    }
  };

  render() {
    if (!userStore.hasRole([USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.TG_OPERATOR])) return null;

    // 'map-horizontal-control show-hint active'
    return (
      <div className={'map-horizontal-controls-wrapper'}>
        <div className={'map-horizontal-control disabled hide'}>
          <Icon name={ICONS.Curbside} />
        </div>
        <div className={'map-horizontal-sepearator hide'}></div>
        <div
          className={classNames('map-horizontal-control', { active: dnfStatisticsStore.statisticMapIsVisible })}
          onClick={this.toggleStatisticsMap}>
          <Icon name={ICONS.DeliveryDeclined} style={{ color: dnfStatisticsStore.statisticMapIsVisible ? '#FFF' : '#8496AF' }} />
        </div>
        <div className={'map-horizontal-sepearator'}></div>
        <div className={'map-horizontal-control'} onClick={this.ndfStatisticsPopup}>
          <Icon name={ICONS.Information} />
        </div>
        <div className={'dnf-hint'}>{localesStore.t('driver_not_found')}</div>
      </div>
    );
  }
}

export default observer(MapDnfStatisticsControl);
