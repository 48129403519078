import { DSP_GROUPS } from '~/constants/dsp';
import { TRACKING_GROUPS } from '~/constants/tracking';

const BASE = ``;

export const SCREENS = {
  ROOT: () => `/${BASE}`,
  LOGIN: () => `/${BASE}login`,
  DASHBOARD: () => `/${BASE}dashboard`,
  DSP_MANAGEMENT: (type: string = DSP_GROUPS.ALL, id: string | undefined = '1') => `/${BASE}dsp_management/${type}/${id}?`,
  DELIVERY_TRACKING: (group: string = TRACKING_GROUPS.ALL, id: string | undefined = '') => `/${BASE}tracking/${group}/${id}?`,
  DELIVERY_MANAGMENT: () => `/${BASE}settings/unavailable-zones`,
  DELIVERY_REQUESTS: () => `/${BASE}requests`,
  DSP: (id: string | number) => `/${BASE}dsp_management/dsp/${id}`,
  GUIDELINE: () => `/${BASE}guideline`,
  ERROR: () => `/${BASE}error`,
  DEVELOP: () => `/${BASE}develop`,
  NOT_FOUND: () => `/${BASE}oops`,
  REST: () => `*`,
  LOG: (id: string | number) => `/${BASE}${id}/log`,
};
