import * as colors from 'taker-web-ui/dist/assets/styles/custom/variables.module.scss';
// const breakpoints = {}

type TThemeColors = {
  // main colors
  mainblue: string;
  dark: string;
  gray: string;
  white: string;

  // secondary colors
  graysoft: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  red: string;
  green: string;
  orange: string;
  yellow: string;
  bluelight: string;
  greenlight: string;
  redlight: string;
  chart: string;
  subscribe: string;
  transparent: string;
};

export enum FONT_FAMILY {
  MEDIUM = 'medium',
  REGULAR = 'regular',
  SEMIBOLD = 'semibold',
  BOLD = 'bold',
}

export enum COLORS {
  MAINBLUE = 'mainblue',
  DARK = 'dark',
  GRAY = 'gray',
  WHITE = 'white',
  GRAYSOFT = 'graysoft',
  GRAY2 = 'gray2',
  GRAY3 = 'gray3',
  GRAY4 = 'gray4',
  GRAY5 = 'gray5',
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  BLUELIGHT = 'bluelight',
  GREENLIGHT = 'greenlight',
  REDLIGHT = 'redlight',
  CHART = 'chart',
  SUBSCRIBE = 'subscribe',
}

export const PALETTE_COLORS: string[] = [
  '#00A1D8',
  '#0061FD',
  '#4D22B2',
  '#982ABC',
  '#B92D5D',
  '#FF4015',
  '#FF6A00',
  '#FFAB01',
  '#FCC700',
  '#FEFB41',
  '#D9EC37',
  '#76BB40',
];

export const PALETTE_COLOR_GREY = '#8496AF';

type TTheme = {
  colors: TThemeColors;
};
const theme: TTheme = { colors: colors.default as TThemeColors };

export default theme;
