import { observer } from 'mobx-react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { appStore } from '~/mobx';
// import Skeleton from 'react-loading-skeleton';
import { userStore } from '~/mobx';
import './style.scss';

class UserInfo extends React.Component {
  render() {
    if (!userStore.user || !appStore.sidebar) return null;
    return (
      <div className={`user-info bg-white mb-4 animate__faster animate__animated ${appStore.sidebar ? 'animate__fadeInLeft' : ''}`}>
        <img className="user-info__avatar mr-3" src={userStore.user?.image} alt="avatar"></img>
        <div className="user-info__text-container">
          <p className="family-semibold color-dark text-size-20">{userStore.user?.name || <Skeleton width={100} />}</p>
          <p className="family-semibold color-gray2 text-size-14 mt-2">{userStore.user?.role_txt || <Skeleton width={40} />}</p>
        </div>
      </div>
    );
  }
}

export default observer(UserInfo);
