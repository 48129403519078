import React from 'react';
import { Button } from 'taker-web-ui';
import Icon from '~/view/components/Controls/Icon/Icon';
import { ICONS } from '~/constants/icons';
import './style.scss';

interface IOption {
  text: string;
  className?: string;
  textClassName?: string;
  appearance?: string;
  onPress?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

interface Props {
  title?: string;
  message?: string;
  closeModal?: () => void;
  options?: IOption[];
  minWidth?: number;
  maxWidth?: number;
  wrapperClassName?: string;
  titleClassName?: string;
  iconClassName?: string;
}

export default class ModalMessage extends React.PureComponent<Props, {}> {
  onButtonPress = (cb: undefined | (() => void)) => () => {
    if (cb) cb();
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };
  render() {
    console.log(this.props.options);
    return (
      <div className="modal-message">
        {this.props.title ? <p className="color-dark text-size-20 family-semibold mb-3 text-center">{this.props.title}</p> : null}
        {this.props.message ? <p className="color-gray4 text-size-16 text-center">{this.props.message}</p> : null}
        <div className="d-flex flex-1 mt-4">
          {this.props.options?.map((option, index) => (
            <Button
              stretched
              onClick={this.onButtonPress(option.onPress)}
              // @ts-ignore TOFIX
              appearance={option.appearance}
              className={`py-3 ${index ? 'ml-2' : ''}`}
              title={option.text}
            />
          ))}
        </div>
        <Icon onPress={this.onButtonPress(undefined)} className="modal-message-close color-gray2 text-pointer" name={ICONS.Close} size={14} />
      </div>
    );
  }
}
