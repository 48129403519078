interface IOptions {
  path?: string;
  expires?: Date | string;
  [option: string]: any;
}

class Cookies {
  getCookie(name: string) {
    const matches = document.cookie.match(
      // eslint-disable-next-line
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(name: string, value: string, options: IOptions = {}) {
    options = {
      path: '/',
      ...options,
    };
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    for (const optionKey of Object.keys(options)) {
      if (options) updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + String(optionValue);
      }
    }
    document.cookie = updatedCookie;
  }

  deleteCookie(name: string) {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }

  deleteAllCookies() {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
}

const cookies = new Cookies();

export default cookies;
