import './style.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';
import { FONT_FAMILY } from '~/constants/styles';
import { dspStore } from '~/mobx';
import { IOrg } from '~/types/dsp';
import Switcher from '~/view/components/Controls/Switcher/Switcher';
import NoResults from '~/view/components/NoResults/NoResults';
import Text from '~/view/components/Typo/Text/Text';

interface IProps {
  organizations: IOrg[];
  dspId?: string;
}

class OrganizationToggles extends Component<IProps> {
  changeStatus = (org: IOrg, status: boolean) => {
    if (this.props.dspId) {
      dspStore.updateOrganizationAvailability(this.props.dspId, [org.id], status, false);
    }
  };

  switcher = (org: IOrg) => {
    return (
      <div className="organization-toggle" key={`organization-${org.id}`}>
        <Text size={16} className="mr-3" family={FONT_FAMILY.SEMIBOLD}>
          {org.name}
        </Text>
        <Switcher status={!!org.available} onChange={(status) => this.changeStatus(org, status)} />
      </div>
    );
  };

  render() {
    const organizations = this.props.organizations;

    if (!organizations.length) {
      return <NoResults />;
    }

    return (
      <ScrollableBlock className="max-height color-gray5">
        <div className="organization-toggles-wrapper hide-scrollbar">{this.props.organizations.map(this.switcher)}</div>
      </ScrollableBlock>
    );
  }
}

export default observer(OrganizationToggles);
