import { isObject } from '~/utils/helpers';
import { CURRENCIES } from '~/constants/main';
import { localesStore } from '~/mobx';
import { TOrganization, TDsp } from '~/constants/tracking';
import { CoordsBounds, CoordsPaths, LatLng } from '~/types/main';

export const roundPrice = (price: number | string) => {
  if (typeof price === 'number' && !isNaN(price)) return Number(price.toFixed(2));

  if (typeof price === 'string') {
    const _price = Number(price);
    if (typeof _price === 'number' && !isNaN(_price)) return Number(_price.toFixed(2));
  }
  if (!price) return 0;
  return price;
};

export const boundsToPath = (bounds: CoordsBounds): CoordsPaths => bounds.map((bound): LatLng => ({ lat: bound[0], lng: bound[1] }));

export const formatPrice = (price: number, currency: string | undefined = CURRENCIES.SAR, position: 'l' | 'r' = 'l') => {
  if (localesStore.language === 'en') {
    if (position === 'l') {
      return `${currency} ${roundPrice(price)}`;
    }
    return `${roundPrice(price)} ${currency}`;
  } else {
    if (position === 'l') {
      return `${roundPrice(price)} ${currency}`;
    }
    return `${currency} ${roundPrice(price)}`;
  }
};

export const formatUnit = (unit_name: string, weight: number): string | null => {
  if (!unit_name) return null;
  return `${weight} ${unit_name}`;
};

export const hash = (json: string) => {
  let _hash = 0;
  for (let i = 0; i < json.length; i++) {
    const chr = json.charCodeAt(i);
    _hash = (_hash << 5) - _hash + chr;
    _hash |= 0; // Convert to 32bit integer
  }
  return `${_hash}`;
};

export const getMessageFromError = (error: any): string => {
  if (!error) return localesStore.t('error_try_again');
  if (error.isAxiosError && error.response?.data?.message) {
    return error.response.data.message;
  }
  return localesStore.t('request_error');
};

export const getHostName = (origin: string) => origin.split(/https:\/\/|http:\/\/|:|\./)[1];

export const objectMerge = <T>(main: any, data: any, allowedKeys: string[]): T => {
  if (!isObject(data)) return main;
  Object.keys(data).forEach((key) => {
    if (allowedKeys.length && !allowedKeys.includes(key)) return;
    main[key] = data[key];
  });
  return { ...main };
};

export const truncateLongString = (value: number | string, lengthLimit = 10) => {
  if (typeof value === 'undefined') return '';

  const str = String(value);
  return str.length > lengthLimit ? `${str.slice(0, 4)}...${str.slice(-4)}` : str;
};

export const formatImagePath = (name: string) => `${process.env.REACT_APP_CLOUD_FRONT || ''}/media/images/${name}`;

export const formatPhoneNumber = (phone: string) => {
  let _phone = String(phone);

  if (_phone.includes('+')) {
    _phone = _phone.replace('+', '');
  }

  if (localesStore.direction === 'rtl') {
    return _phone + '+';
  }

  return '+' + _phone;
};

export const toCamelcase = (str: string) =>
  str
    .split(' ')
    .map((v) => v[0].toUpperCase() + v.slice(1))
    .join(' ');

export const getOrderDspId = (dsp: TDsp) => {
  if (dsp.id === '1' && dsp.order_booking_id) {
    return dsp.order_booking_id;
  }

  return dsp.order_id;
};

export const getRequestId = (organization: TOrganization) => {
  if (Number(organization.id) > 1000000) {
    return String(organization.order_id);
  }

  return String(organization.reference);
};
