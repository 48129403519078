import React from 'react';
import PickerOption from './components/PickerOption/PickerOption';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import Icon from '~/view/components/Controls/Icon/Icon';
import { EmptyList } from '~/view/components/EmptyList/EmptyList';
import { ICONS } from '~/constants/icons';
import theme from '~/constants/styles';
import { localesStore } from '~/mobx';
import { IPickerOption } from '~/types/main';
// import ScrollableBlock from '~/view/containers/ScrollableBlock';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';
import './style.scss';

interface IProps<T> {
  id?: T;
  selectedOptions: IPickerOption[];
  options: IPickerOption[];
  onChange: (options: IPickerOption[], id?: T) => void;
  defaultPlaceholder: string;
  searchable?: boolean;
  isMulti?: boolean;
  withResetIcon: boolean;
  withoutSelectedIcons?: boolean;
  numberVisibleOptionsInDropdown: number;
  emptyListText?: string;
  viewedType: 'icon' | 'input';
  viewedSize: 'small' | 'normal';
  className?: string;
  wrapperClassName?: string;
  emptyListTextClassName?: string;
  loading?: boolean;
}

interface IState {
  options: IPickerOption[];
  searchValue: string;
  isOpen: boolean;
  isAll: boolean;
}

export default class CustomPicker<T> extends React.Component<IProps<T>, IState> {
  itemSize: number = this.props.viewedSize === 'small' ? 39 : 53;
  static defaultProps = {
    selectedOptions: [],
    options: [],
    defaultPlaceholder: localesStore.t('select_item'),
    withResetIcon: true,
    numberVisibleOptionsInDropdown: 7,
    viewedType: 'input',
    viewedSize: 'normal',
  };

  state: IState = {
    options: this.props.options,
    searchValue: '',
    isOpen: false,
    isAll: false,
  };

  componentDidUpdate = () => {
    if (!this.state.options.length && this.props.options.length && !this.state.searchValue) {
      this.setState({ options: this.props.options });
    }
  };

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearch = (searchValue: string) => {
    this.setState({
      searchValue,
      options: this.props.options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())),
    });
  };

  selectHandler = (options: IPickerOption[]) => {
    this.props.onChange(options, this.props.id);
  };

  onSelect = (option: IPickerOption) => {
    if (this.props.isMulti) {
      if (this.props.selectedOptions.some((item) => item.value === option.value)) {
        this.selectHandler(this.props.selectedOptions.filter((item) => item.value !== option.value));
      } else {
        this.selectHandler(this.props.selectedOptions.concat(option));
      }
    } else {
      if (this.props.selectedOptions.some((item) => item.value === option.value)) {
        this.selectHandler([]);
      } else {
        this.selectHandler([option]);
      }
      this.toggleOpen();
    }
  };

  clickReset = () => {
    this.selectHandler([]);
    if (this.state.isOpen && !this.props.isMulti) this.toggleOpen();
  };

  renderItem = (option: IPickerOption, i: number) => {
    if (!option) return null;
    return (
      <PickerOption
        key={option.value + String(i)}
        item={option}
        isSelected={this.props.selectedOptions.some((item) => item.value === option.value)}
        onClick={this.onSelect}
        withoutSelectedIcons={this.props.withoutSelectedIcons}
        viewedSize={this.props.viewedSize === 'small' ? 'small' : 'normal'}
      />
    );
  };

  get placeholder(): string {
    if (!this.props.selectedOptions.length) return this.props.defaultPlaceholder;
    if (this.props.selectedOptions.length === 1) return this.props.selectedOptions[0].label.toString();
    if (this.props.selectedOptions.length > 1) return `${this.props.selectedOptions.length} ${localesStore.t('selected')}`;
    return '';
  }

  get height(): number {
    return this.props.searchable ? (this.state.options.length + 1) * this.itemSize : this.state.options.length * this.itemSize;
  }
  get minHeight(): number {
    if (this.props.searchable) {
      return this.state.options.length ? this.itemSize * 2 : 100 + this.itemSize;
    } else {
      return this.state.options.length ? this.itemSize : 100;
    }
  }
  get maxHeight(): number {
    return this.props.searchable
      ? this.itemSize * this.props.numberVisibleOptionsInDropdown
      : this.itemSize * this.props.numberVisibleOptionsInDropdown - 1;
  }

  get className(): string {
    let className = 'custom-picker';
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.viewedSize === 'small') className += ' custom-picker_small';
    return className;
  }

  render() {
    return (
      <div className={this.className}>
        {this.props.viewedType === 'input' ? (
          <div className="custom-picker__open-input flex-1">
            <div
              className={`d-flex align-items-center full-height full-width pl-4 ${
                this.props.withResetIcon && this.props.selectedOptions.length ? 'custom-picker__placeholder-wrapper-with-reset' : ''
              }`}
              onClick={this.toggleOpen}>
              <p className="custom-picker__placeholder">{this.placeholder}</p>
            </div>
            <div className="d-flex flex-row full-height align-items-center">
              {this.props.selectedOptions.length && this.props.withResetIcon ? (
                <button
                  className={`custom-picker__icon  full-height p-1 ${this.state.isOpen ? 'custom-picker__icon-reset' : ''}`}
                  onClick={this.clickReset}>
                  <Icon name={ICONS.CloseSmall} size={16} color={theme.colors.gray2} />
                </button>
              ) : null}
              <div className="d-flex full-height align-items-center" onClick={this.toggleOpen}>
                <Icon name={ICONS.Chevron} size={20} color={theme.colors.gray2} className="custom-picker__icon" />
              </div>
            </div>
          </div>
        ) : (
          <div className="custom-picker__open-icon">
            <button className="custom-picker__icon-wrapper p-0 ml-3" onClick={this.toggleOpen}>
              <Icon name={ICONS.Chevron} size={18} color={theme.colors.dark} className="mx-auto" />
            </button>
            {this.props.selectedOptions.length && this.props.withResetIcon ? (
              <button className={`custom-picker__icon p-1 ml-3 ${this.state.isOpen ? 'custom-picker__icon-reset' : ''}`} onClick={this.clickReset}>
                <Icon name={ICONS.CloseSmall} size={14} color={theme.colors.dark} />
              </button>
            ) : null}
          </div>
        )}

        {this.state.isOpen ? (
          <>
            <ul
              className={`custom-picker__wrapper d-flex flex-column full-height ${this.props.wrapperClassName || ''}`}
              style={{ height: this.height, minHeight: this.minHeight, maxHeight: this.maxHeight }}>
              {this.props.searchable ? (
                <PickerOption
                  className={`picker-option__search ${this.props.viewedSize === 'small' ? 'picker-option__search_small' : ''}`}
                  viewedSize={this.props.viewedSize === 'small' ? 'small' : 'normal'}>
                  <SearchInput
                    loading={!!this.props.loading}
                    withCloseIcon
                    customPlaceholder
                    onChangeText={this.onSearch}
                    value={this.state.searchValue}
                    placeholder={localesStore.t('search_box')}
                    customPlaceholderIconSize={this.props.viewedSize === 'normal' ? undefined : 14}
                    inputClassName="custom-picker__search-input"
                    customPlaceholderTextClassName={`${this.props.viewedSize === 'normal' ? 'text-size-16' : 'text-size-12'}`}
                  />
                </PickerOption>
              ) : null}

              <ScrollableBlock showScrollbar scrollbarSize="small">
                {this.state.options.map((option, i) => this.renderItem(option, i))}
                {this.state.options.length ? null : (
                  <EmptyList text={this.props.emptyListText || 'options_not_found'} textClassName={`${this.props.emptyListTextClassName || ''}`} />
                )}
              </ScrollableBlock>
            </ul>
            <div className="custom-picker__overlay" onClick={this.toggleOpen} />
          </>
        ) : null}
      </div>
    );
  }
}
