import React, { Component } from 'react';
import './style.scss';

interface Option {
  label: string;
  value: any;
}
interface Props {
  value: Option['value'];
  className: string;
  tabs: string[] | number[];
  children?: React.ReactNode;
}

export default class TabList extends Component<Props, {}> {
  static defaultProps = {
    className: '',
  };
  render() {
    return (
      <div className={`tabs--list d-flex flex-1 ${this.props.className}`}>
        {React.Children.map(this.props.children, (child, index) =>
          index === this.props.value || this.props.tabs[index] === this.props.value ? child : null,
        )}
      </div>
    );
  }
}
