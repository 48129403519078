import { action, observable, makeObservable } from 'mobx';
import { ButtonAppearance } from 'taker-web-ui/dist/components/Button/Button';

interface IOption {
  text: string;
  className?: string;
  textClassName?: string;
  onPress?: () => void;
  appearance: ButtonAppearance;
}

export interface IMessageModalProps {
  type: 'modal' | 'snake' | 'error';
  title?: string;
  message?: string;
  options?: IOption[];
  onClose?: () => any;
  onRequestClose?: () => any;
  contentClassName?: string;
}

export interface IMessageModal {
  id: string;
  message: IMessageModalProps;
}

export class MessagesStore {
  constructor() {
    makeObservable(this, {
      messages: observable,
      addMessage: action,
      removeMessage: action,
    });
  }

  public messages: IMessageModal[] = [];

  public addMessage(message: IMessageModalProps) {
    this.messages.push({ id: `${Date.now()}`, message });
  }

  public removeMessage(id: string) {
    this.messages = this.messages.filter((message) => message.id !== id);
  }
}

const messagesStore = new MessagesStore();

export default messagesStore;
