import './style.scss';

import React, { Component } from 'react';

import DeliveryZonesMapContainer from './containers/DeliveryZonesMapContainer/DeliveryZonesMapContainer';
import { DeliveryZonesSidebar } from './containers/DeliveryZonesSidebar/DeliveryZonesSidebar';
import { deliveryZonesStore } from '~/mobx';

export default class DeliveryZonesScreen extends Component<{}, {}> {
  componentWillUnmount = () => {
    deliveryZonesStore.clear();
  };

  render() {
    return (
      <div className="d-flex flex-row flex-1">
        <DeliveryZonesMapContainer />
        <DeliveryZonesSidebar />
      </div>
    );
  }
}
