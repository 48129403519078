import { action, observable, makeObservable } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

export class AppStore {
  constructor() {
    makeObservable(this, {
      changeIsCapsOn: action,
      changeSidebarVisibility: action,
      isCapsOn: observable,
      lastUsage: observable,
      loading: observable,
      settings: observable,
      sidebar: observable,
    });

    makePersistable(this, {
      storage: window.localStorage,
      name: 'AppStore',
      properties: ['settings', 'sidebar', 'lastUsage'],
    })
      .then(() => {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
          if (e.key === 'CapsLock' || (typeof e.getModifierState === 'function' && e.getModifierState('CapsLock'))) {
            this.changeIsCapsOn(true);
          }
        });
        document.addEventListener('keyup', (e: KeyboardEvent) => {
          if (e.key === 'CapsLock') {
            this.changeIsCapsOn(false);
          }
        });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get isHydrated() {
    return isHydrated(this);
  }

  public settings: any | null = null;
  public sidebar = false;
  public loading = false;
  public isCapsOn = false;
  public lastUsage: number | null = Date.now();

  changeSidebarVisibility = (visible: boolean) => {
    this.sidebar = visible;
  };

  changeIsCapsOn = (isOn: boolean) => {
    this.isCapsOn = isOn;
  };
}

const appStore = new AppStore();

export default appStore;
