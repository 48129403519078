import React from 'react';
import { Navigate } from 'react-router-dom';
import { userStore } from '~/mobx';

interface Props {
  permissions?: string[];
  roles?: number[];
  children: JSX.Element;
  redirectPath?: string;
}

const ProtectedRoute = ({ permissions = [], roles = [], redirectPath = '/oops', children }: Props) => {
  if (!userStore.hasPermissions(permissions) || !userStore.hasRole(roles)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
