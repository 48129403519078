import Timer, { TIMER_TYPE } from './Timer';

type PropsPopupTimer = {
  type: TIMER_TYPE;
  startAt: string | null;
  endAt: string | null;
};

type PropsPopupTimers = {
  assignedAt: string | null;
  pickedUpAt: string | null;
  finalAt: string | null;
};

const PopupTimer = ({ type, startAt, endAt }: PropsPopupTimer) => {
  return Timer({ type, startAt, endAt, withNa: true, className: '' });
};

const PopupTimers = ({ assignedAt, pickedUpAt, finalAt }: PropsPopupTimers) => {
  return (
    <div className="popup-timers">
      <PopupTimer type={TIMER_TYPE.TO_STORE} startAt={assignedAt} endAt={pickedUpAt ?? finalAt} />
      <PopupTimer type={TIMER_TYPE.TO_CUSTOMER} startAt={pickedUpAt} endAt={finalAt} />
    </div>
  );
};

export default PopupTimers;
