import React from 'react';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import { ICONS } from '~/constants/icons';
import Icon from '~/view/components/Controls/Icon/Icon';
import { localesStore } from '~/mobx';
import './style.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  icon?: IconsId;
  iconSize?: number;
  iconClassName?: string;
  placeholder?: string;
  textClassName?: string;
}

const CustomPlaceholder = ({ icon = ICONS.Search, placeholder = '', iconSize, className = '', ...props }: Props) => {
  return (
    <div className={`custom-placeholder d-flex flex-row align-items-center px-3 ${className}`}>
      <Icon name={icon} size={iconSize} className={`link-icon color-gray2 text-size-16 p-0 mr-3 ${props.iconClassName || ''}`} />
      {placeholder ? <span className={`family-medium text-size-16 color-gray2 ${className}`}>{localesStore.t(placeholder)}</span> : null}
    </div>
  );
};

export default CustomPlaceholder;
