import * as React from 'react';
import classNames from 'classnames';
import './style.scss';

interface ISwitcherProps {
  status: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
}

interface ISwitcherState {
  status: boolean;
}

export default class Switcher extends React.Component<ISwitcherProps, ISwitcherState> {
  state: ISwitcherState = {
    status: this.props.status,
  };

  public set status(status: ISwitcherProps['status']) {
    this.setState({ status });
  }

  componentDidUpdate = (props: ISwitcherProps) => {
    if (this.props.status !== props.status) this.setState({ status: this.props.status });
  };

  toggle = () => {
    this.setState((state) => ({ status: !state.status }), this.handleChange);
  };

  handleChange = () => {
    this.props.onChange(this.state.status);
  };

  render() {
    const className = classNames('switcher-wrapper', {
      'bg-mainblue active': this.state.status,
      'bg-gray': !this.state.status,
      switcher_disabled: this.props.disabled,
    });

    return (
      <button className={className} onClick={this.toggle} disabled={this.props.disabled}>
        <div className="switcher-circle" />
      </button>
    );
  }
}
