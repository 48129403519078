import Timer, { TIMER_TYPE } from './Timer';

type PropsTrackingTimer = {
  assignedAt: string | null;
  pickedUpAt: string | null;
  finalAt: string | null;
};

const TrackingTimer = ({ assignedAt, pickedUpAt, finalAt }: PropsTrackingTimer) => {
  if (assignedAt !== null && pickedUpAt === null) {
    return Timer({ type: TIMER_TYPE.TO_STORE, startAt: assignedAt, endAt: pickedUpAt, withNa: false, className: 'm-0' });
  }

  if (pickedUpAt !== null && finalAt === null) {
    return Timer({ type: TIMER_TYPE.TO_CUSTOMER, startAt: pickedUpAt, endAt: finalAt, withNa: false, className: 'm-0' });
  }

  return null;
};

export default TrackingTimer;
