import { observer } from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import GoBackButton from '~/view/screens/LogScreen/components/GoBackButton/GoBackButton';
import { localesStore } from '~/mobx';
import './style.scss';

interface IProps {
  title: string;
  goBackButton?: boolean;
  backToDspId?: number;
  backToDspType?: string;
}

const HeaderTitle = observer((props: IProps) => {
  return (
    <div className={'d-flex align-items-center'}>
      {props.goBackButton ? <GoBackButton backToDspType={props.backToDspType} backToDspId={props.backToDspId} /> : null}
      <h1 className="header-title__title text-size-42 family-bold">
        {!localesStore.timestamp ? <Skeleton width={300} /> : localesStore.t(props.title)}
      </h1>
    </div>
  );
});

export default HeaderTitle;
