import React from 'react';
import { localesStore } from '~/mobx';
import './style.scss';

export const EmptyList: React.FC<{ text: string; textClassName?: string }> = ({ text = 'item_not_found', textClassName = '' }) => {
  return (
    <div className="empty-list d-flex flex-column flex-grow-1 align-items-center justify-content-center full-width py-5">
      <div className={`my-auto ${textClassName}`}>{localesStore.t(text)}</div>
    </div>
  );
};
