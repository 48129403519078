import { SORT_DIR } from '~/constants/main';
import { LoadingStatus } from '~/types/main';
import { TRACKING_GROUPS } from '~/constants/tracking';

export enum FILTER_COLUMNS {
  STATUS = 'status',
  REFERENCE = 'reference',
  ORGANIZATION = 'organization',
  BRANCH = 'branch',
  REQUEST = 'request',
  DSP = 'dsp',
  CITY = 'city',
}

export enum REQ_SORT_COL {
  TIME = 'time',
  DATE = 'created_at',
  STATUS = 'status',
  REFERENCE = 'reference',
  ORGANIZATION = 'organization',
  BRANCH = 'branch',
  REQUEST = 'request',
  DSP = 'dsp',
  CITY = 'city',
}

export interface IRequestsSorting {
  [REQ_SORT_COL.DATE]?: SORT_DIR;
  [REQ_SORT_COL.TIME]?: SORT_DIR;
  [REQ_SORT_COL.STATUS]?: SORT_DIR;
  [REQ_SORT_COL.REFERENCE]?: SORT_DIR;
  [REQ_SORT_COL.ORGANIZATION]?: SORT_DIR;
  [REQ_SORT_COL.BRANCH]?: SORT_DIR;
  [REQ_SORT_COL.REQUEST]?: SORT_DIR;
  [REQ_SORT_COL.DSP]?: SORT_DIR;
  [REQ_SORT_COL.CITY]?: SORT_DIR;
}

export interface IDateFilter {
  from_date: string | null;
  to_date: string | null;
}

export interface ITimeFilter {
  start: string | null;
  end: string | null;
}

export const filterOptionCast = ({ value, name, active }: { value: any; name: any; active: any }) => ({
  value: String(value),
  name: String(name),
  active: Boolean(active),
});

export type IFilter = ReturnType<typeof filterOptionCast>;

export type IRequestsFiltersOptions = {
  [FILTER_COLUMNS.STATUS]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.REFERENCE]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.ORGANIZATION]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.BRANCH]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.REQUEST]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.DSP]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [FILTER_COLUMNS.CITY]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };

  [REQ_SORT_COL.TIME]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
  [REQ_SORT_COL.DATE]: {
    options: IFilter[];
    lastUpdate: number;
    loadingStatus: LoadingStatus;
    needCache: boolean;
  };
};

export const defaultRequestsFiltersOptions: IRequestsFiltersOptions = {
  [REQ_SORT_COL.STATUS]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
  [REQ_SORT_COL.REFERENCE]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: false },
  [REQ_SORT_COL.ORGANIZATION]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
  [REQ_SORT_COL.BRANCH]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: false },
  [REQ_SORT_COL.REQUEST]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: false },
  [REQ_SORT_COL.DSP]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
  [REQ_SORT_COL.CITY]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
  [REQ_SORT_COL.DATE]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
  [REQ_SORT_COL.TIME]: { options: [], lastUpdate: 0, loadingStatus: LoadingStatus.Init, needCache: true },
};

export type IRequestsFilters = {
  [REQ_SORT_COL.TIME]: ITimeFilter;
  [REQ_SORT_COL.DATE]: IDateFilter;
  [REQ_SORT_COL.STATUS]: IFilter[];
  [REQ_SORT_COL.REFERENCE]: IFilter[];
  [REQ_SORT_COL.ORGANIZATION]: IFilter[];
  [REQ_SORT_COL.BRANCH]: IFilter[];
  [REQ_SORT_COL.REQUEST]: IFilter[];
  [REQ_SORT_COL.DSP]: IFilter[];
  [REQ_SORT_COL.CITY]: IFilter[];

  category?: TRACKING_GROUPS;
  search?: string;
};

export const defaultRequestsFilters: IRequestsFilters = {
  [REQ_SORT_COL.DATE]: {
    from_date: null,
    to_date: null,
  },
  [REQ_SORT_COL.TIME]: {
    start: null,
    end: null,
  },
  [REQ_SORT_COL.STATUS]: [],
  [REQ_SORT_COL.REFERENCE]: [],
  [REQ_SORT_COL.ORGANIZATION]: [],
  [REQ_SORT_COL.BRANCH]: [],
  [REQ_SORT_COL.REQUEST]: [],
  [REQ_SORT_COL.DSP]: [],
  [REQ_SORT_COL.CITY]: [],
};

export type IRequestsSelectedFilters = {
  [REQ_SORT_COL.TIME]: ITimeFilter;
  [REQ_SORT_COL.DATE]: IDateFilter;
  [REQ_SORT_COL.STATUS]: any[];
  [REQ_SORT_COL.REFERENCE]: any[];
  [REQ_SORT_COL.ORGANIZATION]: any[];
  [REQ_SORT_COL.BRANCH]: any[];
  [REQ_SORT_COL.REQUEST]: any[];
  [REQ_SORT_COL.DSP]: any[];
  [REQ_SORT_COL.CITY]: any[];
};

export type IWasRequestsSelectedFilters = {
  [REQ_SORT_COL.TIME]: boolean;
  [REQ_SORT_COL.DATE]: boolean;
  [REQ_SORT_COL.STATUS]: boolean;
  [REQ_SORT_COL.REFERENCE]: boolean;
  [REQ_SORT_COL.ORGANIZATION]: boolean;
  [REQ_SORT_COL.BRANCH]: boolean;
  [REQ_SORT_COL.REQUEST]: boolean;
  [REQ_SORT_COL.DSP]: boolean;
  [REQ_SORT_COL.CITY]: boolean;
};
