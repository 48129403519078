import './style.scss';

import React, { ReactElement } from 'react';
import { ButtonPeriod } from '../ButtonPeriod/ButtonPeriod';
import { TIME_PERIODS_LIST } from '~/constants/period';
import { localesStore } from '~/mobx';

const TimePeriods = (): ReactElement => {
  return (
    <div className="time-periods">
      <p className="time-periods__title">{localesStore.t('select_time_availability_zone')}</p>
      <div className="time-periods__list">
        {TIME_PERIODS_LIST.map((timePeriod) => (
          <ButtonPeriod timePeriod={timePeriod} />
        ))}
      </div>
    </div>
  );
};

export default TimePeriods;
