import React, { createContext, FC, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

export type GoogleMapsState = {
  isLoaded: boolean;
  loadError?: Error;
};

export const GoogleMapsContext = createContext<GoogleMapsState>({ isLoaded: false });

export const GoogleMapsProvider: FC<Parameters<typeof useJsApiLoader>[0] & { children: React.ReactNode }> = ({ children, ...loadScriptOptions }) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);

  // Cache values before passing to GoogleMapsContext.Provider
  const value = useMemo(() => ({ isLoaded, loadError }), [isLoaded, loadError]);

  // Do not cache values
  // const value = { isLoaded, loadError };

  return <GoogleMapsContext.Provider value={value}>{children}</GoogleMapsContext.Provider>;
};
