import './style.scss';

import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { deliveryZonesStore } from '~/mobx';
// import MapZoom from '~/view/components/Map2/MapZoom/MapZoom';
import { ICONS } from '~/constants/icons';
import theme from '~/constants/styles';
import { ColorBox } from '~/view/components/Controls/ColorBox/ColorBox';
import Icon from '~/view/components/Controls/Icon/Icon';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
// import MapZoom from '~/view/components/Map/MapZoom/MapZoom';

export const DeliveryZoneMapControls = observer(() => {
  const [loading] = useState(false);

  // const toggleMapLock = useCallback(() => {
  //   deliveryZonesStore.mapStore.setOption({ draggable: !deliveryZonesStore.mapStore.options?.draggable });
  // }, []);

  // const zoomIn = useCallback(() => {
  //   deliveryZonesStore.mapStore.setZoom((deliveryZonesStore.mapStore.zoom || 0) + 1);
  // }, []);

  // const zoomOut = useCallback(() => {
  //   deliveryZonesStore.mapStore.setZoom((deliveryZonesStore.mapStore.zoom || 0) - 1);
  // }, []);

  const onDelete = useCallback(() => {
    deliveryZonesStore.editDraftZone('bounds', []);
  }, []);

  return (
    <div>
      <div className="delivery-zone__map-tools ">
        {/* <span onClick={toggleMapLock} className={`delivery-zone__map-control  animate__animated animate__faster animate__fadeInRight mb-3`}>
          <ColorBox selected={Boolean(deliveryZonesStore.mapStore.options?.draggable)} size={48} color={theme.colors.white}>
            <Icon name={ICONS.Hand} />
          </ColorBox>
        </span> */}

        {deliveryZonesStore.draft?.bounds.length ? (
          <span onClick={onDelete} className="delivery-zone__map-control animate__animated animate__faster animate__fadeInRight">
            <ColorBox size={48} color={theme.colors.red}>
              {loading ? <Spinner size={18} /> : <Icon name={ICONS.Trash} color={theme.colors.white} />}
            </ColorBox>
          </span>
        ) : null}
      </div>
    </div>
  );
});
