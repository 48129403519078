import { useState } from 'react';
import { ICONS } from '~/constants/icons';
import { localesStore } from '~/mobx';
import { userStore } from '~/mobx';
import Icon from '~/view/components/Controls/Icon/Icon';
import ModalMessage from '~/view/components/ModalMessage/ModalMessage';
import PopupModal from '~/view/components/Popup/Popup';

interface ILogoutState {
  state: boolean;
}

export const LogoutButton = () => {
  const [logoutModal, setLogoutModal] = useState<ILogoutState>({ state: false });

  const closeLogoutModal = () => {
    setLogoutModal({ state: false });
  };

  const openLogoutModal = () => {
    setLogoutModal({ state: true });
  };

  return (
    <PopupModal
      justify={'end'}
      selfClose={false}
      backdrop
      onClose={closeLogoutModal}
      visible={logoutModal.state}
      content={({ closeModal }) => (
        <ModalMessage
          minWidth={300}
          title={localesStore.t('you_sure_confirmation')}
          message={localesStore.t('logout_confirmation')}
          closeModal={closeModal}
          iconClassName="color-gray2"
          options={[
            {
              text: localesStore.t('yes_button'),
              onPress: userStore.logout,
              className: 'p-0',
              appearance: 'primary',
              textClassName: 'family-semibold text-size-14 color-white',
            },
            {
              text: localesStore.t('no_button'),
              onPress: closeLogoutModal,
              className: 'bg-white button_old-outlined border-gray p-0',
              appearance: 'tertiary',
              textClassName: 'family-semibold text-size-14 color-dark',
            },
          ]}
        />
      )}>
      <div onClick={openLogoutModal}>
        <Icon name={ICONS.Logout} size={24} className="color-gray2" />
      </div>
    </PopupModal>
  );
};
