import './style.scss';

import React, { Component } from 'react';
import { dspStore } from '~/mobx';
import Label from '~/view/components/Label/Label';

export default class DspLabels extends Component<{}, {}> {
  render() {
    return (
      <span className="dsp-labels">
        <Label text={dspStore.approvedTxt(dspStore.dsp?.approved)} />
        <Label text={dspStore.workingTxt(dspStore.dsp?.working)} color={dspStore.workingColor(dspStore.dsp?.working)} />
      </span>
    );
  }
}
