import './style.scss';

import { observer } from 'mobx-react';
import { Component } from 'react';
import NavigationButton from './components/NavigationButton/NavigationButton';
import NavigationLink from './components/NavigationLink/NavigationLink';
import UserInfo from './components/UserInfo/UserInfo';
import { ICONS } from '~/constants/icons';
import { USER_ROLES } from '~/constants/user';
import { SCREENS } from '~/constants/screens';
import { appStore, userStore } from '~/mobx';
import { localesStore } from '~/mobx';

// import { sidebarStore } from '~/mobx';
import { toCamelcase } from '~/utils/formatter';
import socket from '~/services/socket';
import { withRouter } from '~/utils';
import { RouterComponentProps } from '~/types/main';

class Sidebar extends Component<RouterComponentProps<{}>> {
  componentDidMount() {
    socket.init();
    setTimeout(() => {
      userStore.showOneTimeNotifications();
    }, 3000);
  }

  componentWillUnmount() {
    socket.disconnect();
  }

  closeSidebar = (visible: boolean) => () => {
    appStore.changeSidebarVisibility(visible);
  };

  render() {
    return (
      <div className={`sidebar ${appStore.sidebar ? 'open' : 'close'}`}>
        <div className="d-flex flex-column full-height">
          {appStore.sidebar ? <button className={`sidebar_overlay`} onClick={this.closeSidebar(false)}></button> : null}
          <UserInfo />

          {!appStore.sidebar ? (
            <NavigationButton icon={ICONS.Menu_1} iconSize={17} onClick={this.closeSidebar(!appStore.sidebar)} name={''} permissions={[]} />
          ) : null}

          {process.env.NODE_ENV === 'development' ? (
            <NavigationLink
              icon={ICONS.Menu}
              iconSize={17}
              to={SCREENS.DASHBOARD()}
              path={SCREENS.DASHBOARD()}
              name={'dashboard'}
              permissions={[]}
              roles={[USER_ROLES.TG_OPERATION_MANAGER]}
              onClick={this.closeSidebar(false)}
            />
          ) : null}

          <NavigationLink
            icon={ICONS.IcDspManagement} // class="trans-180-min"
            to={SCREENS.DSP_MANAGEMENT()}
            path={SCREENS.DSP_MANAGEMENT()}
            name={localesStore.t('dsp_management')}
            permissions={[]}
            roles={[USER_ROLES.TG_OPERATION_MANAGER]}
            onClick={this.closeSidebar(false)}
            isActive={/^\/dsp_management/.test(this.props.router.location.pathname)}
          />

          <NavigationLink
            icon={ICONS.IcDeliveryRequest}
            to={SCREENS.DELIVERY_REQUESTS()}
            path={SCREENS.DELIVERY_REQUESTS()}
            name={toCamelcase(localesStore.t('delivery_requests_section'))}
            permissions={[]}
            roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.TG_OPERATOR]}
            onClick={this.closeSidebar(false)}
            isActive={/^\/requests/.test(this.props.router.location.pathname)}
          />

          <NavigationLink
            icon={ICONS.IcDeliveryTracking}
            to={SCREENS.DELIVERY_TRACKING()}
            path={SCREENS.DELIVERY_TRACKING()}
            name={localesStore.t('delivery_tracking_section')}
            permissions={[]}
            roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.TG_CLIENT, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_OPERATOR]}
            onClick={this.closeSidebar(false)}
            isActive={/^\/tracking/.test(this.props.router.location.pathname)}
          />
          <NavigationLink
            icon={ICONS.Settings}
            to={SCREENS.DELIVERY_MANAGMENT()}
            path={SCREENS.DELIVERY_MANAGMENT()}
            name={localesStore.t('settings')}
            permissions={[]}
            roles={[USER_ROLES.TG_OPERATION_MANAGER]}
            onClick={this.closeSidebar(false)}
            isActive={/^\/settings/.test(this.props.router.location.pathname)}
          />

          {/* Will be available just in development mode */}
          {process.env.NODE_ENV === 'development' ? (
            <NavigationLink
              icon={ICONS.Info}
              iconSize={22}
              to={SCREENS.GUIDELINE()}
              path={SCREENS.GUIDELINE()}
              name={'guideline'}
              permissions={[]}
              onClick={this.closeSidebar(false)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(observer(Sidebar));
