import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (WrappedComponent: React.ComponentClass<any>) => {
  const ComponentWithRouter = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <WrappedComponent {...props} router={{ location, navigate, params }} />;
  };
  return ComponentWithRouter;
};

export default withRouter;
