import React from 'react';
import PopoverButton from '~/view/components/PopoverButton/PopoverButton';
import { localesStore } from '~/mobx';

type Props = {
  data: string;
};

const CopyButton = ({ data }: Props) => (
  <PopoverButton
    content={localesStore.t('copied_to_clipboard')}
    iconName={'copy_new'}
    color={'#429eff'}
    size={20}
    onPress={() => navigator.clipboard.writeText(data)}
  />
);

export default CopyButton;
