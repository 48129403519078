import { io, Socket } from 'socket.io-client';
import { TOPICS, TTopicRequestCountEvent, TTopicRequestCreateEvent, TTopicRequestDeleteEvent, TTopicRequestUpdateEvent } from '~/constants/topics';
import { RequestStatus, trackingRequestCast, TRACKING_GROUPS } from '~/constants/tracking';
import { appStore } from '~/mobx';
import { localesStore } from '~/mobx';
import { archiveStore } from '~/mobx';
import { trackingStore } from '~/mobx';
import { userStore } from '~/mobx';
import { getHostName } from '~/utils/formatter';
import { isReqEqualBySearch } from '~/utils';
import { addTimeZoneToRequest } from '~/utils/handleTimeZone';

class SocketClient {
  client: null | Socket<any, any> = null;

  getSocketHost() {
    if (!appStore.settings?.socket_host) return '';
    let host = decodeURIComponent(appStore.settings.socket_host);
    if (!host.includes('https:')) host = `https://${host}`;
    return host;
  }

  get domain() {
    return process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SOCKET_CLIENT || getHostName(window.location.origin)
      : getHostName(window.location.origin);
  }

  private get _headers() {
    return {
      'Accept-Language': localesStore.language,
      Authorization: userStore.user.token || '',
      domain: this.domain,
    };
  }

  handleUpdate = ({ data }: TTopicRequestUpdateEvent) => {
    const updatedRequest = addTimeZoneToRequest(trackingRequestCast(data));
    const { request, organization } = updatedRequest;

    if (userStore.user.organization_ids?.length && !userStore.user.organization_ids.includes(organization.id)) {
      return;
    }

    if (!archiveStore.trackingRequests.data.find(({ request: { id } }) => id === request.id)) {
      archiveStore.addRequestBySocket(updatedRequest, false);
    } else {
      archiveStore.updateRequestById(updatedRequest);
    }

    if (
      // For ALL tabs
      request.status === RequestStatus.RETURNED ||
      request.status === RequestStatus.DELIVERED ||
      request.status === RequestStatus.CANCELED ||
      // For NEW tab only
      (trackingStore.requestsFilters.category === TRACKING_GROUPS.NEW && (request.assigned_at !== null || request.picked_up_at !== null))
    ) {
      return trackingStore.deleteRequest(request.id);
    } else if (
      (request.status === RequestStatus.ASSIGNED ||
        request.status === RequestStatus.WAY_TO_CUSTOMER ||
        request.status === RequestStatus.NEAR_CUSTOMER ||
        request.status === RequestStatus.NEAR_STORE ||
        request.status === RequestStatus.ARRIVED_AT_CUSTOMER ||
        request.status === RequestStatus.ARRIVED_AT_STORE ||
        request.status === RequestStatus.WAY_TO_STORE ||
        request.status === RequestStatus.NO_STATUS ||
        request.status === RequestStatus.PENDING ||
        request.status === RequestStatus.DSP_ISSUE ||
        request.status === RequestStatus.NOT_SENT ||
        request.status === RequestStatus.RETURNING) &&
      trackingStore.trackingRequests.data.find(({ request: { id } }) => id === request.id) === undefined
    ) {
      if (!isReqEqualBySearch(updatedRequest, trackingStore.requestsFilters.search || '')) return;
      if (![TRACKING_GROUPS.ALL, TRACKING_GROUPS.NEW].includes(trackingStore.requestsFilters.category as any)) return;
      if (
        trackingStore.requestsFilters.category === TRACKING_GROUPS.NEW &&
        ![RequestStatus.NO_STATUS, RequestStatus.PENDING, RequestStatus.DSP_ISSUE, RequestStatus.NOT_SENT].includes(updatedRequest.request.status)
      ) {
        return;
      }
      if (trackingStore.requestsFilters?.dsp?.length && !trackingStore.requestsFilters.dsp.find((value) => value === updatedRequest.dsp.id)) {
        return;
      }

      return trackingStore.addRequest(updatedRequest, false);
    }

    trackingStore.updateRequestById(updatedRequest);
  };

  handleCount = (event: TTopicRequestCountEvent) => {
    trackingStore.updateAllCounters(event.data);

    if (
      trackingStore.requestsFilters.category !== TRACKING_GROUPS.ALL &&
      trackingStore.requestsFilters.category !== TRACKING_GROUPS.TO_STORE &&
      trackingStore.requestsFilters.category !== TRACKING_GROUPS.TO_CUSTOMER
    ) {
      if (trackingStore.requestsFilters.category !== TRACKING_GROUPS.ACTION_REQUIRE) {
        trackingStore.allCounters.all.need_action.ids.forEach((id) => trackingStore.deleteRequest(id));
      }
    }
  };

  handleCreate = (event: TTopicRequestCreateEvent) => {
    const data = trackingRequestCast(event.data);

    if (userStore.user.organization_ids?.length && !userStore.user.organization_ids.includes(data.organization.id)) {
      return;
    }

    archiveStore.addRequestBySocket(data);
    trackingStore.addRequestBySocket(data);
  };

  handleDelete = (event: TTopicRequestDeleteEvent) => {
    const data = addTimeZoneToRequest(trackingRequestCast(event.data));

    if (userStore.user.organization_ids?.length && !userStore.user.organization_ids.includes(data.organization.id)) {
      return;
    }

    trackingStore.deleteRequest(data.request.id);
    archiveStore.updateRequestById(data);
  };

  init() {
    try {
      if (!appStore.settings?.socket_host) return;
      this.client = io(this.getSocketHost(), {
        extraHeaders: this._headers,
        path: '/socket.io',
      });

      this.client.on('connect', () => {
        console.info('socket connected');
      });

      this.client.on('disconnect', (reason) => {
        console.info('socket disconnected', reason);
      });

      this.client.on(TOPICS.REQUESTS_DELETE, this.handleDelete);
      this.client.on(TOPICS.REQUESTS_CREATE, this.handleCreate);
      this.client.on(TOPICS.REQUESTS_UPDATE, this.handleUpdate);
      this.client.on(TOPICS.REQUESTS_COUNT, this.handleCount);
    } catch (error) {
      console.log(error);
    }
  }

  disconnect() {
    this.client?.disconnect();
  }
}

const socket = new SocketClient();

export default socket;
