import './style.scss';

import React, { ChangeEvent, FocusEvent, useCallback, useRef, useState } from 'react';

interface InputAltProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  validation?: {
    state: boolean;
    errorText?: string;
    errorBlockClassName?: string;
    styleType?: 'top' | 'bottom';
  };
  wrapperClassName?: string;
  required?: boolean;
  onChangeValue?: (value: number | string) => void;
}

const InputAlt: React.FunctionComponent<InputAltProps> = ({
  placeholder,
  validation,
  className = '',
  wrapperClassName = '',
  required,
  type,
  onChange,
  onInput,
  onChangeValue,
  ...props
}) => {
  const [value, setValue] = useState(props.value || props.defaultValue);
  const [focused, setFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // const _old = usePrevious(props.value || props.defaultValue);

  // useEffect(() => {
  //   if (props.value !== _old && props.value !== value) {
  //     setValue(props.value);
  //   }
  // }, [props.value, props.defaultValue]);

  const _onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      if (onChangeValue) onChangeValue(event.target.value);
      if (onChange) onChange(event);
      if (onInput) onInput(event);
    },
    [onChange, onInput, onChangeValue],
  );

  const onFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (props.onFocus) props.onFocus(event);
    setFocused(true);
  }, []);

  const onBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) props.onBlur(event);
    setFocused(false);
  }, []);

  const focus = useCallback(() => {
    if (inputRef.current && !focused) {
      inputRef.current.focus();
    }
  }, [inputRef.current, focused]);

  return (
    <div className={`d-flex flex-column ${wrapperClassName}`}>
      {validation && validation.state && validation.errorText && validation.styleType !== 'bottom' ? (
        <span className={`alt-input__validation-block mb-1 ${validation.errorBlockClassName || ''}`}>{validation.errorText}</span>
      ) : null}
      {/* not delete this trim() */}
      <div onClick={focus} className={`alt-input ${focused || value?.toString().trim() ? 'focused' : ''}`}>
        <span className={`placeholder ${required ? 'required' : ''}`}>{placeholder}</span>
        <input
          {...props}
          type={type}
          ref={inputRef}
          value={props.value || value}
          onChange={onChange ? _onChange : undefined}
          onInput={onInput || onChangeValue ? _onChange : undefined}
          className={`${className} ${validation && validation.state && validation.styleType === 'bottom' ? 'input-error' : ''}`}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {validation && validation.state && validation.errorText && validation.styleType === 'bottom' ? (
        <span className={`alt-input__validation-block_bottom ${validation.errorBlockClassName || ''}`}>{validation.errorText}</span>
      ) : null}
    </div>
  );
};

export default InputAlt;
