import { observer } from 'mobx-react-lite';
import { RefObject, createRef, useEffect, useState } from 'react';
import { InfoBox, MarkerClusterer } from '@react-google-maps/api';
import { ClusterIconInfo, ClustererOptions } from '@react-google-maps/marker-clusterer';
import Marker, { MarkerType } from '~/view/components/Map/components/Marker';
import dnfStatisticsStore from '~/mobx/driverNotFoundStore/DnfStatisticsStore';

const colorByNDFLevel = {
  [MarkerType.BRANCH_NDF_LOW]: '#FFB3B3',
  [MarkerType.BRANCH_NDF_MEDIUM]: '#FF7373',
  [MarkerType.BRANCH_NDF_HIGH]: '#FF0000',
};

const options: ClustererOptions = {
  averageCenter: true,
  maxZoom: 6,
  zoomOnClick: true,
  calculator: (markers) => {
    const countNDFs = markers.reduce((count, marker) => count + Number(marker.getLabel()?.text ?? 1), 0);
    return {
      text: String(countNDFs),
      index: countNDFs > 10 ? 3 : countNDFs > 5 ? 2 : 1,
    } as ClusterIconInfo;
  },
  styles: [
    {
      textColor: 'white',
      url: '/images/map/cluster_ndf_low.png',
      height: 35,
      width: 35,
      className: 'd-flex align-items-center justify-content-center text-size-16',
    },
    {
      textColor: 'white',
      url: '/images/map/cluster_ndf_medium.png',
      height: 35,
      width: 35,
      className: 'd-flex align-items-center justify-content-center text-size-16',
    },
    {
      textColor: 'white',
      url: '/images/map/cluster_ndf_high.png',
      height: 35,
      width: 35,
      className: 'd-flex align-items-center justify-content-center text-size-16',
    },
  ],
};

const NDFMarkers = ({ zoom }: { zoom: number }) => {
  const [infoWindow, setInfoWindow] = useState<{ content: React.ReactNode; lat: number; lng: number }>({ content: null, lat: 0, lng: 0 });
  const [prevZoom, setPrevZoom] = useState(zoom);
  // Fix cluster update problem
  const [key, setKey] = useState<React.Key | null>(null);

  const pins = (dnfStatisticsStore.statistics?.coordinates || []).map((coordinate) => {
    const markerType =
      coordinate.count > 10 ? MarkerType.BRANCH_NDF_HIGH : coordinate.count > 5 ? MarkerType.BRANCH_NDF_MEDIUM : MarkerType.BRANCH_NDF_LOW;

    const color = colorByNDFLevel[markerType];
    return {
      ...coordinate,
      color,
      markerType,
    };
  });

  const [prevPins, setPrevPins] = useState(pins);

  const ref: RefObject<HTMLSpanElement> = createRef();

  const hideInfoWindow = () => setInfoWindow({ content: null, lat: 0, lng: 0 });

  if (zoom !== prevZoom) {
    hideInfoWindow();
    setPrevZoom(zoom);
  }
  if (infoWindow.content && !pins.some(({ latitude, longitude }) => infoWindow.lat === latitude && infoWindow.lng === longitude)) {
    hideInfoWindow();
  }

  if (pins.some(({ count }, i) => prevPins[i]?.count !== count)) {
    setKey(+new Date());
    setPrevPins(pins);
  }

  // Fix tooltip text-overflow:ellipsis problem
  useEffect(() => {
    if (ref.current?.scrollLeft !== undefined && infoWindow.content) {
      ref.current.scrollLeft = 0;
    }
  }, [infoWindow.content]);

  return (
    <>
      <MarkerClusterer key={key} options={options}>
        {(clusterer) =>
          pins.map(({ markerType, latitude, longitude, color, count, name }) => (
            <Marker
              key={String(latitude + longitude)}
              zIndex={5}
              type={markerType}
              position={{ lat: latitude, lng: longitude }}
              clusterer={clusterer}
              onClick={() => {
                if (!infoWindow.content || (infoWindow.lat !== latitude && infoWindow.lng !== longitude)) {
                  setInfoWindow({
                    content: <>{name}</>,
                    lat: latitude,
                    lng: longitude,
                  });
                } else {
                  hideInfoWindow();
                }
              }}
              label={{
                text: String(count),
                fontSize: '18',
                fontFamily: 'PrimarySemiBold',
                className: 'ndf-label',
                color,
              }}
            />
          )) as any
        }
      </MarkerClusterer>
      <InfoBox
        position={new google.maps.LatLng(infoWindow.lat, infoWindow.lng)}
        options={{
          isHidden: !infoWindow.content,
          pixelOffset: { height: -105, width: 0, equals: () => false },
          disableAutoPan: true,
          boxClass: 'ndf-map-tooltip-wrapper',
        }}
        onCloseClick={hideInfoWindow}>
        <div className="ndf-map-tooltip animate__animated animate__faster animate__fadeInUp" onClick={hideInfoWindow}>
          <div className="d-flex  flex-row">
            <span ref={ref} className="ndf-map-tooltip-option text-size-12 family-semibold text-elipsis">
              {infoWindow.content}
            </span>
          </div>
        </div>
      </InfoBox>
      {/* } */}
    </>
  );
};

export default observer(NDFMarkers);
