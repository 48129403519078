import React, { Component } from 'react';
import './style.scss';

interface Option {
  label: string;
  value: any;
}
interface Props {
  errors: {
    [key: string]: boolean;
  };
  options: Option[];
  value: Option['value'];
  onChange: (value: Option['value']) => void;
}

export default class Tabs extends Component<Props, {}> {
  static defaultProps = {
    errors: {},
  };

  onChange = (status: Option['value']) => {
    this.props.onChange(status);
  };

  renderTab = (tab: Option) => (
    <div
      onClick={() => this.onChange(tab.value)}
      className={`tabs--tab bg-graysoft bg_active-mainblue color_active-white  color-dark ${this.props.errors[tab.value] ? 'input-error' : ''}  ${
        this.props.value === tab.value ? 'active' : ''
      }`}
    >
      <span className=" family-semibold ">{tab.label}</span>
    </div>
  );

  render() {
    return <div className="tabs">{this.props.options.map(this.renderTab)}</div>;
  }
}
