import { IDateFilter, ILogFilters, ILogSelectedFilters, ILogSorting, IWasSelectedFilters, LOG_SORT_COLUMN } from '~/types/log';

export const defaultLogSorting: ILogSorting = {
  [LOG_SORT_COLUMN.DATE]: undefined,
  [LOG_SORT_COLUMN.AUTHOR]: undefined,
  [LOG_SORT_COLUMN.OBJECT]: undefined,
  [LOG_SORT_COLUMN.EVENT]: undefined,
  [LOG_SORT_COLUMN.OLD_VALUE]: undefined,
  [LOG_SORT_COLUMN.NEW_VALUE]: undefined,
};

export const defaultLogFilters: ILogFilters = {
  [LOG_SORT_COLUMN.DATE]: {
    from_date: null,
    to_date: null,
  },
  [LOG_SORT_COLUMN.AUTHOR]: [],
  [LOG_SORT_COLUMN.OBJECT]: [],
  [LOG_SORT_COLUMN.EVENT]: [],
  [LOG_SORT_COLUMN.OLD_VALUE]: [],
  [LOG_SORT_COLUMN.NEW_VALUE]: [],
};

export const defaultSelectedFilters: ILogSelectedFilters = {
  [LOG_SORT_COLUMN.DATE]: {
    from_date: null,
    to_date: null,
  } as IDateFilter,
  [LOG_SORT_COLUMN.AUTHOR]: [],
  [LOG_SORT_COLUMN.OBJECT]: [],
  [LOG_SORT_COLUMN.EVENT]: [],
  [LOG_SORT_COLUMN.OLD_VALUE]: [],
  [LOG_SORT_COLUMN.NEW_VALUE]: [],
};

export const defaultWasSelected: IWasSelectedFilters = {
  [LOG_SORT_COLUMN.DATE]: false,
  [LOG_SORT_COLUMN.AUTHOR]: false,
  [LOG_SORT_COLUMN.OBJECT]: false,
  [LOG_SORT_COLUMN.EVENT]: false,
  [LOG_SORT_COLUMN.OLD_VALUE]: false,
  [LOG_SORT_COLUMN.NEW_VALUE]: false,
};
