import { observer } from 'mobx-react';
import moment from 'moment';
import React, { ReactNode, RefObject } from 'react';
import TrackingTimer from './components/TrackingTimer';
import { ICONS } from '~/constants/icons';
import { PAYMENT_TYPES } from '~/constants/main';
import { RequestStatus, REQUESTS_STATUSES_LABELS, TTrackingRequest } from '~/constants/tracking';
import { getOrderDspId, getRequestId, truncateLongString } from '~/utils/formatter';
import Icon from '~/view/components/Controls/Icon/Icon';
import { localesStore } from '~/mobx';
import { showNAInsteadNull } from '~/utils';
import './style.scss';

type Props = {
  request: TTrackingRequest;
  selected: boolean;
  onSelect: (request: TTrackingRequest, ref: RefObject<HTMLDivElement>) => void;
};

class RequestItem extends React.Component<Props, any> {
  ref = React.createRef<HTMLDivElement>();

  id = getOrderDspId(this.props.request.dsp);

  componentDidMount() {
    if (this.props.selected) {
      this.handleSelect();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.selected !== this.props.selected && this.props.selected) {
      this.handleSelect();
    }
  }

  handleSelect = () => {
    this.props.onSelect(this.props.request, this.ref);
  };

  formatDueTime = (due_time: string) => {
    // due_time = due_time * 1000;
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    const time = moment(due_time);

    let formattedText = ``;

    if (time.isSame(today, 'day')) {
      formattedText += ` ${localesStore.t('today')},  ${time.format('h:mm A')}`;
    } else if (time.isSame(tomorrow, 'day')) {
      formattedText += ` ${localesStore.t('tomorrow')},  ${time.format('h:mm A')}`;
    } else {
      formattedText += ` ${time.format('DD/MM/YYYY')}`;
    }

    return formattedText;
  };

  getIsStopTimer = (status: RequestStatus) => {
    switch (status) {
      case RequestStatus.CANCELED:
        return true;
      case RequestStatus.DELIVERED:
        return true;
      case RequestStatus.DSP_ISSUE:
        return true;
      case RequestStatus.RETURNED:
        return true;
      default:
        return false;
    }
  };

  renderPaymentIcon = (paymentType: PAYMENT_TYPES): ReactNode => {
    switch (paymentType) {
      case PAYMENT_TYPES.CASH:
        return <Icon name={ICONS.IcCash} />;
      default:
        return <Icon name={ICONS.IcCard} />;
    }
  };

  render() {
    const { dsp, request, organization } = this.props.request;

    return (
      <div className={`request-item ${this.props.selected ? 'selected' : ''}`} onClick={this.handleSelect} ref={this.ref}>
        <div className={'d-flex justify-content-between align-items-end mb-3'}>
          <div className={'text-size-18 family-medium'}>{truncateLongString(getRequestId(organization))}</div>
          <TrackingTimer assignedAt={request.assigned_at} pickedUpAt={request.picked_up_at} finalAt={request.final_at} />
        </div>
        <div className={'d-flex justify-content-between align-items-center mb-1'}>
          <div className={'text-size-12 family-medium text-uppercase color-gray4'}>{organization.name}</div>
          <div className={'text-size-24 family-medium text-uppercase color-gray4'}>{this.renderPaymentIcon(request.payment_type)}</div>
        </div>
        <div className={'d-flex justify-content-between align-items-end mb-2'}>
          <div className={'text-size-12 family-medium text-uppercase color-gray4'}>
            {localesStore.t(`${REQUESTS_STATUSES_LABELS[request.status as RequestStatus]}`)}
          </div>
        </div>
        <div className={'d-flex justify-content-between align-items-end mb-2'}>
          <div className={'text-size-12 family-medium text-uppercase color-black'}>{dsp.name}</div>
        </div>
        <div className={'d-flex justify-content-between align-items-end'}>
          <div className={'text-size-12 family-medium text-uppercase color-black'}>{truncateLongString(showNAInsteadNull(this.id))}</div>
          <div className={'text-size-12 family-medium text-uppercase color-gray2'}>{this.formatDueTime(request.due_at)}</div>
        </div>
      </div>
    );
  }
}

export default observer(RequestItem);
