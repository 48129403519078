import { observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';
import { generatePath } from 'react-router-dom';
import { RequestStatus, REQUESTS_STATUSES_CLASSNAMES, REQUESTS_STATUSES_LABELS, TTrackingRequest } from '~/constants/tracking';
import { localesStore } from '~/mobx';
import { modalStore } from '~/mobx';
import { archiveStore } from '~/mobx';
import { showNAInsteadNull } from '~/utils';
import { getOrderDspId, getRequestId, truncateLongString } from '~/utils/formatter';
import './style.scss';
import withRouter from '~/utils/withRouter';
import { RouterComponentProps } from '~/types/main';

interface IItem {
  text?: string | number;
  className: string;
  charsLimit?: number;
}

interface IItemLabel {
  text?: string | number;
  status: RequestStatus;
  className: string;
}

interface IProps extends RouterComponentProps<{ id: string }> {
  deliveryRequestItem?: TTrackingRequest;
  id: string | undefined;
}

const Item = (item: IItem) => {
  return (
    <td className={`${item.className}`}>
      <p className="family-medium text-size-16 color-dark">{item.text}</p>
    </td>
  );
};

const ItemLabel = (item: IItemLabel) => {
  return (
    <td className={`${item.className}`}>
      <b className={`label ml-n2 ${REQUESTS_STATUSES_CLASSNAMES[item.status]}`}>{item.text}</b>
    </td>
  );
};

export const DeliveryRequestsTableBodyRow = withRouter(
  observer(
    class DeliveryRequestsTableBodyRow extends Component<IProps, {}> {
      onOpenAchivePopup = () => {
        this.props.router.navigate(
          generatePath('/requests/:id', {
            id: this.props.deliveryRequestItem?.request.id,
          }),
        );
      };

      onCloseAchivePopup = () => {
        this.props.router.navigate(generatePath('/requests'));
      };

      handleOpenTimeLine = (id: string) => () => {
        if (archiveStore.popupLockAnotherArea) return;

        archiveStore.getRequest(id);
        modalStore.openArchiveRequestInfoModal({
          id,
          onClose: this.onCloseAchivePopup,
          onOpen: this.onOpenAchivePopup,
        });
      };

      render() {
        const { deliveryRequestItem } = this.props;

        if (!deliveryRequestItem) return null;
        return (
          <tr className="py-4 requests-table-row" onClick={this.handleOpenTimeLine(deliveryRequestItem.request.id)}>
            <Item text={moment(deliveryRequestItem.request.created_at).format('D/M/Y, h:mm:ss A')} className="text-nowrap pr-1" />
            <ItemLabel
              status={deliveryRequestItem.request.status}
              text={localesStore.t(REQUESTS_STATUSES_LABELS[deliveryRequestItem.request.status as RequestStatus])}
              className="text-nowrap pr-2"
            />
            <Item text={truncateLongString(getRequestId(deliveryRequestItem.organization))} className="text-nowrap pr-2" />
            <Item text={deliveryRequestItem.organization.name} className="text-nowrap pr-2" />
            <Item text={deliveryRequestItem.organization.branch} className="text-nowrap pr-2" />
            <Item text={truncateLongString(showNAInsteadNull(getOrderDspId(deliveryRequestItem.dsp)))} className="text-nowrap pr-2" />
            <Item text={deliveryRequestItem.dsp.name} className="text-nowrap pr-2" />
            <Item text={deliveryRequestItem.organization.city} className="text-nowrap pr-2" />
          </tr>
        );
      }
    },
  ),
);
