import { action, observable, makeObservable, flow } from 'mobx';
// import { dspsCast, orgsDspCast, orgsListCast } from '~/mobx/dspStore/casts';
import { DSP_APPROVED_TXT, DSP_C_APPROVED, DSP_C_WORKING, DSP_GROUPS, DSP_TYPE, DSP_TYPES_GROUPS, DSP_WORKING_TXT } from '~/constants/dsp';
import { localesStore } from '~/mobx';
import client from '~/services/client';
import { dspsCast, IDsp, IOrg, orgCast, orgsDspCast, TOrgsDsp } from '~/types/dsp';

type TDspsFilters = {
  value?: string;
  approved?: DSP_C_APPROVED;
  working?: DSP_C_WORKING;
  type?: DSP_TYPE;
  status?: DSP_GROUPS;
};

type TDspFilters = {
  value?: string;
};

const defaultDspFilters: TDspFilters = {
  value: '',
};

const defaultDspsFilters: TDspsFilters = {
  value: '',
  approved: undefined,
  working: undefined,
  type: DSP_TYPE.ALL,
  status: DSP_GROUPS.ALL,
};

export class DspStore {
  constructor() {
    makeObservable(this, {
      dsps: observable,
      dsp: observable,
      setActiveDsp: action,
      orgs: observable,
      sidebar: observable,
      dspsLoading: observable,
      dspLoading: observable,
      dspUpdating: observable,
      dspsFilters: observable,
      dspFilters: observable,
      setDspsFilters: action,
      getDsps: action,
      getDsp: action,
      updateOrganizationAvailability: action,
      globalDspUpdate: action,
    });
  }

  public dsps: IDsp[] = [];
  public dsp: TOrgsDsp | null = null;
  public orgs: IOrg[] = [];

  public sidebar = true;

  public dspsLoading = false;
  public dspLoading = false;
  public dspUpdating = false;

  public dspsFilters: TDspsFilters = defaultDspsFilters;
  public dspFilters: TDspsFilters = defaultDspFilters;

  dspsAbortController = new AbortController();
  dspAbortController = new AbortController();

  setDspsFilters = flow(function* (this: DspStore, dspsFilters: TDspsFilters, clear: boolean) {
    this.dspsFilters = clear ? defaultDspsFilters : { ...this.dspsFilters, ...dspsFilters };
    yield this.getDsps();
  }).bind(this);

  public setActiveDsp = (dsp: IDsp) => {
    this.dsp = dsp;
  };

  public getDsps = flow(function* (this: DspStore) {
    this.dspsLoading = true;
    this.dspsAbortController.abort();

    const dspsAbortController = new AbortController();
    this.dspsAbortController = dspsAbortController;

    try {
      if (this.dspsFilters.status === undefined) {
        this.dspsFilters.status = DSP_GROUPS.ALL;
      }

      const tabFilter = DSP_TYPES_GROUPS[DSP_TYPE.ALL][this.dspsFilters.status];

      const response = yield client.get(`/v1/dsp`, {
        signal: this.dspsAbortController.signal,
        params: {
          filter: {
            provider: this.dspsFilters.value,
            approved: tabFilter.approved,
            working: tabFilter.working,
          },
        },
      });

      this.dsps = response.map(dspsCast);
    } catch (error) {
      console.log(error);
    } finally {
      if (!dspsAbortController.signal.aborted) {
        this.dspsLoading = false;
      }
    }
  }).bind(this);

  public getDsp = flow(function* (this: DspStore, id: string) {
    this.dspLoading = true;
    this.dspAbortController.abort();

    const dspAbortController = new AbortController();
    this.dspAbortController = dspAbortController;

    try {
      const response = yield client.get(`/v1/organizations/dsp/${id}`, {
        signal: this.dspAbortController.signal,
        params: {
          filter: {
            name: this.dspFilters.value,
          },
        },
      });

      this.dsp = orgsDspCast(response.dsp);
      this.orgs = response.list.map(orgCast);
    } catch (error) {
      console.log(error);
    } finally {
      if (!dspAbortController.signal.aborted) {
        this.dspLoading = false;
      }
    }
  }).bind(this);

  public updateOrganizationAvailability = flow(function* (
    this: DspStore,
    dspId: string,
    organizationIds: string[],
    availability: boolean,
    isAll: boolean,
  ) {
    this.dspUpdating = true;

    try {
      yield client.patch(`/v1/organizations/dsp/${dspId}`, {
        available: availability,
        organization_ids: organizationIds,
        is_all: isAll,
      });
    } catch (error) {
      console.log(error);
      this.orgs = this.orgs.map((org) => {
        if (organizationIds.includes(org.id)) {
          org.available = !availability;
        }
        return org;
      });
    } finally {
      this.dspUpdating = false;
    }
    this.orgs = this.orgs.map((org) => {
      if (organizationIds.includes(org.id)) {
        org.available = availability;
      }
      return org;
    });
  }).bind(this);

  public globalDspUpdate = flow(function* (this: DspStore, data: any) {
    if (!this.dsp) {
      return;
    }
    this.dspUpdating = true;
    const oldData = {
      priority: this.dsp.priority,
      enabled: this.dsp.enabled,
      available: this.dsp.available,
      approved: this.dsp.approved,
      working: this.dsp.working,
      acceptance_timeout: this.dsp.acceptance_timeout,
    };
    const newData = {
      ...oldData,
      ...data,
    };
    try {
      yield client.patch(`/v1/dsp/global/${this.dsp.id}`, newData);
    } catch (error) {
      console.log(error);
    } finally {
      this.dspUpdating = false;
    }
    this.dsp = {
      ...this.dsp,
      ...newData,
    };

    this.updateDsps();
  }).bind(this);

  updateDsps = (): boolean => {
    if (this.dsps.length > 0 && this.dsp !== null && this.dsp.id !== null) {
      for (let i = 0; i < this.dsps.length; i++) {
        if (this.dsps[i].id === this.dsp.id) {
          this.dsps[i] = { ...this.dsps[i], ...this.dsp };
          return true;
        }
      }
    }

    return false;
  };

  workingColor = (working: DSP_C_WORKING | undefined) => {
    if (working === DSP_C_WORKING.WORKING) {
      return 'green';
    }

    if (working === DSP_C_WORKING.DORMANT) {
      return 'red';
    }

    return 'gray';
  };

  approvedTxt = (approved: DSP_C_APPROVED | undefined) => {
    if (approved !== undefined) {
      return localesStore.t(DSP_APPROVED_TXT[approved]);
    }

    return '';
  };

  workingTxt = (working: DSP_C_WORKING | undefined) => {
    if (working !== undefined) {
      return localesStore.t(DSP_WORKING_TXT[working]);
    }

    return '';
  };
}

const dspStore = new DspStore();
export default dspStore;
