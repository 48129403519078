import { observer } from 'mobx-react';
import { Component } from 'react';
import styles from 'taker-web-ui/dist/constants/styles';
import Modal from '~/view/components/Controls/Modal/Modal';
import { localesStore } from '~/mobx';
import ModalHeader from '~/view/components/Controls/Modal/ModalHeader/ModalHeader';
import ModalContent from '~/view/components/Controls/Modal/ModalContent/ModalContent';
import ModalBackground from '~/view/components/Controls/Modal/ModalBackground/ModalBackground';
import { modalStore } from '~/mobx';
import './style.scss';
import { IDNFTopStatistics } from '~/types/dnfStatistics';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import dnfStatisticsStore from '~/mobx/driverNotFoundStore/DnfStatisticsStore';
import { LoadingStatus } from '~/types/main';

class DnfModal extends Component<{}, {}> {
  closeModal = () => {
    modalStore.closeDnfModal();
  };

  renderStatistics = () => {
    if (dnfStatisticsStore.statistics === undefined || dnfStatisticsStore.statistics.count === 0) {
      return <div className={'d-flex flex-column empty'}>{this.renderCount(0)}</div>;
    }

    return (
      <div className={'d-flex flex-column scrollable'}>
        {this.renderCount(dnfStatisticsStore.statistics.count)}
        {this.renderTopList(dnfStatisticsStore.statistics.top_organizations, 'top_ndf_clients')}
        {this.renderTopList(dnfStatisticsStore.statistics.top_branches, 'top_ndf_branches')}
      </div>
    );
  };

  renderCount = (count: number) => {
    return (
      <>
        <h3 className={'dnf-modal-title'}>{localesStore.t('ndf_cases_counter', { n: 60 })}</h3>
        <p className={'dnf-modal-values'}>
          <p className={'dnf-modal-value'}>{count}</p>
        </p>
      </>
    );
  };

  renderTopList = (tops: IDNFTopStatistics[], key: string) => {
    if (tops.length === 0) return null;

    return (
      <>
        <h3 className={'dnf-modal-title'}>{localesStore.t(key)}</h3>
        <div className={'dnf-modal-values'}>{tops.map(this.renderTop)}</div>
      </>
    );
  };

  renderTop = (top: IDNFTopStatistics, index: number) => {
    return (
      <p key={index} className={'dnf-modal-value'}>
        {top.name} ({top.count})
      </p>
    );
  };

  render() {
    return (
      <Modal
        className="order-info-modal dnf-modal"
        isOpen={modalStore.dnfModal !== null}
        onRequestClose={this.closeModal}
        withCloseIcon
        iconClassName={'color-gray2'}>
        <ModalBackground>
          <ModalHeader title={`${localesStore.t('ndf_statistics')}:`} className={'align-items-center'}></ModalHeader>
          <ModalContent className={'h-100 dnf-modal-content'} contentClassName={'h-100'} contentPY={'py-6'}>
            {dnfStatisticsStore.statisticsStatus !== LoadingStatus.Success ? (
              <div className="d-flex flex-grow-1 justify-content-center m-auto">
                <Spinner color={styles.colors.mainblue} size={24} />
              </div>
            ) : (
              this.renderStatistics()
            )}
          </ModalContent>
        </ModalBackground>
      </Modal>
    );
  }
}

export default observer(DnfModal);
