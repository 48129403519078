import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import { generatePath } from 'react-router-dom';
import AdditionalInfoPopup from './containers/AdditionalInfoPopup/AdditionalInfoPopup';
import MapNotificationContainer from './containers/MapNotificationContainer/MapNotificationContainer';
import RequestsList from './containers/RequestsList/RequestsList';
import TrackingGroups from './containers/TrackingGroups/TrackingGroups';
import NDFMarkers from './components/NDFMarkers/NDFMarkers';
import { TRACKING_GROUPS, TTrackingRequest, TTrackingSingleRequest } from '~/constants/tracking';
import Headers from '~/view/components/Headers/Headers';
import Marker, { MarkerType } from '~/view/components/Map/components/Marker';
import Map, { CoordsWithType, TClustering, TClusteringSelected } from '~/view/components/Map/Map';
import { Table } from '~/view/components/Table/Table';
import { trackingStore } from '~/mobx';
import { withRouter } from '~/utils';
import './style.scss';
import { RouterComponentProps } from '~/types/main';
import { TTrackingRequestsFilters } from '~/mobx/trackingStore/trackingStore';
import dnfStatisticsStore from '~/mobx/driverNotFoundStore/DnfStatisticsStore';

type State = {
  zoom: number;
  prevTrackingRequest: TTrackingSingleRequest | null;
};

type IProps = RouterComponentProps<{ group: TRACKING_GROUPS; id: string | undefined }, {}>;

class DeliveryTrackingScreen extends React.Component<IProps, State> {
  mapRef: RefObject<Map> = React.createRef<Map>();
  lRef: RefObject<any> = React.createRef();

  state = {
    zoom: 0,
    prevTrackingRequest: null,
  };

  componentDidUpdate() {
    if (this.state.prevTrackingRequest !== null && trackingStore.trackingRequest === null) {
      this.mapRef.current?.setCenter();
      this.mapRef.current?.setZoom(trackingStore.lastMapPosition.zoom);
      this.setState({ prevTrackingRequest: null });
    } else if (this.state.prevTrackingRequest === null && trackingStore.trackingRequest !== null) {
      this.setState({ prevTrackingRequest: trackingStore.trackingRequest });
    }

    if (!this.props.router.params.id && this.state.prevTrackingRequest) {
      trackingStore.trackingRequest = null;
      this.mapRef.current?.setCenter();
      this.mapRef.current?.setZoom(trackingStore.lastMapPosition.zoom);
      this.setState({ prevTrackingRequest: null });
    }
  }

  async componentDidMount() {
    const defaultParams = this.props.router.params;

    trackingStore.setRequestsFilters({ category: defaultParams.group } as TTrackingRequestsFilters, false);

    if (defaultParams.id) {
      trackingStore.getRequest(defaultParams.id);
      this.getRequestAndFocusOnMarker(defaultParams.id, true);
    } else {
      this.mapRef.current?.setCenter();
      this.mapRef.current?.setZoom(trackingStore.lastMapPosition.zoom);
    }
    await trackingStore.getAvailableDSPs();
  }

  onChangeGroup = (group: TRACKING_GROUPS) => {
    this.props.router.navigate(
      generatePath('/tracking/:group', {
        group,
        id: undefined,
      }),
    );

    // @ts-ignore
    trackingStore.sRef.current.clearText();

    trackingStore.setRequestsFilters({ category: group, search: '' } as TTrackingRequestsFilters, false);
  };

  getRequestAndFocusOnMarker = (requestId: string, force?: boolean) => {
    const trackingRequest: TTrackingSingleRequest | null = trackingStore.trackingRequest;

    if (trackingRequest?.request.id === requestId && !force) return;

    if (!trackingRequest || (trackingRequest && trackingRequest.request.id !== requestId && !force)) {
      trackingStore.getRequest(requestId);
    }

    const customerLocation = trackingStore.clustering.selected.customer;
    const branchLocation = trackingStore.clustering.selected.branch;

    if (trackingRequest && customerLocation && branchLocation) {
      const distanceInMeters = this.mapRef.current?.getDistanceBetweenTwoMarkers(customerLocation, branchLocation)!;

      const distanceInKms = distanceInMeters / 1000;

      const getZoomForDistance = (distanceKms: number) => {
        if (distanceKms >= 8500) {
          return 1;
        } else if (distanceKms >= 3500) {
          return 2;
        } else if (distanceKms < 1000) {
          return 10;
        }
      };

      this.mapRef.current?.setCameraOnMidOfTwoMarkers(customerLocation, branchLocation, getZoomForDistance(distanceInKms));
      // this.mapRef.current?.setCamera(customerLocation, 10);
    }
  };

  handleRequestSelect = (request: TTrackingRequest) => {
    if (this.props.router.params.id === request.request.id) {
      return;
    }

    this.props.router.navigate(
      generatePath('/tracking/:group/:id', {
        group: trackingStore.requestsFilters.category,
        id: String(request.request.id),
      }),
    );

    this.getRequestAndFocusOnMarker(request.request.id);
  };

  handleMarkerClick = (_event: globalThis.google.maps.MapMouseEvent, data: CoordsWithType) => {
    this.getRequestAndFocusOnMarker(data.requestId!);
  };

  renderSelectedMarkers = (selected: TClusteringSelected) => {
    if (selected.customer && selected.branch && (this.state.zoom || 0) >= 7) {
      return [
        <Marker
          zIndex={5}
          selected={true}
          type={selected.customer?.type}
          position={{ lat: selected.customer.lat, lng: selected.customer.lng }}
          key={'selected-customer-location'}
        />,
        <Marker
          zIndex={5}
          selected={true}
          type={selected.branch?.type}
          position={{ lat: selected.branch.lat, lng: selected.branch.lng }}
          key={'selected-branch-location'}
        />,
      ];
    }

    return null;
  };

  renderDriverLocation = () => {
    if (!trackingStore.driverLocation || (this.state.zoom || 0) <= 6) return null;

    return <Marker zIndex={5} type={MarkerType.CAR} position={trackingStore.driverLocation} label={{ className: 'pulse-label', text: ' ' }} />;
  };

  c: any = {};

  render() {
    const clustering: TClustering = {
      ...trackingStore.clustering,
      onMarkerClick: this.handleMarkerClick,
    };

    const { selected } = clustering;

    return (
      <div className="d-flex flex-column full-width">
        <Headers>
          <TrackingGroups onChange={this.onChangeGroup} activeGroup={trackingStore.requestsFilters.category} />
        </Headers>

        <div className="d-flex flex-column flex-grow-1 pt-6">
          <Table>
            <React.Fragment>
              <div className="d-flex flex-row flex-grow-1">
                <div className={'flex-column'}>
                  <RequestsList onSelect={this.handleRequestSelect} selectedId={trackingStore.selectedRequestId} />
                </div>
                <div className="map-container d-flex flex-column pl-4 full-width">
                  <Map
                    className={'map'}
                    clustering={clustering}
                    onZoomChanged={() => this.setState({ zoom: this.mapRef.current?.getCurrentZoom() || 0 })}
                    onUnmount={(map) => {
                      const center = map.getCenter();
                      trackingStore.setLastMapPos({
                        zoom: map.getZoom() || 0,
                        pos: { lat: center?.lat() || 0, lng: center?.lng() || 0 },
                      });
                    }}
                    ref={this.mapRef}
                    isStatistics={true}>
                    {this.renderSelectedMarkers(selected)}
                    {this.renderDriverLocation()}
                    {dnfStatisticsStore.statisticMapIsVisible && <NDFMarkers zoom={this.state.zoom} />}
                    <MapNotificationContainer />
                    <AdditionalInfoPopup opened={Boolean(trackingStore.trackingRequest)} request={trackingStore.trackingRequest} />
                  </Map>
                </div>
              </div>
            </React.Fragment>
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(DeliveryTrackingScreen));
