// import { TBooleanInNumber } from '~/types/main';

export const orgCast = (org: any) => ({
  id: String(org.id),
  name: String(org.name),
  dsp_id: String(org.dsp_id),
  available: Boolean(org.available),
});

export const orgsDspCast = (orgsDsp: any) => ({
  id: String(orgsDsp.id),
  name: String(orgsDsp.name),
  provider: String(orgsDsp.provider),
  priority: parseInt(orgsDsp.priority, 10),
  enabled: parseInt(orgsDsp.enabled, 10),
  available: parseInt(orgsDsp.available, 10),
  approved: parseInt(orgsDsp.approved, 10),
  working: parseInt(orgsDsp.working, 10),
  acceptance_timeout: parseInt(orgsDsp.acceptance_timeout, 10),
  created_at: String(orgsDsp.created_at),
  updated_at: String(orgsDsp.updated_at),
  approved_txt: String(orgsDsp.approved_txt),
  working_txt: String(orgsDsp.working_txt),
});

export const dspsCast = ({ organization_credentials = [], ...dsp }) => ({
  id: String(dsp.id),
  name: String(dsp.name),
  provider: String(dsp.provider),
  priority: parseInt(dsp.priority, 10),
  enabled: parseInt(dsp.enabled, 10),
  available: parseInt(dsp.available, 10),
  approved: parseInt(dsp.approved, 10),
  working: parseInt(dsp.working, 10),
  acceptance_timeout: parseInt(dsp.acceptance_timeout, 10),
  created_at: String(dsp.created_at),
  updated_at: String(dsp.updated_at),
  approved_txt: String(dsp.approved_txt),
  working_txt: String(dsp.working_txt),
  organization_credentials: organization_credentials.map((organization_credential: any) => ({
    id: String(organization_credential.id),
    organization_id: String(organization_credential.organization_id),
    dsp_id: String(organization_credential.dsp_id),
    auth_token: String(organization_credential.auth_token),
    dsp_token: String(organization_credential.dsp_token),
    dsp_url: String(organization_credential.dsp_url),
    credentials: String(organization_credential.credentials),
    created_at: String(organization_credential.created_at),
    updated_at: String(organization_credential.updated_at),
  })),
});

export type IDsp = ReturnType<typeof dspsCast>;
export type TOrgsDsp = ReturnType<typeof orgsDspCast>;

export type IOrg = ReturnType<typeof orgCast>;
