import { observable, makeObservable, action } from 'mobx';

interface ITestModalParams {
  id: string;
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (customers: string[], isAll: boolean) => void;
}

interface IErrorModalParams {
  text: string;
  onClose?: () => void;
  onOpen?: () => void;
}

interface RequestInfoModalParams {
  id: string;
  onClose?: () => void;
  onOpen?: () => void;
}

interface DnfModalParams {
  onClose?: () => void;
  onOpen?: () => void;
}

export class ModalStore {
  constructor() {
    makeObservable(this, {
      testModal: observable,
      requestInfoModal: observable,
      archiveRequestInfoModal: observable,
      dnfModal: observable,
      errorModal: observable,
      openRequestInfoModal: action,
      closeRequestInfoModal: action,
      openArchiveRequestInfoModal: action,
      closeArchiveRequestInfoModal: action,
      openDnfModal: action,
      closeDnfModal: action,
      openErrorModal: action,
      closeErrorModal: action,
      openTestModal: action,
      closeTestModal: action,
    });
  }

  testModal: ITestModalParams | null = null;
  errorModal: IErrorModalParams | null = null;
  requestInfoModal: RequestInfoModalParams | null = null;
  archiveRequestInfoModal: RequestInfoModalParams | null = null;
  dnfModal: DnfModalParams | null = null;

  openRequestInfoModal = (params: RequestInfoModalParams) => {
    this.requestInfoModal = params;
  };

  closeRequestInfoModal = () => {
    this.requestInfoModal = null;
  };

  openArchiveRequestInfoModal = (params: RequestInfoModalParams) => {
    this.archiveRequestInfoModal = params;
  };

  closeArchiveRequestInfoModal = () => {
    this.archiveRequestInfoModal = null;
  };

  openDnfModal = (params: DnfModalParams) => {
    this.dnfModal = params;
  };

  closeDnfModal = () => {
    this.dnfModal = null;
  };

  openErrorModal = (errorModal: IErrorModalParams) => {
    this.errorModal = errorModal;
  };

  closeErrorModal = () => {
    this.errorModal = null;
  };

  openTestModal = (testModal: ITestModalParams) => {
    this.testModal = testModal;
  };

  closeTestModal = () => {
    this.testModal = null;
  };
}

const modalStore = new ModalStore();
export default modalStore;
