import './style.scss';
import * as React from 'react';
import { ReactNode } from 'react';
import Icon from '~/view/components/Controls/Icon/Icon';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import { EmptyList } from '~/view/components/EmptyList/EmptyList';
import { ICONS } from '~/constants/icons';
import { throttle } from '~/utils/perfomance';
import styles from '~/utils/styles';
import NoResults from '~/view/components/NoResults/NoResults';

interface IScrollableListProps<T> {
  list: T[];
  current_page: number;
  last_page: number;
  renderItem: (item: T | undefined, index: number) => any;
  // renderEmptyList: any;
  loading: boolean;
  refreshing?: boolean;
  fetch: (page?: number) => Promise<void>;
  clear?: (cancellable: boolean) => void;
  listClassName?: string;
  lastPageText?: string;
  emptyListText?: string;
  emptyListComponent?: ReactNode;
  scrollToTop: boolean;
  scrollbar?: boolean;
  scrollbarClassName?: string;
  autoFetch: boolean;
  pages: number[];
}
class ScrollableList<T> extends React.Component<IScrollableListProps<T>> {
  wrapper: React.RefObject<HTMLDivElement> = React.createRef();
  listClassName = this.props.listClassName || '';
  constructor(props: IScrollableListProps<any>) {
    super(props);
    if (props.clear) {
      props.clear(false);
    }
  }

  state = {
    scrollToTop: false,
  };

  componentDidMount() {
    if (this.props.autoFetch) {
      this.fetchData(1);
    }
    if (this.wrapper.current) {
      this.wrapper.current.addEventListener('scroll', this.throttleScroll);
    }
  }

  fetchData = async (page?: number) => {
    try {
      await this.props.fetch(page);
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount = () => {
    if (this.props.clear) {
      this.props.clear(true);
    }
    if (this.wrapper.current) {
      this.wrapper.current.removeEventListener('scroll', this.throttleScroll);
    }
  };

  onWindowScroll = async () => {
    if (!this.wrapper?.current) return;
    const height = this.wrapper.current.offsetHeight;
    const scrollHeight = this.wrapper.current.scrollHeight;
    const scrollOffset = this.wrapper.current.scrollTop;
    const scrollBottomLeft = scrollHeight - (height + scrollOffset);
    const firstPage = this.props.pages.length ? this.props.pages[0] : 1;
    const lastPage = this.props.pages[this.props.pages.length - 1];
    if (scrollOffset > height) {
      this.setState({ scrollToTop: true });
    } else {
      this.setState({ scrollToTop: false });
    }

    if (this.props.loading) return;
    if (lastPage < this.props.last_page && scrollBottomLeft < height / 2) {
      this.fetchData(this.props.current_page + 1);
    }

    if (firstPage > 0 && this.props.pages.length && this.props.pages[0] !== 1 && scrollOffset < height / 2) {
      await this.fetchData(lastPage - 1);
      this.wrapper.current.scrollTo({
        top: this.wrapper.current.scrollHeight - (this.wrapper.current.offsetHeight + this.wrapper.current.scrollTop) - scrollBottomLeft,
      });
    }
  };

  throttleScroll = throttle(this.onWindowScroll, 50);

  renderLoader = (i: any, index: number) => this.props.renderItem(undefined, index);

  renderFooter = () => {
    if (!this.props.loading && this.props.current_page >= this.props.last_page && this.props.list.length) {
      if (!this.props.current_page) {
        return null;
      } else {
        return <EmptyList text={this.props.lastPageText || 'no_more_dsp'} />;
      }
    }
    if (this.props.pages[this.props.pages.length - 1] < this.props.last_page) {
      return [undefined].map(this.renderLoader);
    }
    return null;
  };

  renderHeader = () => {
    if (this.props.pages.length && this.props.pages[0] !== 1) {
      return [undefined].map(this.renderLoader);
    }
    return null;
  };

  renderEmptyList = () => {
    if (
      (!this.props.loading && this.props.current_page === this.props.last_page && !this.props.list.length) ||
      (!this.props.loading && this.props.list && this.props.list.length === 0)
    ) {
      return this.props.emptyListComponent || <NoResults />;
    }
    return null;
  };

  scrollToTop = () => {
    if (this.wrapper.current) {
      this.wrapper.current.scrollTo({ top: 0, behavior: 'smooth' });
      if (this.props.current_page !== 1) {
        this.props.fetch(1);
      }
    }
  };

  public render() {
    const { scrollbar, scrollbarClassName = '' } = this.props;

    return (
      <div className="d-flex flex-column  flex-grow-1" style={{ position: 'relative' }}>
        <div
          ref={this.wrapper}
          className={`d-flex flex-column scroll-list-wrapper flex-grow-1 ${scrollbar ? `${scrollbarClassName}` : 'hide-scrollbar'}`}>
          <div className={`d-flex flex-column scroll-list hide-scrollbar ${this.listClassName} full-height`}>
            {this.renderHeader()}
            {this.props.list.map(this.props.renderItem)}
            {this.renderEmptyList()}
            {this.renderFooter()}
          </div>
        </div>
        {this.props.refreshing || this.props.loading ? (
          <div className="scroll-list-refreshing d-flex">
            <div className="d-flex flex-grow-1 justify-content-center m-auto">
              <Spinner color={styles.colors.mainblue} size={24} container={true} />
            </div>
          </div>
        ) : null}

        {this.props.scrollToTop && this.state.scrollToTop ? (
          <div onClick={this.scrollToTop} className="scroll-to">
            <Icon name={ICONS.ArrowTop} size={24} className="color-mainblue" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ScrollableList;
