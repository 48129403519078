import { observer } from 'mobx-react';
import React from 'react';

import { DeliveryZonesForm } from '../../components/DeliveryZonesForm/DeliveryZonesForm';
import { deliveryZonesStore } from '~/mobx';

const DeliveryZonesDraft = observer(() => {
  if (!deliveryZonesStore.draft) return null;

  return (
    <DeliveryZonesForm
      zone={deliveryZonesStore.draft}
      onSave={deliveryZonesStore.updateZone}
      onDelete={deliveryZonesStore.deleteZone}
      onCancel={deliveryZonesStore.clearDraft}
    />
  );
});

export default DeliveryZonesDraft;
