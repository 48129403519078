import { observer } from 'mobx-react';
import { Component } from 'react';
import DeliveryRequestsTableBody from './DeliveryRequestsTableBody';
import DeliveryRequestsTableFooter from './DeliveryRequestsTableFooter';
import DeliveryRequestsTableHeader from './DeliveryRequestsTableHeader';
import { localesStore } from '~/mobx';
import { archiveStore } from '~/mobx';
import NoResults from '~/view/components/NoResults/NoResults';
import './styles.scss';

export const DeliveryRequestsTable = observer(
  class DeliveryRequestsTable extends Component<{}, {}> {
    render() {
      return (
        <div className="d-flex flex-1 flex-column">
          <div className="flex-1 pos-relative mt-6">
            <div className="requests-table__wrapper requests-table__scrollbar pos-absolute">
              <table className="requests-table">
                <DeliveryRequestsTableHeader />
                <DeliveryRequestsTableBody data={archiveStore.trackingRequests.data} />
              </table>
            </div>
            {archiveStore.trackingRequests.pagination.total === 0 &&
              !archiveStore.requestsLoading &&
              (archiveStore.filtersWasSelected ? (
                <div className={'my-auto mx-auto text-center pos-absolute requests-table__empty'}>
                  <p className="text-size-18 my-auto color-gray5 font-weight-600">
                    <span>{localesStore.t('filters_no_results')}</span>
                  </p>
                  <p className="text-size-16 my-auto color-gray5 font-weight-500">
                    <span>{localesStore.t('adjust_filters')}</span>
                  </p>
                </div>
              ) : (
                <div className="requests-table__empty pos-absolute">
                  <NoResults />
                </div>
              ))}
          </div>
          <DeliveryRequestsTableFooter />
        </div>
      );
    }
  },
);
