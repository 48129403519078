export enum DSP_TYPE {
  ALL = 'all',
}

export enum DSP_FILTERS {
  C_APPROVED = 'approved',
  C_WORKING = 'working',
}

export enum DSP_C_APPROVED {
  BETA = 0,
  APPROVED = 1,
}

export enum DSP_C_WORKING {
  DEVELOPMENT = 0,
  AWAITING = 1,
  WORKING = 2,
  DORMANT = 3,
}

export const DSP_APPROVED_TXT = {
  [DSP_C_APPROVED.BETA]: 'beta_status',
  [DSP_C_APPROVED.APPROVED]: 'approved_status',
};

export const DSP_WORKING_TXT = {
  [DSP_C_WORKING.DEVELOPMENT]: 'development_status',
  [DSP_C_WORKING.AWAITING]: 'awaiting_status',
  [DSP_C_WORKING.WORKING]: 'working_status',
  [DSP_C_WORKING.DORMANT]: 'dormant_status',
};

export const DSP_STATUS_LABELS = {
  all: 'all_filter_tab',
  approved: 'approved_filter_tab',
  beta: 'beta_filter_tab',
  working: 'working_filter_tab',
  dormant: 'dormant_filter_tab',
};

export enum DSP_GROUPS {
  ALL = 'all',
  APPROVED = 'approved',
  BETA = 'beta',
  WORKING = 'working',
  DORMANT = 'dormant',
}

export const DSP_TYPES_GROUPS = {
  [DSP_TYPE.ALL]: {
    // ALL
    [DSP_GROUPS.ALL]: {
      [DSP_FILTERS.C_APPROVED]: undefined,
      [DSP_FILTERS.C_WORKING]: undefined,
    },
    // APPROVED
    [DSP_GROUPS.APPROVED]: {
      [DSP_FILTERS.C_APPROVED]: DSP_C_APPROVED.APPROVED,
      [DSP_FILTERS.C_WORKING]: undefined,
    },
    // BETA
    [DSP_GROUPS.BETA]: {
      [DSP_FILTERS.C_APPROVED]: DSP_C_APPROVED.BETA,
      [DSP_FILTERS.C_WORKING]: undefined,
    },
    // WORKING
    [DSP_GROUPS.WORKING]: {
      [DSP_FILTERS.C_APPROVED]: undefined,
      [DSP_FILTERS.C_WORKING]: DSP_C_WORKING.WORKING,
    },
    // DORMANT
    [DSP_GROUPS.DORMANT]: {
      [DSP_FILTERS.C_APPROVED]: undefined,
      [DSP_FILTERS.C_WORKING]: DSP_C_WORKING.DORMANT,
    },
  },
};
