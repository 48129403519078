import './style.scss';

import { observer } from 'mobx-react';
import { Component } from 'react';
import { DEBOUNCE_TIME } from '~/constants/main';
import { dspStore } from '~/mobx';
import { localesStore } from '~/mobx';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import { TableHeader } from '~/view/components/Table/components/TableHeader/TableHeader';

class DspHeader extends Component {
  onChangeText = (value: string) => {
    dspStore.setDspsFilters({ value }, false);
  };

  render() {
    return (
      <TableHeader>
        <div className="d-flex flex-column full-width">
          <SearchInput
            loading={dspStore.dspsLoading}
            className={'input-wrapper bg-graysoft border-transparent color-black'}
            inputClassName={'bg-graysoft text-size-16 family-medium'}
            onChangeText={this.onChangeText}
            withCloseIcon
            placeholder={localesStore.t('search_box')}
            value={dspStore.dspsFilters.value}
            debounceTime={DEBOUNCE_TIME.LARGE}
          />
        </div>
      </TableHeader>
    );
  }
}

export default observer(DspHeader);
