import { action, computed, observable, makeObservable, flow, runInAction } from 'mobx';
import moment from 'moment';
import client from '~/services/client';
import { LoadingStatus } from '~/types/main';
import { SORT_DIR } from '~/constants/main';
import { defaultRequestsSorting, wasDefaultSelectedFilters } from '~/constants/requests';
import {
  RequestStatus,
  timelineCast,
  trackingRequestCast,
  TTrackingRequest,
  TTrackingRequestTimeline,
  TTrackingSingleRequest,
} from '~/constants/tracking';

import { localesStore } from '~/mobx';
import {
  defaultRequestsFilters,
  defaultRequestsFiltersOptions,
  filterOptionCast,
  IDateFilter,
  IFilter,
  IRequestsFilters,
  IRequestsFiltersOptions,
  IRequestsSorting,
  ITimeFilter,
  IWasRequestsSelectedFilters,
  REQ_SORT_COL,
} from '~/types/requests';
import downloadFile from '~/utils/downloadFile';
import { getRequestId } from '~/utils/formatter';
import { addTimeZoneToRequest, addTimeZoneToTimeline } from '~/utils/handleTimeZone';
import { isEqAsString } from '~/utils/helpers';
import { isReqEqualBySearch } from '~/utils';

type TTrackingPagination = {
  pagination: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
  data: TTrackingRequest[];
};

export const defaultPagination = {
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  },
  data: [],
};

const requestDelay = 1000;

export class ArchiveStore {
  constructor() {
    makeObservable(this, {
      addRequestBySocket: action,
      clearFilter: action,
      clearFilterOption: action,
      exportExcel: action,
      filterOptions: observable,
      filtersWasSelected: observable,
      getRequest: action,
      getRequests: action,
      getSingleRequest: action,
      getTimeline: action,
      isExcelExportAvailable: computed,
      isExcelExporting: observable,
      lastFilterOptionsRequestTime: observable,
      pages: observable,
      pagination: computed,
      popupLockAnotherArea: observable,
      requestLoading: observable,
      requestsLoading: observable,
      searchFilterOptions: action,
      selectedFilters: observable,
      setLockedPopupAnotherArea: action,
      setSelectedFilter: action,
      setSorting: action,
      sort: observable,
      timeline: observable,
      timelineLoading: observable,
      trackingRequest: observable,
      trackingRequests: observable,
      updateRequestById: action,
      wasSelected: observable,
    });
  }

  requestsAbortController = new AbortController();

  public trackingRequests: TTrackingPagination = defaultPagination;

  public pages: number[] = [];

  sort: IRequestsSorting = defaultRequestsSorting;

  public trackingRequest: TTrackingSingleRequest | null = null;
  public timeline: TTrackingRequestTimeline = [];

  public requestsLoading = false;
  public requestLoading = false;
  public timelineLoading = false;

  public isExcelExporting = false;

  public selectedFilters: IRequestsFilters = defaultRequestsFilters;
  public wasSelected: IWasRequestsSelectedFilters = wasDefaultSelectedFilters;
  public filtersWasSelected = false;

  public filterOptions: IRequestsFiltersOptions = defaultRequestsFiltersOptions;
  lastFilterOptionsRequestTime = 0;

  public popupLockAnotherArea = false;

  requestsTimeoutId: any;

  // For Pagination component
  public get pagination(): any {
    return {
      current_page: this.trackingRequests.pagination.current_page,
      data: this.trackingRequests.data,
      from: 0,
      last_page: this.trackingRequests.pagination.last_page,
      to: 0,
      total: this.trackingRequests.pagination.total,
      loading: false,
      refreshing: this.requestsLoading,
    };
  }

  public get isExcelExportAvailable(): boolean {
    return Object.entries(this.wasSelected).filter((value) => value[1]).length > 1 && !this.isExcelExporting;
  }

  setLockedPopupAnotherArea = (isLock: boolean) => (this.popupLockAnotherArea = isLock);

  getTimeline = flow(function* (this: ArchiveStore) {
    if (!this.trackingRequest) return;

    this.timelineLoading = true;

    const id = this.trackingRequest?.request.id;

    try {
      const response = yield client.get(`/v1/requests/${id}/timeline`);
      this.timeline = addTimeZoneToTimeline(response.map(timelineCast));
    } catch (e) {
      console.error(e);
    } finally {
      this.timelineLoading = false;
    }
  }).bind(this);

  updateRequestById = flow(function* (this: ArchiveStore, data: TTrackingRequest) {
    const requestIndex = this.trackingRequests.data.findIndex((req) => req.request.id === data.request.id);

    if (requestIndex === -1) return;

    if ([RequestStatus.RETURNED, RequestStatus.NO_STATUS, RequestStatus.CANCELED].includes(data.request.status)) {
      this.trackingRequests.data[requestIndex] = data;
    }

    this.trackingRequests.data[requestIndex] = data;

    if (this.trackingRequest?.request.id === data.request.id) {
      this.trackingRequest = data;
    }
  }).bind(this);

  addRequestBySocket = (tRequest: TTrackingRequest, fixTimeZone = true) => {
    if (this.requestsLoading) {
      return;
    }
    // TOFIX make filter checking function
    const sortKeys = Object.keys(this.sort);
    if (
      this.trackingRequests.data.findIndex(({ request }) => request.id === tRequest.request.id) >= 0 ||
      (sortKeys.length > 0 && sortKeys.includes(REQ_SORT_COL.DATE) && this.sort?.[REQ_SORT_COL.DATE] !== SORT_DIR.DESC) ||
      (sortKeys.length > 0 && !sortKeys.includes(REQ_SORT_COL.DATE)) ||
      !isReqEqualBySearch(tRequest, this.selectedFilters.search || '') ||
      this.trackingRequests.pagination.current_page > 1 ||
      Object.entries(this.selectedFilters).some(([key, value]) => {
        switch (key) {
          case REQ_SORT_COL.TIME:
            return !!(value as ITimeFilter)?.end || !!(value as ITimeFilter).start;
          case REQ_SORT_COL.DATE:
            return !!(value as IDateFilter)?.from_date || !!(value as IDateFilter).to_date;
          case REQ_SORT_COL.STATUS:
            return (
              (value as IFilter[])?.length > 0 &&
              (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.request.status)) === -1
            );
          case REQ_SORT_COL.REFERENCE:
            return (
              (value as IFilter[])?.length > 0 &&
              (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, getRequestId(tRequest.organization))) === -1
            );
          case REQ_SORT_COL.ORGANIZATION:
            return (
              (value as IFilter[])?.length > 0 &&
              (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.organization.id)) === -1
            );
          case REQ_SORT_COL.BRANCH:
            return (
              (value as IFilter[])?.length > 0 &&
              (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.organization.branch)) === -1
            );
          case REQ_SORT_COL.REQUEST:
            return (
              (value as IFilter[])?.length > 0 && (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.dsp.order_id)) === -1
            );
          case REQ_SORT_COL.DSP:
            return (value as IFilter[])?.length > 0 && (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.dsp.id)) === -1;
          case REQ_SORT_COL.CITY:
            return (
              (value as IFilter[])?.length > 0 &&
              (value as IFilter[]).findIndex((filter) => isEqAsString(filter.value, tRequest.organization.city)) === -1
            );
          default:
            return false;
        }
      })
    ) {
      return;
    }

    const trackingRequest: TTrackingRequest = fixTimeZone ? addTimeZoneToRequest(tRequest) : tRequest;

    this.trackingRequests.data = [trackingRequest, ...this.trackingRequests.data].slice(0, 10);
  };

  public getSingleRequest = flow(function* (this: ArchiveStore, id: string) {
    try {
      const response: TTrackingSingleRequest = yield client.get(`/v1/requests/${id}`, { params: {} });

      return addTimeZoneToRequest(trackingRequestCast(response));
    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  public getRequest = (id: string) => {
    this.requestLoading = true;

    try {
      const trackingRequest = this.trackingRequests.data.find((r) => r.request.id === id);
      if (!trackingRequest) return;

      this.trackingRequest = trackingRequestCast(trackingRequest);
    } catch (error) {
      console.error(error);
    } finally {
      this.requestLoading = false;
    }
  };

  public getRequestForAchivePopup = async (id: string) => {
    this.requestLoading = true;

    try {
      const trackingRequest = await this.getSingleRequest(id);
      if (!trackingRequest) return;

      this.trackingRequest = trackingRequestCast(trackingRequest);
    } catch (error) {
      console.error(error);
    } finally {
      this.requestLoading = false;
    }
  };

  setSorting = (field: REQ_SORT_COL, direction: SORT_DIR | undefined, clear: boolean) => {
    if (clear) {
      this.sort = defaultRequestsSorting;
    }
    this.sort[field] = direction;
    this.trackingRequests.pagination.current_page = 1;

    clearTimeout(this.requestsTimeoutId);
    this.requestsTimeoutId = setTimeout(() => {
      runInAction(async () => {
        await this.getRequests(0);
      });
    }, requestDelay);
  };

  prepareFilters() {
    const filters: any = {};
    const filter: any = {
      category: 'list',
    };
    const newWasSelected = this.wasSelected;
    for (const [key, value] of Object.entries(this.selectedFilters)) {
      if (key === REQ_SORT_COL.DATE) {
        if ((value as IDateFilter).from_date === null || (value as IDateFilter).to_date === null) {
          continue;
        }
        filter.gap = {
          start: moment((value as IDateFilter).from_date, 'D/M/Y').format('Y-MM-DD') + ' 00:00:00',
          end: moment((value as IDateFilter).to_date, 'D/M/Y').format('Y-MM-DD') + ' 23:59:59',
        };
        newWasSelected[REQ_SORT_COL.DATE] = true;
        continue;
      }

      if (key === REQ_SORT_COL.TIME) {
        if ((value as ITimeFilter).start === null || (value as ITimeFilter).end === null) {
          continue;
        }
        filter.time = {
          start: moment((value as ITimeFilter).start).format('HH:mm:ss'),
          end: moment((value as ITimeFilter).end).format('HH:mm:ss'),
        };
        newWasSelected[REQ_SORT_COL.TIME] = true;
        continue;
      }

      if ((value as IFilter[]).length <= 0) {
        newWasSelected[key as REQ_SORT_COL] = false;
        continue;
      }

      filters[key as REQ_SORT_COL] = (value as IFilter[]).map((item) => item.value);
      newWasSelected[key as REQ_SORT_COL] = true;
    }
    this.wasSelected = newWasSelected;
    return { filters, filter };
  }

  shouldUseCachedFiltersOptions = () => Date.now() - this.lastFilterOptionsRequestTime < 15 * 60 * 1000;

  clearFilterOption = (field: REQ_SORT_COL) => {
    // @ts-ignore
    this.filterOptions[field].options = defaultRequestsFilters[field];
  };

  searchFilterOptions = flow(function* (this: ArchiveStore, text: string, field: REQ_SORT_COL) {
    // Update filters no more than 15 minutes
    if (
      this.filterOptions[field].needCache &&
      this.filterOptions[field].lastUpdate > 0 &&
      Date.now() - this.filterOptions[field].lastUpdate < 15 * 60 * 1000
    ) {
      return;
    }
    this.filterOptions[field].lastUpdate = Date.now();

    this.filterOptions[field].loadingStatus = LoadingStatus.Loading;

    try {
      const { data: filterOptions }: { data: any[] } = yield client.get(`/v1/requests/filters/${field}`, {
        params: {
          page: 1,
          per_page: 10,
          search: text,
        },
      });

      this.filterOptions[field].options = filterOptions.map(({ name, value, active }: { name: any; value: any; active: any }) =>
        filterOptionCast({
          value,
          name: field === REQ_SORT_COL.STATUS ? localesStore.t(`${String(name).toLowerCase()}_status`) : name,
          active,
        }),
      );

      this.filterOptions[field].loadingStatus = LoadingStatus.Success;
    } catch (error) {
      console.error(error);
      this.filterOptions[field].loadingStatus = LoadingStatus.Error;
    }
  }).bind(this);

  setSelectedFilter = (field: REQ_SORT_COL | 'search', value: any) => {
    if (field === REQ_SORT_COL.DATE) {
      this.selectedFilters[field].from_date = value.from_date;
      this.selectedFilters[field].to_date = value.to_date;
    } else if (field === REQ_SORT_COL.TIME) {
      this.selectedFilters[field].start = value.start;
      this.selectedFilters[field].end = value.end;
    } else if (field === 'search') {
      this.selectedFilters.search = value;
    } else {
      const exists = this.selectedFilters[field].findIndex((filter) => filter.value === value) !== -1;

      if (exists) {
        this.selectedFilters[field] = this.selectedFilters[field].filter((filter) => filter.value !== value);
      } else {
        const newFilter = this.filterOptions[field].options.find((filter) => filter.value === value);
        if (newFilter) {
          this.selectedFilters[field].push(newFilter);
        }
      }
    }
    this.trackingRequests.pagination.current_page = 1;

    clearTimeout(this.requestsTimeoutId);
    this.requestsTimeoutId = setTimeout(async () => {
      await this.getRequests(0);
    }, requestDelay);
  };

  clearFilter = flow(function* (this: ArchiveStore, field: REQ_SORT_COL | 'search') {
    if (field === REQ_SORT_COL.DATE || field === REQ_SORT_COL.TIME) {
      this.selectedFilters[REQ_SORT_COL.DATE] = defaultRequestsFilters[REQ_SORT_COL.DATE];
      this.selectedFilters[REQ_SORT_COL.TIME] = defaultRequestsFilters[REQ_SORT_COL.TIME];
      this.wasSelected[REQ_SORT_COL.DATE] = false;
      this.wasSelected[REQ_SORT_COL.TIME] = false;
    } else if (field === 'search') {
      this.selectedFilters.search = '';
    } else {
      this.selectedFilters[field] = [] as any[];
      this.wasSelected[field] = false;
      yield this.searchFilterOptions('', field);
    }

    this.trackingRequests.pagination.current_page = 1;
    this.requestsTimeoutId = setTimeout(async () => {
      await this.getRequests(0);
    }, requestDelay);
    // await this.searchFilterOptions('', field);
  }).bind(this);

  public getRequests = flow(function* (this: ArchiveStore, page = 1) {
    this.requestsLoading = true;

    this.requestsAbortController.abort();
    const requestsAbortController = new AbortController();
    this.requestsAbortController = requestsAbortController;

    try {
      let body = {
        page,
        filter: {},
        filters: {},
        sort: this.sort,
        per_page: 10,
      };

      if (this.selectedFilters.search) {
        body.filter = { search: this.selectedFilters.search, category: 'list' };
      } else if (Object.keys(this.prepareFilters())?.length > 0) {
        body = {
          ...body,
          ...this.prepareFilters(),
        };
      } else {
        body.filter = { ...defaultRequestsFilters };
      }

      const requests: TTrackingPagination = yield client.post(`/v1/requests`, body, { signal: requestsAbortController.signal });

      this.trackingRequests = {
        ...requests,
        data: requests.data.slice(0, 10).map(trackingRequestCast).map(addTimeZoneToRequest),
      };

      if (this.trackingRequests.pagination.current_page !== page) {
        this.pages = [...this.pages, this.trackingRequests.pagination.current_page].sort((a, b) => (a > b ? 1 : -1));
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (!requestsAbortController.signal.aborted) {
        this.requestsLoading = false;
      }
    }
  }).bind(this);

  exportExcel = flow(function* (this: ArchiveStore) {
    const data = this.prepareFilters();
    if (!this.isExcelExportAvailable) {
      return;
    }
    this.isExcelExporting = true;
    try {
      const filterFieldsCount = Object.keys(data.filter).length + Object.keys(data.filters).length;

      if (Object.keys(data.filter).length === 0) {
        delete data.filter;
      }

      if (filterFieldsCount > 1) {
        yield downloadFile('/v1/export/requests', data);
      }
    } catch (error) {
      console.error(error);
      this.isExcelExporting = false;
    } finally {
      this.isExcelExporting = false;
    }
  }).bind(this);
}

const archiveStore = new ArchiveStore();
export default archiveStore;
