import { action, observable, makeObservable, flow } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import dnfStatisticsStore from '../driverNotFoundStore/DnfStatisticsStore';
import { IUser, userCast, USER_HINT_MESSAGES } from '~/types/user';
import { appStore, localesStore, messagesStore } from '~/mobx';
import { USER_ROLES } from '~/constants/user';
import client from '~/services/client';
import { booleanToNumber } from '~/utils/helpers';

const oneTimeNotifications: string[] = [];
export class UserStore {
  constructor() {
    makeObservable(this, {
      login: action,
      onLogin: action,
      changeHintMessage: action,
      logout: action,
      showOneTimeNotifications: action,
      isLogin: observable,
      user: observable,
      oneTimeNotifications: observable,
    });

    makePersistable(this, {
      storage: window.localStorage,
      name: 'UserStore',
      properties: ['isLogin', 'user', 'oneTimeNotifications'],
    })
      .then(() => {
        try {
          if (this.isLogin && this.user.token) {
            client.headers = {
              Authorization: `Bearer ${this.user.token}`,
            };
          } else if (this.isLogin) {
            this.logout();
          }
        } catch (error) {
          console.log(error);
        }
        return;
      })
      .catch(console.error);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  private initialUserObject: IUser = {
    id: '0',
    name: 'No Name',
    login: '',
    role: USER_ROLES.TG_OPERATION_MANAGER,
    role_txt: 'No Role',
    image: '/images/no-user.jpeg',
    token: 'test-token',
    permissions: [],
    organization_ids: [],
    hint_messages: {
      change_profile_branch_dispatching_rules: 0,
      draw_polygon: 0,
    },
  };

  oneTimeNotifications: string[] = [];
  public isLogin = false;
  public user: IUser = this.initialUserObject;
  // actions

  public showOneTimeNotifications = () => {
    oneTimeNotifications.map((oneTimeNotification) => {
      if (!this.oneTimeNotifications.includes(oneTimeNotification)) {
        messagesStore.addMessage({
          type: 'modal',
          message: localesStore.t(oneTimeNotification),
        });
        this.oneTimeNotifications.push(oneTimeNotification);
      }
    });
  };

  public login = flow(function* (this: UserStore, data: { login: string; password: string }) {
    try {
      const response = yield client.post('/v1/login', data);
      const user: IUser = userCast(response);

      this.onLogin(user);
      this.isLogin = true;
    } catch (error: any) {
      console.log(error);
      if (error.response && (error.response.status === 422 || error.response.status === 400 || error.response.status === 401)) {
        throw error.response.data;
      }
      throw error;
    }
  }).bind(this);

  onLogin = (user: IUser) => {
    this.user = user;
    this.user.permissions = [];
    this.user.organization_ids = this.user.organization_ids == null ? [] : this.user.organization_ids;
    this.user.image = '/images/no-user.jpeg';
    appStore.sidebar = true;
    client.headers = { Authorization: 'Bearer ' + this.user.token };
  };

  public logout = flow(function* (this: UserStore) {
    dnfStatisticsStore.statisticMapIsVisible = false;

    try {
      if (this.user.token !== this.initialUserObject.token) {
        yield client.post('/v1/logout');
      }
      this.isLogin = false;
      this.user = this.initialUserObject;
      client.headers = {
        Authorization: `Bearer` + this.user.token,
      };
      // TODO: call clear in all stores
    } catch (error) {
      console.log(error);
    }
  }).bind(this);

  public hasPermissions = (permissions: string[]): boolean => {
    if (!permissions.length || !this.user.permissions.length) return true;
    return permissions.some((permission) => this.user.permissions.includes(permission));
  };

  public hasRole = (roles: number[]): boolean => {
    if (!roles.length) return true;
    return roles.includes(this.user.role);
  };

  changeHintMessage = (key: USER_HINT_MESSAGES) => {
    try {
      if (key === USER_HINT_MESSAGES.EDIT_PROFILES) {
        this.user.hint_messages = {
          draw_polygon: this.user.hint_messages?.draw_polygon || 0,
          change_profile_branch_dispatching_rules: booleanToNumber(!this.user.hint_messages?.change_profile_branch_dispatching_rules),
        };
      } else if (key === USER_HINT_MESSAGES.DRAW_POLYGON) {
        this.user.hint_messages = {
          change_profile_branch_dispatching_rules: this.user.hint_messages?.change_profile_branch_dispatching_rules || 0,
          draw_polygon: booleanToNumber(!this.user.hint_messages?.draw_polygon),
        };
      }
      // const response: IUser['hint_messages'] = await client.post(`v3/hint-message/${key}/viewed`);
      // this.user.hint_messages = response;
    } catch (error: any) {
      if (key === USER_HINT_MESSAGES.EDIT_PROFILES) {
        this.user.hint_messages = {
          draw_polygon: this.user.hint_messages?.draw_polygon || 0,
          change_profile_branch_dispatching_rules: booleanToNumber(!this.user.hint_messages?.change_profile_branch_dispatching_rules),
        };
      } else if (key === USER_HINT_MESSAGES.DRAW_POLYGON) {
        this.user.hint_messages = {
          change_profile_branch_dispatching_rules: this.user.hint_messages?.change_profile_branch_dispatching_rules || 0,
          draw_polygon: booleanToNumber(!this.user.hint_messages?.draw_polygon),
        };
      }
      console.error({ error });
    }
  };
}

const userStore = new UserStore();
export default userStore;
