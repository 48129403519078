import './style.scss';

import { observer } from 'mobx-react';
import moment from 'moment';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button } from 'taker-web-ui';
import { ICONS } from '~/constants/icons';
import { timezones } from '~/constants/main';
import theme from '~/constants/styles';
import { deliveryZonesStore, localesStore } from '~/mobx';
import { IUnavailableZone } from '~/types/deliveryManagment';
import { IPickerOption } from '~/types/main';
import { confirmDelete, leaveWithoutSaving } from '~/utils/modalConfirmations';
import { throttle } from '~/utils/perfomance';
import Palette from '~/view/components/Controls/ColorPalette/ColorPalette';
import CustomPicker from '~/view/components/Controls/CustomPicker/CustomPicker';
import DateTimePicker from '~/view/components/Controls/DateTimePicker/DateTimePicker';
import Icon from '~/view/components/Controls/Icon/Icon';
import InputAlt from '~/view/components/Controls/InputAlt/InputAlt';
import TimePeriods from '~/view/components/Controls/TimePeriods/TimePeriods';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';

enum FORM_INPUTS {
  NAME = 'name',
  RADIUS = 'radius',
  EXTERNAL_ID = 'external_id',
}

interface Props {
  zone: IUnavailableZone;
  onCancel: (zone: IUnavailableZone) => void;
  onDelete: (id: string) => void;
  onSave: (zone: IUnavailableZone) => void;
}

export const DeliveryZonesForm = observer((props: Props) => {
  const [visibility] = useState('animate__fadeInRight');
  const [loading, setLoading] = useState<boolean>(false);

  const changeInputStatus = (event: ChangeEvent<HTMLInputElement>) => {
    if (!deliveryZonesStore.draft) return;

    // if (event.target.name === FORM_INPUTS.NAME) {
    //   // deliveryZonesStore.editDraftZone(FORM_INPUTS.NAME, {
    //   //   en: event.target.value,
    //   //   ar: event.target.value,
    //   // });
    //   // // update validation
    //   // if (event.target.value) deliveryZonesStore.setValidation();
    //   // update tooltip
    //   deliveryZonesStore.selectZone(deliveryZonesStore.draft);
    // } else {
    deliveryZonesStore.editDraftZone(event.target.name, event.target.value);
    // }
  };

  const changeZoneColor = (color: string) => {
    deliveryZonesStore.editDraftZone('color', color);
  };

  const deleteZone = async () => {
    try {
      setLoading(true);
      await deliveryZonesStore.deleteZone(props.zone.id);
      deliveryZonesStore.clearDraft();
    } finally {
      setLoading(false);
    }
  };
  const onDelete = useCallback(
    throttle(() => {
      confirmDelete('delete_zone_permanently', deleteZone, 'delete_zone');
    }, 1000),
    [props.zone],
  );

  const onCancel = useCallback(() => {
    leaveWithoutSaving(deliveryZonesStore.clearDraft);
  }, []);

  const errors = useMemo(
    () => ({
      date:
        moment(deliveryZonesStore.draft?.end_time).diff(moment(deliveryZonesStore.draft?.start_time), 'minutes') < 0 ||
        !deliveryZonesStore.draft?.start_time ||
        !deliveryZonesStore.draft?.end_time,
      name:
        deliveryZonesStore.pagination.data.findIndex(
          (zone) => zone.name.trim() === deliveryZonesStore.draft?.name.trim() && deliveryZonesStore.draft.id !== zone.id,
        ) !== -1 || !deliveryZonesStore.draft?.name,
    }),
    [deliveryZonesStore.draft?.name, deliveryZonesStore.draft?.start_time, deliveryZonesStore.draft?.end_time],
  );

  const onSubmit = throttle(async () => {
    try {
      // deliveryZonesStore.setValidation();

      // if (Object.values(deliveryZonesStore.validation).some((validation) => validation)) {
      //   scrollToErrorElement();
      //   return;
      // }
      if (
        errors.date ||
        errors.name ||
        !deliveryZonesStore.draft?.bounds?.length ||
        (deliveryZonesStore.draft?.bounds.length && deliveryZonesStore.draft?.bounds.length < 3)
      ) {
        return;
      }

      setLoading(true);
      if (!deliveryZonesStore.draft) return;
      // if id is not 0 that's mean zone is already exist
      if (deliveryZonesStore.draft?.id !== '0') {
        await deliveryZonesStore.updateZone(deliveryZonesStore.draft);
      } else {
        await deliveryZonesStore.createZone(deliveryZonesStore.draft);
      }
    } finally {
      setLoading(false);
    }
  }, 1000);

  const handleChangeTimezone = useCallback(([{ value }]: IPickerOption[]) => {
    // recalculate UTC time
    deliveryZonesStore.editDraftZone(
      'start_time',
      moment(deliveryZonesStore.draft?.start_time, 'YYYY-MM-DD HH:mm:ss')
        .add(60 * (deliveryZonesStore.draft?.timezone_offset ?? 0), 'minutes')
        .subtract(60 * Number(value), 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
    );

    deliveryZonesStore.editDraftZone(
      'end_time',
      moment(deliveryZonesStore.draft?.end_time, 'YYYY-MM-DD HH:mm:ss')
        .add(60 * (deliveryZonesStore.draft?.timezone_offset ?? 0), 'minutes')
        .subtract(60 * Number(value), 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
    );

    deliveryZonesStore.editDraftZone('timezone_offset', value);
  }, []);

  const handleChangeStartTime = useCallback((value: Date) => {
    deliveryZonesStore.editDraftZone(
      'start_time',
      moment(value)
        .subtract(60 * (deliveryZonesStore.draft?.timezone_offset ?? 0), 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
    );
  }, []);

  const handleChangeEndTime = useCallback((value: Date) => {
    deliveryZonesStore.editDraftZone(
      'end_time',
      moment(value)
        .subtract(60 * (deliveryZonesStore.draft?.timezone_offset ?? 0), 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
    );
  }, []);

  if (!deliveryZonesStore.draft) return null;

  return (
    <div className={`delivery-zone__form animate__animated animate__faster ${visibility}`}>
      <div className="d-flex flex-1 flex-column">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <span onClick={onCancel}>
            <Icon name={ICONS.Back} color={theme.colors.mainblue} className={'delivery-zone__icon-back cursor-pointer'} size={24} />
          </span>

          {deliveryZonesStore.draft.id !== '0' ? (
            <span onClick={onDelete}>
              <Icon name={ICONS.Trash} size={24} color={theme.colors.dark} className="cursor-pointer" />
            </span>
          ) : null}
        </div>

        <ScrollableBlock>
          <div className="d-flex align-items-center mb-4">
            <p className="text-size-20 family-bold text-elipsis ml-3">{deliveryZonesStore.draft.name}</p>
          </div>

          <InputAlt
            required
            className="bg-transparent"
            value={deliveryZonesStore.draft.name}
            onChange={changeInputStatus}
            name={FORM_INPUTS.NAME}
            placeholder={localesStore.t('name')}
            validation={{
              state: errors.name,
              errorText: deliveryZonesStore.draft?.name ? localesStore.t('zone_name_update_error') : '',
              styleType: 'bottom',
            }}
          />
          <div className="pt-2">
            <TimePeriods />
          </div>
          <DateTimePicker
            className="pt-3"
            disabled={false}
            error={errors.date}
            onDateChange={handleChangeStartTime}
            date={moment(deliveryZonesStore.draft.start_time)
              .add(60 * deliveryZonesStore.draft.timezone_offset, 'minutes')
              .toDate()}
          />
          <DateTimePicker
            className="pt-3"
            disabled={false}
            error={errors.date}
            onDateChange={handleChangeEndTime}
            date={moment(deliveryZonesStore.draft.end_time)
              .add(60 * deliveryZonesStore.draft.timezone_offset, 'minutes')
              .toDate()}
          />
          <CustomPicker
            className="mt-3"
            onChange={handleChangeTimezone}
            options={timezones as IPickerOption[]}
            selectedOptions={timezones.filter(({ value }) => value === String(deliveryZonesStore.draft?.timezone_offset))}
            defaultPlaceholder={localesStore.t('dsp_info')}
            loading={false}
            withResetIcon={false}
          />
          <div className="pt-2">
            <Palette onSelect={changeZoneColor} selected={deliveryZonesStore.draft.color} />
          </div>
        </ScrollableBlock>
      </div>
      <Button
        appearance="primary"
        loading={loading}
        disabled={loading}
        onClick={onSubmit}
        stretched
        className="mt-3"
        title={localesStore.t('txt_save')}
      />
    </div>
  );
});
