import { Component } from 'react';
import { ICONS } from '~/constants/icons';
import { SORT_DIR } from '~/constants/main';
import Icon from '~/view/components/Controls/Icon/Icon';
import './style.scss';

interface IProps {
  sortField?: string;
  currentField?: string;
  currentDir?: SORT_DIR;
  changeSorting: (sortField: string | undefined, sortDir: SORT_DIR | undefined) => void;
  children?: React.ReactNode;
}

export default class SortingButton extends Component<IProps, {}> {
  checkActive = () => {
    if (this.props.currentField === this.props.sortField) {
      return this.props.currentDir;
    } else return undefined;
  };

  changeSorting = () => {
    const { currentDir, sortField, changeSorting } = this.props;
    if (currentDir === SORT_DIR.DESC || !currentDir) {
      changeSorting(sortField, SORT_DIR.ASC);
    } else {
      changeSorting(sortField, SORT_DIR.DESC);
    }
  };

  render() {
    return (
      <button className="d-flex flex-row m-0 p-0" onClick={this.changeSorting}>
        {this.props.children}
        <div className="sorting-button__arrow ml-3">
          <Icon
            size={12}
            name={ICONS.ArrowHalf}
            className={`sorting-button__arrow-asc ${this.checkActive() === SORT_DIR.ASC ? 'sorting-button__arrow-asc_active' : ''}`}
          />
          <Icon
            size={12}
            name={ICONS.ArrowHalf}
            className={`sorting-button__arrow-desc ${this.checkActive() === SORT_DIR.DESC ? 'sorting-button__arrow-desc_active' : ''}`}
          />
        </div>
      </button>
    );
  }
}
