import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { ICONS } from '~/constants/icons';
import { SORT_DIR } from '~/constants/main';
import { COLORS } from '~/constants/styles';
import { localesStore, logStore, archiveStore } from '~/mobx';
import { IDateFilter } from '~/types/log';
import { LoadingStatus } from '~/types/main';
import { REQ_SORT_COL } from '~/types/requests';
import theme from '~/utils/styles';
import ButtonOld from '~/view/components/Controls/ButtonOld/ButtonOld';
import Icon from '~/view/components/Controls/Icon/Icon';
import SortingButton from '~/view/components/Controls/SortingButton/SortingButton';
import ModalPopup from '~/view/components/ModalPopup/ModalPopup';
import ColumnCalendarFilter from '~/view/components/Table/components/ColumnCalendarFilter/ColumnCalendarFilter';
import ColumnTimeFilter from '~/view/components/Table/components/ColumnTimeFilter';
import ColumnValueFilter from '~/view/components/Table/components/ColumnValueFilter/ColumnValueFilter';
import Text from '~/view/components/Typo/Text/Text';
import './style.scss';

interface IProps {
  className: string;
  itemName: string;
  withFilter?: boolean;
  columnName: REQ_SORT_COL;
  preventLoadOnShow?: boolean;
  truncateItemName?: boolean;
}

type State = {
  selectedDates: null | Date[];
  selectedTime: null | Date[];
  modalType: null | 'date' | 'time';
};

export const DeliveryRequestsTableHeaderItem = observer(
  class DeliveryRequestsTableHeaderItem extends React.Component<IProps, State> {
    state = {
      selectedDates: null,
      modalType: null,
      selectedTime: null,
    };

    modal: ModalPopup | null = null;

    closeModalMessage = () => {
      this.modal?.closeModalPopup();
    };

    changeSorting = (sortField: any, sortDir: SORT_DIR | undefined) => {
      archiveStore.setSorting(sortField, sortDir, true);
    };

    onFilterSelected = (selected: any) => {
      if (this.props.columnName) {
        archiveStore.setSelectedFilter(this.props.columnName, selected);
      }
    };

    onDateFilterSelected = (dates: Date[]) => {
      if (dates.length < 2) {
        return;
      }

      this.setState({ selectedDates: dates }, () => {
        archiveStore.setSelectedFilter(this.props.columnName, {
          from_date: moment(dates[0]).format('D/M/Y'),
          to_date: moment(dates[1]).format('D/M/Y'),
        } as IDateFilter);
      });
    };

    handleChangeTimeFilter = (from: Date, to: Date) => {
      this.setState({ selectedTime: [from, to] }, () => {
        archiveStore.setSelectedFilter(REQ_SORT_COL.TIME, {
          start: from,
          end: to,
        });
      });
    };

    clearFilter = async () => {
      await archiveStore.clearFilter(this.props.columnName);

      if (this.state.selectedDates) {
        this.setState({ selectedDates: null });
      }
    };

    isActiveFilter = (): boolean => {
      if (!this.props.withFilter) {
        return false;
      }
      if (this.props.columnName === REQ_SORT_COL.DATE) {
        return (
          archiveStore.selectedFilters[this.props.columnName].from_date !== null ||
          archiveStore.selectedFilters[this.props.columnName].to_date !== null ||
          archiveStore.selectedFilters[REQ_SORT_COL.TIME].start !== null ||
          archiveStore.selectedFilters[REQ_SORT_COL.TIME].end !== null
        );
      }
      if (this.props.columnName === REQ_SORT_COL.TIME) {
        return archiveStore.selectedFilters[this.props.columnName].start !== null || archiveStore.selectedFilters[this.props.columnName].end !== null;
      }
      return archiveStore.selectedFilters[this.props.columnName].length > 0;
    };

    handleSearch = (value: string) => {
      const { columnName } = this.props;
      if (!archiveStore.filterOptions[columnName].needCache && !value) {
        archiveStore.clearFilterOption(columnName);
        return;
      }
      archiveStore.searchFilterOptions(value, columnName);
    };

    renderCalendarBottom = () => (
      <div className="calendar-filter-bottom">
        <Text size={14}>{localesStore.t('calendar_max_period')}</Text>
      </div>
    );

    handleOpenModal = (event: React.MouseEvent<HTMLElement>) => {
      if (this.props.preventLoadOnShow) {
        archiveStore.clearFilterOption(this.props.columnName);
      }

      archiveStore.setLockedPopupAnotherArea(true);

      switch (event.currentTarget.getAttribute('data-type')) {
        case 'date':
          this.setState({ modalType: 'date' }, () => this.modal?.openModalPopup());
          break;
        case 'time':
          this.setState({ modalType: 'time' }, () => this.modal?.openModalPopup());
          break;
        default:
          this.modal?.openModalPopup();
      }
    };

    handleCloseModal = () => {
      this.setState({ modalType: null }, () => {
        this.modal?.closeModalPopup();
      });
    };

    render() {
      const { columnName, className, itemName, withFilter } = this.props;
      const { modalType, selectedDates, selectedTime } = this.state;

      if (!archiveStore.filterOptions[columnName] && columnName !== REQ_SORT_COL.DATE) {
        return null;
      }

      return (
        <th>
          <div className={className}>
            <SortingButton
              sortField={columnName}
              currentField={columnName}
              currentDir={archiveStore.sort[columnName]}
              changeSorting={this.changeSorting}>
              <p className="family-medium color-gray2 text-size-12 text-uppercase letter-spacing-12 text-elipsis">{itemName}</p>
            </SortingButton>
            {withFilter ? (
              <ModalPopup
                autoClose
                vertical={'bottom'}
                horizontal={columnName === REQ_SORT_COL.DATE ? 'right' : 'center'}
                ref={(modal) => (this.modal = modal)}
                wrapperClassName="filter-popup d-flex align-items-center justify-content-start"
                onClose={() => {
                  archiveStore.setLockedPopupAnotherArea(false);
                }}
                content={
                  <>
                    {columnName === REQ_SORT_COL.DATE && modalType === 'date' && (
                      <ColumnCalendarFilter
                        close={this.closeModalMessage}
                        values={logStore.selectedFilters[columnName]}
                        rawValues={selectedDates}
                        onChange={this.onDateFilterSelected}
                        bottom={
                          <div className="calendar-filter-bottom">
                            <Text color={COLORS.GRAY2} size={14}>
                              {localesStore.t('calendar_max_period')}
                            </Text>
                          </div>
                        }
                        maxPeriod={90}
                      />
                    )}
                    {columnName === REQ_SORT_COL.DATE && modalType === 'time' && (
                      <ColumnTimeFilter
                        onChange={this.handleChangeTimeFilter}
                        timeFrom={selectedTime?.[0] || new Date('01-01-1970 00:00:00')}
                        timeTo={selectedTime?.[1] || new Date('01-01-1970 23:59:00')}
                      />
                    )}
                    {columnName !== REQ_SORT_COL.DATE && columnName !== REQ_SORT_COL.TIME && (
                      <ColumnValueFilter
                        values={archiveStore.filterOptions[columnName].options}
                        selected={archiveStore.selectedFilters[columnName]}
                        onSelect={this.onFilterSelected}
                        close={this.closeModalMessage}
                        onSearch={this.handleSearch}
                        loading={archiveStore.filterOptions[columnName].loadingStatus === LoadingStatus.Loading}
                        preventLoadOnShow={this.props.preventLoadOnShow}
                        truncateItemName={this.props.truncateItemName}
                      />
                    )}
                  </>
                }>
                <ButtonOld
                  data-type="date"
                  className={`filter-dropdown-btn ml-3 mx-2 ${columnName === REQ_SORT_COL.DATE ? 'filter-dropdown-btn__transparent' : ''}`}
                  onClick={this.handleOpenModal}>
                  {columnName === REQ_SORT_COL.DATE ? (
                    <Icon name={ICONS.Calendar} size={18} color={theme.colors.mainblue} />
                  ) : (
                    <Icon name={ICONS.Chevron} size={16} color={theme.colors.dark} />
                  )}
                </ButtonOld>
                {/* TOFIX refactor this */}
                {columnName === REQ_SORT_COL.DATE && (
                  <ButtonOld
                    data-type="time"
                    // eslint-disable-next-line max-len
                    className={`d-flex align-items-center filter-dropdown-btn ml-1 mr-3 ${
                      columnName === REQ_SORT_COL.DATE ? 'filter-dropdown-btn__transparent' : ''
                    }`}
                    onClick={this.handleOpenModal}>
                    <Icon name={ICONS.Clock} size={24} color={theme.colors.mainblue} className="pl-1" />
                  </ButtonOld>
                )}
                <div className="filter-dropdown-btn__clear__wrapper">
                  {this.isActiveFilter() ? (
                    <ButtonOld className="filter-dropdown-btn__clear" onClick={this.clearFilter}>
                      <Icon name={ICONS.Close} size={11} color={theme.colors.dark} />
                    </ButtonOld>
                  ) : null}
                </div>
              </ModalPopup>
            ) : null}
          </div>
        </th>
      );
    }
  },
);
