import './style.scss';

import { useCallback } from 'react';
import { DSP_TYPE } from '~/constants/dsp';

// import { appStore } from '~/mobx';

interface Props {
  label: string;
  onPress: (value: Props['value']) => void;
  active?: boolean;
  value: any;
  type?: DSP_TYPE;
  count?: number;
}

export const TabLabel = (props: Props) => {
  const handlePress = useCallback(() => {
    props.onPress(props.value);
  }, [props.value]);
  return (
    <div className={'tab-label__with_counter'}>
      {Boolean(props.count) && <div className={'counter'}>{props.count! > 9 ? '9+' : props.count}</div>}
      <button className={`tab-label mr-3 ${props.active ? 'active' : ''}`} onClick={handlePress}>
        {props.label}
      </button>
    </div>
  );
};
