import { USER_PERMISSIONS, USER_ROLES } from '~/constants/user';
import ExternalOpenButton from '~/view/screens/DeliveryTrackingScreen/components/ExternalOpenButton/ExternalOpenButton';

type Props = {
  title: string;
  value: string;
  url?: string | null;
};

const Block = ({ title, value, url }: Props) => (
  <div>
    <div className={'text-size-10 text-uppercase family-semibold'}>{title}</div>
    <div className={'text-size-16 family-semibold'}>
      {value}
      <span className={'ml-2 external-open-button-content'}>
        <ExternalOpenButton
          url={url}
          roles={[USER_ROLES.TG_OPERATION_MANAGER, USER_ROLES.ORG_MANAGER, USER_ROLES.TG_OPERATOR]}
          permissions={USER_PERMISSIONS.DASHBOARD}
        />
      </span>
    </div>
  </div>
);

export default Block;
