import React, { Component } from 'react';
import './style.scss';

interface Option {
  label: string;
  value: any;
}
interface Props {
  disabled?: boolean;
  options: Option[];
  value: Option['value'];
  onChange: (value: Option['value']) => void;
  error?: boolean;
}
export default class TabPicker extends Component<Props, {}> {
  onChange = (status: Option['value']) => {
    if (this.props.disabled) return null;
    this.props.onChange(status);
  };

  renderTab = (tab: Option) => (
    <div
      key={tab.value}
      onClick={() => this.onChange(tab.value)}
      className={`tab-picker--tab bg_active-gray color_active-dark  color-gray2   ${this.props.value === tab.value ? 'active' : ''}`}
    >
      <span className="text-size-16">{tab.label}</span>
    </div>
  );

  render() {
    return (
      <div aria-disabled={this.props.disabled} className={`tab-picker border-gray ${this.props.error ? 'tab-picker-error' : ''}`}>
        {this.props.options.map(this.renderTab)}
      </div>
    );
  }
}
