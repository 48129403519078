import * as React from 'react';
import { ICONS } from '~/constants/icons';
import { localesStore } from '~/mobx';
import { IFilter } from '~/types/log';
import { truncateLongString } from '~/utils/formatter';
import theme from '~/utils/styles';
import Icon from '~/view/components/Controls/Icon/Icon';
import SearchInput from '~/view/components/Controls/SearchInput/SearchInput';
import ScrollableBlock from '~/view/containers/ScrollableBlock/ScrollableBlock';
import './style.scss';

interface IProps {
  values?: IFilter[];
  selected?: any[];
  onSelect?: (selected: any) => void;
  onSearch?: (search: string) => void;
  close: () => void;
  loading?: boolean;
  preventLoadOnShow?: boolean;
  truncateItemName?: boolean;
}

interface IState {
  search: string;
}

export default class ColumnValueFilter extends React.Component<IProps, IState> {
  state: IState = {
    search: '',
  };

  componentDidMount() {
    const { onSearch = () => undefined, preventLoadOnShow } = this.props;
    if (!preventLoadOnShow) onSearch(this.state.search);
  }

  handleSelect = (value: any) => {
    const { onSelect = () => undefined } = this.props;
    onSelect(value);
  };

  renderValue = (item: IFilter, active: boolean) => {
    const { truncateItemName } = this.props;

    return (
      <div key={`filter-${item.name}-${item.value}`} className="filter-dropdown-item" onClick={() => this.handleSelect(item.value)}>
        <Icon name={ICONS.Check} color={theme.colors.mainblue} size={14} className={`filter-dropdown-item__icon ${active ? '' : 'hidden'}`} />
        {truncateItemName ? truncateLongString(item.name) : item.name}
      </div>
    );
  };

  handleChangeText = (search: string) => {
    const { onSearch = () => undefined } = this.props;
    this.setState({ search }, () => onSearch(search));
  };

  public render() {
    const { loading = false, values = [], selected = [] } = this.props;
    const { search } = this.state;

    const selectedValues = selected?.map(({ value }) => value);
    // Spread need for copy mobx data and disable reactions
    const selectedItems = [...selected]
      .sort((a, b) => String(a.name).localeCompare(String(b.name)))
      .filter(({ name = '' }) => String(name).toLowerCase().includes(search.toLocaleLowerCase()));

    const filteredItems = values
      .filter(({ value, name }) => String(name).toLowerCase().includes(search.toLocaleLowerCase()) && !selectedValues.includes(value))
      .sort((a, b) => +selectedValues.includes(b.value) - (+selectedValues.includes(a.value) || String(b.name).localeCompare(String(a.name))));

    return (
      <div className="filter-dropdown-modal">
        <SearchInput
          className="input-wrapper color-gray5 w-100 filter-dropdown-modal_search-input bg-transparent border-transparent"
          onChangeText={this.handleChangeText}
          placeholder={localesStore.t('search_box')}
          customPlaceholder
          withCloseIcon
          loading={loading}
          debounceTime={700}
        />
        {search.length >= 0 || selectedItems.length > 0 ? (
          <div className="filter-dropdown-modal__values-wrapper">
            {(filteredItems.length > 0 || selectedItems.length > 0) && !loading && (
              <ScrollableBlock className={'pos-relative'}>
                {selectedItems.map((value) => this.renderValue(value, true))}
                {filteredItems.map((value) => this.renderValue(value, false))}
              </ScrollableBlock>
            )}
            {filteredItems.length === 0 && selectedItems.length === 0 && !loading && search && (
              <div className="d-flex align-items-center justify-content-center full-height filter-dropdown-modal__no-matches text-center pb-3">
                {localesStore.t('no_matches')}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
