export const USER_PERMISSIONS = {
  DASHBOARD: ['dashboard'],
  DSP_MANAGEMENT: ['dsp_management'],
  LOG: ['log'],
};

export enum USER_ROLES {
  TG_OPERATION_MANAGER = 1,
  ORG_MANAGER = 2,
  DSP_MANAGER = 3,
  TG_CLIENT = 4,
  TG_OPERATOR = 6,
}
// const PERMISSION_MATRIX = {
// // A R C H I V E
// /**
//                                    | D |   |   |
//                                    | A |   |   |
//                                    | S | T | A |
//                                    | H | R | R |
//                                    | B | A | C |
//                                    | O | K | H |
//                                    | A | I | I |
//                                    | R | N | V |
//                                    | D | G | E |*/
// /* DASHBOARD ────────────────────────────────┐
//    DASHBOARD ────────────────────────────┐   │
//    DASHBOARD ────────────────────────┐   │   │ */
// [USER_ROLES.TG_OPERATION_MANAGER]: [ 1,  0,  1 ],
//          [USER_ROLES.ORG_MANAGER]: [ 1,  0,  1 ],
//          [USER_ROLES.DSP_MANAGER]: [ 1,  0,  1 ],
//            [USER_ROLES.TG_CLIENT]: [ 1,  0,  1 ],
//          [USER_ROLES.TG_OPERATOR]: [ 1,  0,  1 ],
// }
