import { Provider } from 'mobx-react';
import React from 'react';
import mainStore from './mobx/mainStore';
import { GoogleMapsProvider } from './utils/GoogleMapsProvider';
import Routers from './view/routers';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const googleMapsLibraries: ('places' | 'geometry' | 'drawing' | 'localContext' | 'visualization')[] = ['places', 'geometry', 'drawing'];

export default class App extends React.Component<{}> {
  public render() {
    return (
      <Provider {...mainStore}>
        <GoogleMapsProvider googleMapsApiKey={googleMapsApiKey} language="en" libraries={googleMapsLibraries}>
          <Routers />
        </GoogleMapsProvider>
      </Provider>
    );
  }
}
