import React from 'react';
import { observer } from 'mobx-react';
import { deliveryZonesStore } from '~/mobx';
import { Polygon } from '~/view/components/Map2/Polygon/Polygon';

const DeliveryZonesMapAreas = () => {
  return (
    <>
      {deliveryZonesStore.pagination.data
        .filter(
          (zone) =>
            zone.id !== deliveryZonesStore.draft?.id &&
            (!deliveryZonesStore.filters.search || zone.name.toLocaleLowerCase().includes(deliveryZonesStore.filters.search.toLocaleLowerCase())),
        )
        .reverse()
        .map((zone, index) => (
          <Polygon
            index={index}
            onClick={() => {
              deliveryZonesStore.selectZone(zone);
            }}
            color={deliveryZonesStore.getColor(zone)}
            paths={zone.bounds}
          />
        ))}
    </>
  );
};

export default observer(DeliveryZonesMapAreas);
