import './style.scss';

import React, { Component, ReactElement, RefObject } from 'react';
import ReactDOM from 'react-dom';

import DropDownPortal from '~/view/components/DropDownPortal/DropDownPortal';

interface IPopupModalListProps {
  content: ReactElement | null;
  contentClassName: string;
  wrapperClassName: string;
}

export interface IPopupModalProps extends IPopupModalListProps {
  content: IPopupModalListProps['content'];
  // selfClose?: boolean;
  // direction?: 'left' | 'right' | 'up' | 'down';
  // position?: 'left' | 'right' | 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right' | 'start' | 'end';
  vertical: 'bottom' | 'center' | 'top';
  offset: number;

  autoClose?: boolean;
  callAfterClose: any;
  // align?: 'center';
  // justify?: 'center' | 'start' | 'end';
  // backdrop?: boolean;
  // visible?: boolean;
  // mobile?: {
  //   popup?: boolean;
  //   sheet?: boolean;
  // };
  onOpen?: () => void;
  onClose?: () => void;
  // className?: string;
  children?: React.ReactNode;
}

type Visibility = 'visible' | 'hidden' | 'default';

interface ModalPopupState {
  visibility: Visibility;
  rect: DOMRect | null | undefined;
}
export default class ModalPopup extends Component<IPopupModalProps, ModalPopupState> {
  static defaultProps: IPopupModalProps = {
    offset: 15,
    horizontal: 'start',
    vertical: 'bottom',
    content: null,
    contentClassName: '',
    wrapperClassName: '',
    callAfterClose: null,
  };

  wrapper: RefObject<HTMLDivElement> = React.createRef();
  content: RefObject<HTMLDivElement> = React.createRef();

  state: ModalPopupState = {
    visibility: 'default',
    rect: null,
  };

  public closeModalPopup = () => {
    this.setState({ rect: null }, this.onClose);
  };
  public openModalPopup = () => {
    if (this.state.rect === null) {
      return this.setState({ rect: this.wrapper.current?.getBoundingClientRect() }, this.onOpen);
    }
    this.closeModalPopup();
  };

  onOpen = () => {
    this.setState({ rect: this.wrapper.current?.getBoundingClientRect() });
    window.addEventListener('click', this.handleWindowClick);
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    window.removeEventListener('click', this.handleWindowClick);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleWindowClick);
  };

  handleWindowClick = (event: MouseEvent) => {
    if (!this.props.autoClose) return;
    if (this.wrapper.current === event.target || this.content.current === event.target) return;
    // @ts-ignore
    if (this.wrapper.current?.contains(event.target) || this.content.current?.contains(event.target)) {
      return;
    }

    const ignoreClasses = [
      'react-calendar__year-view',
      'react-calendar__year-view__months',
      'react-calendar__month-view',
      'react-calendar__decade-view',
      'react-calendar__century-view',
    ];

    // @ts-ignore
    const targetClassName = ReactDOM.findDOMNode(event.target)?.parentNode?.parentNode?.className;
    if (ignoreClasses.includes(targetClassName)) {
      return;
    }

    // @ts-ignore
    if (event.target?.className.includes('filter-dropdown-item')) return;

    this.closeModalPopup();
  };

  getContentPosition = () => {
    const style: any = {};
    if (!this.state.rect || !this.content.current) return style;

    if (this.props.horizontal === 'left') {
      style.left = this.state.rect.x - this.props.offset - this.content.current.clientWidth;
    } else if (this.props.horizontal === 'right') {
      style.left = this.state.rect.x + this.state.rect.width + this.props.offset;
    } else if (this.props.horizontal === 'center') {
      style.left = this.state.rect.x + this.state.rect.width / 2 - this.content.current.clientWidth / 2;
    } else if (this.props.horizontal === 'start') {
      style.left = this.state.rect.x;
    } else if (this.props.horizontal === 'end') {
      style.left = this.state.rect.x + this.state.rect.width - this.content.current.clientWidth;
    }

    // vertical top
    if (this.props.vertical === 'top') {
      // if does not fit on top
      if (this.content.current.offsetHeight + this.state.rect.height + this.props.offset > this.state.rect.y) {
        // if does not fit on top and bottom
        if (this.state.rect.y + this.state.rect.height + this.content.current.offsetHeight > window.innerHeight) {
          style.top = 1; // top from of screen edge
        } else {
          style.top = this.state.rect.y + this.state.rect.height + this.props.offset; // bottom
        }
      } else {
        style.top = this.state.rect.y - this.content.current.offsetHeight - this.props.offset; // top
      }
    }

    // vertical bottom
    else if (this.props.vertical === 'bottom') {
      // if does not fit on bottom
      if (this.state.rect.y + this.state.rect.height + this.content.current.offsetHeight + this.props.offset > window.innerHeight) {
        // if does not fit on bottom and top
        if (this.content.current.offsetHeight + this.state.rect.height > this.state.rect.y + this.state.rect.height) {
          style.bottom = 1; // bottom from of screen edge
        } else {
          style.top = this.state.rect.y - this.content.current.offsetHeight - this.props.offset; // top
        }
      } else {
        style.top = this.state.rect.y + this.state.rect.height + this.props.offset; // bottom
      }
    }

    // vertical center
    else if (this.props.vertical === 'center') {
      // if does not fit on bottom
      if (this.state.rect.y + this.state.rect.height / 2 - this.content.current.offsetHeight / 2 < this.state.rect.height / 2) {
        style.top = 1; // top from of screen edge
      }
      // if does not fit on top
      else if (this.state.rect.y + this.content.current.offsetHeight / 2 + this.state.rect.height > window.innerHeight) {
        style.bottom = 1; // bottom from of screen edge
      } else {
        style.top = this.state.rect.y + this.state.rect.height / 2 - this.content.current.offsetHeight / 2; // center
      }
    }

    // handle window overflow x
    if (this.state.rect.x + this.content.current.offsetWidth > window.innerWidth) {
      style.left = this.state.rect.x + this.state.rect.width - this.content.current.clientWidth; // end
    }
    if (this.content.current.offsetWidth - this.state.rect.x > 0) {
      style.left = this.state.rect.x; // start
    }
    return style;
  };

  render() {
    return (
      <React.Fragment>
        <div ref={this.wrapper} className={`${this.props.wrapperClassName}`}>
          {this.props.children}
        </div>
        <DropDownPortal>
          {this.state.rect ? (
            <div ref={this.content} className={`modal-popup ${this.props.contentClassName}`} style={this.getContentPosition()}>
              {this.props.content}
            </div>
          ) : null}
        </DropDownPortal>
      </React.Fragment>
    );
  }
}
