import './style.scss';

import { observer } from 'mobx-react';
import { Component } from 'react';
import DspListItem from '~/view/screens/DspManagementScreen/components/DspListItem/DspListItem';
import DspHeader from '~/view/screens/DspManagementScreen/containers/DspHeader/DspHeader';
import { dspStore } from '~/mobx';
import { IDsp } from '~/types/dsp';
import ScrollableList from '~/view/components/ScrollableList/ScrollableList';
import { RouterComponentProps } from '~/types/main';
import { withRouter } from '~/utils';

interface IProps extends RouterComponentProps<{ id: string; type: string }, IDsp> {
  selectedId?: string;
  onSelect: (id: string) => void;
}

class DspList extends Component<IProps> {
  componentDidMount() {
    const selectedDsp = dspStore.dsps.find((dsp) => dsp.id === this.props.selectedId);

    if (!selectedDsp) return;

    dspStore.setActiveDsp(selectedDsp);

    this.openDsp(selectedDsp);
  }

  openDsp = (dsp: IDsp) => {
    dspStore.getDsp(dsp.id);

    this.props.onSelect(dsp.id);

    this.forceUpdate();
  };

  renderDspItem = (dsp: IDsp | undefined, index: number) => {
    const isDefaultSelected = this.props.selectedId === dsp?.id;

    return <DspListItem selected={isDefaultSelected} onSelect={this.openDsp} key={dsp?.id || index} index={index} dsp={dsp} />;
  };

  render() {
    return (
      <div className="dsp-list d-flex flex-column">
        <DspHeader />
        <ScrollableList
          listClassName={''}
          autoFetch={false}
          fetch={dspStore.getDsps}
          list={dspStore.dsps}
          loading={dspStore.dspsLoading}
          current_page={0}
          last_page={0}
          renderItem={this.renderDspItem}
          scrollToTop
          pages={[1]}
          scrollbar={true}
          scrollbarClassName="dsp-list__scrollbar"
          emptyListText=""
        />
      </div>
    );
  }
}

export default withRouter(observer(DspList));
