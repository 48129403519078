import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DnfModal from '../Modals/DnfModal/DnfModal';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';
import { modalStore } from '~/mobx';
import ArchiveRequestInfoModal from '~/view/containers/Modals/ArchiveRequestInfoModal/ArchiveRequestInfoModal';
import RequestInfoModal from '~/view/containers/Modals/RequestInfoModal/RequestInfoModal';
import TestModal from '~/view/containers/Modals/TestModal/TestModal';

class ModalsContainer extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        {/* TODO: PUT MODALS HERE */}
        {modalStore.requestInfoModal ? <RequestInfoModal /> : null}
        {modalStore.archiveRequestInfoModal ? <ArchiveRequestInfoModal /> : null}
        {modalStore.dnfModal ? <DnfModal /> : null}
        {modalStore.errorModal ? <ErrorModal /> : null}
        {modalStore.testModal ? <TestModal /> : null}
      </React.Fragment>
    );
  }
}

export default observer(ModalsContainer);
