import { observer } from 'mobx-react';
import { Component } from 'react';
import DeliveryRequestsTableBodyRow from './DeliveryRequestsTableBodyRow';
import { TTrackingRequest } from '~/constants/tracking';

type Props = {
  data: TTrackingRequest[];
};

export const DeliveryRequestsTableBody = observer(
  class DeliveryRequestsTableBody extends Component<Props> {
    render() {
      return (
        <tbody>
          {this.props.data.map((deliveryRequestItem: TTrackingRequest) => (
            <DeliveryRequestsTableBodyRow key={deliveryRequestItem.request.id} deliveryRequestItem={deliveryRequestItem} />
          ))}
        </tbody>
      );
    }
  },
);
