// import { CoordsBounds } from '~/types/main';

export type Coords = {
  lat: number;
  lng: number;
};

export const unavailableZonesCast = (zone: any) => ({
  name: String(zone.name),
  bounds: zone.bounds.map((bound: any) => ({ lat: parseFloat(bound.lat), lng: parseFloat(bound.lng) })) as google.maps.LatLngLiteral[],
  color: String(zone.color),
  start_time: String(zone.start_time),
  end_time: String(zone.end_time),
  timezone_offset: parseInt(zone.timezone_offset, 10),
  id: String(zone.id),
});

export type IUnavailableZone = ReturnType<typeof unavailableZonesCast>;
