import React, { ReactElement } from 'react';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import './style.scss';

interface IButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  style?: React.CSSProperties;
  className?: string;
  titleClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  spinnerColor?: string;
  fullSize?: boolean;
  children?: React.ReactNode;
}

const ButtonOld = ({
  title = 'button',
  style,
  className = '',
  titleClassName,
  disabled = false,
  loading = false,
  fullSize = false,
  children = null,
  spinnerColor = '#ffffff',
  onClick,
  ...props
}: IButtonProps): ReactElement => {
  let button: null | HTMLButtonElement = null;
  const handleClick = (event: any) => {
    if (disabled || loading) {
      if (button && button.contains(event.target)) {
        event.stopPropagation();
      }
    }
  };

  return (
    <button
      {...props}
      ref={(ref) => (button = ref)}
      onClick={onClick}
      className={`button_old ${className} ${loading ? 'loading' : ''} ${disabled ? 'button_old-disabled' : ''} ${fullSize ? 'full-size' : ''}`}
      style={style}
      disabled={disabled || loading}>
      <div
        className={titleClassName}
        onClick={handleClick}
        style={
          {
            // opacity: loading ? 0 : 1, // keeps button width the same when spinner is showing
          }
        }>
        {children || title}
      </div>
      {loading && <Spinner size={24} className="button_old-spinner" color={spinnerColor || '#ffffff'} />}
    </button>
  );
};

export default ButtonOld;
