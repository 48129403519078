import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _package from '~/../package.json';
import { isObject, getTimeZoneDiffInHours } from '~/utils/helpers';
import { userStore } from '~/mobx';

class Axios {
  private defaultHeaders = {
    'Content-Type': 'application/json',
    // Accept: 'application/json',
    // 'x-device-id': 'taker_staff',
    // 'Content-Type': 'application/json'
    // 'Access-Control-Allow-Origin': '*',
    Timezone: String(getTimeZoneDiffInHours()),
    // Authorization: 'Bearer ' + userStore.user.token ?? null,
  };

  private errorHandler(options: any, error: AxiosError) {
    if (error.response && error.response.status === 401) {
      // Do not call logout method if we trying to login
      if (options.url !== '/v3/auth/login') {
        userStore.logout();
      }
      //   userStore.logout();
      // mainStore.userStore.logout();
      // TODO: redirect to root
    }

    throw error;
  }

  private transformRequest = (data = {}) => {
    if (!isObject(data) || !Object.keys(data).length) return JSON.stringify({});
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value === undefined) return acc;
      return { ...acc, [key]: value };
    }, {});
    return JSON.stringify(data);
  };

  private paramsSerializer = (data = {}): string => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (!isObject(data)) return `${data}`;
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value === undefined || value === '') return acc;
      return { ...acc, [key]: value };
    }, {});
    return qs.stringify(data, { arrayFormat: 'brackets' });
  };

  private client = axios.create({
    timeout: 30000,
    headers: this.defaultHeaders,
    // withCredentials: true,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    baseURL: process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_API}` : '',
    transformRequest: this.transformRequest,
    paramsSerializer: this.paramsSerializer,
  });

  public get headers() {
    return this.defaultHeaders;
  }

  public set headers(headers: AxiosRequestConfig['headers']) {
    this.defaultHeaders = {
      ...this.defaultHeaders,
      ...headers,
    };
    this.client.defaults.headers = {
      ...this.client.defaults.headers,
      ...headers,
    };
  }

  private endpoint(url: string) {
    if (url.includes('http') || url.includes('https')) return url;
    if (url[0] !== '/') url = `/${url}`;

    switch (process.env.NODE_ENV) {
      case 'test':
        if (_package.proxy[_package.proxy.length - 1] === '/') _package.proxy = _package.proxy.slice(0, _package.proxy.length - 1);
        url = `${_package.proxy}${url}`;
        break;
      // case 'development':
      case 'production':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url = `${process.env.REACT_APP_API}${url}`;
        break;

      default:
        url = `${window.location.origin}${url}`;
        break;
    }

    return url;
  }

  async get(url: string, options?: AxiosRequestConfig) {
    try {
      const endpoint = this.endpoint(url);
      const response = await this.client.get(endpoint, options);

      if (options?.responseType === 'blob') {
        return response;
      }

      return response.data;
    } catch (error: any) {
      console.log(error);
      this.errorHandler({ url, options }, error);
    }
  }

  async patch(url: string, body: any = {}, options?: AxiosRequestConfig) {
    try {
      const endpoint = this.endpoint(url);
      const response = await this.client.patch(endpoint, body, options);
      return response.data;
    } catch (error: any) {
      console.log(error);
      this.errorHandler({ url, body, options }, error);
    }
  }

  async post(url: string, body: any = {}, options?: AxiosRequestConfig) {
    try {
      const endpoint = this.endpoint(url);
      const response = await this.client.post(endpoint, body, options);
      return response.data;
    } catch (error: any) {
      console.log(error);
      this.errorHandler({ url, body, options }, error);
    }
  }

  async put(url: string, body: any = {}, options?: AxiosRequestConfig) {
    try {
      const endpoint = this.endpoint(url);
      const response = await this.client.put(endpoint, body, options);
      return response.data;
    } catch (error: any) {
      console.log(error);
      this.errorHandler({ url, body, options }, error);
    }
  }

  async delete(url: string, options?: AxiosRequestConfig) {
    try {
      const endpoint = this.endpoint(url);
      const response = await this.client.delete(endpoint, options);
      return response.data;
    } catch (error: any) {
      console.log(error);
      this.errorHandler({ url, options }, error);
    }
  }
}

const client = new Axios();
export default client;
