import { observer } from 'mobx-react';
import * as React from 'react';
import Spinner from '~/view/components/Controls/Spinner/Spinner';
import Icon from '~/view/components/Controls/Icon/Icon';
import { ICONS } from '~/constants/icons';
import styles from '~/constants/styles';
import { localesStore } from '~/mobx';
import { IPagination } from '~/types/main';
import './style.scss';

interface IPaginationProps {
  pagination: IPagination<any>;
  onChange: (page: number) => Promise<void>;
  overlayClassName?: string;
}

class Pagination extends React.Component<IPaginationProps> {
  decrease = (index: number) => {
    this.props.onChange(this.props.pagination.current_page - index);
  };

  increase = (index: number) => {
    this.props.onChange(this.props.pagination.current_page + index);
  };

  renderButton = ({ value, label, active }: { value: number; label: string; active: boolean }) => {
    return (
      <button
        key={value}
        className={`pagination__button ${active ? 'pagination__button_active' : ''} family-medium color-dark text-size-14 mr-2 px-2`}
        onClick={() => {
          this.props.onChange(value);
        }}>
        {label}
      </button>
    );
  };

  public render() {
    const { pagination, overlayClassName = '' } = this.props;
    return (
      <div className="pagination d-flex flex-row justify-content-center">
        {pagination.loading && !pagination.total ? null : (
          <>
            <button
              className={`pagination__button family-medium color-dark text-size-14 mr-2 px-2 `}
              onClick={() => this.decrease(1)}
              disabled={pagination.current_page <= 1}>
              <Icon shouldBeReversed={localesStore.direction === 'rtl'} name={ICONS.SliderLeft} size={14} />
            </button>
            {generatePageItems(this.props.pagination.last_page, this.props.pagination.current_page, 11).map(this.renderButton)}
            <button
              className={`pagination__button family-medium color-dark text-size-14 mr-2 px-2 `}
              onClick={() => this.increase(1)}
              disabled={pagination.current_page >= pagination.last_page}>
              <Icon shouldBeReversed={localesStore.direction === 'rtl'} name={ICONS.SliderRight} size={14} />
            </button>
          </>
        )}

        {pagination.refreshing ? (
          <div className={`scroll-list-refreshing d-flex ${overlayClassName}`}>
            <div className="d-flex flex-grow-1 justify-content-center m-auto">
              <Spinner color={styles.colors.mainblue} size={24} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default observer(Pagination);

const MINIMAL_PAGE_ITEM_COUNT = 11;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function generatePageItems(total: number, current: number, width: number) {
  if (width < MINIMAL_PAGE_ITEM_COUNT) {
    throw new Error(`Must allow at least ${MINIMAL_PAGE_ITEM_COUNT} page items`);
  }
  if (width % 2 === 0) {
    throw new Error(`Must allow odd number of page items`);
  }
  if (total < width) {
    return [...new Array(total)].map((_, index) => ({
      value: index + 1,
      label: String(index + 1),
      active: current === index + 1,
    }));
  }
  const left = Math.max(0, Math.min(total - width, current - Math.floor(width / 2) - 1));
  const items: { value: number; label: string | number; active: boolean }[] = new Array(width);
  for (let i = 0; i < width; i += 1) {
    items[i] = { value: i + left, label: i + left, active: false };
  }
  // replace non-ending items with placeholders
  if (items[0].value > 0) {
    items[0] = { value: 0, label: 0, active: false };
    // prev-more
    items[1] = { value: left + 1, label: '...', active: false };
  }
  if (items[items.length - 1].value < total - 1) {
    items[items.length - 1] = { value: total - 1, label: total - 1, active: false };
    // next-more
    items[items.length - 2] = { value: left + width - 2, label: '...', active: false };
  }
  return items.map(({ value, label }) => ({
    label: typeof label === 'number' ? String(label + 1) : label,
    value: value + 1,
    active: current === value + 1,
  }));
}
