import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SCREENS } from '~/constants/screens';
import { userStore } from '~/mobx';
import ButtonOld from '~/view/components/Controls/ButtonOld/ButtonOld';

class DashboardScreen extends Component {
  render() {
    return (
      <div className="d-flex flex-column full-width">
        DASHBOARD
        <div className="d-flex">
          <Link to={SCREENS.GUIDELINE()}>
            <ButtonOld className="bg-mainblue" title={'TO GUIDELINE'} />
          </Link>
          <ButtonOld className="bg-mainblue" title={'LOGOUT'} onClick={userStore.logout} />
        </div>
      </div>
    );
  }
}

export default observer(DashboardScreen);
