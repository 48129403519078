import { SORT_DIR } from '~/constants/main';

export type TLog = {
  id: number;
  created_at: string;
  author: string;
  object: string;
  event: string;
  event_txt: string;
  old_value_txt: string;
  old_value: string;
  new_value_txt: string;
  new_value: string;
};

export enum LOG_SORT_COLUMN {
  DATE = 'created_at',
  AUTHOR = 'author_id',
  OBJECT = 'object_id',
  EVENT = 'event',
  OLD_VALUE = 'old_value',
  NEW_VALUE = 'new_value',
}

export interface ILogSorting {
  [LOG_SORT_COLUMN.DATE]?: SORT_DIR;
  [LOG_SORT_COLUMN.AUTHOR]?: SORT_DIR;
  [LOG_SORT_COLUMN.OBJECT]?: SORT_DIR;
  [LOG_SORT_COLUMN.EVENT]?: SORT_DIR;
  [LOG_SORT_COLUMN.OLD_VALUE]?: SORT_DIR;
  [LOG_SORT_COLUMN.NEW_VALUE]?: SORT_DIR;
}

export interface IFilter {
  value: string | number;
  name: string;
  active: boolean;
}

export interface IDateFilter {
  from_date: string | null;
  to_date: string | null;
}

export type ILogFilters = {
  [LOG_SORT_COLUMN.DATE]: IDateFilter;
  [LOG_SORT_COLUMN.AUTHOR]: IFilter[];
  [LOG_SORT_COLUMN.OBJECT]: IFilter[];
  [LOG_SORT_COLUMN.EVENT]: IFilter[];
  [LOG_SORT_COLUMN.OLD_VALUE]: IFilter[];
  [LOG_SORT_COLUMN.NEW_VALUE]: IFilter[];
};

export type ILogSelectedFilters = {
  [LOG_SORT_COLUMN.DATE]: IDateFilter;
  [LOG_SORT_COLUMN.AUTHOR]: any[];
  [LOG_SORT_COLUMN.OBJECT]: any[];
  [LOG_SORT_COLUMN.EVENT]: any[];
  [LOG_SORT_COLUMN.OLD_VALUE]: any[];
  [LOG_SORT_COLUMN.NEW_VALUE]: any[];
};

export type IWasSelectedFilters = {
  [LOG_SORT_COLUMN.DATE]: boolean;
  [LOG_SORT_COLUMN.AUTHOR]: boolean;
  [LOG_SORT_COLUMN.OBJECT]: boolean;
  [LOG_SORT_COLUMN.EVENT]: boolean;
  [LOG_SORT_COLUMN.OLD_VALUE]: boolean;
  [LOG_SORT_COLUMN.NEW_VALUE]: boolean;
};
