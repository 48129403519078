import './style.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { generatePath } from 'react-router-dom';
import DspList from './containers/DspList/DspList';
import DspOrganizations from './containers/DspOrganizations/DspOrganizations';
import FloatingButton from '~/view/components/FloatingButton/FloatingButton';
import { DSP_GROUPS } from '~/constants/dsp';
import { dspStore } from '~/mobx';
import { localesStore } from '~/mobx';
import HeaderTitle from '~/view/components/Headers/components/HeaderTitle/HeaderTitle';
import Headers from '~/view/components/Headers/Headers';
import { Table } from '~/view/components/Table/Table';
import DspStatuses from '~/view/screens/DspManagementScreen/containers/DspStatuses/DspStatuses';
import { RouterComponentProps } from '~/types/main';
import { withRouter } from '~/utils';

type IProps = RouterComponentProps<{ id?: string; type: string }, {}>;

type State = {
  selectedId: string | undefined;
  dspStatus: DSP_GROUPS;
};

class DspManagementScreen extends Component<IProps, State> {
  state = {
    selectedId: this.props.router.params.id,
    dspStatus: (this.props.router.params.type || 'all') as DSP_GROUPS,
  };

  componentDidMount = () => {
    dspStore.getDsps();
  };

  handleChangeSelected = (id: string) => {
    this.setState({ selectedId: id });

    this.props.router.navigate(
      generatePath('/dsp_management/:type/:id', {
        type: this.props.router.params.type,
        id: String(id),
      }),
    );
  };

  handleChangeStatus = async (status: string) => {
    const firstDsp = dspStore.dsps[0];

    this.setState({ dspStatus: status as DSP_GROUPS });

    this.props.router.navigate(
      generatePath('/dsp_management/:type/:id', {
        type: status,
        id: firstDsp ? String(firstDsp.id) : undefined,
      }),
    );

    if (firstDsp) {
      await dspStore.getDsp(firstDsp.id);

      this.setState({ selectedId: firstDsp.id });
    }
  };

  render() {
    return (
      <div className="d-flex flex-column full-width pb-4 pl-4">
        <Headers>
          <HeaderTitle title={localesStore.t('dsp_management')} />
        </Headers>

        <div className="d-flex flex-column flex-grow-1 pt-6">
          <div className="dsp-screen__tabs-container d-flex flex-row justify-content-between pr-4">
            <DspStatuses status={this.state.dspStatus} onStatusChange={this.handleChangeStatus} />
          </div>

          <Table>
            <React.Fragment>
              <div className="d-flex flex-row flex-grow-1">
                <DspList selectedId={this.state.selectedId} onSelect={this.handleChangeSelected} />
                {dspStore.dsps.length || dspStore.dsp ? <DspOrganizations /> : null}
              </div>
            </React.Fragment>
          </Table>
        </div>
        {dspStore.dsps.length ? (
          <FloatingButton
            title={localesStore.t('view_log_button')}
            onClick={() => {
              const state = {
                type: this.state.dspStatus as string,
              };

              // @ts-ignore
              this.props.router.navigate(`/${dspStore.dsp?.id || dspStore.dsps[0].id}/log`, { state });
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(observer(DspManagementScreen));
