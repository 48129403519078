import moment from 'moment';
import { TTrackingRequest, TTrackingRequestTimeline, TTrackingRequestTimelineItem } from '~/constants/tracking';

const formatStr = 'YYYY-MM-DD HH:mm:ss';

export const utcLocalizeAndFormat = (utcTime: string, formatTemplate: string) => {
  return moment.utc(utcTime).add(moment().utcOffset(), 'minutes').format(formatTemplate);
};

export const utcLocalizeAndFormatOrNull = (utcTime: string | null, formatTemplate: string) => {
  if (utcTime === null) return null;
  return utcLocalizeAndFormat(utcTime, formatTemplate);
};

export const addTimeZoneToRequest = (request: TTrackingRequest) => {
  return {
    ...request,
    request: {
      ...request.request,
      external_created_at: utcLocalizeAndFormat(request.request.external_created_at, formatStr),
      created_at: utcLocalizeAndFormat(request.request.created_at, formatStr),
      assigned_at: utcLocalizeAndFormatOrNull(request.request.assigned_at, formatStr),
      picked_up_at: utcLocalizeAndFormatOrNull(request.request.picked_up_at, formatStr),
      final_at: utcLocalizeAndFormatOrNull(request.request.final_at, formatStr),
      due_at: utcLocalizeAndFormat(request.request.due_at, formatStr),
      due_time: utcLocalizeAndFormat(request.request.due_time, formatStr),
    },
  };
};

export const addTimeZoneToTimeline = (timeline: TTrackingRequestTimeline): TTrackingRequestTimeline => {
  return timeline.map((item: TTrackingRequestTimelineItem) => {
    item.created_at = moment.utc(item.created_at).local().format(formatStr);
    return item;
  });
};
