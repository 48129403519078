import { DrawingManager } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { deliveryZonesStore } from '~/mobx';

const DeiveryZoneDrawingTools = observer(() => {
  const onPolygonComplete = useCallback((polygon: google.maps.Polygon) => {
    const bounds = polygon
      .getPath()
      .getArray()
      .map<google.maps.LatLngLiteral>((latLng) => latLng.toJSON());

    polygon.setMap(null);

    deliveryZonesStore.editDraftZone('bounds', bounds);
  }, []);

  if (!deliveryZonesStore.draft) return null;

  if (deliveryZonesStore.draft?.bounds.length) {
    return null;
  }

  return (
    <DrawingManager
      options={{ drawingControl: false }}
      onPolygonComplete={onPolygonComplete}
      drawingMode={google?.maps?.drawing?.OverlayType.POLYGON}
    />
  );
});

export default DeiveryZoneDrawingTools;
