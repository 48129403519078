import './style.scss';

import { observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { IconsId } from 'taker-web-ui/dist/assets/fonts/Icons/icons';
import { localesStore } from '~/mobx';
import Icon from '~/view/components/Controls/Icon/Icon';
import { userStore } from '~/mobx';

type TLink = {
  icon?: IconsId;
  iconSize: number;
  className?: string;
  name: string;
  to: string;
  path: string;
  permissions: string[];
  leftComponent?: React.ReactElement | null;
  rightComponent?: React.ReactElement | null;
  onClick?: () => void;
  roles: number[];
  isActive?: boolean;
};

class NavigationLink extends React.Component<TLink> {
  static defaultProps = {
    link: '',
    iconSize: 22,
    className: '',
    leftComponent: null,
    rightComponent: null,
    roles: [],
    isActive: false,
  };

  public render() {
    if (!userStore.hasPermissions(this.props.permissions) || !userStore.hasRole(this.props.roles)) return null;
    const { className = '', isActive } = this.props;

    return (
      <NavLink to={this.props.to} className={() => (isActive ? 'active sidebar_link py-3' : `sidebar_link py-3`)} onClick={this.props.onClick}>
        <div className="d-flex flex-grow-1 sidebar_link-content align-items-center">
          {this.props.leftComponent}
          {this.props.icon ? (
            <div className={'sidebar_link-icon ml-4'}>
              <Icon name={this.props.icon} size={this.props.iconSize || 22} className={className} />
            </div>
          ) : null}
          <p className={`sidebar_link-text ml-4 family-semibold text-size-18 `}>{localesStore.t(this.props.name)}</p>
          {this.props.rightComponent}
        </div>
      </NavLink>
    );
  }
}

export default observer(NavigationLink);
