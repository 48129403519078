import { Component } from 'react';
import { userStore } from '~/mobx';

interface Props {
  permissions: string[];
  children?: React.ReactNode;
}

export default class Permission extends Component<Props, {}> {
  render() {
    const { permissions } = this.props;
    if (!userStore.hasPermissions(permissions)) return null;

    return this.props.children;
  }
}
