import './style.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { dspStore } from '~/mobx';
import { IDsp } from '~/types/dsp';

interface Props {
  dsp?: IDsp;
  index: number;
  selected: boolean;
  onSelect: (dsp: IDsp) => void;
}

class DspListItem extends Component<Props> {
  componentDidMount() {
    if (this.props.selected) {
      this.selectDsp();
    }
  }

  selectDsp = () => {
    if (!this.props.dsp) return;
    dspStore.setActiveDsp(this.props.dsp);
    this.props.onSelect(this.props.dsp);
  };

  active = () => {
    // if (dspStore.dsp && dsp &&  && dspStore.dsp.id === dsp.id) {
    if (this.props.selected) {
      return 'active';
    }

    return '';
  };

  render() {
    const { dsp, index } = this.props;

    return (
      <div id={`dsp-${dsp?.id || index}`} className={`dsps-dsp ${this.active()}`} onClick={this.selectDsp}>
        <div className="d-flex flex-row justify-content-between align-items-center mb-1">
          <p className="text-size-22 dsps-dsp-subtitle family-medium">{dsp?.provider || <Skeleton width={100} />}</p>

          <p className="dsps-dsp-text text-size-14 dsps-dsp-subtitle text-size-12 family-medium">
            {dspStore.approvedTxt(dsp?.approved) || <Skeleton width={50} />}
          </p>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center mt-2">
          <div className="dsps-dsp-info">
            <p
              className={`dsps-dsp-status dsps-dsp-text text-size-14 uppercase family-medium dsps-dsp-working-${dspStore.workingColor(
                dsp?.working,
              )}`}>
              {dspStore.workingTxt(dsp?.working) || <Skeleton width={100} />}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(DspListItem);
