import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Icon from '~/view/components/Controls/Icon/Icon';
import InputOld from '~/view/components/Controls/InputOld/InputOld';
import TimePicker from '~/view/components/Controls/TimePicker/TimePicker';
import ReactCalendar from '~/view/components/ReactCalendar/ReactCalendar';
import ModalPopup from '~/view/components/ModalPopup/ModalPopup';
import { debounce } from '~/utils/perfomance';
import { localesStore } from '~/mobx';
import { LOCALES, LOCALES_DOUBLE } from '~/constants/main';
import { ICONS } from '~/constants/icons';
import './style.scss';

interface IProps {
  date: Date;
  onDateChange?: (date: Date) => void;
  error?: boolean;
  disabled: boolean;
  className?: string;
}
interface IState {
  date: Moment;
  time: string;
  visible: boolean;
}
@observer
export default class DateTimePicker extends Component<IProps, IState> {
  static defaultProps = {
    date: new Date(),
  };
  modal: ModalPopup | null = null;
  state: IState = {
    date: moment(this.props.date),
    time: moment(this.props.date).format('hh:mm A'),
    visible: false,
  };

  componentDidUpdate(prevProps: any) {
    const isDateDiffMoreOneMinute = moment(prevProps.date).diff(moment(this.props.date), 's') !== 0;

    if (isDateDiffMoreOneMinute) {
      const date = moment(this.props.date);
      this.setState({ date }, () => this.onTimeChanged(this.props.date));
    }
  }

  changeVisibility = (visible: boolean) => () => {
    if (visible) {
      this.modal?.openModalPopup();
    } else {
      this.modal?.closeModalPopup();
    }
  };

  changeDate = (value: Date) => {
    this.setState(
      {
        date: moment(`${value.getDate()}:${value.getMonth() + 1}:${value.getFullYear()} ${this.state.time}`, `DD:MM:YYYY hh:mm A`),
      },
      this.onDateChange,
    );
  };

  recalculateDate = () => {
    const date = moment(
      `${this.state.date.get('date')}:${this.state.date.get('month') + 1}:${this.state.date.get('years')} ${this.state.time}`,
      `DD:MM:YYYY hh:mm A`,
    );

    this.setState({ date });
    if (this.props.onDateChange) {
      this.props.onDateChange(date.toDate());
    }
  };

  onDateChange = debounce(this.recalculateDate, 300);

  onTimeChanged = (time: Date) => {
    this.setState({ time: moment(time).format('hh:mm A') }, this.recalculateDate);
  };

  render() {
    const { className = '' } = this.props;
    return (
      <div className={`datetime-picker ${className}`}>
        <ModalPopup
          autoClose={true}
          ref={(modal) => (this.modal = modal)}
          vertical="center"
          content={
            <div className="datetime-picker-modal bg-white animate__faster animate__animated animate__fadeInRight p-2">
              <div className="d-flex px-4 pt-4 pb-2 flex-1 full-width">
                <TimePicker onTimeChanged={this.onTimeChanged} date={this.props.date} />
              </div>
              <ReactCalendar
                value={this.props.date}
                //   maxDate={new Date()}
                // minDate={new Date()}
                locale={localesStore.language === LOCALES.EN ? LOCALES_DOUBLE.en : LOCALES_DOUBLE.ar}
                selectRange={false}
                onChange={this.changeDate}
                //   onClickDay={this.clickDay}
                defaultValue={this.props.date}
                // minDetail="decade"
                formatMonth={(locale: string, date: Date) => moment(date).format('MMM')}
                formatShortWeekday={(locale: string, date: Date) => moment(date).format('dd')}
              />
            </div>
          }>
          <div
            onClick={this.changeVisibility(!this.props.disabled)}
            className={`d-flex datetime-picker-input ${this.props.disabled ? 'datetime-picker-input-disabled' : ''}`}>
            <InputOld
              disabled={this.props.disabled}
              error={this.props.error}
              value={moment(this.state.date).format('DD/MM/YYYY hh:mm A')}
              // onBlur={this.changeVisibility(false)}
              placeholder={localesStore.t('choose_date')}
              className="input-mini family-medium text-size-16"
            />
            <Icon size={24} name={ICONS.Calendar} className="datetime-picker-calendar color-mainblue" />
          </div>
        </ModalPopup>
      </div>
    );
  }
}
