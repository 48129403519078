import './style.scss';

import { Component, PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ICONS } from '~/constants/icons';
import { localesStore } from '~/mobx';
import theme from '~/utils/styles';
import Icon from '~/view/components/Controls/Icon/Icon';

export default class NoResults extends Component<PropsWithChildren<any>, {}> {
  render() {
    return (
      <div className="my-auto mx-auto text-center dsp-organizations-no-results">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Skeleton width={90} height={15} style={{ marginRight: 7, backgroundColor: theme.colors.gray }} />
          <Icon name={ICONS.Search} color={theme.colors.gray} />
        </div>
        <Skeleton width={109} height={15} style={{ marginLeft: 10, marginTop: 10, backgroundColor: theme.colors.gray, opacity: 0.5 }} />
        <Skeleton
          width={109}
          height={15}
          style={{ marginLeft: 25, marginTop: 10, marginBottom: 30, backgroundColor: theme.colors.gray, opacity: 0.3 }}
        />
        {localesStore.t('no_results_found')}
        {this.props.children}
      </div>
    );
  }
}
