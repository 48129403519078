import './style.scss';

import React from 'react';

import DeliveryZonesDraft from '../DeliveryZonesDraft/DeliveryZonesDraft';
import { DeliveryZonesSidebarList } from '../DeliveryZonesSidebarList/DeliveryZonesSidebarList';

export const DeliveryZonesSidebar = () => {
  return (
    <div className="delivery-zone__sidebar px-1 py-3">
      <DeliveryZonesSidebarList />
      <DeliveryZonesDraft />
    </div>
  );
};
