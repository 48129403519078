import { TRACKING_GROUPS } from '~/constants/tracking';
import {
  IRequestsFilters,
  IRequestsSelectedFilters,
  IRequestsSorting,
  ITimeFilter,
  IWasRequestsSelectedFilters,
  REQ_SORT_COL,
} from '~/types/requests';

export interface IFilter {
  value: string | number;
  name: string;
  active: boolean;
}

export interface IDateFilter {
  from_date: string | null;
  to_date: string | null;
}

export const defaultRequestsSorting: IRequestsSorting = {};

export const defaultRequestsFilters: IRequestsFilters = {
  [REQ_SORT_COL.DATE]: {
    from_date: null,
    to_date: null,
  },
  [REQ_SORT_COL.TIME]: {
    start: null,
    end: null,
  },
  [REQ_SORT_COL.STATUS]: [],
  [REQ_SORT_COL.REFERENCE]: [],
  [REQ_SORT_COL.ORGANIZATION]: [],
  [REQ_SORT_COL.BRANCH]: [],
  [REQ_SORT_COL.REQUEST]: [],
  [REQ_SORT_COL.DSP]: [],
  [REQ_SORT_COL.CITY]: [],
  category: TRACKING_GROUPS.ALL,
  search: '',
};

export const defaultSelectedFilters: IRequestsSelectedFilters = {
  [REQ_SORT_COL.DATE]: {
    from_date: null,
    to_date: null,
  } as IDateFilter,
  [REQ_SORT_COL.TIME]: {
    start: null,
    end: null,
  } as ITimeFilter,
  [REQ_SORT_COL.STATUS]: [],
  [REQ_SORT_COL.REFERENCE]: [],
  [REQ_SORT_COL.ORGANIZATION]: [],
  [REQ_SORT_COL.BRANCH]: [],
  [REQ_SORT_COL.REQUEST]: [],
  [REQ_SORT_COL.DSP]: [],
  [REQ_SORT_COL.CITY]: [],
};

export const wasDefaultSelectedFilters: IWasRequestsSelectedFilters = {
  [REQ_SORT_COL.TIME]: false,
  [REQ_SORT_COL.DATE]: false,
  [REQ_SORT_COL.STATUS]: false,
  [REQ_SORT_COL.REFERENCE]: false,
  [REQ_SORT_COL.ORGANIZATION]: false,
  [REQ_SORT_COL.BRANCH]: false,
  [REQ_SORT_COL.REQUEST]: false,
  [REQ_SORT_COL.DSP]: false,
  [REQ_SORT_COL.CITY]: false,
};

export const refreshInterval = 60_000;
