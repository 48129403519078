export enum LOCALES {
  EN = 'en',
  AR = 'ar',
  en = 'EN',
  ar = 'AR',
}

export enum FORM_MODE {
  HIDDEN,
  VIEW,
  EDIT,
  CREATE,
}

export const LOCALES_DOUBLE = {
  en: LOCALES.EN + '-' + LOCALES.en,
  ar: LOCALES.AR + '-' + LOCALES.ar,
};

export const WINDOW_SIZES = {
  xs: 575,
  sm: 768,
  md: 991,
  lg: 1280,
  xl: 2000,
  // xxl: 991,
};

export const defaultPagination = {
  current_page: 1,
  last_page: 0,
  data: [],
  from: 0,
  to: 0,
  total: 0,
  loading: false,
  refreshing: false,
};

export enum PAYMENT_TYPES {
  CASH,
  ONLINE_PAYMENT,
  POINTS,
  TERMINAL,
}

export const PAYMENT_TYPES_LOCALES: Record<PAYMENT_TYPES, string> = {
  [PAYMENT_TYPES.CASH]: 'cash_payment_type',
  [PAYMENT_TYPES.ONLINE_PAYMENT]: 'online_payment_type',
  [PAYMENT_TYPES.POINTS]: 'points_payment_type',
  [PAYMENT_TYPES.TERMINAL]: 'terminal_payment_type',
};

export enum CURRENCIES {
  SAR = 'SAR',
  USD = 'USD',
}

export const DEBOUNCE_TIME = {
  SMALL: 100,
  MEDIUM: 300,
  LARGE: 700,
};

export enum SORT_DIR {
  ASC = 'asc',
  DESC = 'desc',
}

export const timezones = [
  {
    value: '0',
    label: 'GMT +0',
  },
  {
    value: '1',
    label: 'GMT +1',
  },
  {
    value: '2',
    label: 'GMT +2',
  },
  {
    value: '3',
    label: 'GMT +3',
  },
  {
    value: '4',
    label: 'GMT +4',
  },
  {
    value: '5',
    label: 'GMT +5',
  },
  {
    value: '6',
    label: 'GMT +6',
  },
  {
    value: '7',
    label: 'GMT +7',
  },
  {
    value: '8',
    label: 'GMT +8',
  },
  {
    value: '9',
    label: 'GMT +9',
  },
  {
    value: '10',
    label: 'GMT +10',
  },
  {
    value: '11',
    label: 'GMT +11',
  },
  {
    value: '12',
    label: 'GMT +12',
  },
  // {
  //   "value": '1',
  //   "label": "GMT -1"
  // },
  // {
  //   "value": '2',
  //   "label": "GMT -2"
  // },
  // {
  //   "value": '3',
  //   "label": "GMT -3"
  // },
  // {
  //   "value": '4',
  //   "label": "GMT -4"
  // },
  // {
  //   "value": '5',
  //   "label": "GMT -5"
  // },
  // {
  //   "value": '6',
  //   "label": "GMT -6"
  // },
  // {
  //   "value": '7',
  //   "label": "GMT -7"
  // },
  // {
  //   "value": '8',
  //   "label": "GMT -8"
  // },
  // {
  //   "value": '9',
  //   "label": "GMT -9"
  // },
  // {
  //   "value": '10',
  //   "label": "GMT -10"
  // },
  // {
  //   "value": '11',
  //   "label": "GMT -11"
  // },
  // {
  //   "value": '12',
  //   "label": "GMT -12"
  // }
];
