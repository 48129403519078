import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import { computed, observable, makeObservable } from 'mobx';
import React from 'react';
import { defaultCoordinates } from '~/constants/map';

const defaultOptions: GoogleMapProps['options'] = {
  draggable: true,
  zoomControl: false,
  fullscreenControl: false,
  panControl: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  rotateControl: false,
  streetViewControl: false,
  scrollwheel: true,
  gestureHandling: 'greedy',
  // disableDefaultUI: true,
};
const MAX_ZOOM = 16;
const MIN_ZOOM = 2;
export const DEFAULT_ZOOM = 2;

class MapStore {
  private maxZoom = MAX_ZOOM;
  private minZoom = MIN_ZOOM;
  public zoom = DEFAULT_ZOOM;
  public center = defaultCoordinates;
  public map: google.maps.Map | null = null;

  public ref: React.RefObject<GoogleMap> | null = React.createRef();

  public options: GoogleMapProps['options'] = defaultOptions;
  public handlers: GoogleMapProps = {
    onDragEnd: () => {
      const center = this.ref?.current?.state.map?.getCenter()?.toJSON();
      if (center) {
        this.center = center;
      }
    },
    onZoomChanged: () => {
      try {
        const zoom = this.ref?.current?.state.map?.getZoom();
        if (zoom) {
          this.zoom = zoom;
        }
      } catch (error) {
        console.info({ error });
      }
    },
  };

  constructor() {
    makeObservable(this, {
      map: observable,
      options: observable,
      handlers: observable,
      props: computed,
    });
  }

  get props(): GoogleMapProps {
    return {
      options: this.options,
      ...this.handlers,
    };
  }

  public fitMap = (bounds: google.maps.LatLngLiteral[]) => {
    const mapsBounds = new google.maps.LatLngBounds();
    for (const bound of bounds) {
      mapsBounds.extend(bound);
    }

    this.map?.fitBounds(mapsBounds, 100);
  };

  public setOption = (options: GoogleMapProps['options']) => {
    this.options = {
      ...this.options,
      ...options,
    };
  };

  public setZoom = (zoom: number) => {
    if (zoom > this.maxZoom) {
      zoom = this.maxZoom;
    }
    if (zoom < this.minZoom) {
      zoom = this.minZoom;
    }
    this.zoom = zoom;
    this.ref?.current?.state.map?.setZoom(zoom);
  };

  public setCenter = (lat: number, lng: number) => {
    const center = {
      lat,
      lng,
    };
    this.center = center;
    this.ref?.current?.state.map?.setCenter(center);
  };

  public setCamera = (lat: number, lng: number, zoom: number) => {
    if (!lat || !lng) return;
    const center = {
      lat,
      lng,
    };
    this.center = center;

    // setCamera is available only in beta version of google maps
    // this.ref?.current?.state.map?.moveCamera({
    //   zoom,
    //   center,
    // });

    this.setCenter(center.lat, center.lng);
    this.setZoom(zoom);
  };

  clear = () => {
    this.center = defaultCoordinates;
    this.maxZoom = MAX_ZOOM;
    this.minZoom = MIN_ZOOM;
    this.zoom = DEFAULT_ZOOM;
    this.options = defaultOptions;
  };

  reset = () => {
    this.setCenter(defaultCoordinates.lat, defaultCoordinates.lng);
    this.setZoom(4.5);
  };
}

export default MapStore;
