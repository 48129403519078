import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button } from 'taker-web-ui';
import CopyButton from '~/view/screens/DeliveryTrackingScreen/components/CopyButton/CopyButton';
import MapPopup from '~/view/components/Map/components/MapPopup/MapPopup';
import { RequestStatus, REQUESTS_STATUSES_LABELS, TTrackingSingleRequest } from '~/constants/tracking';
import { getOrderDspId, getRequestId, truncateLongString } from '~/utils/formatter';
import { formatPhoneNumber } from '~/utils/formatter';
import { localesStore, trackingStore } from '~/mobx';
import { showNAInsteadNull } from '~/utils';
import { userStore, messagesStore, modalStore } from '~/mobx';
import { appStore } from '~/mobx';
import './style.scss';

type Props = {
  opened: boolean;
  request: TTrackingSingleRequest | null;
};

const AdditionalInfoPopup = observer(({ opened, request: trackingRequest }: Props) => {
  const [fullOpened, setFullOpened] = useState(false);

  if (!trackingRequest) return null;

  const { dsp, request, organization, customer, driver } = trackingRequest;

  const handleRequest = () => {
    messagesStore.addMessage({
      type: 'modal',
      title: localesStore.t('you_sure_confirmation'),
      message: localesStore.t('request_delivery_confirmation'),
      options: [
        {
          text: localesStore.t('yes_button'),
          onPress: () => trackingStore.requestNextDsp(),
          appearance: 'primary',
        },
        {
          text: localesStore.t('no_button'),
          onPress: () => null,
          appearance: 'tertiary',
        },
      ],
    });
  };

  const handleCancelRequest = () => {
    messagesStore.addMessage({
      type: 'modal',
      title: localesStore.t('you_sure_confirmation'),
      message: localesStore.t('cancel_delivery_confirmation'),
      options: [
        {
          text: localesStore.t('yes_button'),
          onPress: () => trackingStore.cancelDelivery(),
          appearance: 'primary',
        },
        {
          text: localesStore.t('no_button'),
          onPress: () => null,
          appearance: 'tertiary',
        },
      ],
    });
  };

  const handleOpenPopup = () => {
    // open order info modal
    modalStore.openRequestInfoModal({
      id: '666',
    });
  };

  const hasDriver = Boolean(driver);

  const id = getOrderDspId(trackingRequest.dsp);

  if (typeof window.renderAssist === 'function') {
    setTimeout(() => {
      window.renderAssist();
    }, 0);
  }

  const isRequestDeliveryVisible =
    !appStore.settings?.on_takergo_assist || typeof request.status === undefined || [RequestStatus.CANCELED].includes(request.status);

  const isCancelDeliveryVisible = !appStore.settings?.on_takergo_assist;

  return (
    <MapPopup opened={opened} className={'addinfo-popup'} onToggleFullOpen={(value) => setFullOpened(value)}>
      <div className={'d-flex flex-row align-items-center justify-content-between mb-6'}>
        <div className={'header-info d-flex flex-row align-items-center'}>
          <div className={'text-size-22 text-uppercase family-bold'}>{truncateLongString(getRequestId(organization))}</div>
          <div>
            <CopyButton data={getRequestId(organization)} />
          </div>
          <div className={'dot'}>·</div>
          <div className={'text-size-18 text-uppercase'}>{localesStore.t(`${REQUESTS_STATUSES_LABELS[request.status as RequestStatus]}`)}</div>
        </div>
        <div className={'header-info d-flex flex-row align-items-center'}>
          {isRequestDeliveryVisible && (
            <Button
              onClick={handleRequest}
              loading={trackingStore.requestNextDspLoading}
              title={
                !trackingStore.requestNextDspLoading
                  ? localesStore.t('request_delivery_button')
                  : localesStore.t('request_delivery_button').split(' ')[0]
              }
            />
          )}
          {isCancelDeliveryVisible && (
            <Button
              onClick={handleCancelRequest}
              loading={trackingStore.deliveryCancelLoading}
              appearance="tertiary"
              title={
                !trackingStore.deliveryCancelLoading
                  ? localesStore.t('cancel_delivery_button')
                  : localesStore.t('cancel_delivery_button').split(' ')[0]
              }
            />
          )}
          {appStore.settings?.on_takergo_assist &&
            [
              RequestStatus.PENDING,
              RequestStatus.DSP_ISSUE,
              RequestStatus.NOT_SENT,
              RequestStatus.NO_STATUS,
              RequestStatus.ASSIGNED,
              RequestStatus.WAY_TO_STORE,
              RequestStatus.NEAR_STORE,
              RequestStatus.ARRIVED_AT_STORE,
              RequestStatus.WAY_TO_CUSTOMER,
              RequestStatus.NEAR_CUSTOMER,
              RequestStatus.ARRIVED_AT_CUSTOMER,
              RequestStatus.RETURNING,
            ].includes(request.status) && (
              <div
                key={request.id}
                data-widget-host="taker-assist"
                data-prop-title="TakerGo"
                data-prop-request-id={request.id}
                data-prop-user-token={userStore.user.token}
                data-prop-request-status={request.status}
                data-prop-organization-id={organization.id}
                data-prop-dsp-name={dsp.name}
                data-prop-source="TGo_dashboard"
                data-prop-lang={localesStore.language}
                data-prop-is-dashboard="1"
                data-prop-username={userStore.user.name}
              />
            )}
        </div>
      </div>
      <table className="tg">
        <thead>
          <tr>
            <th className={'text-size-18 font-weight-400 text-uppercase letter-spacing-12 color-gray2'}>{localesStore.t('delivery_from')}</th>
            <th className={'text-size-18 font-weight-400 text-uppercase letter-spacing-12 color-gray2'}>{localesStore.t('delivery_to')}</th>
            {hasDriver && (
              <th className={'text-size-18 font-weight-400 text-uppercase letter-spacing-12 color-gray2'}>{localesStore.t('driver_info')}</th>
            )}
            <th className={'text-size-18 font-weight-400 text-uppercase letter-spacing-12 color-gray2'}>{localesStore.t('dsp_info')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0lax vertical-align-center-row">
              <div className={`name-title text-size-16 family-bold mb-2`}>
                {organization.name} <br />
                {organization.branch ? `${organization.branch}` : ''}
              </div>
            </td>
            <td className="tg-0lax vertical-align-center-row">
              <div className={`name-title text-size-16 family-bold mb-2`}>{customer.name}</div>
            </td>
            {hasDriver && (
              <td className="tg-0lax vertical-align-center-row">
                <div className={`name-title text-size-16 family-bold mb-2`}>{driver?.name}</div>
              </td>
            )}
            <td className="tg-0lax vertical-align-center-row">
              <div className={`name-title text-size-16 family-bold mb-2`}>{dsp.name}</div>
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">
              <div className={`text-size-14 family-regular letter-spacing-5 ${fullOpened ? '' : 'mb-2'}`}>
                {`${organization.phone ? formatPhoneNumber(organization.phone) : ''}`}
              </div>
            </td>
            <td className="tg-0lax">
              <div className={`text-size-14 family-regular letter-spacing-5 ${fullOpened ? '' : 'mb-2'}`}>
                {`${customer.phone ? formatPhoneNumber(customer.phone) : ''}`}
              </div>
            </td>
            {hasDriver && (
              <div className={`text-size-14 family-regular letter-spacing-5 ${fullOpened ? '' : 'mb-2'}`}>
                {`${driver?.phone ? formatPhoneNumber(driver?.phone) : ''}`}
              </div>
            )}
            <td className="tg-0lax">
              <div className={'d-flex flex-row align-items-center'}>
                <div
                  className={'text-size-14 family-regular letter-spacing-5 color-mainblue cursor-pointer text-uppercase'}
                  onClick={handleOpenPopup}>
                  {truncateLongString(showNAInsteadNull(id))}
                </div>
                <div className={'ml-2'}>{id !== '' && <CopyButton data={String(id)} />}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">
              <div className={`text-size-14 family-regular letter-spacing-5 ${fullOpened ? '' : 'hidden'}`}>{organization.address}</div>
            </td>
            <td className="tg-0lax">
              <div className={`text-size-14 family-regular letter-spacing-5 ${fullOpened ? '' : 'hidden'}`}>{customer.address}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </MapPopup>
  );
});

export default AdditionalInfoPopup;
