import React, { useCallback } from 'react';
import './style.scss';
interface Props {
  onSelect?: (color: string) => void;
  color: string;
  selected?: boolean;
  size?: number;
  className?: string;
  children?: React.ReactNode;
}

export const ColorBox: React.FunctionComponent<Props> = ({ size = 24, className = '', ...props }) => {
  const onSelect = useCallback(() => {
    if (props?.onSelect) {
      props?.onSelect(props.color);
    }
  }, [props.onSelect, props.color]);

  return (
    <div
      onClick={onSelect}
      style={{ backgroundColor: props.color, width: size, height: size, minWidth: size, borderRadius: size / 3 }}
      className={`color-box border-mainblue ${props.selected ? 'active' : ''} ${className}`}>
      {props.children}
    </div>
  );
};
